
import { defineComponent, onMounted, ref } from "vue";
import { selectionService } from "@/services";
import { get, set } from "lodash";
import { helper } from "@/utils/helper";
import { useStore } from "@/store";
import { generateBlobRadius } from "@/utils/generate-blob-radius";

export default defineComponent({
  name: "InProcessOrdersWidget",
  setup() {
    const store = useStore();
    const themeColor = "#bcffbc";
    const invertThemeColor = helper.invertColor(themeColor);

    const loading = ref<boolean>(false);
    const count = ref<number>(0);

    const blobRadius = generateBlobRadius();

    const fetch = () => {
      const where: Record<string, any> = {
        status: "PREPARING",
      };

      if (store.getters["auth/isAdmin"]) {
        set(where, "site", store.getters["system/site"]);
      }
      loading.value = true;
      selectionService
        .fetch({
          count: {
            "@source": "order",
            "@count": true,
            "@group": ["order_group_id"],
            "@select": ["order_group_id"],
            "@where": where,
          },
        })
        .then((result) => {
          if (result.kind === "ok") {
            count.value = get(result.data, "count") || 0;
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    onMounted(() => {
      fetch();
    });

    return {
      loading,
      count,
      themeColor,
      invertThemeColor,
      blobRadius,
    };
  },
});
