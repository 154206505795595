
import { inject, ref, onMounted, defineComponent } from "vue";
import { init } from ".";

export default defineComponent({
  name: "Dropzone",
  directives: {
    fileUpload: {
      mounted(el, { value }) {
        init(
          el,
          value.options,
          value.uploading,
          value.uploaded,
          value.completed
        );
      },
      unmounted(el) {
        if (el.dropzone) el.dropzone.destroy();
      },
    },
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    uploading: {
      type: Function,
      default: () => null,
    },
    uploaded: {
      type: Function,
      default: () => null,
    },
    completed: {
      type: Function,
      default: () => null,
    },
    refKey: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const fileUploadRef = ref();
    const bindInstance = () => {
      if (props.refKey) {
        const bind = inject(`bind[${props.refKey}]`);
        if (typeof bind === "function") {
          bind(fileUploadRef.value);
        }
      }
    };

    onMounted(() => {
      bindInstance();
    });

    return {
      props,
      ...context,
      fileUploadRef,
    };
  },
});
