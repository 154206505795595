import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";
import { findIndex, get } from "lodash";

// Getters types
export type Getters = {
  token(state: LocalState): string | null;
  profile(state: LocalState): any;
  visibleSplashScreen(state: LocalState): boolean;
  isAdmin(state: LocalState): boolean;
  isStaff(state: LocalState): boolean;
  isMerchant(state: LocalState): boolean;
  isSiteAdmin(state: LocalState, getters: Getters): boolean;
  merchantId(state: LocalState): any;
  getRoleType(state: LocalState): any;
  getSiteKey(state: LocalState, getters: Getters, rootState: RootState): any;
  getPermissions(state: LocalState): any[];
  hasPermission(
    state: LocalState,
    getters: Getters
  ): (permission: any) => boolean;
  hasPermissionWithMerchant(
    state: LocalState,
    getters: Getters
  ): (permission: any) => boolean;
  hasPermissionWithSiteAdmin(
    state: LocalState,
    getters: Getters
  ): (permission: any) => boolean;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  token: (state) => {
    return state.token;
  },
  profile: (state) => {
    return state.profile;
  },
  visibleSplashScreen: (state) => {
    return state.visibleSplashScreen;
  },
  isAdmin: (state) => {
    return get(state.profile, "type") === "admin";
  },
  isSiteAdmin: (state, getters) => {
    return !!getters["isAdmin"] || get(state.profile, "site_admin", false);
  },
  isMerchant: (state) => {
    return get(state.profile, "type") === "merchant";
  },
  isStaff: (state) => {
    return get(state.profile, "type") === "staff";
  },
  getRoleType: (state) => {
    return get(state.profile, "type");
  },
  getPermissions: (state) => {
    return get(state.profile, "permissions") || [];
  },
  hasPermission: (state, getters) => (permission) => {
    if (getters["isAdmin"]) return true;
    const permissions = get(state.profile, "permissions") || [];
    return permissions.indexOf(permission) > -1;
  },
  hasPermissionWithMerchant: (state, getters) => (permission) => {
    if (getters["isMerchant"]) return true;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return getters["hasPermission"](permission) as boolean;
  },
  hasPermissionWithSiteAdmin: (state, getters) => (permission) => {
    if (getters["isSiteAdmin"]) return true;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return getters["hasPermission"](permission) as boolean;
  },
  merchantId: (state) => {
    return get(state.profile, "merchant_id", null);
  },
  getSiteKey: (state, getters, rooState) => {
    if (!getters["isSiteAdmin"]) return 1;
    const merchantId = getters["merchantId"];
    const index = findIndex(rooState.system.sites, { merchant_id: merchantId });
    return get(rooState.system.sites, `${index}.key`) || 1;
  },
};
