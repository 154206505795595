
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  GeneralApiResponse,
  stockGroupCodeDefinitions,
  StockGroupCodeDefinitionInterface,
  GetStockGroupCodeDefinitionResult,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueButton from "@/components/form-elements/button/Main.vue";
import StockGroupCodeGeneralInformation from "./components/GeneralInformation.vue";

export default defineComponent({
  name: "StockGroupCodeCreateOrUpdate",
  components: {
    StockGroupCodeGeneralInformation,
    VueButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const id = computed(() => get(route.params, "id"));

    const {
      data: merchant,
      loading,
      fetch,
    } = useReadRecord<
      StockGroupCodeDefinitionInterface,
      GetStockGroupCodeDefinitionResult
    >({
      id: id.value,
      serviceMethod: stockGroupCodeDefinitions.show,
      success(result) {
        if (result.kind === "ok") {
          setQuery(result.data);
        }
      },
      fail() {
        router.go(-1);
      },
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<
        StockGroupCodeDefinitionInterface,
        GeneralApiResponse<GetStockGroupCodeDefinitionResult>
      >({
        query: {
          code: "",
          merchant_id: "",
          title: "",
          description: "",
        },
        storeServiceMethod: stockGroupCodeDefinitions.store,
        updateServiceMethod: stockGroupCodeDefinitions.update,
        success(result) {
          if (result.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "Stok Grup Kodu Kaydedildi!",
            });
            router.go(-1);
          }
        },
      });

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) fetch(id.value);
    });

    return {
      loading,
      processing,
      query,
      merchant,
      validationErrors,
      onSubmit,
    };
  },
});
