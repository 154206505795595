
import { defineComponent } from "vue";
import { GetMerchant } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";

export default defineComponent({
  name: "MerchantSideNavigation",
  props: {
    merchant: {
      type: Object as () => GetMerchant,
      default: () => ({}),
    },
    changeView: {
      type: Function,
      default: () => null,
    },
    activeView: {
      type: String,
      default: () => null,
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
});
