export default {
  order: {
    value: "data.order",
    title: "Sipariş",
    children: [
      {
        value: "id",
        key: "id",
        title: "Sipariş Numarası",
      },
      {
        value: "piece",
        key: "total_piece",
        title: "Toplam Parça Sayısı",
      },
      {
        value: "old_net_total",
        key: "old_net_total",
        title: "Eski Ara Toplam",
      },
      {
        value: "net_total",
        key: "net_total",
        title: "Ara Toplam",
      },
      {
        value: "old_total_vat",
        key: "old_total_vat",
        title: "Eski KDV Toplam",
      },
      {
        value: "total_vat",
        key: "total_vat",
        title: "KDV Toplam",
      },
      {
        value: "",
        key: "total_vat_1",
        title: "%1 KDV Toplam",
      },
      {
        value: "",
        key: "total_vat_8",
        title: "%8 KDV Toplam",
      },
      {
        value: "",
        key: "total_vat_10",
        title: "%10 KDV Toplam",
      },
      {
        value: "",
        key: "total_vat_18",
        title: "%18 KDV Toplam",
      },
      {
        value: "",
        key: "total_vat_20",
        title: "%20 KDV Toplam",
      },
      {
        value: "old_gross_total",
        key: "old_gross_total",
        title: "Eski Toplam Tutar",
      },
      {
        value: "gross_total",
        key: "gross_total",
        title: "Toplam Tutar",
      },
      {
        value: "created_at",
        key: "order_date",
        title: "Sipariş Tarihi",
      },
      {
        value: "lines.0.delivered_date",
        key: "delivered_date",
        title: "Teslim Tarihi",
      },
      {
        value: "lines.0.cargo_tracking_number",
        key: "cargo_number",
        title: "Kargo Numarası",
      },
      {
        value: "",
        key: "",
        title: "Teslimat Adresi İçin Değerler",
        children: [
          {
            value: "shipping_address.name_surname",
            key: "sa_name_surname",
            title: "Ad Soyad",
          },
          {
            value: "shipping_address.address",
            key: "sa_address",
            title: "Adres",
          },
          {
            value: "shipping_address.district",
            key: "sa_district",
            title: "İlçe",
          },
          {
            value: "shipping_address.city",
            key: "sa_city",
            title: "Şehir",
          },
          {
            value: "shipping_address.phone",
            key: "sa_phone",
            title: "Telefon",
          },
          {
            value: "shipping_address.company_title",
            key: "sa_company_title",
            title: "Şirket Adı",
          },
          {
            value: "shipping_address.tax_office",
            key: "sa_tax_office",
            title: "Vergi Dairesi",
          },
          {
            value: "shipping_address.tax_number",
            key: "sa_tax_number",
            title: "Vergi Numarası",
          },
        ],
      },
      {
        value: "",
        key: "",
        title: "Fatura Adresi İçin Değerler",
        children: [
          {
            value: "invoice_address.name_surname",
            key: "ia_name_surname",
            title: "Ad Soyad",
          },
          {
            value: "invoice_address.address",
            key: "ia_address",
            title: "Adres",
          },
          {
            value: "invoice_address.district",
            key: "ia_district",
            title: "İlçe",
          },
          {
            value: "invoice_address.city",
            key: "ia_city",
            title: "Şehir",
          },
          {
            value: "invoice_address.phone",
            key: "ia_phone",
            title: "Telefon",
          },
          {
            value: "invoice_address.company_title",
            key: "ia_company_title",
            title: "Şirket Adı",
          },
          {
            value: "invoice_address.tax_office",
            key: "ia_tax_office",
            title: "Vergi Dairesi",
          },
          {
            value: "invoice_address.tax_number",
            key: "ia_tax_number",
            title: "Vergi Numarası",
          },
        ],
      },
      {
        value: "",
        key: "",
        title: "Sipariş Satırları İçin Değerler",
        children: [
          {
            value: "shipping_address.name_surname",
            key: "line_customer_name",
            title: "Müşteri Adı",
          },
          {
            value: "order_group_id",
            key: "line_order_id",
            title: "Sipariş Numarası",
          },
          {
            value: "line_no",
            key: "line_seq_no",
            title: "Satır Numarası",
          },
          {
            value: "piece",
            key: "line_piece",
            title: "Parça Sayısı",
          },
          {
            value: "count",
            key: "line_count",
            title: "Adet",
          },
          {
            value: "m_group.title",
            key: "line_product_name",
            title: "Ürün Adı",
          },
          {
            value: "m_group.barcode",
            key: "line_product_barcode",
            title: "Ürün Barkod Numarası",
          },
          {
            value: "m_group.item_code",
            key: "line_product_item_code",
            title: "Ürün Stok Grup Numarası",
          },
          {
            value: "m_group.stock_code",
            key: "line_product_stock_code",
            title: "Ürün Stok Numarası",
          },
          {
            value: "m_group.photo",
            key: "line_product_photo",
            title: "Ürün Fotoğrafı",
          },
          {
            value: (obj) => {
              const filters = _.get(obj, "m_group.filters", []);
              const index = _.findIndex(filters, { key: "filter_brand" });
              return `m_group.filters.${index}.value`;
            },
            key: "line_brand",
            title: "Ürün Markası",
          },
          {
            value: (obj) => {
              const filters = _.get(obj, "m_group.filters", []);
              const index = _.findIndex(filters, { key: "filter_size" });
              return `m_group.filters.${index}.value`;
            },
            key: "line_size",
            title: "Ürün Bedeni",
          },
          {
            value: (obj) => {
              const filters = _.get(obj, "m_group.filters", []);
              const index = _.findIndex(filters, { key: "filter_color" });
              return `m_group.filters.${index}.value`;
            },
            key: "line_color",
            title: "Ürün Rengi",
          },
          {
            value: "content.{replace}",
            key: "line_bulkqty",
            title: "İçerik Adet",
            content_replace: {
              value: "content._keys.BulkQty",
            },
          },
          {
            value: "content.{replace}",
            key: "line_xmlcode",
            title: "İçerik Ürün",
            content_replace: {
              value: "content._keys.XmlCode",
            },
          },
          {
            value: "content.{replace}",
            key: "line_width",
            title: "İçerik En",
            content_replace: {
              value: "content._keys.Width",
            },
          },
          {
            value: "content.{replace}",
            key: "line_height",
            title: "İçerik Boy",
            content_replace: {
              value: "content._keys.Height",
            },
          },
          {
            value: "content.{replace}",
            key: "line_direction",
            title: "İçerik Yön",
            content_replace: {
              value: "content._keys.Direction",
            },
          },
          {
            value: "content.{replace}",
            key: "line_zincir",
            title: "İçerik Zincir",
            content_replace: {
              value: "content._keys.ZincirId",
            },
          },
          {
            value: "content.{replace}",
            key: "line_kasa",
            title: "İçerik Kasa",
            content_replace: {
              value: "content._keys.StorKasaSekliId",
            },
          },
          {
            value: "content.{replace}",
            key: "line_etek",
            title: "İçerik Etek",
            content_replace: {
              value: "content._keys.Etek",
            },
          },
          {
            value: "content.{replace}",
            key: "line_boncuk",
            title: "İçerik Boncuk",
            content_replace: {
              value: "content._keys.Boncuk",
            },
          },
          {
            value: "content.{replace}",
            key: "line_description",
            title: "İçerik Açıklama",
            content_replace: {
              value: "content._keys.Description",
            },
          },
          {
            value: "shipping_date",
            key: "line_shipping_date",
            title: "Kargolama Tarihi",
          },
          {
            value: "delivered_date",
            key: "line_delivered_date",
            title: "Teslim Tarihi",
          },
          {
            value: "cargo_tracking_number",
            key: "line_cargo_number",
            title: "Kargo Numarası",
          },
          {
            value: "merchant.title",
            key: "line_merchant_title",
            title: "Mağaza Adı",
          },
          {
            value: "merchant.commercial_title",
            key: "line_merchant_commercial_title",
            title: "Mağaza Ticari Ünvanı",
          },
          {
            value: "merchant.address",
            key: "line_merchant_address",
            title: "Mağaza Adres",
          },
          {
            value: "merchant.district",
            key: "line_merchant_district",
            title: "Mağaza İlçe",
          },
          {
            value: "merchant.city",
            key: "line_merchant_city",
            title: "Mağaza Şehir",
          },
          {
            value: "merchant.tax_number",
            key: "line_merchant_tax_number",
            title: "Mağaza Vergi Numarası",
          },
          {
            value: "merchant.tax_office",
            key: "line_merchant_tax_office",
            title: "Mağaza Vergi Dairesi",
          },
          {
            value: "net_total",
            key: "line_net_total",
            title: "Net Tutar",
          },
          {
            value: "total_vat",
            key: "line_total_vat",
            title: "KDV Tutar",
          },
          {
            value: "gross_total",
            key: "line_gross_total",
            title: "Toplam Tutar",
          },
        ],
      },
      {
        value: "",
        key: "",
        title: "Sipariş Hesap Satırları İçin Değerler",
        children: [
          {
            value: "gross_total",
            key: "calc_gross_total",
            title: "Toplam Tutar",
          },
          {
            value: "title",
            key: "calc_product_title",
            title: "Ürün Adı",
          },
          {
            value: "category_name",
            key: "calc_category_name",
            title: "Kategori Adı",
          },
          {
            value: "qty",
            key: "calc_qty",
            title: "Miktar",
          },
          {
            value: "unit",
            key: "calc_unit",
            title: "Stok Birimi",
          },
          {
            value: "vat_rate",
            key: "calc_vat_rate",
            title: "Vergi Oranı",
          },
          {
            value: "product_code",
            key: "calc_product_code",
            title: "Ürün Kodu",
          },
          {
            value: "old_price",
            key: "calc_old_price",
            title: "Eski Fiyat",
          },
          {
            value: "price",
            key: "calc_price",
            title: "Fiyat",
          },
          {
            value: "old_net_total",
            key: "calc_old_net_total",
            title: "Eski Ara Toplam",
          },
          {
            value: "net_total",
            key: "calc_net_total",
            title: "Ara Toplam",
          },
          {
            value: "old_total_vat",
            key: "calc_old_total_vat",
            title: "Eski KDV Toplam",
          },
          {
            value: "total_vat",
            key: "calc_total_vat",
            title: "KDV Toplam",
          },
          {
            value: "old_gross_total",
            key: "calc_old_gross_total",
            title: "Eski Toplam Tutar",
          },
          {
            value: "gross_total",
            key: "calc_gross_total",
            title: "Toplam Tutar",
          },
        ],
      },
    ],
  },
  lot: {
    title: "Ürün Lot",
    children: [
      {
        value: "lot_id",
        key: "lot_id",
        title: "Lot ID",
      },
      {
        value: "product_code",
        key: "product_code",
        title: "Ürün Kodu",
      },
      {
        value: "warehouse_code",
        key: "warehouse_code",
        title: "Depo Kodu",
      },
      {
        value: "warehouse_shelf",
        key: "warehouse_shelf",
        title: "Depo Rafı",
      },
      {
        value: "qty",
        key: "qty",
        title: "Lot Miktarı",
      },
      {
        value: "product.title",
        key: "product_title",
        title: "Ürün Adı",
      },
      {
        value: "product.unit",
        key: "unit",
        title: "Birim",
      },
    ],
  },
};
