import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import TiptapVueComponent from "./ImageSliderItem.vue";

export const ImageSliderItem = Node.create({
  name: "imageSliderItem",
  defining: true,
  draggable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      content: {
        default: "",
      },
      content_url: {
        default: "",
      },
      photo: {
        default: "",
      },
      photo_mobile: {
        default: "",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "tiptap-vue-image-slider-item",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "tiptap-vue-image-slider-item",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(TiptapVueComponent);
  },
});
