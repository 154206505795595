
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  filterService,
  GeneralApiResponse,
  GetFilterResult,
  Filter,
  FilterOption,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueButton from "@/components/form-elements/button/Main.vue";
import FilterGeneralInformation from "@/views/filters/create/components/GeneralInformation.vue";
import FilterOptions from "@/views/filters/create/components/Options.vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "FilterCreateOrUpdate",
  components: {
    FilterOptions,
    FilterGeneralInformation,
    VueButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const id = computed(() => get(route.params, "id"));

    const {
      data: merchant,
      loading,
      fetch,
    } = useReadRecord<Filter, GetFilterResult>({
      id: id.value,
      serviceMethod: filterService.show,
      success(result) {
        if (result.kind === "ok") {
          const options = get(result.data, "options.data", []).map(
            (l: FilterOption) => ({
              ...l,
              uuid: helper.uuidv4(),
            })
          );
          setQuery({ ...result.data, options: { data: options } });
        }
      },
      fail() {
        router.go(-1);
      },
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<Filter, GeneralApiResponse<GetFilterResult>>({
        query: {
          title: "",
          slug: "",
          description: "",
          multiple: 0,
          options: {
            data: [],
          },
        },
        storeServiceMethod: filterService.store,
        updateServiceMethod: filterService.update,
        success(result) {
          if (result.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "Filtre Kaydedildi!",
            });
            router.go(-1);
          }
        },
      });

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) fetch(id.value);
    });

    return {
      loading,
      processing,
      query,
      merchant,
      validationErrors,
      onSubmit,
    };
  },
});
