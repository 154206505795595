import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createBlock(_component_Datepicker, {
    modelValue: _ctx.date,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
    monthChangeOnScroll: false,
    position: "left",
    calendarClassName: "dp-custom-calendar",
    calendarCellClassName: "dp-custom-cell",
    inputClassName: "dp-custom-input",
    menuClassName: "dp-custom-menu",
    autoPosition: false,
    autoApply: "",
    closeOnAutoApply: false,
    placeholder: _ctx.placeholder,
    range: _ctx.range,
    locale: "tr",
    monthNameFormat: "long",
    minutesGridIncrement: "1",
    format: _ctx.previewFormat,
    enableTimePicker: _ctx.enableTimepicker,
    "preset-ranges": _ctx.presetRanges,
    "month-picker": _ctx.monthPicker,
    clearable: _ctx.clearable
  }, null, 8, ["modelValue", "placeholder", "range", "format", "enableTimePicker", "preset-ranges", "month-picker", "clearable"]))
}