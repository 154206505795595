
import { defineComponent, onMounted, ref } from "vue";
import { useFetchRecords } from "@/utils/records/fetch";
import { useFilterRecords } from "@/utils/records/filter";
import Tippy from "@/components/tippy/Main.vue";
import { helper } from "@/utils/helper";
import { cacheService } from "@/services";
import { useDeleteRecord } from "@/utils/records/delete";
import { findIndex } from "lodash";
import TransactionStatement from "@/components/transaction-statement/Main.vue";

export default defineComponent({
  name: "CacheSettings",
  components: {
    TransactionStatement,
    Tippy,
  },
  setup() {
    const modalRefId = helper.uuidv4();
    const detail = ref<any>(null);

    const { loading, list, fetch, selectedRows, selectAll } = useFetchRecords({
      serviceMethod: cacheService.fetch,
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {},
    });

    const { deleteState, triggerDelete, removeDeleteError } = useDeleteRecord({
      serviceMethod: cacheService.destroy,
      massServiceMethod: cacheService.massDestroy,
      success(key: any) {
        const index = findIndex(list.data, { key });
        if (index > -1) list.data.splice(index, 1);
      },
    });

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
    });

    return {
      modalRefId,
      detail,
      loading,
      list,
      filterState,
      filterFunctions,
      filterCount,
      selectedRows,
      selectAll,
      deleteState,
      triggerDelete,
      removeDeleteError,
    };
  },
});
