<template>
  <node-view-wrapper
    class="tiptap-disabled-block tiptap-vue-category-container draggable-item"
  >
    <div contenteditable="false">
      <span class="drag-handle label" draggable="true" data-drag-handle>
        {{ node.attrs.label }}
      </span>
    </div>
    <div class="tiptap-disabled-block-content">
      <VueInputContainer class="col-span-full">
        <template v-slot:label>
          <div class="flex items-center leading-none">
            <span>Galeri Adı</span>
            <Tippy
              class="ml-1.5"
              content="Arama motorları (Google) için anlamlı bir isim girmelisiniz!"
            >
              <i class="feather-help-circle text-xl"></i>
            </Tippy>
          </div>
        </template>
        <VueInput v-model="title" :disabled="!isEnabled" />
      </VueInputContainer>
      <VueInputContainer class="col-span-full">
        <template v-slot:label> Görsel Genişliği </template>
        <VueInput v-model="width" :disabled="!isEnabled" />
      </VueInputContainer>
      <VueInputContainer class="col-span-full">
        <template v-slot:label>Görseller</template>
        <FileManagerSelect v-model="photos" multiple :disabled="!isEnabled" />
      </VueInputContainer>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-3";
import VueInputContainer from "@/components/form-elements/input-container/Main";
import Tippy from "@/components/tippy/Main";
import VueInput from "@/components/form-elements/input/Main";
import FileManagerSelect from "@/components/file-manager/Select";
import { cloneDeep, get, isArray, isNumber, isString } from "lodash";
export default {
  name: "TiptapVueCategoryContainer",
  components: {
    FileManagerSelect,
    VueInput,
    Tippy,
    VueInputContainer,
    NodeViewWrapper,
  },
  props: nodeViewProps,
  computed: {
    title: {
      get() {
        return get(this.node, "attrs.title") || "";
      },
      set(value) {
        this.updateAttributes({
          title: value || "",
        });
      },
    },
    width: {
      get() {
        return get(this.node, "attrs.width") || "";
      },
      set(value) {
        this.updateAttributes({
          width: value || "",
        });
      },
    },
    photos: {
      get() {
        const value = get(this.node, "attrs.photos") || "";
        if (value && (isString(value) || isNumber(value))) {
          return value.toString().split(",");
        }
        return [];
      },
      set(val) {
        const value = cloneDeep(val);
        this.updateAttributes({
          photos: isArray(value) ? value.join(",") : "",
        });
      },
    },
    isEnabled() {
      return get(this.editor, "options.editable") || false;
    },
  },
};
</script>
