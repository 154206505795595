
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  GeneralApiResponse,
  GetProductTabResult,
  ProductTabInterface,
  productTabService,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueButton from "@/components/form-elements/button/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import { quickSubPage, QuickSubPageInterface } from "@/components/quick-page";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import { useStore } from "@/store";
import ContentAssets from "@/components/assets/Main.vue";

export default defineComponent({
  name: "ProductTabCreateOrUpdate",
  components: {
    ContentAssets,
    VueSelect2,
    VueSelect,
    VueInput,
    VueInputContainer,
    VueButton,
  },
  props: {
    quickPage: {
      type: Object as () => QuickSubPageInterface,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const quickPage = quickSubPage(props.quickPage);
    const id = computed(() => quickPage.id(get(route.params, "id")));

    const formIdSelect2Options: Select2Options = {
      multiple: true,
      data: store.getters["order/forms"].map((form) => {
        return { id: form.id, text: form.title };
      }),
    };

    const categorySelect2Options = computed(() => {
      return {
        multiple: true,
        data: store.getters["category/categories"].map((item) => ({
          id: item.id,
          value: item.id,
          text: item.title,
        })),
      };
    });

    const { loading, fetch } = useReadRecord<
      ProductTabInterface,
      GetProductTabResult
    >({
      id: id.value,
      serviceMethod: productTabService.show,
      success(result) {
        if (result.kind === "ok") {
          // if (!result.data.workspaces || result.data.workspaces.length === 0) {
          //   result.data.workspaces = convertAssetsToWorkspace([
          //     {
          //       type: "text",
          //       text: result.data.text,
          //     },
          //   ]);
          // }

          setQuery({
            ...result.data,
            form_id: result.data.form_id || "",
          });
        }
      },
      fail() {
        if (!quickPage.enabled) {
          router.go(-1);
        } else {
          quickPage.onClose();
        }
      },
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<
        ProductTabInterface,
        GeneralApiResponse<GetProductTabResult>
      >({
        query: {
          type: "other",
          title: "",
          text: "",
          form_id: "",
          category_id: "",
          status: "1",
          can_customizable: "1",
          workspaces: [],
        },
        storeServiceMethod: productTabService.store,
        updateServiceMethod: productTabService.update,
        success(result) {
          if (result.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "Ürün Sekmesi Kaydedildi!",
            });
            if (!quickPage.enabled) {
              router.go(-1);
            } else {
              quickPage.onSave(result.data);
            }
          }
        },
      });

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (quickPage.enabled) setQuery(quickPage.data);

      if (id.value) fetch(id.value);
    });

    return {
      loading,
      processing,
      query,
      validationErrors,
      formIdSelect2Options,
      categorySelect2Options,
      onSubmit,
    };
  },
});
