import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueColorPicker = _resolveComponent("VueColorPicker")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "hu-color-picker-container",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _createVNode(_component_VueColorPicker, {
          theme: "light",
          color: _ctx.color,
          "colors-default": _ctx.colorsDefault,
          onChangeColor: _ctx.onChange,
          class: "absolute",
          style: _normalizeStyle({ top: `${_ctx.top}px`, left: `${_ctx.left}px` }),
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent","stop"]))
        }, null, 8, ["color", "colors-default", "onChangeColor", "style"])
      ]))
    : _createCommentVNode("", true)
}