
import { defineComponent } from "vue";
import OrderFormLineMixin from "@/components/order/form";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import { findIndex, get } from "lodash";

export default defineComponent({
  name: "OrderFormRules",
  components: { VueSelect, VueInputContainer },
  mixins: [OrderFormLineMixin],
  computed: {
    options() {
      if (get(this.line, "type") === "opening") {
        return [
          { value: "single", text: "Tek Açılır" },
          { value: "double", text: "Çift Açılır" },
        ];
      }
      return this.line.options.map((o) => {
        return {
          ...o,
          value: o.value.toString(),
        };
      });
    },
  },
  beforeMount() {
    const index = findIndex(this.options, { value: this.lineValue.toString() });
    if (index === -1) {
      let value = "";
      if (this.isRequired) value = get(this.options, "0.value") || "";
      this.onChangeValue(value);
    }
  },
});
