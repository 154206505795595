
import { defineComponent } from "vue";
import { Editor } from "@tiptap/vue-3";

export default defineComponent({
  name: "TiptapTextAlign",
  props: {
    editor: {
      type: Object as () => Editor,
      required: true,
    },
    align: {
      type: String,
      default: "left",
    },
  },
});
