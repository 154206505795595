
import { defineComponent } from "vue";
import OrderFormLineMixin from "@/components/order/form";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import { findIndex, get } from "lodash";

export default defineComponent({
  name: "OrderFormRules",
  components: { VueSelect, VueInputContainer },
  mixins: [OrderFormLineMixin],
  computed: {
    options() {
      let values = get(this.priceCalculationRules, `l${this.line.id}`) || "";
      values = values
        .toString()
        .split(",")
        .filter((o: string) => !!o);
      return this.line.options
        .filter(
          (o) => values.length === 0 || values.indexOf(o.value.toString()) > -1
        )
        .map((o) => {
          return {
            ...o,
            value: o.value.toString(),
          };
        });
    },
  },
  beforeMount() {
    const index = findIndex(this.options, { value: this.lineValue.toString() });
    if (index === -1) {
      let value = "";
      if (this.isRequired) value = get(this.options, "0.value") || "";
      this.onChangeValue(value);
    }
  },
});
