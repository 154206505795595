import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: "color--box",
    style: _normalizeStyle({
      color: _ctx.textColor,
      'background-color': _ctx.backgroundColor,
      'background-image': _ctx.backgroundImage,
    })
  }, _toDisplayString(_ctx.boxTitle), 5))
}