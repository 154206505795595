import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["value", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "inputGroupRef",
    class: _normalizeClass({ 'input-group': !_ctx.isButton })
  }, [
    (_ctx.isButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          style: _normalizeStyle({ color: _ctx.modelValue || 'inherit' }),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openColorPicker && _ctx.openColorPicker(...args)))
        }, [
          _renderSlot(_ctx.$slots, "button-text")
        ], 4))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", {
            class: "input-group-text cursor-pointer w-10",
            style: _normalizeStyle({ 'background-color': _ctx.modelValue }),
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openColorPicker && _ctx.openColorPicker(...args)))
          }, null, 4),
          _createElementVNode("input", {
            class: _normalizeClass(["form-control", { 'border-red-200 bg-red-100': _ctx.hasError, [_ctx.inputClass]: true }]),
            value: _ctx.modelValue,
            onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeValue && _ctx.changeValue(...args))),
            autocomplete: "eip_1506",
            placeholder: _ctx.placeholder,
            disabled: _ctx.disabled
          }, null, 42, _hoisted_1)
        ], 64))
  ], 2))
}