import { GeneralApiResponse, GetBasketResults, useAxios } from "@/services";

export * from "./api.types";

export const basketService = {
  fetch: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetBasketResults>>(
      `/api/reports/baskets`,
      params
    ),
};
