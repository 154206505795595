import { RouteRecordRaw, RouterView } from "vue-router";
import Merchants from "@/views/merchant/list/Main.vue";
import MerchantCreate from "@/views/merchant/create/Main.vue";
import MerchantApplications from "@/views/merchant-applications/list/Main.vue";

const route: Array<RouteRecordRaw> = [
  {
    path: "merchants",
    component: RouterView,
    children: [
      {
        path: "",
        name: "merchants",
        component: Merchants,
        meta: {
          title: "Mağaza Listesi",
        },
      },
      {
        path: "create",
        name: "merchants.create",
        component: MerchantCreate,
        meta: {
          title: "Mağaza Ekle",
        },
      },
      {
        path: "edit/:id",
        name: "merchants.edit",
        component: MerchantCreate,
        meta: {
          title: "Mağaza Düzenle",
        },
      },
    ],
  },
  {
    path: "merchant-applications",
    component: RouterView,
    children: [
      {
        path: "",
        name: "merchant.applications",
        component: MerchantApplications,
        meta: {
          title: "Mağaza Başvuruları",
        },
      },
    ],
  },
];

export default route;
