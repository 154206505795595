
import { defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { FilterFamily, StockGroupCodeDefinitionInterface } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import VueTextarea from "@/components/form-elements/textarea/Main.vue";

export default defineComponent({
  name: "StockGroupCodeGeneralInformation",
  components: {
    VueTextarea,
    VueSelect2,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => StockGroupCodeDefinitionInterface,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableQuery = reactive<StockGroupCodeDefinitionInterface>(
      toRaw(props.query)
    );

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    return {
      editableQuery,
      merchantSelect2Options,
    };
  },
});
