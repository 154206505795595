
import { defineComponent, ref } from "vue";
import VueModal from "@/components/modal/Main.vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "FilterModalContainer",
  components: { VueModal },
  props: {
    count: { type: Number, default: 0 },
    onApply: { type: Function, default: null },
    onClear: { type: Function, default: null },
  },
  setup(props) {
    const modalRefId = helper.uuidv4();
    const isVisible = ref<boolean>(false);

    cash(`#${modalRefId}`).modal("on.show", () => {
      isVisible.value = true;
    });
    cash(`#${modalRefId}`).modal("on.hide", () => {
      isVisible.value = false;
    });

    const toggleModal = () => {
      cash(`#${modalRefId}`).modal(isVisible.value ? "hide" : "show");
    };

    const clearFilters = () => {
      if (typeof props.onClear === "function") props.onClear();
      cash(`#${modalRefId}`).modal("hide");
    };

    const applyFilters = () => {
      if (typeof props.onApply === "function") props.onApply();
      cash(`#${modalRefId}`).modal("hide");
    };

    return {
      modalRefId,
      isVisible,
      toggleModal,
      clearFilters,
      applyFilters,
    };
  },
});
