<template>
  <node-view-wrapper
    class="tiptap-disabled-block tiptap-vue-most-commented-products"
  >
    <div contenteditable="false">
      <span class="drag-handle label" draggable="true" data-drag-handle>
        {{ node.attrs.label }}
      </span>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-3";
export default {
  name: "TiptapVueMostCommentedProducts",
  components: {
    NodeViewWrapper,
  },
  props: nodeViewProps,
};
</script>
