import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";
import { Menu } from "@/layouts/default";

// Getters types
export type Getters = {
  menu(
    state: LocalState,
    getters: Getters,
    rootState: RootState,
    rootGetters: any
  ): Array<Menu | string>;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  menu: (state, getters, rootState, rootGetters) => {
    if (rootGetters["auth/isAdmin"] || rootGetters["auth/isStaff"])
      return state.menu.staff;
    if (rootGetters["auth/isSiteAdmin"]) return state.menu.siteAdmin;
    return state.menu.merchant;
  },
};
