
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import { cloneDeep, get, isArray } from "lodash";
import { FileManagerRef } from "@/components/file-manager/index";
import Tippy from "@/components/tippy/Main.vue";
import { fileService } from "@/services";
import { useStore } from "@/store";
import { helper } from "@/utils/helper";
import Draggable from "vuedraggable";

export default defineComponent({
  name: "FileManagerSelect",
  components: { Tippy, Draggable },
  props: {
    modelValue: {
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const fileManagerRef = inject<Ref<FileManagerRef>>(`fileManagerRef`);
    const selected = ref<string[]>([]);
    const signedPathLoading = ref<boolean>(false);
    const store = useStore();
    const uuid = helper.uuidv4();

    const setSelected = () => {
      const value = cloneDeep(props.modelValue);
      if (value) {
        selected.value = isArray(value) ? value : [value];
      } else {
        selected.value = [];
      }
    };

    const isImage = computed(() => {
      return true;
    });

    const openFileManager = () => {
      fileManagerRef?.value?.show({
        callback: (files) => {
          emit("file:add", files);

          const paths = files.map((file) => file.path);
          if (paths.length > 0) {
            if (props.multiple) selected.value = [...selected.value, ...paths];
            else selected.value = [get(paths, 0)];
          }

          emit(
            "update:model-value",
            props.multiple ? selected.value : get(selected.value, 0, "")
          );
        },
      });
    };

    const removeFile = (index: number) => {
      emit("file:remove", get(selected.value, index));
      selected.value.splice(index, 1);
      emit(
        "update:model-value",
        props.multiple ? selected.value : get(selected.value, 0, "")
      );
    };

    const openOriginalFile = (path: string) => {
      if (signedPathLoading.value) return;

      if (!store.getters["auth/isAdmin"]) {
        return window.open(helper.imagePath(path, "large"), "_blank");
      }

      signedPathLoading.value = true;
      fileService
        .signedUrl({
          path,
        })
        .then((result) => {
          if (result.kind === "ok") {
            window.open(result.data, "_blank");
          }
        })
        .finally(() => {
          signedPathLoading.value = false;
        });
    };

    const onMoveEnd = () => {
      if (props.multiple) {
        emit("update:model-value", selected.value);
        emit("move:end", selected.value);
      }
    };

    onMounted(() => {
      setSelected();
    });

    watch(() => props.modelValue, setSelected, { flush: "post" });

    return {
      uuid,
      selected,
      isImage,
      signedPathLoading,
      openFileManager,
      removeFile,
      openOriginalFile,
      onMoveEnd,
    };
  },
});
