import Velocity from "velocity-animate";

(function (cash) {
  "use strict";

  // Show accordion content
  cash("body").on("click", ".accordion-button", function (this: HTMLElement) {
    Velocity(
      cash(this).closest(".accordion").find(".accordion-collapse"),
      "slideUp",
      {
        duration: 300,
        complete: function (el: HTMLElement) {
          cash(el).removeClass("show");
          cash(el)
            .closest(".accordion-item")
            .find(".accordion-button")
            .addClass("collapsed")
            .attr("aria-expanded", false);
        },
      }
    );

    // Set active accordion
    if (!cash(this).hasClass("collapsed")) {
      Velocity(
        cash(this).closest(".accordion-item").find(".accordion-collapse"),
        "slideUp",
        {
          duration: 300,
          complete: function (el: HTMLElement) {
            cash(el).removeClass("show");
            cash(el)
              .closest(".accordion-item")
              .find(".accordion-button")
              .addClass("collapsed")
              .attr("aria-expanded", false);
          },
        }
      );
    } else {
      Velocity(
        cash(this).closest(".accordion-item").find(".accordion-collapse"),
        "slideDown",
        {
          duration: 300,
          complete: function (el: HTMLElement) {
            cash(el).addClass("show");
            cash(el)
              .closest(".accordion-item")
              .find(".accordion-button")
              .removeClass("collapsed")
              .attr("aria-expanded", true);
          },
        }
      );
    }
  });
})(cash);
