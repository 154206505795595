
import { computed, defineComponent } from "vue";
import { quickUploadService } from "@/services";
import { ProductGroupExcel } from "@/utils/excel/product-group";
import QuickUpload from "@/views/quick-uploads/QuickUpload.vue";
import { useStore } from "@/store";
import { get } from "lodash";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ProductGroupQuickUpload",
  components: {
    QuickUpload,
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const formId = computed(() => get(route.params, "formId"));
    const categoryId = computed(() => get(route.params, "categoryId"));
    const form = computed(() => store.getters["order/form"](formId.value));
    const excelUpload = new ProductGroupExcel(
      categoryId.value,
      null,
      formId.value
    );
    const method = async (params: unknown) =>
      quickUploadService.productGroup(params, formId.value);

    return {
      form,
      excelUpload,
      method,
    };
  },
});
