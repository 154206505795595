
import { defineComponent, watch } from "vue";
import { useSubmitRecord } from "@/utils/records/submit";
import {
  FileType,
  fileService,
  GeneralApiResponse,
  GetFileResult,
} from "@/services";
import { cloneDeep, get, set } from "lodash";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueButton from "@/components/form-elements/button/Main.vue";
import VueModal from "@/components/modal/Main.vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "EditFile",
  components: { VueModal, VueButton, VueInput, VueInputContainer },
  props: {
    modelValue: { type: Array, default: () => [] },
    index: { type: Number, default: () => -1 },
  },
  setup(props, { emit }) {
    const modalRefId = helper.uuidv4();
    watch(
      () => props.index,
      (val) => {
        if (val > -1) showModal();
      },
      { flush: "post" }
    );

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<FileType, GeneralApiResponse<GetFileResult>>({
        query: {
          name: "",
          tags: "",
        },
        updateServiceMethod: fileService.update,
        success(result) {
          if (result.kind === "ok") {
            const list = cloneDeep(props.modelValue);
            set(list, `${props.index}.name`, result.data.name);
            set(list, `${props.index}.tags`, result.data.tags);
            emit("update:modelValue", list);
            hideModal();
          }
        },
      });

    const onSubmit = () => submit(get(props.modelValue, `${props.index}.id`));

    const showModal = () => {
      setQuery({
        name: get(props.modelValue, `${props.index}.name`, ""),
        tags: get(props.modelValue, `${props.index}.tags`, ""),
      });
      cash(`#${modalRefId}`).modal("show");
    };

    const hideModal = () => {
      emit("update:index", -1);
      cash(`#${modalRefId}`).modal("hide");
    };

    return {
      modalRefId,
      processing,
      query,
      validationErrors,
      hideModal,
      onSubmit,
    };
  },
});
