
import { computed, defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { PriceCalculationRule } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import VueSelect from "@/components/form-elements/select/Main.vue";
import { get } from "lodash";

export default defineComponent({
  name: "PriceCalculationRuleWidthCalculation",
  components: {
    VueSelect,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => PriceCalculationRule,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableQuery = reactive<PriceCalculationRule>(toRaw(props.query));

    const beforeCalculationType = computed({
      get() {
        return editableQuery.width_add * 1 >= 0 ? "plus" : "minus";
      },
      set(variable: string) {
        editableQuery.width_add =
          Math.abs(editableQuery.width_add) * (variable === "minus" ? -1 : 1);
      },
    });

    const beforeCalculationValue = computed({
      get() {
        return Math.abs(editableQuery.width_add);
      },
      set(variable: any) {
        editableQuery.width_add =
          Math.abs(variable) *
          (beforeCalculationType.value === "minus" ? -1 : 1);
      },
    });

    const greaterOne = computed({
      get() {
        return get(editableQuery.width_greater.toString().split("-"), 0, 0);
      },
      set(variable: any) {
        const e = editableQuery.width_greater.toString().split("-");
        editableQuery.width_greater = `${variable || 0}-${get(e, 1, 0)}`;
      },
    });

    const greaterTwo = computed({
      get() {
        return get(editableQuery.width_greater.toString().split("-"), 1, 0);
      },
      set(variable: any) {
        const e = editableQuery.width_greater.toString().split("-");
        editableQuery.width_greater = `${get(e, 0, 0)}-${variable || 0}`;
      },
    });

    return {
      editableQuery,
      beforeCalculationType,
      beforeCalculationValue,
      greaterOne,
      greaterTwo,
    };
  },
});
