
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import { useFetchRecords } from "@/utils/records/fetch";
import { useFilterRecords } from "@/utils/records/filter";
import FilterButton from "@/components/filter-container/Button.vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import dayjs from "dayjs";
import { ficheService } from "@/services";
import { cloneDeep, get, sumBy } from "lodash";
import VueDatetimePicker from "@/components/form-elements/datetime-picker/Main.vue";
import { helper } from "@/utils/helper";
import { AccountingReportListExcel } from "@/utils/excel/accounting-report-list";
import { useStore } from "@/store";
import { Select2Options } from "@/components/form-elements/select2";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { useRouter } from "vue-router";
import Tippy from "@/components/tippy/Main.vue";

export default defineComponent({
  name: "Transactions",
  components: {
    Tippy,
    VueSelect2,
    VueDatetimePicker,
    VueInputContainer,
    FilterContainer,
    FilterButton,
  },
  setup() {
    const preparingExcel = ref<boolean>(false);

    const today = dayjs();
    const originalDate = cloneDeep(today.subtract(0, "month"));
    const store = useStore();
    const router = useRouter();

    const modalRef = ref<HTMLElement>();
    const detail = ref<any>(null);

    // const presetRanges = [
    //   { label: "Bugün", range: [today, today] },
    //   {
    //     label: "Bu Hafta",
    //     range: [today.startOf("week"), today.endOf("week")],
    //   },
    //   { label: "Bu Ay", range: [today.startOf("month"), today.endOf("month")] },
    //   {
    //     label: "Geçen Ay",
    //     range: [fromDate, toDate],
    //   },
    //   {
    //     label: "2 Ay Önce",
    //     range: [
    //       today.subtract(2, "month").startOf("month"),
    //       today.subtract(2, "month").endOf("month"),
    //     ],
    //   },
    //   {
    //     label: "3 Ay Önce",
    //     range: [
    //       today.subtract(3, "month").startOf("month"),
    //       today.subtract(3, "month").endOf("month"),
    //     ],
    //   },
    // ];

    const { loading, list, fetch } = useFetchRecords({
      serviceMethod: ficheService.accountingReport,
      formatted(item: any): any {
        const transactionTypes = get(item, "transaction_types") || [];
        return {
          transaction_types: transactionTypes,
          transaction_titles: transactionTypes
            .map((o: any) => {
              return store.getters["system/definition"]("transaction_types", o);
            })
            .filter((o: any) => !!o),
          description: get(item, "description"),
          fiche_type: get(item, "fiche_type"),
          fiche_id: get(item, "fiche_id"),
          order_group_id: get(item, "order_group_id"),
          merchant_id: get(item, "merchant_id"),
          merchant_title: get(item, "merchant_title"),
          time: get(item, "time"),
          receipt_amount: get(item, "receipt_amount", "0") * 1,
          money_amount: get(item, "money_amount", "0") * 1,
          invoice_0_amount: get(item, "invoice_0_amount", "0") * 1,
          invoice_18_amount: get(item, "invoice_18_amount", "0") * 1,
        };
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        date: originalDate.format("YYYY-MM"),
        merchant_id: "",
      },
    });

    const totalInvoice0Amount = computed(() => {
      return sumBy(list.data, "invoice_0_amount");
    });

    const totalInvoice18Amount = computed(() => {
      return sumBy(list.data, "invoice_18_amount");
    });

    const totalReceiptAmount = computed(() => {
      return sumBy(list.data, "receipt_amount");
    });

    const totalMoneyAmount = computed(() => {
      return sumBy(list.data, "money_amount");
    });

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
      if (!cash(".side-nav").hasClass("side-nav--simple")) {
        cash(".side-nav").addClass("side-nav--simple");
      }
    });

    onBeforeUnmount(() => {
      if (modalRef.value) {
        cash(modalRef.value).modal("hide");
      }
      if (cash(".side-nav").hasClass("side-nav--simple")) {
        cash(".side-nav").removeClass("side-nav--simple");
      }
    });

    const openDetail = (row: any) => {
      if (
        row.fiche_type === "fiche" &&
        store.getters["auth/hasPermission"]("fiches.show")
      ) {
        router.push({ name: "fiches.edit", params: { id: row.fiche_id } });
      }
    };

    const downloadToExcel = async () => {
      preparingExcel.value = true;
      setTimeout(async () => {
        const rows = cloneDeep(list.data);
        const filename = `muhasebe_raporu_${helper.formatDate(
          new Date(),
          "DD_MM_YYYY_HH_mm"
        )}`;

        const excel = new AccountingReportListExcel();
        await excel.process();
        excel.setRows(rows, "primary");
        await excel.export(filename);

        preparingExcel.value = false;
      });
    };

    return {
      modalRef,
      detail,
      loading,
      list,
      filterState,
      filterCount,
      filterFunctions,
      totalInvoice0Amount,
      totalInvoice18Amount,
      totalReceiptAmount,
      totalMoneyAmount,
      preparingExcel,
      merchantSelect2Options,
      openDetail,
      downloadToExcel,
    };
  },
});
