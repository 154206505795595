import { RouteRecordRaw } from "vue-router";
import FileManager from "@/views/file-manager/Main.vue";
import Payments from "@/views/payments/list/Main.vue";
import Comments from "@/views/comments/list/Main.vue";
import Caches from "@/views/caches/list/Main.vue";
import Reports from "@/views/reports/Main.vue";
import MerchanReports from "@/views/merchant-reports/Main.vue";

const route: Array<RouteRecordRaw> = [
  {
    path: "file-manager",
    name: "file-manager",
    component: FileManager,
    meta: {
      title: "Dosya Yönetim Sistemi",
      roles: [],
      permissions: ["files.list"],
    },
  },
  {
    path: "payments",
    name: "payments",
    component: Payments,
    meta: {
      title: "Ödemeler",
      roles: [],
      permissions: ["payments.list"],
    },
  },
  {
    path: "reports",
    name: "reports",
    component: Reports,
    meta: {
      title: "İstatistik Raporu",
      roles: [],
      permissions: ["statistics.show"],
    },
  },
  {
    path: "merchant-reports",
    name: "reports.merchant",
    component: MerchanReports,
    meta: {
      title: "Mağaza Raporu",
      roles: [],
      permissions: ["statistics.show"],
    },
  },
  {
    path: "comments",
    name: "comments",
    component: Comments,
    meta: {
      title: "Ürün Yorumları",
      roles: [],
      permissions: ["comments.list"],
    },
  },
  {
    path: "caches",
    name: "caches",
    component: Caches,
    meta: {
      title: "Ön Bellek Ayarları",
      roles: [],
      permissions: ["cache.list"],
    },
  },
];

export default route;
