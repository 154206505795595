import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosTransformer,
} from "axios";
import { get, isArray } from "lodash";
import { useStore } from "@/store";
import {
  GeneralApiProblem,
  GeneralApiResponse,
  getGeneralApiProblem,
} from "@/services/api.types";

export const NODE_ENV = process.env.NODE_ENV;
export const FRONTEND_URL = process.env.VUE_APP_FRONTEND_URL;
export const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;

const axiosDefaultConfig: AxiosRequestConfig = {
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/vnd.api+json",
  },
};

export interface UseAxios {
  instance: AxiosInstance;
  get<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<GeneralApiResponse<T> | GeneralApiProblem>;
  post<T>(
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ): Promise<GeneralApiResponse<T> | GeneralApiProblem>;
  put<T>(
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ): Promise<GeneralApiResponse<T> | GeneralApiProblem>;
  delete<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<GeneralApiResponse<T> | GeneralApiProblem>;
}

export const useAxios = (
  config: AxiosRequestConfig & { responseField?: string } = {}
): UseAxios => {
  const { responseField = "data", ...axiosConfig } = config;
  const store = useStore();
  const token =
    store.getters["auth/token"] || window.localStorage.getItem("auth_token");
  if (token)
    axiosConfig.headers = {
      ...axiosConfig.headers,
      Authorization: `Bearer ${token}`,
    };

  const site = store.getters["system/site"];
  if (token)
    axiosConfig.headers = {
      ...axiosConfig.headers,
      "X-SITE": site,
    };

  const createConfig = {
    baseURL: BACKEND_URL,
    ...axiosDefaultConfig,
    ...axiosConfig,
  };

  let transformRequest: AxiosTransformer[] = [];
  if (axios.defaults.transformRequest) {
    if (isArray(axios.defaults.transformRequest))
      transformRequest = axios.defaults.transformRequest;
    else transformRequest = [axios.defaults.transformRequest];
  }

  let transformResponse: AxiosTransformer[] = [];
  if (axios.defaults.transformResponse) {
    if (isArray(axios.defaults.transformResponse))
      transformResponse = axios.defaults.transformResponse;
    else transformResponse = [axios.defaults.transformResponse];
  }

  const instance = axios.create({
    ...createConfig,
    transformRequest: transformRequest.concat((data) => {
      return data;
    }),
    transformResponse: transformResponse.concat((data) => {
      return data;
    }),
  });

  const getField = (result: any) => {
    if (responseField) return get(result, responseField, null);
    return result;
  };

  return {
    instance,
    get: async (url: string, config?: AxiosRequestConfig) => {
      const result = await instance.get(url, config).catch((error) => error);
      if (result instanceof Error) {
        return getGeneralApiProblem(result as AxiosError);
      }
      return {
        kind: "ok",
        data: getField(result.data),
        meta: get(result.data, "meta"),
      };
    },
    post: async (url: string, params?: any, config?: AxiosRequestConfig) => {
      const result = await instance
        .post(url, params, config)
        .catch((error) => error);
      if (result instanceof Error) {
        return getGeneralApiProblem(result as AxiosError);
      }
      return {
        kind: "ok",
        data: getField(result.data),
        meta: get(result.data, "meta"),
      };
    },
    put: async (url: string, params?: any, config?: AxiosRequestConfig) => {
      const result = await instance
        .put(url, params, config)
        .catch((error) => error);
      if (result instanceof Error) {
        return getGeneralApiProblem(result as AxiosError);
      }
      return {
        kind: "ok",
        data: getField(result.data),
        meta: get(result.data, "meta"),
      };
    },
    delete: async (url: string, config?: AxiosRequestConfig) => {
      const result = await instance.delete(url, config).catch((error) => error);
      if (result instanceof Error) {
        return getGeneralApiProblem(result as AxiosError);
      }
      return { kind: "ok" };
    },
  };
};
