import {
  GeneralApiResponse,
  GetPriceCalculationRuleResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const priceCalculationRuleService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetPriceCalculationRuleResult>>(
      `/api/rules/${id}`
    ),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetPriceCalculationRuleResult>>(
      `/api/rules`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetPriceCalculationRuleResult>>(
      `/api/rules/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/rules/${id}`),
};
