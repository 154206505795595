import { RouteRecordRaw, RouterView } from "vue-router";
import Menus from "@/views/menus/list/Main.vue";
import MenuCreate from "@/views/menus/create/Main.vue";

const route: Array<RouteRecordRaw> = [
  {
    path: "menus",
    component: RouterView,
    children: [
      {
        path: "",
        name: "menus",
        component: Menus,
        meta: {
          title: "Menü Listesi",
          roles: [],
          permissions: ["contents.list"],
        },
      },
      {
        path: "create",
        name: "menus.create",
        component: MenuCreate,
        meta: {
          title: "Menü Ekle",
          roles: [],
          permissions: ["contents.create"],
        },
      },
      {
        path: "edit/:id",
        name: "menus.edit",
        component: MenuCreate,
        meta: {
          title: "Menü Düzenle",
          roles: [],
          permissions: ["contents.edit"],
        },
      },
    ],
  },
];

export default route;
