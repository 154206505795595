export const state = {
  menu: {
    merchant: [
      {
        icon: "feather-home",
        pageName: "dashboard",
        title: "Anasayfa",
      },
      "devider",
      {
        icon: "feather-award",
        pageName: "orders",
        title: "Siparişler",
      },
      {
        icon: "feather-pie-chart",
        pageName: "reports.merchant",
        title: "Mağaza Raporu",
        roles: [],
        permissions: ["statistics.show"],
      },
      // {
      //   icon: "feather-dollar-sign",
      //   pageName: "fiches",
      //   title: "Fiş Hareketleri",
      // },
      "devider",
      {
        icon: "feather-grid",
        pageName: "product-groups",
        title: "Ürün Listesi",
      },
      {
        icon: "feather-award",
        pageName: "comments",
        title: "Ürün Yorumları",
      },
      {
        icon: "feather-list",
        pageName: "product-tabs",
        title: "Ürün Sekmeleri",
      },
      "devider",
      {
        icon: "feather-hard-drive",
        pageName: "file-manager",
        title: "Dosya Yönetim Sistemi",
      },
    ],
    siteAdmin: [
      {
        icon: "feather-home",
        pageName: "dashboard",
        title: "Anasayfa",
      },
      "devider",
      {
        icon: "feather-award",
        pageName: "orders",
        title: "Siparişler",
      },
      // {
      //   icon: "feather-dollar-sign",
      //   pageName: "fiches",
      //   title: "Fiş Hareketleri",
      // },
      {
        icon: "feather-credit-card",
        pageName: "payments",
        title: "Ödemeler",
      },
      {
        icon: "feather-pie-chart",
        pageName: "reports.merchant",
        title: "Mağaza Raporu",
        roles: [],
        permissions: ["statistics.show"],
      },
      "devider",
      {
        icon: "feather-grid",
        pageName: "product-groups",
        title: "Ürün Listesi",
      },
      {
        icon: "feather-award",
        pageName: "comments",
        title: "Ürün Yorumları",
      },
      {
        icon: "feather-list",
        pageName: "product-tabs",
        title: "Ürün Sekmeleri",
      },
      "devider",
      {
        icon: "feather-layout",
        pageName: "contents",
        title: "Sayfalar",
      },
      {
        icon: "feather-menu",
        pageName: "menus",
        title: "Site Menüsü",
      },
      {
        icon: "feather-users",
        pageName: "users",
        title: "Kullanıcılar",
      },
      "devider",
      {
        icon: "feather-hard-drive",
        pageName: "file-manager",
        title: "Dosya Yönetim Sistemi",
      },
      {
        icon: "feather-database",
        pageName: "caches",
        title: "Ön Bellek Ayarları",
      },
    ],
    staff: [
      {
        icon: "feather-home",
        pageName: "dashboard",
        title: "Anasayfa",
      },
      "devider",
      {
        icon: "feather-award",
        pageName: "orders",
        title: "Siparişler",
        roles: [],
        permissions: ["orders.list"],
      },
      {
        icon: "feather-shopping-cart",
        pageName: "baskets",
        title: "Sepette Bekleyenler",
        roles: [],
        permissions: ["orders.list"],
      },
      {
        icon: "feather-credit-card",
        pageName: "payments",
        title: "Ödemeler",
        roles: [],
        permissions: ["payments.list"],
      },
      // {
      //   icon: "feather-dollar-sign",
      //   pageName: "fiches",
      //   title: "Fiş Hareketleri",
      // },
      {
        icon: "feather-pie-chart",
        pageName: "",
        title: "Raporlar",
        roles: [],
        permissions: ["statistics.show"],
        subMenu: [
          {
            icon: "",
            pageName: "reports",
            title: "Genel İstatistik Raporu",
            roles: [],
            permissions: [],
          },
          {
            icon: "",
            pageName: "reports.merchant",
            title: "Mağaza Raporu",
            roles: [],
            permissions: [],
          },
        ],
      },
      {
        icon: "feather-dollar-sign",
        pageName: "",
        title: "Muhasebe",
        roles: [],
        permissions: ["fiches.list"],
        subMenu: [
          {
            icon: "",
            pageName: "fiches.commissions",
            title: "Komisyon Faturaları",
            roles: [],
            permissions: [],
          },
          {
            icon: "",
            pageName: "fiches.receipts",
            title: "Dekont Listesi",
            roles: [],
            permissions: [],
          },
          {
            icon: "",
            pageName: "fiches.moneys",
            title: "Hakediş Listesi",
            roles: [],
            permissions: [],
          },
          {
            icon: "",
            pageName: "fiches.report",
            title: "Gelir & Gider",
            roles: [],
            permissions: [],
          },
        ],
      },
      "devider",
      {
        icon: "feather-book",
        pageName: "categories",
        title: "Ürün Kategorileri",
        roles: [],
        permissions: ["categories.list"],
      },
      {
        icon: "feather-grid",
        pageName: "product-groups",
        title: "Ürün Listesi",
        roles: [],
        permissions: ["products.list"],
      },
      {
        icon: "feather-award",
        pageName: "comments",
        title: "Ürün Yorumları",
        roles: [],
        permissions: ["comments.list"],
      },
      {
        icon: "feather-list",
        pageName: "product-tabs",
        title: "Ürün Sekmeleri",
        roles: [],
        permissions: ["contents.list"],
      },
      "devider",
      {
        icon: "feather-layout",
        pageName: "contents",
        title: "Sayfalar",
        roles: [],
        permissions: ["contents.list"],
      },
      {
        icon: "feather-menu",
        pageName: "menus",
        title: "Site Menüsü",
        roles: [],
        permissions: ["contents.list"],
      },
      {
        icon: "feather-users",
        pageName: "users",
        title: "Kullanıcılar",
        roles: [],
        permissions: ["users.list"],
      },
      "devider",
      {
        icon: "feather-shopping-bag",
        pageName: "merchants",
        title: "Mağazalar",
        roles: [],
        permissions: ["merchants.list"],
      },
      {
        icon: "feather-file-text",
        pageName: "merchant.applications",
        title: "Mağaza Başvuruları",
        roles: [],
        permissions: ["merchants.list"],
      },
      "devider",
      {
        icon: "feather-hard-drive",
        pageName: "file-manager",
        title: "Dosya Yönetim Sistemi",
        roles: [],
        permissions: ["files.list"],
      },
      {
        icon: "feather-database",
        pageName: "caches",
        title: "Ön Bellek Ayarları",
        roles: [],
        permissions: ["cache.list"],
      },
      {
        icon: "feather-settings",
        pageName: "",
        title: "Tanımlamalar",
        subMenu: [
          {
            icon: "",
            pageName: "definitions.stock-group-codes",
            title: "Stok Grup Kodları",
            roles: ["admin"],
            permissions: [],
          },
        ],
      },
      {
        icon: "feather-settings",
        pageName: "",
        title: "Yönetici Ayarları",
        subMenu: [
          {
            icon: "",
            pageName: "order-forms",
            title: "Sipariş Formları",
            roles: ["admin"],
            permissions: [],
          },
          {
            icon: "",
            pageName: "material-categories",
            title: "Materyel Kategorileri",
            roles: [],
            permissions: ["categories.list"],
          },
          {
            icon: "",
            pageName: "price-calculation-rules",
            title: "Fiyat Hesap Kuralları",
            roles: ["admin"],
            permissions: [],
          },
          {
            icon: "",
            pageName: "filter.families",
            title: "Filtre Grupları",
            roles: [],
            permissions: ["filters.list"],
          },
          {
            icon: "",
            pageName: "filters",
            title: "Filtreler",
            roles: [],
            permissions: ["filters.list"],
          },
          {
            icon: "",
            pageName: "roles",
            title: "Kullanıcı Rolleri",
            roles: ["admin"],
            permissions: [],
          },
        ],
      },
    ],
  },
};

// Declare state
export type State = typeof state;
