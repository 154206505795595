
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useReadRecord } from "@/utils/records/read";
import {
  OrderGroupInterface,
  GetOrderGroupResult,
  orderService,
} from "@/services";
import OrderSideNavigation from "@/views/orders/detail/components/SideNavigation.vue";
import OrderMerchant from "@/views/orders/detail/components/merchant/Main.vue";
import { get } from "lodash";
import PrintDocument from "@/components/print-document/Main.vue";

export default defineComponent({
  name: "OrderDetail",
  components: { PrintDocument, OrderMerchant, OrderSideNavigation },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const orderGroupId = computed<any>(() => route.params.id);
    const { data, loading, fetch } = useReadRecord<
      OrderGroupInterface,
      GetOrderGroupResult
    >({
      id: orderGroupId.value,
      serviceMethod: orderService.show,
      fail() {
        router.go(-1);
      },
    });

    const merchants = computed(() => {
      return get(data.value, "merchants", []);
    });

    const reloadData = () => {
      fetch(orderGroupId.value).finally();
    };

    watch(orderGroupId, (val) => fetch(val).finally(), {
      flush: "post",
    });
    onMounted(() => {
      reloadData();
    });

    return {
      orderGroupId,
      loading,
      data,
      merchants,
      reloadData,
    };
  },
});
