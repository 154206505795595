
import { defineComponent, reactive } from "vue";
import { ConfirmOptions } from "./index";
import { set, get, cloneDeep } from "lodash";
import VueModal from "@/components/modal/Main.vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "Confirm",
  components: { VueModal },
  setup() {
    const modalRefId = helper.uuidv4();
    // region Reactive
    const defaultOptions = {
      title: "",
      text: "",
      icon: "",
      iconClass: "",
      confirmText: "Devam Et",
      confirmClass: "btn-danger",
      cancelText: "İptal",
      cancelClass: "",
      textClass: "",
      callback: () => null,
      cancelCallback: () => null,
    };

    const options = reactive(cloneDeep(defaultOptions)) as ConfirmOptions;
    // endregion Reactive

    // region Methods
    const show = (ctx: ConfirmOptions): void => {
      cash(`#${modalRefId}`).modal("show");
      Object.keys(defaultOptions).map((item) => {
        set(options, item, get(defaultOptions, item, null));
      });
      Object.keys(ctx).map((item) => {
        set(options, item, get(ctx, item, null));
      });
    };

    const hide = (): void => {
      cash(`#${modalRefId}`).modal("hide");
    };

    const onConfirm = (): void => {
      if (typeof options.callback === "function") options.callback();
      hide();
    };

    const onCancel = (): void => {
      if (typeof options.cancelCallback === "function")
        options.cancelCallback();
      hide();
    };
    //

    return {
      modalRefId,
      options,
      show,
      hide,
      onConfirm,
      onCancel,
    };
  },
});
