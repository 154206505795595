import { GeneralApiResponse, GetContentResult, useAxios } from "@/services";

export * from "./api.types";

export const contentService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetContentResult>>(`/api/contents/${id}`),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetContentResult>>(
      `/api/contents`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetContentResult>>(
      `/api/contents/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/contents/${id}`),
};
