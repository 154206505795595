
import { computed, defineComponent } from "vue";
import { OrderLineInterface } from "@/services";
import { get } from "lodash";
import { useStore } from "@/store";
import { getOrderLines } from "@/utils/recursive-form-data";

export default defineComponent({
  name: "OrderFormLine",
  props: {
    orderLine: {
      type: Object as () => OrderLineInterface,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();

    const formSchemas = computed(() => {
      return store.getters["order/forms"];
    });

    const getOrderFormDetail = (id: any) => {
      let returnForm = { id };
      formSchemas.value.map((form) => {
        if (form.id && form.id * 1 === id * 1) returnForm = { id, ...form };
      });
      return returnForm;
    };

    const form = computed(() => {
      const formId = get(props.orderLine, "form_id") * 1;
      const f: any = getOrderFormDetail(formId);
      return {
        ...f,
        data: getOrderLines(formId, formSchemas.value, [props.orderLine]),
      };
    });

    return {
      form,
    };
  },
});
