
import { computed, defineComponent, ref } from "vue";
import { get, has, isString, cloneDeep } from "lodash";
import { helper } from "@/utils/helper";
import { debounce } from "debounce";

export default defineComponent({
  name: "VueTextarea",
  props: {
    modelValue: { default: () => "" },
    modelModifiers: { default: () => ({}) },
    hasError: {
      type: Boolean,
      default: () => false,
    },
    counter: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const inputRef = ref<HTMLInputElement>();

    const stringLength = computed<number>(() => {
      if (props.modelValue) return props.modelValue.toString().length;
      return 0;
    });

    let changeValue = (e: any): void => {
      let str: string = cloneDeep(get(e, "target.value", ""));
      if (has(props.modelModifiers, "capitalize") && isString(str)) {
        str = helper.capitalizeEachWords(str);
      }
      if (has(props.modelModifiers, "uppercase") && isString(str)) {
        str = helper.upperCase(str);
      }
      emit("update:modelValue", str);
    };

    if (has(props.modelModifiers, "lazy")) {
      changeValue = debounce(changeValue, 500);
    }

    const onFocus = () => {
      if (
        inputRef.value &&
        typeof inputRef.value.setSelectionRange === "function"
      ) {
        inputRef.value?.setSelectionRange(0, stringLength.value);
      }
    };

    return {
      inputRef,
      stringLength,
      changeValue,
      onFocus,
    };
  },
});
