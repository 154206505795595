
import { defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { FilterFamily } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";

export default defineComponent({
  name: "FilterFamilyGeneralInformation",
  components: {
    VueSelect2,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => FilterFamily,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableQuery = reactive<FilterFamily>(toRaw(props.query));

    const filterSelect2Options: Select2Options = {
      ajaxQuery: {
        filter: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    return {
      editableQuery,
      filterSelect2Options,
    };
  },
});
