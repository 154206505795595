<template>
  <!-- BEGIN: Mobile Menu -->
  <div
    :class="{
      'mobile-menu--dashboard': dashboardLayout,
    }"
    class="mobile-menu md:hidden"
  >
    <div class="mobile-menu-bar">
      <div>
        <img class="h-12" :src="logo" />
      </div>
      <a href="javascript:;" @click="toggleMobileMenu">
        <i
          class="feather-bar-chart-2 text-4xl text-white inline-block transform -rotate-90"
        ></i>
      </a>
    </div>
    <transition @enter="enter" @leave="leave">
      <ul v-if="activeMobileMenu" class="mobile-menu-box py-4 hidden">
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu">
          <li
            v-if="menu === 'devider'"
            :key="menu + menuKey"
            class="menu__devider my-4"
          ></li>
          <li v-else :key="menu + menuKey">
            <a
              href="javascript:;"
              class="menu"
              :class="{
                'menu--active': menu.active,
                'menu--open': menu.activeDropdown,
              }"
              @click="linkTo(menu, router)"
            >
              <div class="menu__icon">
                <i :class="menu.icon"></i>
              </div>
              <div class="menu__title">
                {{ menu.title }}
                <div
                  v-if="menu.subMenu"
                  class="menu__sub-icon"
                  :class="{ 'transform rotate-180': menu.activeDropdown }"
                >
                  <i class="feather-chevron-down"></i>
                </div>
              </div>
            </a>
            <!-- BEGIN: Second Child -->
            <transition @enter="enter" @leave="leave">
              <ul v-if="menu.subMenu && menu.activeDropdown">
                <li
                  v-for="(subMenu, subMenuKey) in menu.subMenu"
                  :key="subMenuKey"
                >
                  <a
                    href="javascript:;"
                    class="menu"
                    :class="{ 'menu--active': subMenu.active }"
                    @click="linkTo(subMenu, router)"
                  >
                    <div class="menu__icon">
                      <i class="feather-activity"></i>
                    </div>
                    <div class="menu__title">
                      {{ subMenu.title }}
                      <div
                        v-if="subMenu.subMenu"
                        class="menu__sub-icon"
                        :class="{
                          'transform rotate-180': subMenu.activeDropdown,
                        }"
                      >
                        <i class="feather-chevron-down"></i>
                      </div>
                    </div>
                  </a>
                  <!-- BEGIN: Third Child -->
                  <transition @enter="enter" @leave="leave">
                    <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                      <li
                        v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                        :key="lastSubMenuKey"
                      >
                        <a
                          href="javascript:;"
                          class="menu"
                          :class="{ 'menu--active': lastSubMenu.active }"
                          @click="linkTo(lastSubMenu, router)"
                        >
                          <div class="menu__icon">
                            <i class="feather-zap"></i>
                          </div>
                          <div class="menu__title">
                            {{ lastSubMenu.title }}
                          </div>
                        </a>
                      </li>
                    </ul>
                  </transition>
                  <!-- END: Third Child -->
                </li>
              </ul>
            </transition>
            <!-- END: Second Child -->
          </li>
        </template>
        <!-- END: First Child -->
      </ul>
    </transition>
  </div>
  <!-- END: Mobile Menu -->
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave,
} from "./index";
import { nestedMenu } from "@/layouts/default";

export default defineComponent({
  name: "MobileMenu",
  props: {
    layout: {
      type: String,
      default: "side-menu",
    },
    dashboardLayout: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const mobileMenu = computed(() =>
      nestedMenu(store.getters["sideMenu/menu"], route, store)
    );

    const logo = store.getters["system/logo"];

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(mobileMenu.value);
      }
    );

    onMounted(() => {
      formattedMenu.value = $h.toRaw(mobileMenu.value);
    });

    return {
      activeMobileMenu,
      toggleMobileMenu,
      formattedMenu,
      router,
      logo,
      linkTo,
      enter,
      leave,
    };
  },
});
</script>
