<template>
  <node-view-wrapper class="t-accordion-item">
    <div class="t-accordion-header flex items-center" contenteditable="false">
      <div class="flex-shrink-0 flex">
        <a
          href="javascript:;"
          class="tiptap-accordion-button drag-handle"
          draggable="true"
          data-drag-handle
        >
          <i class="feather-move text-xl"></i>
        </a>
        <a
          href="javascript:;"
          class="tiptap-accordion-button text-red-500"
          @click="deleteItem"
          v-if="isEnabled"
        >
          <i class="feather-trash-2 text-xl"></i>
        </a>
        <a
          href="javascript:;"
          class="tiptap-accordion-button"
          @click="toggleVisible"
        >
          <i
            class="feather-arrow-right text-2xl inline-block transition-all ease-in-out"
            :class="{ 'transform rotate-90': !!isVisible }"
          ></i>
        </a>
      </div>
      <VueInput
        class="flex-1 rounded-none border-0 focus:ring-0"
        placeholder="Lütfen buraya başlığınızı yazın..."
        v-model="title"
        @keydown.enter.prevent.stop="visibleState"
        :disabled="!isEnabled"
      />
    </div>
    <node-view-content
      class="t-accordion-collapse"
      :class="{ hidden: !isVisible }"
    />
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from "@tiptap/vue-3";
import VueInput from "@/components/form-elements/input/Main";
import { getNodeType } from "@tiptap/core/src/helpers/getNodeType";
import { get } from "lodash";
export default {
  name: "TiptapVueAccordion",
  components: {
    VueInput,
    NodeViewWrapper,
    NodeViewContent,
  },
  props: nodeViewProps,
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    isEnabled() {
      return get(this.editor, "options.editable") || false;
    },
    title: {
      get() {
        return get(this.node, "attrs.title") || "";
      },
      set(value) {
        this.updateAttributes({
          title: value || "",
        });
      },
    },
  },
  methods: {
    toggleVisible() {
      if (!this.isVisible) this.visibleState();
      else this.isVisible = false;
    },
    visibleState() {
      this.isVisible = true;
      this.$nextTick(() => {
        this.editor.commandManager
          .chain()
          .setNodeSelection(this.getPos() + 1)
          .focus()
          .run();
      });
    },
    deleteItem() {
      this.editor.commandManager
        .chain()
        .setNodeSelection(this.getPos() + 1)
        .focus()
        .run();

      let permission = true;
      const state = this.editor.state;
      const type = getNodeType("accordion", state.schema);
      const $pos = state.tr.selection.$anchor;

      for (let depth = $pos.depth; depth > 0; depth -= 1) {
        const node = $pos.node(depth);

        if (node.type === type && node.childCount < 2) {
          permission = false;
        }
      }

      if (permission) {
        this.deleteNode();
      }
    },
  },
};
</script>
