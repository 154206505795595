
import { defineComponent, onMounted, ref, watch } from "vue";
import { helper } from "@/utils/helper";
import Tippy from "@/components/tippy/Main";
import Draggable from "vuedraggable";
import { cloneDeep, get, isArray } from "lodash";
import VueModal from "@/components/modal/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";

export default defineComponent({
  name: "VideoList",
  components: {
    VueSelect,
    VueInput,
    VueInputContainer,
    VueModal,
    Tippy,
    Draggable,
  },
  props: {
    modelValue: {
      default: [],
    },
    hasMultipleSite: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const uuid = helper.uuidv4();
    const editIndex = ref<number>(-1);
    const defaultQuery = {
      uuid: "",
      type: "youtube",
      id: "",
      url: "",
      visible: props.hasMultipleSite ? "only_self" : "all",
      title: "",
    };
    const query = ref(cloneDeep(defaultQuery));
    const selected = ref<any[]>([]);

    const setSelected = () => {
      const value = cloneDeep(props.modelValue);
      if (value) {
        selected.value = isArray(value) ? value : [value];
      } else {
        selected.value = [];
      }
    };

    const add = () => {
      editIndex.value = -1;
      query.value = { ...cloneDeep(defaultQuery), uuid: helper.uuidv4() };
      cash(`#${uuid}`).modal("show");
    };

    const edit = (index: number) => {
      editIndex.value = index;
      query.value = {
        ...defaultQuery,
        ...cloneDeep(get(selected.value, `${index}`) || {}),
      };
      cash(`#${uuid}`).modal("show");
    };

    const remove = (index: number) => {
      selected.value.splice(index, 1);
      emit("update:model-value", selected.value);
    };

    const save = () => {
      if (query.value.type === "youtube") {
        const regex = new RegExp(
          "^.*(?:youtu\\.be\\/|v\\/|u\\/\\w\\/|embed\\/|watch\\?v=|\\&v=)([^#\\&\\?]*).*"
        );

        const match = regex.exec(query.value.url);

        query.value.id = get(match, 1) || "";
        query.value.url = `https://www.youtube.com/watch?v=${query.value.id}`;
      }

      if (editIndex.value === -1) {
        selected.value.push(query.value);
      } else {
        selected.value[editIndex.value] = {
          ...selected.value[editIndex.value],
          ...query.value,
        };
      }

      emit("update:model-value", selected.value);
      query.value = cloneDeep(defaultQuery);
      cash(`#${uuid}`).modal("hide");
    };

    const getThumbnail = (item: any): string => {
      switch (get(item, "type")) {
        case "youtube":
          return `https://i.ytimg.com/vi/${get(item, "id")}/default.jpg`;
        default:
          return "";
      }
    };

    onMounted(() => {
      setSelected();
    });

    watch(() => props.modelValue, setSelected, { flush: "post" });

    return {
      uuid,
      query,
      selected,
      add,
      edit,
      remove,
      save,
      getThumbnail,
    };
  },
});
