
import { defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { PostMerchant } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import VueTextarea from "@/components/form-elements/textarea/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import VueSelect from "@/components/form-elements/select/Main.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "MerchantGeneralInformation",
  components: {
    VueSelect,
    VueSelect2,
    VueTextarea,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => PostMerchant,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();
    const editableQuery = reactive<PostMerchant>(toRaw(props.query));

    const formIdSelect2Options: Select2Options = {
      multiple: true,
      data: store.getters["order/forms"].map((form) => {
        return { id: form.id, text: form.title };
      }),
    };

    return {
      editableQuery,
      formIdSelect2Options,
    };
  },
});
