import {
  FormLine,
  OrderFormInterface,
  ProductGroupInterface,
} from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";

import { defineComponent } from "vue";
import { cloneDeep, get, max, sum } from "lodash";
import { debounce } from "debounce";

export default defineComponent({
  props: {
    query: {
      type: Object as () => ProductGroupInterface,
      required: true,
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      required: true,
    },
    forms: {
      type: Object as () => { data: OrderFormInterface[] },
      default: () => ({
        data: [],
      }),
    },
    formPath: {
      type: Array as () => any[],
      default: () => [],
    },
  },
  data() {
    return {
      lineValue: "",
    };
  },
  computed: {
    lines(): FormLine[] {
      const linesPath = this.formPath.slice(
        0,
        this.formPath.lastIndexOf("data") + 1
      );
      return get(this.forms, linesPath, []);
    },
    line(): FormLine {
      return get(this.forms, this.formPath);
    },
    lineTitle(): string {
      return get(this.line, "title", "");
    },
    lineDescription(): string {
      return (
        get(this.line, "form_description", "") ||
        get(this.line, "description", "")
      );
    },
    isRequired(): boolean {
      return (
        get(this.line, "required", "0") * 1 === 1 ||
        get(this.line, "master", "0") * 1 === 1 ||
        get(this.line, "select_first", "0") * 1 === 1
      );
    },
    isMaster(): boolean {
      return get(this.line, "master", "0") * 1 === 1;
    },
    parentLineValue() {
      return get(this.line, "value");
    },
    validationPath(): string {
      return ["materials", ...this.formPath, "value"].join(".");
    },
    priceCalculationRules(): Record<string, any> {
      return get(this.query, "price_calculation_rules", {});
    },
    widthIndex(): number {
      let rspIndex = -1;
      this.lines.map((line, lineIndex) => {
        if (
          ["width", "widthmeter"].indexOf(line.type) > -1 &&
          rspIndex === -1
        ) {
          rspIndex = lineIndex;
        }
      });
      return rspIndex;
    },
    widthLine(): FormLine {
      return get(this.lines, this.widthIndex);
    },
    widthValues(): any[] {
      return get(this.widthLine, "value", "")
        .toString()
        .split("+")
        .filter((o: any) => !!o)
        .map((o: any) => o * 1);
    },
    heightIndex() {
      let rspIndex = -1;
      this.lines.map((line, lineIndex) => {
        if (
          ["height", "heightmeter"].indexOf(line.type) > -1 &&
          rspIndex === -1
        ) {
          rspIndex = lineIndex;
        }
      });
      return rspIndex;
    },
    heightLine(): FormLine {
      return get(this.lines, this.heightIndex);
    },
    heightValues(): any[] {
      return get(this.heightLine, "value", "")
        .toString()
        .split("+")
        .filter((o: any) => !!o)
        .map((o: any) => o * 1);
    },
    masterProductIndex() {
      let rspIndex = -1;
      this.lines.map((line, lineIndex) => {
        if (
          ["product"].indexOf(line.type) > -1 &&
          line.master === 1 &&
          rspIndex === -1
        ) {
          rspIndex = lineIndex;
        }
      });
      return rspIndex;
    },
    masterProductLine(): FormLine {
      return get(this.lines, this.masterProductIndex);
    },
    sumOfWidthInForm(): number {
      return sum(this.widthValues);
    },
    maxOfWidthInForm(): number {
      return max(this.widthValues);
    },
    sumOfHeightInForm(): number {
      return sum(this.heightValues);
    },
    maxOfHeightInForm(): number {
      return max(this.heightValues);
    },
  },
  watch: {
    parentLineValue(value) {
      this.lineValue = value;
    },
  },
  beforeMount() {
    this.lineValue = cloneDeep(this.parentLineValue);
    this.onChangeValue = debounce(this.onChangeValue, 500);
  },
  methods: {
    onChangeValue(e: any) {
      if (e !== this.lineValue) {
        this.lineValue = e;
        this.$emit("update:line-value", e, this.formPath);
      }
    },
  },
});
