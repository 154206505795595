
import { defineComponent, onBeforeUnmount, onMounted, provide, ref } from "vue";
import SideNavigation from "@/layouts/default/SideNavigation.vue";
import TopNavigation from "@/layouts/default/TopNavigation.vue";
import { ConfirmRef } from "@/components/confirm";
import Confirm from "@/components/confirm/Main.vue";
import ColorPicker, {
  ColorPickerRef,
} from "@/components/color-picker/Main.vue";
import FileManagerModal from "@/components/file-manager/Modal.vue";
import { FileManagerRef } from "@/components/file-manager";
import MobileMenu from "@/components/mobile-menu/Main.vue";
import PdfViewer from "@/components/pdf-viewer/Main.vue";

export default defineComponent({
  name: "LayoutDefault",
  components: {
    PdfViewer,
    MobileMenu,
    FileManagerModal,
    Confirm,
    ColorPicker,
    TopNavigation,
    SideNavigation,
  },
  setup() {
    const confirmRef = ref<ConfirmRef>();
    provide("confirmRef", confirmRef);

    const fileManagerRef = ref<FileManagerRef>();
    provide("fileManagerRef", fileManagerRef);

    const colorPickerRef = ref<ColorPickerRef>();
    provide("colorPickerRef", colorPickerRef);

    const pdfViewerRef = ref();
    provide("pdfViewerRef", pdfViewerRef);

    const dashboardLayout = ref(false);
    provide("setDashboardLayout", (newVal: boolean) => {
      dashboardLayout.value = newVal;
    });
    onMounted(() => {
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .removeClass("welcome")
        .addClass("main");
    });
    onBeforeUnmount(() => {
      cash("body").removeClass("main");
    });
    return {
      dashboardLayout,
      confirmRef,
      fileManagerRef,
      colorPickerRef,
      pdfViewerRef,
    };
  },
});
