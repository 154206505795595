
import { defineComponent, inject, Ref, watch } from "vue";
import {
  EditorContent,
  useEditor,
  FloatingMenu,
  BubbleMenu,
  isNodeSelection,
} from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Paragraph from "@tiptap/extension-paragraph";
import TextStyle from "@tiptap/extension-text-style";
import ListItem from "@tiptap/extension-list-item";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import Heading from "@tiptap/extension-heading";
import Placeholder from "@tiptap/extension-placeholder";
import Focus from "@tiptap/extension-focus";
import Link from "@tiptap/extension-link";
import { MostCommentedProducts } from "@/components/assets/extensions/most-commented-products";
import { CategoryContainer } from "@/components/assets/extensions/category-container";
import { PhotoGallery } from "@/components/assets/extensions/photo-gallery";
import { CategoryPromotion } from "@/components/assets/extensions/category-promotion";
import {
  Accordion,
  AccordionItem,
} from "@/components/assets/extensions/accordion";
import { FileManagerRef } from "@/components/file-manager";
import { Photo } from "@/components/assets/extensions/photo";
import { CoverPhoto } from "@/components/assets/extensions/cover-photo";
import {
  ImageSlider,
  ImageSliderItem,
} from "@/components/assets/extensions/image-slider";
import { Video } from "@/components/assets/extensions/video";
import { cloneDeep } from "lodash";
import { IsCompare } from "@/utils/isCompare";
import { isTextSelection } from "@tiptap/core";

export default defineComponent({
  name: "ContentAssetTiptap",
  components: {
    EditorContent,
    FloatingMenu,
    BubbleMenu,
  },
  props: {
    workspace: {
      type: Object,
      default: () => ({}),
    },
    assetTypes: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const fileManagerRef = inject<Ref<FileManagerRef>>(`fileManagerRef`);

    let extensions = [
      StarterKit.configure({
        paragraph: false,
        heading: false,
        listItem: false,
        orderedList: false,
        bulletList: false,
      }),
      Placeholder.configure({
        placeholder: ({ node }: any) => {
          if (node.type.name === "heading") {
            return "Lütfen buraya başlığınızı yazın...";
          }

          return "Lütfen buraya metninizi yazın...";
        },
      }),
      Underline,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Paragraph.configure({
        HTMLAttributes: {
          class: "tiptap-paragraph",
        },
      }),
      TextStyle,
      BulletList.configure({
        HTMLAttributes: {
          class: "tiptap-bullet-list",
        },
      }),
      OrderedList.configure({
        HTMLAttributes: {
          class: "tiptap-ordered-list",
        },
      }),
      ListItem,
      Heading.configure({
        levels: [1, 2, 3],
        HTMLAttributes: {
          class: "tiptap-heading",
        },
      }),
      Focus.configure({
        className: "has-focus",
        mode: "deepest",
      }),
      // Image.configure({
      //   inline: false,
      //   HTMLAttributes: {
      //     class: "tiptap-image",
      //   },
      // }),
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          class: "tiptap-link",
        },
      }),
      MostCommentedProducts,
      CategoryContainer,
      PhotoGallery,
      CategoryPromotion,
      Accordion,
      AccordionItem,
      CoverPhoto,
      ImageSlider,
      ImageSliderItem,
      Photo,
      Video,
    ];

    const editor = useEditor({
      editable: !props.disabled,
      content: cloneDeep(props.workspace),
      extensions,
      onUpdate: () => {
        emit("update:workspace", cloneDeep(editor.value?.getJSON()));
      },
    });

    watch(
      () => props.workspace,
      (value) => {
        const isSame = IsCompare(
          cloneDeep(value),
          cloneDeep(editor.value?.getJSON())
        );

        if (isSame) {
          return;
        }

        editor.value?.commands.setContent(cloneDeep(value), false);
      },
      {
        flush: "post",
        deep: true,
      }
    );

    const textBubbleMenuShouldShow = ({ view, state, from, to }: any) => {
      const { doc, selection } = state;
      const { empty } = selection;

      // Sometime check for `empty` is not enough.
      // Doubleclick an empty paragraph returns a node size of 2.
      // So we check also for an empty text size.
      const isEmptyTextBlock =
        !doc.textBetween(from, to).length && isTextSelection(state.selection);

      if (
        !view.hasFocus() ||
        empty ||
        isEmptyTextBlock ||
        isNodeSelection(state.selection)
      ) {
        return false;
      }

      return true;
    };

    const changeAssetType = (type: string) => {
      switch (type) {
        case "most-commented-products":
          editor.value?.chain().focus().setMostCommentedProducts().run();
          break;
        case "category-container":
          editor.value?.chain().focus().setCategoryContainer().run();
          break;
        case "photo-gallery":
          editor.value?.chain().focus().setPhotoGallery().run();
          break;
        case "category-promotion":
          editor.value?.chain().focus().setCategoryPromotion().run();
          break;
        case "accordion":
          editor.value?.chain().focus().setAccordion().run();
          break;
        case "image-slider":
          editor.value?.chain().focus().setImageSlider().run();
          break;
        case "cover":
          editor.value?.chain().focus().setCoverPhoto().run();
          break;
        case "photo":
          openFileManager();
          break;
        case "video":
          editor.value?.chain().focus().setVideo().run();
          break;
      }
    };

    const openFileManager = () => {
      fileManagerRef?.value?.show({
        callback: (files) => {
          files.map((file) => {
            if (file.path) {
              editor.value
                ?.chain()
                .focus()
                .setPhoto({
                  photo: file.path,
                  title: file.name || "",
                })
                .run();
              // editor.value
              //   ?.chain()
              //   .focus()
              //   .insertContentAt(editor.value?.state.doc.content.size, {
              //     type: "paragraph",
              //   })
              //   .run();
            }
          });
        },
      });
    };

    return { editor, changeAssetType, textBubbleMenuShouldShow };
  },
});
