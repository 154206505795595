
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRaw,
} from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { CategoryInterface } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import VueTextarea from "@/components/form-elements/textarea/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import { findIndex, set } from "lodash";
import { useStore } from "@/store";

export default defineComponent({
  name: "CategoryGeneralInformation",
  components: {
    VueSelect2,
    VueSelect,
    VueTextarea,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => CategoryInterface,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();

    const enterCustomGoogleCategoryId = ref(false);
    const editableQuery = reactive<CategoryInterface>(toRaw(props.query));

    const googleCategories = store.getters["system/definitions"](
      "google_category_types"
    );

    const formSelect2Options: Select2Options = {
      ajaxQuery: {
        form: {
          "@select": {
            value: "id",
            text: "title",
          },
          "@order": "sq:asc",
        },
      },
    };

    const parentCategorySelect2Options = computed<Select2Options>(() => {
      const whereNot: Record<string, any> = {};
      if (editableQuery.id) set(whereNot, "id", editableQuery.id);
      return {
        ajaxQuery: {
          category: {
            "@select": {
              value: "id",
              text: "title",
            },
            "@whereNot": whereNot,
          },
        },
      };
    });

    const filterFamilySelect2Options: Select2Options = {
      ajaxQuery: {
        filter_family: {
          "@select": {
            value: "id",
            text: "title",
          },
        },
      },
    };

    onMounted(() => {
      enterCustomGoogleCategoryId.value =
        findIndex(googleCategories, {
          value: (editableQuery.google_category_id || "").toString(),
        }) === -1;
    });

    return {
      googleCategories,
      enterCustomGoogleCategoryId,
      editableQuery,
      parentCategorySelect2Options,
      formSelect2Options,
      filterFamilySelect2Options,
    };
  },
});
