
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useFetchRecords } from "@/utils/records/fetch";
import { useFilterRecords } from "@/utils/records/filter";
import Pagination from "@/components/pagination/Main.vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import { helper } from "@/utils/helper";
import {
  GetMaterialProductResult,
  ProductCloneInterface,
  productService,
  selectionService,
} from "@/services";
import { findIndex, forEach, get, isNumber, set } from "lodash";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import FilterButton from "@/components/filter-container/Button.vue";
import Tippy from "@/components/tippy/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import Clipboard from "@/components/clipboard/Main.vue";
import { useRoute, useRouter } from "vue-router";
import { useDeleteRecord } from "@/utils/records/delete";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { MaterialProductExcel } from "@/utils/excel/material-product";
import { MaterialDownloadList } from "@/views/product-materials/list/download-list";
import { useStore } from "@/store";
import VueModal from "@/components/modal/Main.vue";
import VueButton from "@/components/form-elements/button/Main.vue";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";

export default defineComponent({
  name: "MaterialProductList",
  components: {
    VueButton,
    VueModal,
    TransactionStatement,
    Clipboard,
    VueInput,
    Tippy,
    FilterButton,
    VueSelect2,
    VueInputContainer,
    FilterContainer,
    Pagination,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const cloneModalRefId = helper.uuidv4();
    const preparingExcel = ref<boolean>(false);
    const priceKey =
      get(store.getters["auth/profile"], "use_merchant_price") === true
        ? "merchant_price"
        : "price";

    const counts = reactive({
      loading: false,
      active: 0,
      passive: 0,
    });

    const materialCategoryId = computed(() => {
      return get(filterState.fields, "category_id");
    });

    const tempCounts = computed(() => {
      if (!filterCount.value) return counts;
      const obj: Record<string, any> = {
        loading: counts.loading || loading.value,
      };

      forEach(counts, (countVal, countKey) => {
        if (isNumber(countVal)) {
          set(
            obj,
            countKey,
            filterState.fields.status === countKey ? list.meta.total : 0
          );
        }
      });
      return obj;
    });

    const { loading, list, fetch, getFilterValues } = useFetchRecords({
      selectionQuery: () => ({
        source: "product",
        config: {
          "@page": 1,
          "@select": [
            "title",
            "barcode",
            "merchant_id",
            "material_category_id",
            "status",
            "price",
            "merchant_price",
            "vat_rate",
          ],
          "@order": "id:desc",
          "@func": ["withCount", "withCoverPhoto"],
          merchant: {
            "@select": ["id", "title"],
          },
          category: {
            "@select": ["id", "title"],
          },
        },
        filterFormat: {
          q: "@where.q",
          id: "@where._id",
          title: "@where._title",
          barcode: "@where._barcode",
          stock_code: "@where._stock_code",
          item_code: "@where._item_code",
          merchant_id: "@where.merchant_id",
          category_id: "@where.material_category_id",
          status: "@customWhere.status",
        },
      }),
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        id: "",
        title: "",
        barcode: "",
        stock_code: "",
        item_code: "",
        merchant_id: "",
        category_id: get(route.query, "category_id") || "",
        status: "active",
      },
    });

    const { deleteState, triggerDelete, removeDeleteError } = useDeleteRecord({
      serviceMethod: productService.delete,
      success(id: any) {
        const index = findIndex(list.data, { id });
        if (index > -1) {
          list.data.splice(index, 1);
          set(
            counts,
            filterState.fields.status,
            get(counts, filterState.fields.status, 0) * 1 - 1
          );
        }
      },
    });

    const {
      query: cloneQuery,
      processing: cloneProcessing,
      submit: cloneSubmit,
      validationErrors: cloneValidationErrors,
    } = useSubmitRecord<ProductCloneInterface, GetMaterialProductResult>({
      query: {
        id: "",
        original_barcode: "",
        barcode: "",
        merchant_id: "",
        category_id: "",
      },
      updateServiceMethod: productService.clone,
      success(result) {
        if (result?.kind === "ok") {
          cash(`#${cloneModalRefId}`).modal("hide");
          ToastNotify({
            className: "success",
            text: "Kopya Ürün Oluşturuldu!",
          });
          goToEdit(result.data.id);
        }
      },
    });

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const categorySelect2Options = computed<Select2Options>(() => ({
      ajaxQuery: {
        material_category: {
          "@select": { value: "id", text: "title" },
        },
      },
    }));

    const merchantId = computed(() => {
      if (store.getters["auth/isMerchant"])
        return store.getters["auth/merchantId"];
      return filterState.fields.merchant_id;
    });

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
      readTotalCounts();
    });

    const readTotalCounts = () => {
      counts.loading = true;
      selectionService
        .fetch(
          {
            active: {
              "@source": "product",
              "@count": true,
              "@where": {
                status: "1",
                material_category_id: materialCategoryId.value,
              },
            },
            passive: {
              "@source": "product",
              "@count": true,
              "@where": {
                status: "0",
                material_category_id: materialCategoryId.value,
              },
            },
          },
          true
        )
        .then((result) => {
          if (result.kind === "ok") {
            counts.active = get(result.data, "active") || 0;
            counts.passive = get(result.data, "passive") || 0;
          }
        })
        .finally(() => {
          counts.loading = false;
        });
    };

    const changeFilterStatus = (status: any) => {
      filterState.fields.status = status;
      filterFunctions.applyFilter();
    };

    const goToEdit = (id: any) => {
      router.push({
        name: "material-products.edit",
        params: {
          id,
        },
      });
    };

    const downloadToExcel = (withRows = false, withCondition = false) => {
      if (!materialCategoryId.value || !merchantId.value) return;

      preparingExcel.value = true;
      setTimeout(async () => {
        let rows: any[] = [];
        if (withRows) {
          rows = await MaterialDownloadList(
            materialCategoryId.value,
            merchantId.value,
            withCondition
              ? getFilterValues(filterFunctions.formattedQuery())
              : {}
          );
        }

        const filename = `alt_urunleriniz_${helper.formatDate(
          new Date(),
          "DD_MM_YYYY_HH_mm"
        )}`;

        const materialProductExcel = new MaterialProductExcel(
          materialCategoryId.value
        );
        await materialProductExcel.process();
        materialProductExcel.setRows(rows, "primary");
        await materialProductExcel.export(filename);

        preparingExcel.value = false;
      }, 100);
    };

    const openCloneModal = (row: any) => {
      const barcode = get(row, "barcode");
      const time = new Date().getTime();

      cloneQuery.value.id = get(row, "id");
      cloneQuery.value.original_barcode = barcode;
      cloneQuery.value.barcode = `${barcode}-${time}`;
      cloneQuery.value.merchant_id = get(row, "merchant_id");
      cloneQuery.value.category_id = get(row, "category_id");

      cash(`#${cloneModalRefId}`).modal("show");
    };

    const saveClone = () => {
      cloneSubmit(cloneQuery.value.id);
    };

    return {
      cloneModalRefId,
      loading,
      list,
      filterState,
      filterFunctions,
      merchantSelect2Options,
      categorySelect2Options,
      filterCount,
      counts,
      tempCounts,
      deleteState,
      preparingExcel,
      merchantId,
      cloneQuery,
      cloneProcessing,
      cloneValidationErrors,
      priceKey,
      saveClone,
      removeDeleteError,
      triggerDelete,
      changeFilterStatus,
      goToEdit,
      downloadToExcel,
      openCloneModal,
    };
  },
});
