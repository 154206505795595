import { App } from "vue";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { get, isString } from "lodash";
import { useStore } from "@/store";
import md5 from "blueimp-md5";

dayjs.extend(duration);

const store = useStore();

const helpers = {
  cutText(text: string, length: number): string {
    if (text.split(" ").length > 1) {
      const string = text.substring(0, length);
      const splitText = string.split(" ");
      splitText.pop();
      return splitText.join(" ") + "...";
    } else {
      return text;
    }
  },
  formatDate(date: null | string | Date, format = "DD.MM.YYYY HH:mm"): string {
    if (!date) return "";
    return dayjs(date).format(format);
  },
  capitalizeFirstLetter(string: string): string {
    if (string) {
      return helpers.upperCase(string.charAt(0)) + string.slice(1);
    } else {
      return "";
    }
  },
  capitalizeEachWords(string: string): string {
    if (string) {
      return string.replace(/(^|\s)\S/g, (l) => helpers.upperCase(l));
    } else {
      return "";
    }
  },
  convertFileSize(fileSizeInBytes: number): string {
    let i = -1;
    const byteUnits = [" KB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
    do {
      fileSizeInBytes /= 1024;
      i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  },
  upperCase(string: string): string {
    if (string) {
      const letters = {
        i: "İ",
        ş: "Ş",
        ğ: "Ğ",
        ü: "Ü",
        ö: "Ö",
        ç: "Ç",
        ı: "I",
      };
      const newString: string = string.replace(
        /(([iışğüçö]))+/g,
        function (letter: string): string {
          return get(letters, letter, "");
        }
      );
      return newString.toUpperCase();
    } else {
      return "";
    }
  },
  lowerCase(string: string): string {
    if (string) {
      const letters = {
        İ: "i",
        I: "ı",
        Ş: "ş",
        Ğ: "ğ",
        Ü: "ü",
        Ö: "ö",
        Ç: "ç",
      };
      const newString: string = string.replace(
        /(([İIŞĞÜÇÖ]))+/g,
        function (letter: string): string {
          return get(letters, letter, "");
        }
      );
      return newString.toLowerCase();
    } else {
      return "";
    }
  },
  onlyNumber(string: string): string {
    if (string) {
      return string.replace(/\D/g, "");
    } else {
      return "";
    }
  },
  formatCurrency(number: number): string {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, "");
      const rest = formattedNumber.length % 3;
      let currency = formattedNumber.substr(0, rest);
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g);
      let separator;

      if (thousand) {
        separator = rest ? "." : "";
        currency += separator + thousand.join(".");
      }

      return currency;
    } else {
      return "";
    }
  },
  timeAgo(time: string): string | false {
    const date = new Date(
      (time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")
    );
    const diff = (new Date().getTime() - date.getTime()) / 1000;
    const dayDiff = Math.floor(diff / 86400);

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format("MMMM DD, YYYY");
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && "just now") ||
          (diff < 120 && "1 minute ago") ||
          (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
          (diff < 7200 && "1 hour ago") ||
          (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
      (dayDiff === 1 && "Yesterday") ||
      (dayDiff < 7 && dayDiff + " days ago") ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + " weeks ago")
    );
  },
  diffTimeByNow(time: string): {
    days: string | number;
    hours: string | number;
    minutes: string | number;
    seconds: string | number;
    toString(): string;
  } {
    const startDate = dayjs(dayjs().format("YYYY-MM-DD HH:mm:ss").toString());
    const endDate = dayjs(dayjs(time).format("YYYY-MM-DD HH:mm:ss").toString());

    const duration = dayjs.duration(endDate.diff(startDate));
    return helpers.millisecondToDay(Math.floor(duration.asMilliseconds()), "0");
  },
  millisecondToDay(
    milliseconds: number,
    prefix = ""
  ): {
    days: string | number;
    hours: string | number;
    minutes: string | number;
    seconds: string | number;
    toString(): string;
  } {
    let days: string | number = Math.round(milliseconds / 86400000);
    let hours: string | number = Math.round(
      (milliseconds % 86400000) / 3600000
    );
    let minutes: string | number = Math.round(
      ((milliseconds % 86400000) % 3600000) / 60000
    );
    let seconds: string | number = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    );

    if (days.toString().length < 2) days = `${prefix}${days}`;
    if (hours.toString().length < 2) hours = `${prefix}${hours}`;
    if (minutes.toString().length < 2) minutes = `${prefix}${minutes}`;
    if (seconds.toString().length < 2) seconds = `${prefix}${seconds}`;

    return {
      days,
      hours,
      minutes,
      seconds,
      toString: () => {
        const str: string[] = [];
        if (days !== `${prefix}0`) str.push(`${days} Gün`);
        if (hours !== `${prefix}0`) str.push(`${hours} Saat`);
        if (minutes !== `${prefix}0`) str.push(`${minutes} Dakika`);
        if (seconds !== `${prefix}0`) str.push(`${seconds} Saniye`);
        return str.join(" ");
      },
    };
  },
  isset(obj: Record<string, unknown> | number | string): boolean | number {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === "object" || Array.isArray(obj)) {
        return Object.keys(obj).length;
      } else {
        return obj.toString().length;
      }
    }

    return false;
  },
  toRaw(obj: Record<string, any>): any {
    return JSON.parse(JSON.stringify(obj));
  },
  randomNumbers(from: number, to: number, length: number): Array<number> {
    const numbers = [0];
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to));
    }

    return numbers;
  },
  uuidv4(): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  },
  base64Encode(str: string): string {
    return new Buffer(str, "utf-8").toString("base64");
  },
  base64Decode(str: string): string {
    return new Buffer(str, "base64").toString("utf-8");
  },
  formatNameSurname(data: Record<string, any>): string {
    const arr: any[] = [];

    const title = get(data, "title", "");
    const salutation = get(data, "salutation", "");
    const name = get(data, "name", "");
    const surname = get(data, "surname", "");

    if (title && isString(title) && title.length < 10) arr.push(title);
    else if (salutation) arr.push(salutation);

    if (name) arr.push(name);
    if (surname) arr.push(helpers.upperCase(surname));

    return arr.join(" ");
  },
  snakeCaseToCamelCase(str: string): string {
    return helpers.capitalizeEachWords(
      helpers.lowerCase(str).replace(/_/g, " ")
    );
  },
  round(number: any, digits = 2): number {
    if (!number) return 0;
    let decimal = 1;
    for (let i = 0; i < digits; i += 1) {
      decimal *= 10;
    }
    return Math.round(number * decimal) / decimal;
  },
  imagePath(path: string, type = "avatar", site: any = null): string {
    const cdn = store.getters["system/definitions"]("cdn_image");
    if (!site) site = store.getters["system/site"];
    return `${cdn}${type}/${site}/${path}`;
  },
  staticPath(path: string): string {
    const cdn = store.getters["system/definitions"]("cdn_static");
    return `${cdn}${path}`;
  },
  countDecimals(value: any): number {
    if (Math.floor(value * 1) === value * 1) return 0;
    return value.toString().split(".")[1].length || 0;
  },
  currency(value: any, currency = 0): string {
    const sign = "₺";

    const countDecimals = helpers.countDecimals(value);
    return `${(value * 1)
      .toFixed(countDecimals < 2 ? 2 : countDecimals)
      .toString()
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${sign || "-"}`;
  },
  md5HexCode(value: string): string {
    const str = md5(value).substr(0, 6);
    return str ? `#${str}` : "#000000";
  },
  addSpaceToString(value: string, char = 4): string {
    const regex = new RegExp(`(.{${char}})`, "g");
    return value.replace(regex, "$1 ").trim();
  },
  copyToClipboard(text: string): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const clipboardData = window.clipboardData || navigator.clipboard;
    clipboardData.writeText(text);
  },
  invertColor(hex: string, bw = true): string {
    let { r, g, b } = helpers.hexToRGB(hex);
    if (bw) {
      // http://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + helpers.padZero(r) + helpers.padZero(g) + helpers.padZero(b);
  },
  hexToRGB(hex: any): any {
    if (typeof hex !== "string") return "#000000";

    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      return "#000000";
    }
    return {
      r: parseInt(hex.slice(0, 2), 16),
      g: parseInt(hex.slice(2, 4), 16),
      b: parseInt(hex.slice(4, 6), 16),
    };
  },
  padZero(str: string, len = 2): string {
    len = len || 2;
    const zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
  },
  incrementString(value: string): string {
    let carry = 1;
    let res = "";

    for (let i = value.length - 1; i >= 0; i--) {
      let char = value.toUpperCase().charCodeAt(i);

      char += carry;

      if (char > 90) {
        char = 65;
        carry = 1;
      } else {
        carry = 0;
      }

      res = String.fromCharCode(char) + res;

      if (!carry) {
        res = value.substring(0, i) + res;
        break;
      }
    }

    if (carry) {
      res = "A" + res;
    }

    return res;
  },
};

const install = (app: App): void => {
  app.config.globalProperties.$h = helpers;
};

export { install as default, helpers as helper };
