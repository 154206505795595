import { computed, ref, reactive } from "vue";
import {
  GeneralApiProblem,
  GeneralApiResponse,
  PaginationMeta,
  selectionService,
} from "@/services";
import { get, has, set, unset, cloneDeep } from "lodash";
import { ToastNotify } from "@/utils/toast-notify";
import { ComputedRef } from "vue";

type SelectionQuery = {
  source: string;
  config: Record<string, any> | ComputedRef<Record<string, any>>;
  filterFormat?: Record<string, string>;
  withoutQueue?: boolean;
};

type Props<T> = {
  selectionQuery?: (() => SelectionQuery) | SelectionQuery;
  serviceMethod?(params: any): Promise<GeneralApiResponse<T>>;
  success?(result?: any): void;
  fail?(error?: GeneralApiProblem): void;
  finish?(result?: GeneralApiResponse<T>): void;
  formatted?(item: any): any;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useFetchRecords<T>(props: Props<T>) {
  const loading = ref<boolean>(true);
  const selectedRows = ref<number[]>([]);

  const list = reactive<{ data: Record<string, any>[]; meta: PaginationMeta }>({
    data: [],
    meta: { current_page: 1, total: 0 },
  });

  const selectAll = computed<boolean>({
    get: () => selectedRows.value?.length === list.data.length,
    set: (value) => {
      let selected: any = [];
      if (value) {
        selected = list.data.map((item) => item.id || item.key);
      }
      selectedRows.value = selected;
    },
  });

  const fetch = async (query?: Record<string, any>) => {
    if (!props.serviceMethod) {
      await fetchThroughSelection(query);
    } else {
      loading.value = true;
      const result = await props.serviceMethod(query || {});
      if (result.kind !== "ok") {
        ToastNotify({ text: result.message, className: "error" });
        if (props.fail) props.fail(result);
      } else {
        list.data = formattedList(get(result, "data", []));
        list.meta = get(result, "meta", { current_page: 1, total: 0 });
        if (props.success) props.success(result);
        selectedRows.value = [];
      }
      loading.value = false;
    }
  };

  const fetchThroughSelection = async (query?: Record<string, any>) => {
    if (props.selectionQuery) {
      const selectionQuery =
        typeof props.selectionQuery === "function"
          ? props.selectionQuery()
          : props.selectionQuery;
      let config = cloneDeep(selectionQuery.config);
      if (has(selectionQuery.config, "effect")) {
        config = cloneDeep(selectionQuery.config.value);
      }
      unset(config, "@first");
      const params = {
        [selectionQuery.source]: {
          ...getFilterValues(query, cloneDeep(config)),
        },
      };
      if (!has(config, "@offset")) {
        set(params, [selectionQuery.source, "@page"], query?.page || 1);
      }
      loading.value = true;
      const result = await selectionService.fetch(
        params,
        selectionQuery.withoutQueue || false
      );
      try {
        if (result.kind !== "ok") {
          ToastNotify({ text: result.message, className: "error" });
          if (props.fail) props.fail(result);
        } else {
          const { data = [], ...meta } = result.data[selectionQuery.source];
          list.data = formattedList(data);
          list.meta = meta as PaginationMeta;
          if (props.success) props.success(result);
          selectedRows.value = [];
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    }
  };

  const getFilterValues = (
    query?: Record<string, any>,
    params: Record<string, any> = {}
  ) => {
    if (props.selectionQuery) {
      const selectionQuery =
        typeof props.selectionQuery === "function"
          ? props.selectionQuery()
          : props.selectionQuery;

      if (selectionQuery.filterFormat && query) {
        Object.keys(query).map((key) => {
          const value = get(selectionQuery, `filterFormat.${key}`, "");
          if (value) {
            set(params, `${value}`, query[key]);
          }
        });
      }
    }
    return params;
  };

  const formattedList = (list: any[]) => {
    return list.map((item) => {
      if (props.formatted) return props.formatted(item);
      return item;
    });
  };

  return {
    selectedRows,
    selectAll,
    loading,
    list,
    selectionQuery: props?.selectionQuery,
    fetch,
    getFilterValues,
  };
}
