
import { defineComponent, reactive, toRaw } from "vue";
import { PostMerchant } from "@/services";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueButton from "@/components/form-elements/button/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import { get } from "lodash";
import { ValidationErrors } from "@/utils/records/validation-errors";

export default defineComponent({
  name: "MerchantBankInformation",
  components: { Tippy, VueButton, VueInput, VueInputContainer },
  props: {
    query: {
      type: Object as () => PostMerchant,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableQuery = reactive<PostMerchant>(toRaw(props.query));

    const changeDefault = (e: any, index: number) => {
      editableQuery.iban.map((v, i) => {
        if (i === index) v.is_default = get(e, "target.checked", false) * 1;
        else v.is_default = 0;
      });
    };

    const deleteRow = (index: number) => {
      let selectFirst = editableQuery.iban[index].is_default * 1 === 1;
      editableQuery.iban.splice(index, 1);
      if (editableQuery.iban.length > 0 && selectFirst)
        editableQuery.iban[0].is_default = 1;
    };

    const addRow = () => {
      editableQuery.iban.push({
        is_default: editableQuery.iban.length === 0 ? 1 : 0,
        title: "",
        name_surname: "",
        iban: "",
      });
    };

    return {
      editableQuery,
      changeDefault,
      deleteRow,
      addRow,
    };
  },
});
