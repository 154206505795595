
import { defineComponent, onMounted } from "vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import Pagination from "@/components/pagination/Main.vue";
import { useRouter } from "vue-router";
import { findIndex } from "lodash";
import { useFetchRecords } from "@/utils/records/fetch";
import { useFilterRecords } from "@/utils/records/filter";
import { useDeleteRecord } from "@/utils/records/delete";
import { materialCategoryService } from "@/services";
import VueSelect from "@/components/form-elements/select/Main.vue";
import FilterButton from "@/components/filter-container/Button.vue";

export default defineComponent({
  name: "PriceCalculationRules",
  components: {
    FilterButton,
    VueSelect,
    Pagination,
    Tippy,
    TransactionStatement,
    VueInput,
    VueInputContainer,
    FilterContainer,
  },
  setup() {
    const router = useRouter();

    const { loading, list, fetch } = useFetchRecords({
      selectionQuery: {
        source: "material_category",
        config: {
          "@page": 1,
          "@select": ["id", "title", "rule_id", "status", "updated_at"],
          "@order": "id:desc",
          "@func": ["withCount"],
        },
        filterFormat: {
          q: "@where.q",
          id: "@where.id",
          title: "@where._title",
          status: "@where.status",
          rule_id: "@where.rule_id",
        },
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        id: "",
        title: "",
        status: "",
        rule_id: "",
      },
    });

    const { deleteState, triggerDelete, removeDeleteError } = useDeleteRecord({
      serviceMethod: materialCategoryService.delete,
      success(id: any) {
        const index = findIndex(list.data, { id });
        if (index > -1) list.data.splice(index, 1);
      },
    });

    const goToEdit = (id: any) => {
      router.push({ name: "material-categories.edit", params: { id } });
    };

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
    });

    return {
      loading,
      list,
      filterState,
      filterFunctions,
      deleteState,
      filterCount,
      triggerDelete,
      removeDeleteError,
      goToEdit,
    };
  },
});
