
import { computed, defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { PriceCalculationRule } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import VueSelect from "@/components/form-elements/select/Main.vue";

export default defineComponent({
  name: "PriceCalculationRuleAfterCalculation",
  components: {
    VueSelect,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => PriceCalculationRule,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableQuery = reactive<PriceCalculationRule>(toRaw(props.query));

    const type = computed({
      get() {
        return editableQuery.after_rule * 1 >= 0 ? "multiple" : "divide";
      },
      set(variable: string) {
        editableQuery.after_rule =
          Math.abs(editableQuery.after_rule) * (variable === "divide" ? -1 : 1);
      },
    });

    const value = computed({
      get() {
        return Math.abs(editableQuery.after_rule);
      },
      set(variable: any) {
        editableQuery.after_rule =
          Math.abs(variable) * (type.value === "divide" ? -1 : 1);
      },
    });

    return {
      editableQuery,
      type,
      value,
    };
  },
});
