<template>
  <node-view-wrapper class="tiptap-disabled-block tiptap-vue-cover-photo">
    <div contenteditable="false">
      <span class="drag-handle label" draggable="true" data-drag-handle>
        {{ node.attrs.label }}
      </span>
    </div>
    <div class="tiptap-disabled-block-content">
      <VueInputContainer class="col-span-full">
        <template v-slot:label>
          <div class="flex items-center leading-none">
            <span>Video Tipi</span>
          </div>
        </template>
        <VueSelect v-model="type" :disabled="!isEnabled">
          <option value="youtube">YouTube</option>
        </VueSelect>
      </VueInputContainer>
      <VueInputContainer class="col-span-full">
        <template v-slot:label>
          <div class="flex items-center leading-none">
            <span>Video Linki</span>
          </div>
        </template>
        <VueInput v-model="url" :disabled="!isEnabled" />
      </VueInputContainer>
      <VueInputContainer class="col-span-full">
        <template v-slot:label>
          <div class="flex items-center leading-none">
            <span>Alternatif Video Adı</span>
            <Tippy
              class="ml-1.5"
              content="Arama motorları (Google) için anlamlı bir isim girmelisiniz!"
            >
              <i class="feather-help-circle text-xl"></i>
            </Tippy>
          </div>
        </template>
        <VueInput v-model="title" :disabled="!isEnabled" />
      </VueInputContainer>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-3";
import VueInputContainer from "@/components/form-elements/input-container/Main";
import Tippy from "@/components/tippy/Main";
import VueInput from "@/components/form-elements/input/Main";
import { get } from "lodash";
import VueSelect from "@/components/form-elements/select/Main";
export default {
  name: "TiptapVueVideo",
  components: {
    VueSelect,
    VueInput,
    Tippy,
    VueInputContainer,
    NodeViewWrapper,
  },
  props: nodeViewProps,
  computed: {
    title: {
      get() {
        return get(this.node, "attrs.title") || "";
      },
      set(value) {
        this.updateAttributes({
          title: value || "",
        });
      },
    },
    type: {
      get() {
        return get(this.node, "attrs.type") || "";
      },
      set(value) {
        this.updateAttributes({
          type: value || "",
        });
      },
    },
    url: {
      get() {
        return get(this.node, "attrs.url") || "";
      },
      set(value) {
        let id = "";
        let url = "";
        if (this.type === "youtube") {
          const regex = new RegExp(
            "^.*(?:youtu\\.be\\/|v\\/|u\\/\\w\\/|embed\\/|watch\\?v=|\\&v=)([^#\\&\\?]*).*"
          );

          const match = regex.exec(value);

          id = get(match, 1) || "";
          url = `https://www.youtube.com/watch?v=${id}`;
        }

        this.updateAttributes({
          id,
          url,
        });
      },
    },
    isEnabled() {
      return get(this.editor, "options.editable") || false;
    },
  },
};
</script>
