import { RouteRecordRaw, RouterView } from "vue-router";
import FicheCreateOrUpdate from "@/views/fiches/create/Main.vue";
import AccountingReport from "@/views/fiches/list/AccountingReport.vue";
import CommissionInvoices from "@/views/fiches/list/CommissionInvoices.vue";
import OrderFiches from "@/views/fiches/list/OrderFiches.vue";

const route: Array<RouteRecordRaw> = [
  {
    path: "fiches",
    component: RouterView,
    children: [
      {
        path: "create",
        name: "fiches.create",
        component: FicheCreateOrUpdate,
        meta: {
          title: "Fiş Ekle",
        },
      },
      {
        path: "edit/:id",
        name: "fiches.edit",
        component: FicheCreateOrUpdate,
        meta: {
          title: "Fiş Düzenle",
        },
      },
      {
        path: "accounting-report",
        name: "fiches.report",
        component: AccountingReport,
        meta: {
          title: "Muhasebe Raporu",
        },
      },
      {
        path: "commissions",
        name: "fiches.commissions",
        component: CommissionInvoices,
        meta: {
          title: "Komisyon Faturaları",
        },
      },
      {
        path: "receipts",
        name: "fiches.receipts",
        component: OrderFiches,
        meta: {
          title: "Ödeme Kuruluşu Dekont Listesi",
        },
      },
      {
        path: "moneys",
        name: "fiches.moneys",
        component: OrderFiches,
        meta: {
          title: "Ödeme Kuruluşu Hakediş Listesi",
        },
      },
    ],
  },
];

export default route;
