
import { defineComponent } from "vue";
import Tippy from "@/components/tippy/Main.vue";

export default defineComponent({
  name: "Clipboard",
  components: { Tippy },
  props: {
    tag: {
      type: String,
      default: "a",
    },
    text: {
      default: "",
    },
  },
});
