
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  GeneralApiResponse,
  GetFicheResult,
  FicheInterface,
  ficheService,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueButton from "@/components/form-elements/button/Main.vue";
import dayjs from "dayjs";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueDatetimePicker from "@/components/form-elements/datetime-picker/Main.vue";
import VueTextarea from "@/components/form-elements/textarea/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import FicheLines from "@/views/fiches/create/components/Lines.vue";

export default defineComponent({
  name: "FicheCreateOrUpdate",
  components: {
    FicheLines,
    VueSelect2,
    VueTextarea,
    VueDatetimePicker,
    VueInputContainer,
    VueButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const id = computed(() => get(route.params, "id"));

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const {
      data: merchant,
      loading,
      fetch,
    } = useReadRecord<FicheInterface, GetFicheResult>({
      id: id.value,
      serviceMethod: ficheService.show,
      success(result) {
        if (result.kind === "ok") {
          setQuery(result.data);
        }
      },
      fail() {
        router.go(-1);
      },
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<FicheInterface, GeneralApiResponse<GetFicheResult>>({
        query: {
          merchant_id: "",
          description: "",
          time: dayjs().format("YYYY-MM-DD HH:mm"),
          status: 1,
          lines: [],
        },
        storeServiceMethod: ficheService.store,
        updateServiceMethod: ficheService.update,
        success(result) {
          if (result.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "Fiş Kaydedildi!",
            });
            router.go(-1);
          }
        },
      });

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) fetch(id.value);
    });

    return {
      loading,
      processing,
      query,
      merchant,
      validationErrors,
      merchantSelect2Options,
      onSubmit,
    };
  },
});
