
import { defineComponent, onMounted, watch } from "vue";
import { EditorContent, Extensions, useEditor } from "@tiptap/vue-3";
import { cloneDeep, flatten, get, startsWith } from "lodash";
import StarterKit from "@tiptap/starter-kit";

export default defineComponent({
  name: "ProductGroupDescription",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditorContent,
  },
  setup(props, { emit }) {
    const encode = (data: any): string[] => {
      if (get(data, "type", "") === "text") return [get(data, "text") || ""];
      let response: string[] = [];
      const content: any[] = get(data, "content") || [];
      content.map((item) => {
        response = [...response, ...flatten(encode(item))];
      });
      return response.filter((o) => !!o);
    };

    const encodeString = (data: any) => {
      let strValue = encode(cloneDeep(data)).join(" *").trim();
      if (!startsWith(strValue, "*")) strValue = `*${strValue}`;
      return strValue;
    };

    const decode = (str: string): any => {
      const content = str
        .trim()
        .split("*")
        .filter((o) => !!o.trim())
        .map((o) => {
          return {
            type: "paragraph",
            content: [
              {
                type: "text",
                text: o.trim(),
              },
            ],
          };
        });

      return {
        type: "doc",
        content,
      };
    };

    const editor = useEditor({
      content: decode(props.modelValue || ""),
      extensions: [
        StarterKit.configure({
          blockquote: false,
          bold: false,
          bulletList: false,
          code: false,
          codeBlock: false,
          dropcursor: false,
          gapcursor: false,
          hardBreak: false,
          heading: false,
          horizontalRule: false,
          italic: false,
          listItem: false,
          orderedList: false,
          strike: false,
        }),
      ] as Extensions,
      onUpdate: () => {
        emit("update:modelValue", encodeString(editor.value?.getJSON()));
      },
    });

    watch(
      () => props.modelValue,
      (value) => {
        const isSame = encodeString(editor.value?.getJSON()) === value;

        if (isSame) {
          return;
        }

        editor.value?.commands.setContent(decode(value || ""), false);
      }
    );

    onMounted(() => {
      const formatted = encodeString(decode(props.modelValue || ""));
      if (formatted !== props.modelValue) {
        emit("update:modelValue", formatted);
      }
    });

    return {
      editor,
    };
  },
});
