
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import Hansontable, { GridSettings } from "handsontable";

export default defineComponent({
  name: "VueHandsontable",
  props: {
    options: {
      type: Object as () => GridSettings,
      required: true,
    },
  },
  setup(props) {
    const containerRef = ref<HTMLElement>();
    const handsontableRef = ref<HTMLElement>();
    const handsontable = ref<Hansontable>();

    const init = (width = 500) => {
      if (!handsontableRef.value) return;
      handsontable.value = new Hansontable(handsontableRef.value, {
        height: 500,
        width,
        stretchH: "all",
        rowHeaders: true,
        colHeaders: true,
        enterBeginsEditing: false,
        ...props.options,
      });
    };

    watch(
      () => props.options.data,
      (val) => {
        if (handsontable.value) {
          handsontable.value?.loadData(val);
        }
      },
      { flush: "post", deep: true }
    );

    onMounted(() => {
      init(containerRef.value?.clientWidth);
    });

    onBeforeUnmount(() => {
      if (handsontable.value) {
        handsontable.value?.destroy();
      }
    });

    return {
      containerRef,
      handsontableRef,
    };
  },
});
