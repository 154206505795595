
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper } from "@/utils/helper";
import { linkTo, nestedMenu, enter, leave } from "./index";
import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue";

export default defineComponent({
  name: "SideNavigation",
  components: { SideMenuTooltip },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const sideMenu = computed(() =>
      nestedMenu(store.getters["sideMenu/menu"], route, store)
    );

    const logo = store.getters["system/logo"];

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = helper.toRaw(sideMenu.value);
      }
    );

    onMounted(() => {
      formattedMenu.value = helper.toRaw(sideMenu.value);
    });

    return {
      formattedMenu,
      router,
      logo,
      linkTo,
      enter,
      leave,
    };
  },
});
