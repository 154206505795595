import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import TiptapVueComponent from "./Accordion.vue";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    accordion: {
      setAccordion: () => ReturnType;
    };
  }
}

export const Accordion = Node.create({
  name: "accordion",
  group: "block",
  atom: true,
  draggable: true,
  selectable: true,

  content() {
    return `${this.options.itemTypeName}*`;
  },

  addOptions() {
    return {
      itemTypeName: "accordionItem",
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      label: {
        default: "Akordion",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'tiptap-vue-accordion[data-type="draggable-item"]',
        priority: 51,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "tiptap-vue-accordion",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-type": "draggable-item",
      }),
      0,
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(TiptapVueComponent);
  },

  addCommands() {
    return {
      setAccordion:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            content: [
              {
                type: this.options.itemTypeName,
                content: [
                  {
                    type: "paragraph",
                  },
                ],
              },
            ],
          });
        },
    };
  },
});
