
import { defineComponent, ref } from "vue";
import Dropzone from "@/components/dropzone/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { FileType } from "@/services";
import { cloneDeep } from "lodash";
import { helper } from "@/utils/helper";
import VueModal from "@/components/modal/Main.vue";

export default defineComponent({
  name: "UploadFiles",
  components: { VueModal, VueInput, VueInputContainer, Dropzone },
  props: {
    modelValue: { type: Array, default: () => [] },
  },
  setup(props, { emit }) {
    const tags = ref<string>("");
    const modalRefId = helper.uuidv4();
    const uploading = ref(false);

    const showModal = () => {
      cash(`#${modalRefId}`).modal("show");
    };

    const uploaded = (file: FileType) => {
      const list = cloneDeep(props.modelValue);
      list?.splice(0, 0, file);
      emit("update:modelValue", list);
    };

    const completed = () => {
      uploading.value = false;
      cash(`#${modalRefId}`).modal("hide");
    };

    return {
      uploading,
      tags,
      modalRefId,
      showModal,
      uploaded,
      completed,
    };
  },
});
