
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  GeneralApiResponse,
  userRoleService,
  GetUserRoleResult,
  UserRoleInterface,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueButton from "@/components/form-elements/button/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "UserRoleCreateOrUpdate",
  components: {
    VueInputContainer,
    VueInput,
    VueButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const id = computed(() => get(route.params, "id"));

    const permissions = store.getters["system/definitions"]("permissions");

    const {
      data: merchant,
      loading,
      fetch,
    } = useReadRecord<UserRoleInterface, GetUserRoleResult>({
      id: id.value,
      serviceMethod: userRoleService.show,
      success(result) {
        if (result.kind === "ok") {
          setQuery(result.data);
        }
      },
      fail() {
        router.go(-1);
      },
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<UserRoleInterface, GeneralApiResponse<GetUserRoleResult>>(
        {
          query: {
            name: "",
            permissions: [],
          },
          storeServiceMethod: userRoleService.store,
          updateServiceMethod: userRoleService.update,
          success(result) {
            if (result.kind === "ok") {
              ToastNotify({
                className: "success",
                text: "Kullanıcı Rolü Kaydedildi!",
              });

              if (!id.value) {
                router.replace({
                  name: "roles.edit",
                  params: {
                    id: result.data.id,
                  },
                });
              } else {
                setQuery(result.data);
              }
            }
          },
        }
      );

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) fetch(id.value);
    });

    return {
      permissions,
      loading,
      processing,
      query,
      merchant,
      validationErrors,
      onSubmit,
    };
  },
});
