
import { defineComponent, inject, Ref, ref } from "vue";
import VueModal from "@/components/modal/Main.vue";
import { helper } from "@/utils/helper";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueTiptap from "@/components/tiptap/Main.vue";
import { useSubmitRecord } from "@/utils/records/submit";
import {
  GeneralApiResponse,
  GetMerchantFeedbackResult,
  MerchantFeedbackInterface,
  merchantFeedbackService,
} from "@/services";
import { get } from "lodash";
import { ToastNotify } from "@/utils/toast-notify";
import VueSelect from "@/components/form-elements/select/Main.vue";
import VueButton from "@/components/form-elements/button/Main.vue";
import { ConfirmRef } from "@/components/confirm";

export default defineComponent({
  name: "MerchantFeedback",
  components: {
    VueButton,
    VueSelect,
    VueTiptap,
    VueInputContainer,
    VueModal,
  },
  props: {
    buttonClass: {
      type: String,
      default: "",
    },
    buttonLabel: {
      type: String,
      default: "Mağazaya Mesaj Yaz",
    },
    relationId: {
      required: true,
    },
    defaultType: {
      type: String,
      default: "",
    },
    onSaved: {
      type: Function,
      default: () => null,
    },
  },
  setup(props) {
    const modalRefId = helper.uuidv4();
    const selectedType = ref<string>(props.defaultType);
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);

    const types = {
      "product-tab-merchant": {
        title: "Mağazaya Özel Ürün Sekmesi",
        service: merchantFeedbackService.submitProductTab,
      },
      "product-group": {
        title: "Ürün Grubu",
        service: merchantFeedbackService.submitProductGroup,
      },
    };

    const { query, processing, validationErrors, submit, reset } =
      useSubmitRecord<
        MerchantFeedbackInterface,
        GeneralApiResponse<GetMerchantFeedbackResult>
      >({
        query: {
          title: "",
          text: "",
        },
        storeServiceMethod(params: any) {
          const func = get(types, `${selectedType.value}.service`);
          return func(props.relationId, params);
        },
        success(result) {
          if (result.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "Mağaza Geri Bildiriminiz Kaydedildi!",
            });
            props.onSaved(result.data);
            closeModal();
          }
        },
      });

    const onSubmit = () => {
      if (selectedType.value) {
        confirmRef?.value?.show({
          text: "Mağazaya geri bildirim mesajını göndermek istediğinize emin misiniz?",
          icon: "feather-alert-circle",
          iconClass: "text-red-400",
          confirmText: "Evet",
          cancelText: "Hayır",
          callback: () => {
            submit();
          },
        });
      }
    };

    const openModal = () => {
      reset();
      cash(`#${modalRefId}`).modal("show");
    };

    const closeModal = () => {
      cash(`#${modalRefId}`).modal("hide");
    };

    return {
      query,
      processing,
      validationErrors,
      modalRefId,
      selectedType,
      types,
      openModal,
      onSubmit,
    };
  },
});
