
import { defineComponent, reactive } from "vue";
import VueModal from "@/components/modal/Main.vue";
import { helper } from "@/utils/helper";
import {
  QuickPageComponents,
  QuickPageOpenProps,
  components,
} from "@/components/quick-page/index";

export default defineComponent({
  name: "QuickPage",
  components: {
    VueModal,
    ...components,
  },
  setup() {
    const modalRefId = helper.uuidv4();

    const state = reactive<{
      pageKey: QuickPageComponents | null;
      id: any;
      data: Record<string, any>;
      callback?: (result: any) => void;
      referenceKey: string;
    }>({
      pageKey: null,
      id: null,
      data: {},
      callback: undefined,
      referenceKey: helper.uuidv4(),
    });

    const open = (options: QuickPageOpenProps) => {
      state.callback = options.callback;
      state.pageKey = options.pageKey;
      state.id = options.id || null;
      state.data = options.data || {};
      state.referenceKey = helper.uuidv4();
      cash(`#${modalRefId}`).modal("show");
    };

    const close = () => {
      state.pageKey = null;
      cash(`#${modalRefId}`).modal("hide");
    };

    const onSave = (result: any) => {
      if (typeof state.callback === "function") state.callback(result);
      close();
    };

    return {
      modalRefId,
      state,
      open,
      close,
      onSave,
    };
  },
});
