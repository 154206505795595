
import { computed, defineComponent } from "vue";
import { get } from "lodash";
import Tippy from "@/components/tippy/Main.vue";

export default defineComponent({
  name: "OrderMerchantDistribution",
  components: { Tippy },
  props: {
    merchant: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const distributions = computed(() => {
      return get(props.merchant, "distributions");
    });

    return {
      distributions,
    };
  },
});
