
import { defineComponent } from "vue";

export default defineComponent({
  name: "SummaryWidget",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    abbr: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    primaryValue: {
      type: Number,
      default: 0,
    },
    secondaryValue: {
      type: Number,
      default: 0,
    },
    otherValue: {
      type: Number,
      default: 0,
    },
    secondaryTitle: {
      type: String,
      default: "",
    },
    otherTitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#6366f1",
    },
  },
});
