import { selectionService } from "@/services";
import { get, set } from "lodash";

export const ProductGroupDownloadList = async (
  categoryId: any,
  merchantId: any = null,
  condition: Record<string, any> = {},
  page = 1
): Promise<any> => {
  const result = await selectionService.fetch({
    product_group: {
      "@page": page,
      "@paginate": 500,
      "@select": [
        "category_id",
        "merchant_id",
        "product_id",
        "barcode",
        "item_code",
        "stock_code",
        "title",
        "description",
        "status",
        "can_return",
        "min_production_day",
        "max_production_day",
        "materials",
        "filter_brand",
        "filter_size",
        "filter_color",
        "filter_1",
        "filter_2",
        "filter_3",
        "filter_4",
        "filter_5",
        "filter_6",
        "filter_7",
        "filter_8",
        "filter_9",
        "filter_10",
      ],
      ...condition,
      "@where": {
        ...get(condition, "@where", {}),
        category_id: categoryId,
        merchant_id: merchantId,
      },
      "@func": ["excelFormat", "withPhotos", "priceIncludesVat"],
      product: {
        "@select": [
          "price",
          "old_price",
          "merchant_price",
          "merchant_old_price",
          "vat_rate",
          "stock",
          "unit",
          "min_width",
          "max_width",
          "min_height",
          "max_height",
        ],
      },
    },
  });

  let data: any[] = [];

  if (result.kind === "ok") {
    data = get(result.data, "product_group.data", []).map((item: any) => {
      const response: Record<string, any> = {
        category_id: get(item, "category_id") || "",
        merchant_id: get(item, "merchant_id") || "",
        barcode: get(item, "barcode") || "",
        item_code: get(item, "item_code") || "",
        stock_code: get(item, "stock_code") || "",
        title: get(item, "title") || "",
        description: (get(item, "description") || "").replace(/<[^>]*>/g, ""),
        photo_list: get(item, "photo_list") || "",
        status: get(item, "status") || "0",
        can_return: get(item, "can_return") || "0",
        min_production_day: get(item, "min_production_day") || "0",
        max_production_day: get(item, "max_production_day") || "0",
        price_type: get(item, "price_type", "1"),
        price: (get(item, "product.price") || "0")
          .toString()
          .replace(/\./g, ","),
        old_price: (get(item, "product.old_price") || "0")
          .toString()
          .replace(/\./g, ","),
        merchant_price: (get(item, "product.merchant_price") || "0")
          .toString()
          .replace(/\./g, ","),
        merchant_old_price: (get(item, "product.merchant_old_price") || "0")
          .toString()
          .replace(/\./g, ","),
        vat_rate: get(item, "product.vat_rate") || "0",
        stock: get(item, "product.stock") || "0",
        unit: get(item, "product.unit") || "",
        min_width: get(item, "product.min_width") || "0",
        max_width: get(item, "product.max_width") || "0",
        min_height: get(item, "product.min_height") || "0",
        max_height: get(item, "product.max_height") || "0",
        filter_brand: get(item, "filter_brand") || "",
        filter_size: get(item, "filter_size") || "",
        filter_color: get(item, "filter_color") || "",
        filter_1: get(item, "filter_1") || "",
        filter_2: get(item, "filter_2") || "",
        filter_3: get(item, "filter_3") || "",
        filter_4: get(item, "filter_4") || "",
        filter_5: get(item, "filter_5") || "",
        filter_6: get(item, "filter_6") || "",
        filter_7: get(item, "filter_7") || "",
        filter_8: get(item, "filter_8") || "",
        filter_9: get(item, "filter_9") || "",
        filter_10: get(item, "filter_10") || "",
      };

      const lines = get(item, "materials.data.0.lines.data", []);
      lines.map((line: any) => {
        set(response, `material_${line.id}`, line.value);
      });
      return response;
    });

    if (data.length === 500) {
      data = [
        ...data,
        ...(await ProductGroupDownloadList(
          categoryId,
          merchantId,
          condition,
          page + 1
        )),
      ];
    }
  }

  return data;
};
