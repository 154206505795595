
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  Ref,
  ref,
} from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import { useSubmitRecord } from "@/utils/records/submit";
import {
  GeneralApiResponse,
  MassApiResponse,
  QuickUploadInterface,
} from "@/services";
import { useStore } from "@/store";
import { Select2Options } from "@/components/form-elements/select2";
import VueModal from "@/components/modal/Main.vue";
import { helper } from "@/utils/helper";
import { chunk, cloneDeep, forEach, get, groupBy } from "lodash";
import VueHandsontable from "@/components/handsontable/Main.vue";
import { GridSettings } from "handsontable";
import VueButton from "@/components/form-elements/button/Main.vue";
import { ToastNotify } from "@/utils/toast-notify";
import { ConfirmRef } from "@/components/confirm";
import { BaseExcel } from "@/utils/excel/base";
import dayjs from "dayjs";
import Tippy from "@/components/tippy/Main.vue";

interface MASS_CHUNK {
  uuid: any;
  success: any[];
  error: { id: any; text: string }[];
  rows: any[];
}

export default defineComponent({
  name: "QuickUpload",
  props: {
    excelUpload: {
      type: Object as () => BaseExcel,
      required: true,
    },
    method: {
      type: Function,
      required: true,
    },
  },
  components: {
    Tippy,
    VueButton,
    VueHandsontable,
    VueModal,
    VueSelect,
    VueSelect2,
    VueInputContainer,
  },
  setup(props) {
    const store = useStore();
    const modalRefId = helper.uuidv4();
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const readingFile = ref<boolean>(false);
    const cancelledNextRequest = ref<boolean>(false);

    const processingIndex = ref<number>(-1);
    const chunkRows = ref<MASS_CHUNK[]>([]);
    const rows = ref<Array<Record<string, any>>>([]);
    const columns = ref<Array<Record<string, any>>>([]);
    const selectedFile = reactive({
      name: "",
      size: 0,
    });

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const handsontableOptions = computed<GridSettings>(() => {
      return {
        columns: columns.value.map((o) => ({
          data: o.key,
          title: o.title,
          readOnly: true,
        })),
        data: rows.value,
      };
    });

    const waitingRows = computed(() => {
      const response: Array<{ id: any; processing: boolean }> = [];
      chunkRows.value
        .filter(
          (o, i) =>
            i >= processingIndex.value &&
            processing.value &&
            o.success.length === 0 &&
            o.error.length === 0
        )
        .map((group, i) => {
          group.rows.map((o) => {
            response.push({
              id: o.barcode,
              processing: i === 0,
            });
          });
        });
      return response;
    });

    const failedRows = computed(() => {
      const response: Array<{ id: any; text: string }> = [];
      chunkRows.value.map((group) => {
        group.error.map((o) => {
          response.push({
            id: o.id,
            text: o.text,
          });
        });
      });
      return response;
    });

    const successRows = computed(() => {
      const response: Array<any> = [];
      chunkRows.value.map((group) => {
        group.success.map((o) => {
          response.push(o);
        });
      });
      return response;
    });

    const sameBarcodes = computed(() => {
      const barcodes: string[] = [];
      forEach(groupBy(rows.value, "barcode"), (items, barcode) => {
        if (items.length > 1) {
          barcodes.push(barcode);
        }
      });
      return barcodes;
    });

    const { query, processing, validationErrors, submit } = useSubmitRecord<
      QuickUploadInterface,
      GeneralApiResponse<MassApiResponse>
    >({
      query: {
        merchant_id: "",
        if_exists: "1",
        if_not_exists: "1",
        rows: [],
      },
      storeServiceMethod(params) {
        return props.method(params);
      },
      formatRequestBeforeSubmit(request) {
        processingIndex.value++;
        return {
          ...request,
          rows: get(chunkRows.value, `${processingIndex.value}.rows`, []),
        };
      },
      finish(result) {
        const group: MASS_CHUNK = get(
          chunkRows.value,
          `${processingIndex.value}`
        );
        if (result.kind === "ok") {
          group.success = result.data.success || [];
          group.error = result.data.error || [];

          rows.value = rows.value.filter((o) => {
            return group.success.indexOf(o.uuid) === -1;
          });
        } else {
          group.error = group.rows.map((row) => ({
            id: row.barcode,
            text: result.message || "",
          }));
        }

        if (result.kind === "too-many") {
          const times = helper.diffTimeByNow(
            dayjs.unix(result.reset).format("YYYY-MM-DD HH:mm:ss").toString()
          );
          ToastNotify({
            className: "warning",
            text: `${times.seconds} Saniye sonra geri kalan ürünlerinizi göndermeye başlayabilirsiniz.`,
          });
          return;
        }

        if (
          processingIndex.value + 1 < chunkRows.value.length &&
          !cancelledNextRequest.value
        ) {
          submit();
        } else if (rows.value.length === 0) {
          removeFile();
          cash(`#${modalRefId}`).modal("hide");
          ToastNotify({
            className: "success",
            text: "Ürünleriniz başarılı bir şekilde sisteme kayıt edilmiştir!",
          });
        }
      },
    });

    const onSubmit = () => {
      confirmRef?.value?.show({
        title: "Ürünleri yüklemek istediğinize emin misiniz?",
        icon: "feather-alert-circle",
        iconClass: "text-blue-300",
        confirmText: "Evet",
        confirmClass: "btn-success",
        cancelText: "Hayır",
        callback: () => {
          cash(`#${modalRefId}`).modal("show");
          processingIndex.value = -1;
          cancelledNextRequest.value = false;
          chunkRows.value = chunk(cloneDeep(rows.value), 20).map((o) => {
            return {
              uuid: helper.uuidv4(),
              success: [],
              error: [],
              rows: o,
            };
          });
          setTimeout(() => {
            submit();
          }, 100);
        },
      });
    };

    const removeFile = () => {
      rows.value = [];
      columns.value = [];
      selectedFile.name = "";
      selectedFile.size = 0;
    };

    const changeFile = (e: any) => {
      removeFile();
      const file = get(e, "target.files.0");
      selectedFile.name = get(file, "name") || "";
      selectedFile.size = get(file, "size") || 0;
      importExcel(file);
    };

    const importExcel = async (file: any) => {
      readingFile.value = true;
      setTimeout(async () => {
        // const materialProductExcel = new MaterialProductExcel(null);
        props.excelUpload.setMerchantId(query.value.merchant_id);
        await props.excelUpload.process();
        await props.excelUpload.import(file);
        rows.value = props.excelUpload.getRowsFromExcel();
        columns.value = props.excelUpload.getHeadersFromExcel();
        readingFile.value = false;
      }, 100);
    };

    onMounted(() => {
      if (store.getters["auth/isMerchant"]) {
        query.value.merchant_id = store.getters["auth/merchantId"];
      }
    });

    return {
      modalRefId,
      query,
      processing,
      validationErrors,
      merchantSelect2Options,
      selectedFile,
      rows,
      readingFile,
      handsontableOptions,
      processingIndex,
      chunkRows,
      waitingRows,
      failedRows,
      successRows,
      cancelledNextRequest,
      sameBarcodes,
      onSubmit,
      changeFile,
      removeFile,
    };
  },
});
