
import { computed, defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { Content } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import VueTextarea from "@/components/form-elements/textarea/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { get } from "lodash";
import VueDatetimePicker from "@/components/form-elements/datetime-picker/Main.vue";

export default defineComponent({
  name: "ContentGeneralInformation",
  components: {
    VueDatetimePicker,
    VueSelect2,
    VueSelect,
    VueTextarea,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => Content,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableQuery = reactive<Content>(toRaw(props.query));

    const categorySelect2Options = computed(() => ({
      ajaxQuery: {
        category: {
          "@select": { value: "id", text: "title" },
          "@whereNotNull": ["parent_id"],
          "@func": ["withAncestorTitle"],
        },
      },
      dropdownAutoWidth: true,
      templateResult(obj: any): any {
        return `
        <div>${get(obj, "text", "")}</div>
        <div class="text-sm font-medium text-indigo-700 max-w-sm">${get(
          obj,
          "ancestor_title",
          ""
        )}</div>
        `;
      },
    }));

    return { editableQuery, categorySelect2Options };
  },
});
