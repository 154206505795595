
import { defineComponent, ref } from "vue";
import VueButton from "@/components/form-elements/button/Main.vue";
import {
  authService,
  GetForgotPasswordResult,
  PostForgotPassword,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";

export default defineComponent({
  name: "ForgotPasswordView",
  components: { VueInputContainer, VueInput, VueButton },
  setup() {
    const response = ref<string>("");

    const { query, processing, validationErrors, submit } = useSubmitRecord<
      PostForgotPassword,
      GetForgotPasswordResult
    >({
      query: {
        email: "",
      },
      storeServiceMethod: authService.forgotPassword,
      success(result) {
        if (result?.kind === "ok") {
          response.value = result.data.status;
        }
      },
    });

    return {
      processing,
      query,
      validationErrors,
      response,
      submit,
    };
  },
});
