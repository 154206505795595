import { RouteRecordRaw, RouterView } from "vue-router";
import Categories from "@/views/categories/list/Main.vue";
import CategoryCreate from "@/views/categories/create/Main.vue";

const route: Array<RouteRecordRaw> = [
  {
    path: "categories",
    component: RouterView,
    children: [
      {
        path: "",
        name: "categories",
        component: Categories,
        meta: {
          title: "Kategori Listesi",
          roles: [],
          permissions: ["categories.list"],
        },
      },
      {
        path: "create",
        name: "categories.create",
        component: CategoryCreate,
        meta: {
          title: "Kategori Ekle",
          roles: [],
          permissions: ["categories.create"],
        },
      },
      {
        path: "edit/:id",
        name: "categories.edit",
        component: CategoryCreate,
        meta: {
          title: "Kategori Düzenle",
          roles: [],
          permissions: ["categories.edit"],
        },
      },
    ],
  },
];

export default route;
