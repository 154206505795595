/* eslint-disable */
import ExcelJs from "exceljs";
import { cloneDeep, forEach, get, isObject, set } from "lodash";
import { helper } from "@/utils/helper";
import downloadjs from "downloadjs";
import { DataValidation } from "@/types/exceljs";

export const PROMPT_NEW_LINE = "_x000a_";

export interface ExcelHeader {
  width?: number;
  key: string;
  title: string;
  fill: "primary" | "info" | "danger";
  letter?: string;
  validation?: DataValidation;
  numberFormat?: string;
  color?: string;
  rows?: any[];
}

interface Sheet {
  title: string;
  headers: Record<string, ExcelHeader>;
  protected?: boolean;
  state?: "visible" | "hidden";
}

export class BaseExcel {
  protected merchantId: any;
  protected categoryId: any;
  protected protectedKey: string;
  protected sheetIndex: number;
  protected sheets: Record<string, Sheet>;
  protected headerThemes: Record<
    "primary" | "info" | "danger",
    ExcelJs.FillPattern
  >;
  protected wb: ExcelJs.Workbook;
  protected defaultWorksheetProperties: Partial<ExcelJs.WorksheetProperties>;
  protected defaultColumnStyle: any;

  constructor(categoryId: any) {
    this.categoryId = categoryId;
    this.wb = new ExcelJs.Workbook();
    this.protectedKey = "eniyiperde2022";
    this.sheetIndex = -1;
    this.sheets = {};
    this.defaultWorksheetProperties = { defaultRowHeight: 20 };
    this.defaultColumnStyle = {
      alignment: { vertical: "middle", horizontal: "left" },
      font: { size: 13, bold: false, color: { argb: "FF000000" } },
    };
    this.headerThemes = {
      primary: {
        type: "pattern",
        pattern: "darkTrellis",
        fgColor: { argb: "FFF7D6B8" },
        bgColor: { argb: "FFF7D6B8" },
      },
      danger: {
        type: "pattern",
        pattern: "darkTrellis",
        fgColor: { argb: "FFE76C6C" },
        bgColor: { argb: "FFE76C6C" },
      },
      info: {
        type: "pattern",
        pattern: "darkTrellis",
        fgColor: { argb: "FFC0DFFF" },
        bgColor: { argb: "FFC0DFFF" },
      },
    };
  }

  async process(): Promise<void> {
    return new Promise((ok) => {
      ok();
    });
  }

  async baseProcess(): Promise<void> {
    return new Promise((ok) => {
      this.sheetIndex++;
      if (this.getActiveSheet()) {
        this.prepareSheet();
        this.baseProcess();
      }
      ok();
    });
  }

  public setMerchantId(value: any): void {
    this.merchantId = value;
  }

  protected getActiveSheet(): Sheet {
    const sheetKeys = Object.keys(this.sheets);
    return get(this.sheets, `${get(sheetKeys, this.sheetIndex)}`);
  }

  protected sheetField(key: string, field: string): any {
    return get(this.sheets, `${key}.${field}`, "");
  }

  protected findSheetKey(title: string): string {
    let responseKey = "";
    forEach(this.sheets, (val, key) => {
      if (title === get(val, "title") && !responseKey) responseKey = key;
    });
    return responseKey;
  }

  protected findColumnKey(sheetKey: string, title: any): string {
    const headers: Record<string, ExcelHeader> =
      get(this, `sheets.${sheetKey}.headers`) || {};

    let responseKey = "";
    forEach(headers, (val, key) => {
      if (title === get(val, "title") && !responseKey) responseKey = key;
    });
    return responseKey;
  }

  protected findColumnLetter(sheetKey: string, key: any): string {
    return get(this, `sheets.${sheetKey}.headers.${key}.letter`);
  }

  protected getAddressOtherSheetField(key: string): any {
    const title = this.sheetField("other", `title`);
    const column = this.sheetField("other", `headers.${key}`);
    const letter = get(column, "letter", "");
    const rows = get(column, "rows", []);
    return `='${title}'!$${letter}$2:$${letter}$${Math.max(
      rows.length + 1,
      2
    ).toString()}`;
  }

  protected setHeaderLetters(): void {
    forEach(this.sheets, (sheet) => {
      let currentColumnLetter = "A";
      forEach(sheet.headers, (header) => {
        header.letter = currentColumnLetter;
        currentColumnLetter = helper.incrementString(currentColumnLetter);
      });
    });
  }

  private async prepareSheet() {
    const sheet = this.getActiveSheet();
    const ws = this.wb.addWorksheet(sheet.title, {
      properties: this.defaultWorksheetProperties,
      state: sheet.state || "visible",
    });

    ws.columns = Object.keys(sheet.headers).map((key) => {
      const header = get(sheet.headers, key);
      return {
        key: header.key,
        header: header.title,
        width: 30,
        style: {
          ...this.defaultColumnStyle,
          numFmt: header.numberFormat || "@",
        },
      };
    });

    forEach(sheet.headers, (header) => {
      if (header.letter) {
        if (header.validation) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ws.dataValidations.add(
            `${header.letter}2:${header.letter}99999`,
            header.validation
          );
        }

        const headerCell = ws.getCell(`${header.letter}1`);
        headerCell.style = {
          fill: this.headerThemes[header.fill],
          font: {
            bold: true,
            size: 16,
            color: { argb: header.color },
          },
          alignment: {
            horizontal: "center",
            vertical: "middle",
          },
        };

        const rows = header.rows || [];
        rows.map((row, index) => {
          const cell = ws.getCell(`${header.letter}${index + 2}`);
          cell.value = isObject(row) ? get(row, "text", "") : row;
        });
      }
    });
    if (sheet.protected) {
      await ws.protect(this.protectedKey, {});
    }
  }

  setRows(rows: any[], sheetKey: string): void {
    const sheet = get(this.sheets, `${sheetKey}`);
    const ws = this.wb.getWorksheet(sheet.title) as ExcelJs.Worksheet;
    rows.map((row, index) => {
      forEach(row, (columnValue, columnKey) => {
        const letter = get(sheet, `headers.${columnKey}.letter`);
        if (letter) {
          const cell = ws.getCell(`${letter}${index + 2}`);
          cell.value = this.convertValueToLabel(columnKey, columnValue);
        }
      });
    });
  }

  private convertValueToLabel(key: string, value: any): any {
    const rows: any[] = get(this, `sheets.other.headers.${key}.rows`) || [];
    let newValue = cloneDeep(value);
    rows.map((row) => {
      if (isObject(row) && value.toString() === get(row, "value").toString()) {
        newValue = get(row, "text");
      }
    });
    return newValue;
  }

  private convertLabelToValue(key: string, value: any): any {
    const rows: any[] = get(this, `sheets.other.headers.${key}.rows`) || [];
    let newValue = cloneDeep(get(value, "text", value));
    rows.map((row) => {
      if (
        isObject(row) &&
        helper.lowerCase(value.toString()) ==
          helper.lowerCase(get(row, "text").toString())
      ) {
        newValue = get(row, "value");
      }
    });
    return newValue;
  }

  async export(filename = ""): Promise<void> {
    if (!filename) filename = new Date().getTime().toString();
    const blob = await this.getBlob();
    if (blob) downloadjs(blob, filename + ".xlsx");
  }

  import(file: any): Promise<void> {
    return new Promise((ok) => {
      const tempExcelWorkbook = new ExcelJs.Workbook();
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const buffer: any = reader.result;
        if (buffer) {
          tempExcelWorkbook.xlsx
            .load(buffer)
            .then((workbook) => {
              workbook.eachSheet((sheet) => {
                const sheetKey = this.findSheetKey(sheet.name);
                if (sheetKey === "primary") {
                  const originalSheet = this.wb.getWorksheet(
                    sheet.name
                  ) as ExcelJs.Worksheet;
                  for (let i = 1; i <= sheet.columnCount; i++) {
                    const column = sheet.getColumn(i);
                    const columnKey = this.findColumnKey(
                      sheetKey,
                      column.values[1]
                    );
                    if (!columnKey) continue;
                    const originalSheetColumn =
                      originalSheet.getColumn(columnKey);
                    column.eachCell((cell, number) => {
                      if (
                        number > 1 &&
                        cell.value !== undefined &&
                        cell.value !== null
                      ) {
                        const oCell = originalSheet.getCell(
                          `${originalSheetColumn.letter}${number}`
                        );
                        oCell.value = this.convertLabelToValue(
                          columnKey,
                          cell.value
                        );
                      }
                    });
                  }
                }
              });
            })
            .finally(() => {
              ok();
            });
        } else {
          ok();
        }
      };
    });
  }

  getRowsFromExcel(): Array<Record<string, any>> {
    const ws = this.wb.getWorksheet(this.sheetField("primary", "title"));
    if (!ws) return [];

    const converted: Array<Record<string, any>> = [];

    const pureRow: Record<string, any> = {};
    ws.columns.map((column) => {
      const key = this.findColumnKey("primary", column.header);
      if (key) set(pureRow, key, "");
    });

    const rows = ws.getRows(2, ws.rowCount - 1);
    rows?.map((row) => {
      const formattedRow = cloneDeep({ ...pureRow, uuid: helper.uuidv4() });
      forEach(formattedRow, (val, key) => {
        const letter = this.findColumnLetter("primary", key);
        if (letter) {
          set(formattedRow, key, row.getCell(letter).value || "");
        }
      });
      converted.push(formattedRow);
    });

    return converted;
  }

  getHeadersFromExcel(): Array<Record<string, any>> {
    const ws = this.wb.getWorksheet(this.sheetField("primary", "title"));
    if (!ws) return [];

    return ws.columns.map((o) => {
      return {
        key: this.findColumnKey("primary", o.header),
        title: o.header,
      };
    });
  }

  async getBlob(): Promise<Blob | null> {
    const data = await this.wb.xlsx.writeBuffer();
    if (data) {
      return new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    }
    return null;
  }
}
