import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["value", "type"]
const _hoisted_2 = {
  key: 0,
  class: "mt-1 text-indigo-600 text-right text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", _mergeProps({
      ref: "inputRef",
      class: "form-control",
      autocomplete: "eip_1506"
    }, _ctx.$attrs, {
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeValue && _ctx.changeValue(...args))),
      onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onKeypress && _ctx.onKeypress(...args))),
      onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
      class: { 'border-red-200 bg-red-100': _ctx.hasError },
      type: _ctx.type
    }), null, 16, _hoisted_1),
    (_ctx.counter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Karakter Sayısı: " + _toDisplayString(_ctx.stringLength), 1))
      : _createCommentVNode("", true)
  ], 64))
}