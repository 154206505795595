import { BaseExcel } from "@/utils/excel/base";
import ExcelJs from "exceljs";

export class AccountingReportListExcel extends BaseExcel {
  constructor() {
    super(null);
  }

  async process(): Promise<void> {
    this.wb = new ExcelJs.Workbook();
    this.sheetIndex = -1;
    this.sheets = {};
    await this.preparePrimaryColumns();
    await this.setHeaderLetters();
    return await super.baseProcess();
  }

  private async preparePrimaryColumns(): Promise<void> {
    this.sheets["primary"] = {
      title: "Muhasebe Raporu",
      headers: {
        fiche_type: {
          key: "fiche_type",
          title: "Fiş Türü",
          fill: "info",
        },
        fiche_id: {
          key: "fiche_id",
          title: "Fiş No",
          fill: "info",
        },
        order_group_id: {
          key: "order_group_id",
          title: "Sipariş No",
          fill: "info",
        },
        merchant_title: {
          key: "merchant_title",
          title: "Mağaza",
          fill: "info",
        },
        time: {
          key: "time",
          title: "Tarih",
          fill: "info",
        },
        transaction_title: {
          key: "transaction_title",
          title: "İşlem Türü",
          fill: "info",
        },
        receipt_amount: {
          key: "receipt_amount",
          title: "(0%) Dekont Tutarı",
          fill: "info",
          numberFormat: "0.00",
        },
        money_amount: {
          key: "money_amount",
          title: "(18%) Para Tutarı",
          fill: "info",
          numberFormat: "0.00",
        },
        invoice_0_amount: {
          key: "invoice_0_amount",
          title: "(0%) Fatura Tutarı",
          fill: "info",
          numberFormat: "0.00",
        },
        invoice_18_amount: {
          key: "invoice_18_amount",
          title: "(18%) Fatura Tutarı",
          fill: "info",
          numberFormat: "0.00",
        },
      },
    };
  }
}
