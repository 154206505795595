import {
  GeneralApiResponse,
  GetProductTabMerchantResult,
  GetProductTabResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const productTabService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetProductTabResult>>(
      `/api/product-tabs/${id}`
    ),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetProductTabResult>>(
      `/api/product-tabs`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetProductTabResult>>(
      `/api/product-tabs/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/product-tabs/${id}`),
};

export const productTabMerchantService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetProductTabMerchantResult>>(
      `/api/product-tab-merchants/${id}`
    ),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetProductTabMerchantResult>>(
      `/api/product-tab-merchants`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetProductTabMerchantResult>>(
      `/api/product-tab-merchants/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(
      `/api/product-tab-merchants/${id}`
    ),

  changeStatus: (id: any, params: unknown) =>
    useAxios().post<GeneralApiResponse<any>>(
      `/api/product-tab-merchants/${id}/status`,
      params
    ),
};
