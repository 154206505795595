import { useSubmitRecord } from "@/utils/records/submit";
import { computed, ref } from "vue";
import { TransactionInterface } from "@/services";
import { helper } from "@/utils/helper";

export const useEditTransactionLine = () => {
  const errors = ref<string[]>([]);

  const { query, setQuery, reset } = useSubmitRecord({
    query: {
      id: "",
      transaction_type: "ACCRUAL",
      uuid: "",
      amount: "0" as any,
      relations: [] as any[],
    },
  });

  const isDetectRelationFiche = computed(() => {
    return (
      ["INVOICE_0", "INVOICE_18", "RECEIPT", "MONEY"].indexOf(
        query.value.transaction_type
      ) > -1
    );
  });

  const submit = (
    relationAmount: number,
    cb: (data: TransactionInterface) => void
  ) => {
    const absRelationAmount = Math.abs(helper.round(relationAmount));
    const absAmount = Math.abs(helper.round(query.value.amount));
    errors.value = [];

    if (query.value.amount * 1 < 0) {
      errors.value.push("0 dan büyük bir tutar giriniz.");
    }

    if (isDetectRelationFiche.value && query.value.relations.length === 0) {
      errors.value.push("En az 1 tane ilişkili fiş seçiniz.");
    }

    if (absRelationAmount > 0 && absRelationAmount !== absAmount) {
      errors.value.push(
        `Girilen tutar ve seçilen fişlerin tutarları eşleşmiyor. Fark: ${helper.round(
          absAmount - absRelationAmount
        )}`
      );
    }

    if (errors.value.length > 0) return;

    cb(query.value);
  };

  return {
    query,
    setQuery,
    reset,
    isDetectRelationFiche,
    errors,
    submit,
  };
};
