import { RouteRecordRaw, RouterView } from "vue-router";

import MaterialProducts from "@/views/product-materials/list/Main.vue";
import MaterialProductCreate from "@/views/product-materials/create/Main.vue";
import MaterialQuickUpload from "@/views/quick-uploads/Material.vue";

import ProductGroups from "@/views/product-groups/list/Main.vue";
import ProductGroupCreate from "@/views/product-groups/create/Main.vue";
import ProductGroupForms from "@/views/product-groups/form/Main.vue";
import ProductGroupQuickUpload from "@/views/quick-uploads/ProductGroup.vue";

import ProductTabs from "@/views/product-tabs/list/Main.vue";
import ProductTabCreate from "@/views/product-tabs/create/Main.vue";

import ProductTabsMerchant from "@/views/product-tabs/list/MerchantList.vue";
import ProductTabMerchantCreate from "@/views/product-tabs/create/MerchantCreate.vue";

const route: Array<RouteRecordRaw> = [
  {
    path: "product-groups",
    component: RouterView,
    children: [
      {
        path: "",
        name: "product-groups",
        component: ProductGroupForms,
        meta: {
          title: "Ürün Sipariş Formları",
          roles: [],
          permissions: ["products.list"],
        },
      },
      {
        path: "list",
        name: "product-groups.list",
        component: ProductGroups,
        meta: {
          title: "Ürün Listesi",
          roles: [],
          permissions: ["products.list"],
        },
      },
      {
        path: "create",
        name: "product-groups.create",
        component: ProductGroupCreate,
        meta: {
          title: "Ürün Ekle",
          roles: [],
          permissions: ["products.create"],
        },
      },
      {
        path: "edit/:id",
        name: "product-groups.edit",
        component: ProductGroupCreate,
        meta: {
          title: "Ürün Düzenle",
          roles: [],
          permissions: ["products.show"],
        },
      },
      {
        path: "quick-upload/:formId/:categoryId",
        name: "product-groups.quick-upload",
        component: ProductGroupQuickUpload,
        meta: {
          title: "Ürün Yükleme & Güncelleme",
          roles: [],
          permissions: ["products.create"],
        },
      },
    ],
  },
  {
    path: "material-products",
    component: RouterView,
    children: [
      {
        path: "",
        name: "material-products",
        component: MaterialProducts,
        meta: {
          title: "Alt Ürün Listesi",
          roles: [],
          permissions: ["products.list"],
        },
      },
      {
        path: "create",
        name: "material-products.create",
        component: MaterialProductCreate,
        meta: {
          title: "Alt Ürün Ekle",
          roles: [],
          permissions: ["products.create"],
        },
      },
      {
        path: "edit/:id",
        name: "material-products.edit",
        component: MaterialProductCreate,
        meta: {
          title: "Alt Ürün Düzenle",
          roles: [],
          permissions: ["products.show"],
        },
      },
      {
        path: "quick-upload",
        name: "material-products.quick-upload",
        component: MaterialQuickUpload,
        meta: {
          title: "Toplu Alt Ürün Yükleme & Güncelleme",
          roles: [],
          permissions: [],
        },
      },
    ],
  },
  {
    path: "product-tabs",
    component: RouterView,
    children: [
      {
        path: "",
        name: "product-tabs",
        component: ProductTabs,
        meta: {
          title: "Ürün Sekmeleri",
          roles: [],
          permissions: ["contents.list"],
        },
      },
      {
        path: "create",
        name: "product-tabs.create",
        component: ProductTabCreate,
        meta: {
          title: "Ürün Sekmesi Ekle",
          roles: [],
          permissions: ["contents.create"],
        },
      },
      {
        path: "edit/:id",
        name: "product-tabs.edit",
        component: ProductTabCreate,
        meta: {
          title: "Ürün Sekmesi Düzenle",
          roles: [],
          permissions: ["contents.edit"],
        },
      },
      {
        path: "merchants",
        component: RouterView,
        children: [
          {
            path: "",
            name: "product-tabs.merchants",
            component: ProductTabsMerchant,
            meta: {
              title: "Mağazaya Özel Ürün Sekmeleri",
              roles: [],
              permissions: ["contents.list"],
            },
          },
          {
            path: "create",
            name: "product-tabs.merchants.create",
            component: ProductTabMerchantCreate,
            meta: {
              title: "Mağazaya Özel Ürün Sekmesi Ekle",
              roles: [],
              permissions: ["contents.create"],
            },
          },
          {
            path: "edit/:id",
            name: "product-tabs.merchants.edit",
            component: ProductTabMerchantCreate,
            meta: {
              title: "Mağazaya Özel Ürün Sekmesi Düzenle",
              roles: [],
              permissions: ["contents.edit"],
            },
          },
        ],
      },
    ],
  },
];

export default route;
