
import { computed, defineComponent, inject, reactive, Ref, toRaw } from "vue";
import { ValidationErrors } from "@/utils/records/validation-errors";
import {
  GeneralApiResponse,
  GetProductGroupResult,
  MerchantFeedbackInterface,
  ProductGroupInterface,
  productGroupService,
} from "@/services";
import { get } from "lodash";
import MerchantFeedback from "@/components/merchant-feedback/Main.vue";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import { ConfirmRef } from "@/components/confirm";
import VueButton from "@/components/form-elements/button/Main.vue";

export default defineComponent({
  name: "MaterialSideNavigation",
  components: { VueButton, MerchantFeedback },
  props: {
    data: {
      type: Object as () => ProductGroupInterface,
      required: true,
    },
    query: {
      type: Object as () => ProductGroupInterface,
      required: true,
    },
    changeView: {
      type: Function,
      default: () => null,
    },
    activeView: {
      type: String,
      default: () => null,
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
    isRelationToMaterialProduct: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const editableQuery = reactive<ProductGroupInterface>(toRaw(props.query));
    const editableData = reactive<ProductGroupInterface>(toRaw(props.data));

    const gravatar = computed(() => {
      return get(props.query.photos, "data.0.thumb");
    });

    const onSavedFeedback = (feedback: MerchantFeedbackInterface) => {
      if (!editableQuery.feedbacks) editableQuery.feedbacks = [];
      editableQuery.feedbacks.splice(0, 0, feedback);
    };

    const changeStatusActions = useSubmitRecord<
      any,
      GeneralApiResponse<GetProductGroupResult>
    >({
      query: {
        status: "0",
      },
      storeServiceMethod(params: any) {
        return productGroupService.changeCheckStatus(editableQuery.id, params);
      },
      success(result) {
        if (result.kind === "ok") {
          ToastNotify({
            className: "success",
            text: "Ürün Durumu Değiştirildi!",
          });
          editableQuery.check_status = result.data.check_status;
          editableData.check_status = result.data.check_status;
          if (editableQuery.check_status * 1 === 0) {
            editableQuery.feedbacks = [];
          }
        }
      },
    });

    const onChangeCheckStatus = (status: any) => {
      confirmRef?.value?.show({
        text: "Ürünün durumunu değiştirmek istediğinize emin misiniz?",
        icon: "feather-alert-circle",
        iconClass: "text-red-400",
        confirmText: "Evet",
        cancelText: "Hayır",
        callback: () => {
          changeStatusActions.setQuery({ status });
          changeStatusActions.submit();
        },
      });
    };

    return {
      gravatar,
      processing: changeStatusActions.processing,
      onSavedFeedback,
      onChangeCheckStatus,
    };
  },
});
