import {
  GeneralApiResponse,
  GetGeneralStatisticReportResult,
  GetMerchantOrderReportResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const reportService = {
  statistics: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetGeneralStatisticReportResult>>(
      `/api/reports/statistics`,
      params
    ),
  merchantOrder: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetMerchantOrderReportResult>>(
      `/api/reports/merchant/order`,
      params
    ),
  merchantProduct: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetMerchantOrderReportResult>>(
      `/api/reports/merchant/product`,
      params
    ),
};
