
import { defineComponent, onMounted, reactive, ref } from "vue";
import { reportService } from "@/services";
import Tippy from "@/components/tippy/Main.vue";
import Chart from "@/components/chart/Main.vue";
import { helper } from "@/utils/helper";
import { ToastNotify } from "@/utils/toast-notify";

export default defineComponent({
  name: "MerchantProductReport",
  components: { Chart, Tippy },
  props: {
    from: {
      required: true,
    },
    to: {
      required: true,
    },
    merchantId: {
      required: true,
    },
  },
  setup(props) {
    const loading = ref<boolean>(false);
    const state = reactive<any>({
      summary: {},
      list: [],
    });

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: false,
      },
    };

    const chartData = reactive({
      labels: [] as any[],
      datasets: [
        {
          label: "Ürün Tutarları",
          data: [] as any[],
          backgroundColor: [],
        },
      ],
    });

    const fetch = async () => {
      loading.value = true;
      const result = await reportService.merchantProduct({
        from: props.from || "",
        to: props.to || "",
        merchant_id: props.merchantId || "",
      });

      if (result.kind === "ok") {
        state.summary = result.data.summary;
        state.list = result.data.list.map((o) => {
          return { ...o, visible_detail: false };
        });
        chartData.labels = state.list.map((o: any) => o.title);
        chartData.datasets[0].data = state.list.map((o: any) => o.net_total);
        chartData.datasets[0].backgroundColor = state.list.map((o: any) =>
          helper.md5HexCode(o.barcode)
        );
      } else {
        ToastNotify({ text: result.message, className: "error" });
      }

      loading.value = false;
    };

    onMounted(() => {
      fetch();
    });

    return {
      loading,
      state,
      chartOptions,
      chartData,
    };
  },
});
