import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 0,
  class: "mt-2 text-xs font-medium text-gray-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueSelect = _resolveComponent("VueSelect")!
  const _component_VueInputContainer = _resolveComponent("VueInputContainer")!

  return (_openBlock(), _createBlock(_component_VueInputContainer, {
    "validation-errors": _ctx.validationErrors.get(_ctx.validationPath)
  }, {
    label: _withCtx(() => [
      (_ctx.isRequired)
        ? (_openBlock(), _createElementBlock("b", _hoisted_1, "*"))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.lineTitle), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_VueSelect, {
          "model-value": _ctx.lineValue,
          "onUpdate:modelValue": _ctx.onChangeValue,
          "has-error": _ctx.validationErrors.has(_ctx.validationPath)
        }, {
          default: _withCtx(() => [
            _createElementVNode("option", {
              disabled: _ctx.isRequired,
              value: ""
            }, "Seçiniz", 8, _hoisted_2),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
              return (_openBlock(), _createElementBlock("option", {
                key: `${_ctx.line.uuid}-${option.value}`,
                value: option.value
              }, _toDisplayString(option.text), 9, _hoisted_3))
            }), 128))
          ]),
          _: 1
        }, 8, ["model-value", "onUpdate:modelValue", "has-error"]),
        (_ctx.lineDescription)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.lineDescription), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["validation-errors"]))
}