import {
  GeneralApiResponse,
  GetStockGroupCodeDefinitionResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const stockGroupCodeDefinitions = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetStockGroupCodeDefinitionResult>>(
      `/api/definitions/stock-group-codes/${id}`
    ),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetStockGroupCodeDefinitionResult>>(
      `/api/definitions/stock-group-codes`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetStockGroupCodeDefinitionResult>>(
      `/api/definitions/stock-group-codes/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(
      `/api/definitions/stock-group-codes/${id}`
    ),
};
