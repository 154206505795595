
import { defineComponent } from "vue";
import {
  GeneralApiResponse,
  GetMerchant,
  PostIBAN,
  merchantService,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueButton from "@/components/form-elements/button/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import { get } from "lodash";

export default defineComponent({
  name: "UserMerchantBankInformation",
  components: { Tippy, VueButton, VueInput, VueInputContainer },
  props: {
    merchantDetail: {
      type: Object as () => GetMerchant,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const { query, processing, validationErrors, submit } = useSubmitRecord<
      PostIBAN,
      GeneralApiResponse<any>
    >({
      query: {
        iban: props.merchantDetail.iban,
      },
      storeServiceMethod: (params: any) =>
        merchantService.updateIban(props.merchantDetail.id, params),
      success(result) {
        if (result?.kind === "ok") {
          ToastNotify({
            className: "success",
            text: "Banka Hesapları Güncellendi!",
          });
        }
      },
    });

    const changeDefault = (e: any, index: number) => {
      query.value.iban.map((v, i) => {
        if (i === index) v.is_default = get(e, "target.checked", false) * 1;
        else v.is_default = 0;
      });
    };

    const deleteRow = (index: number) => {
      let selectFirst = query.value.iban[index].is_default * 1 === 1;
      query.value.iban.splice(index, 1);
      if (query.value.iban.length > 0 && selectFirst)
        query.value.iban[0].is_default = 1;
    };

    const addRow = () => {
      query.value.iban.push({
        is_default: 0,
        title: "",
        name_surname: "",
        iban: "",
      });
    };

    return {
      processing,
      query,
      validationErrors,
      submit,
      changeDefault,
      deleteRow,
      addRow,
    };
  },
});
