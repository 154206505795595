
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  GeneralApiResponse,
  GetProductTabMerchantResult,
  MerchantFeedbackInterface,
  ProductTabMerchantInterface,
  productTabMerchantService,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueButton from "@/components/form-elements/button/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { useStore } from "@/store";
import MerchantFeedback from "@/components/merchant-feedback/Main.vue";
import { ConfirmRef } from "@/components/confirm";
import ContentAssets from "@/components/assets/Main.vue";

export default defineComponent({
  name: "ProductTabCreateOrUpdate",
  components: {
    ContentAssets,
    MerchantFeedback,
    VueSelect2,
    VueInputContainer,
    VueButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const selectedProductTab = ref();
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);

    const id = computed(() => get(route.params, "id"));

    const getCategoryTitle = computed(() => {
      const categoryId = get(selectedProductTab.value, "category_id");
      if (!categoryId) return "Tüm Kategoriler";

      const titles: string[] = [];
      categoryId
        .toString()
        .split(",")
        .map((o: any) => {
          const title: string = store.getters["category/category"](o, "title");
          if (title) titles.push(title);
        });

      return titles.join(", ");
    });

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const productTabSelect2Options: Select2Options = {
      ajaxQuery: {
        product_tab: {
          "@select": {
            value: "id",
            text: "title",
            category_id: "category_id",
          },
          "@where": { can_customizable: 1 },
        },
      },
    };

    const { data, loading, fetch } = useReadRecord<
      ProductTabMerchantInterface,
      GetProductTabMerchantResult
    >({
      id: id.value,
      serviceMethod: productTabMerchantService.show,
      success(result) {
        if (result.kind === "ok") {
          // if (!result.data.workspaces || result.data.workspaces.length === 0) {
          //   result.data.workspaces = convertAssetsToWorkspace([
          //     {
          //       type: "text",
          //       text: result.data.text,
          //     },
          //   ]);
          // }

          setQuery(result.data);
        }
      },
      fail() {
        router.go(-1);
      },
    });

    const feedbacks = computed(() => {
      if (!data.value || !Array.isArray(data.value.feedbacks)) return [];
      return data.value.feedbacks;
    });

    const {
      query,
      processing: submitProcessing,
      validationErrors,
      submit,
      setQuery,
    } = useSubmitRecord<
      ProductTabMerchantInterface,
      GeneralApiResponse<GetProductTabMerchantResult>
    >({
      query: {
        product_tab_id: get(route.query, "product_tab_id", ""),
        merchant_id: get(route.query, "merchant_id", ""),
        text: "",
        workspaces: [],
        published_text: "",
        status: "0",
      },
      storeServiceMethod: productTabMerchantService.store,
      updateServiceMethod: productTabMerchantService.update,
      success(result) {
        if (result.kind === "ok") {
          ToastNotify({
            className: "success",
            text: "Mağazaya Özel Ürün Sekmesi Kaydedildi!",
          });
          router.go(-1);
        }
      },
    });

    const changeStatusActions = useSubmitRecord<
      any,
      GeneralApiResponse<GetProductTabMerchantResult>
    >({
      query: {
        status: "0",
      },
      storeServiceMethod(params: any) {
        return productTabMerchantService.changeStatus(id.value, params);
      },
      success(result) {
        if (result.kind === "ok") {
          ToastNotify({
            className: "success",
            text: "İçerik Durumu Değiştirildi!",
          });
          query.value.status = result.data.status;
          data.value.status = result.data.status;

          if (result.data.status === 1) {
            query.value.feedbacks = [];
            data.value.feedbacks = [];
          }
        }
      },
    });

    const onSubmit = () => submit(id.value);

    const changeProductTab = (ctx: any) => {
      selectedProductTab.value = ctx;
    };

    const onSavedFeedback = (feedback: MerchantFeedbackInterface) => {
      if (!data.value.feedbacks) data.value.feedbacks = [];
      data.value.feedbacks.splice(0, 0, feedback);
      query.value.status = 2;
    };

    const onChangeStatus = (status: any) => {
      confirmRef?.value?.show({
        text: "İçeriğin durumunu değiştirmek istediğinize emin misiniz?",
        icon: "feather-alert-circle",
        iconClass: "text-red-400",
        confirmText: "Evet",
        cancelText: "Hayır",
        callback: () => {
          changeStatusActions.setQuery({ status });
          changeStatusActions.submit();
        },
      });
    };

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) fetch(id.value);
    });

    const processing = computed(() => {
      return submitProcessing.value || changeStatusActions.processing.value;
    });

    return {
      id,
      feedbacks,
      loading,
      processing,
      query,
      validationErrors,
      merchantSelect2Options,
      productTabSelect2Options,
      getCategoryTitle,
      selectedProductTab,
      onChangeStatus,
      onSubmit,
      changeProductTab,
      onSavedFeedback,
    };
  },
});
