import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "overflow-x-auto" }
const _hoisted_2 = { class: "table table--sm table-bordered" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$_.get(_ctx.form, 'data.headers', []), (header, headerIndex) => {
          return (_openBlock(), _createElementBlock("th", {
            class: "!bg-gray-100",
            key: `${header.title}-${headerIndex}`
          }, _toDisplayString(header.title), 1))
        }), 128))
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$_.get(_ctx.form, 'data.lines', []), (line, lineIndex) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: `${line.code}-${lineIndex}`
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(line, (column, columnIndex) => {
              return (_openBlock(), _createElementBlock("td", {
                class: "bg-white font-medium",
                key: `${line.code}-${lineIndex}-${columnIndex}`,
                innerHTML: _ctx.$orderObjects(column.value, { code: column.code })
              }, null, 8, _hoisted_3))
            }), 128))
          ]))
        }), 128))
      ])
    ])
  ]))
}