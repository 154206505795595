
import { computed, defineComponent } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import { get } from "lodash";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "VueDatetimePicker",
  props: {
    placeholder: {
      type: String,
      default: "Tarih Seçin",
    },
    range: {
      type: Boolean,
      default: false,
    },
    datepicker: {
      type: Boolean,
      default: true,
    },
    timepicker: {
      type: Boolean,
      default: true,
    },
    monthPicker: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: () => false,
    },
    presetRanges: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Datepicker,
  },
  setup(props, { emit }) {
    const enableTimepicker = computed(() => {
      return !props.range && props.timepicker;
    });
    const date = computed<any>({
      get() {
        const values = (props.modelValue || "").toString().split(" - ");
        const fromDate = new Date(get(values, 0));
        const toDate = new Date(get(values, 1));
        if (props.range) {
          return [fromDate, toDate];
        } else if (props.monthPicker) {
          return {
            year: fromDate.getFullYear(),
            month: fromDate.getMonth(),
          };
        }
        return fromDate;
      },
      set(value) {
        if (props.range) {
          const fromDate = get(value, 0);
          const toDate = get(value, 1);
          setValues(fromDate, toDate);
        } else if (props.monthPicker) {
          setValues(new Date(get(value, "year"), get(value, "month")));
        } else {
          setValues(value);
        }
      },
    });

    const setValues = (from: Date, to: Date | null = null) => {
      let fromText = helper.formatDate(from, "YYYY-MM-DD HH:mm");
      let toText = helper.formatDate(to, "YYYY-MM-DD HH:mm");

      if (props.datepicker && !enableTimepicker.value) {
        fromText = helper.formatDate(from, "YYYY-MM-DD");
        toText = helper.formatDate(to, "YYYY-MM-DD");
      }

      if (!props.datepicker && enableTimepicker.value) {
        fromText = helper.formatDate(from, "HH:mm");
        toText = helper.formatDate(to, "HH:mm");
      }

      if (props.monthPicker) {
        fromText = helper.formatDate(from, "YYYY-MM");
      }

      if (toText) {
        emit("update:modelValue", `${fromText} - ${toText}`);
      } else {
        emit("update:modelValue", `${fromText}`);
      }
    };

    const previewFormat = (date: any) => {
      if (props.monthPicker) {
        return helper.formatDate(
          new Date(get(date, "year"), get(date, "month")),
          "MM.YYYY"
        );
      } else if (Array.isArray(date)) {
        const fromDate = get(date, 0);
        const toDate = get(date, 1);

        return `${helper.formatDate(
          fromDate,
          "DD.MM.YYYY"
        )} - ${helper.formatDate(toDate, "DD.MM.YYYY")}`;
      } else {
        if (enableTimepicker.value) {
          return helper.formatDate(date);
        }
        return helper.formatDate(date, "DD.MM.YYYY");
      }
    };

    return {
      date,
      enableTimepicker,
      previewFormat,
    };
  },
});
