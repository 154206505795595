import {
  GeneralApiResponse,
  GetMerchantFeedbackResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const merchantFeedbackService = {
  submitProductGroup: (id: any, params: unknown) =>
    useAxios().post<GeneralApiResponse<GetMerchantFeedbackResult>>(
      `/api/product-groups/${id}/feedback`,
      params
    ),

  submitProductTab: (id: any, params: unknown) =>
    useAxios().post<GeneralApiResponse<GetMerchantFeedbackResult>>(
      `/api/product-tab-merchants/${id}/feedback`,
      params
    ),
};
