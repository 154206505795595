
import { defineComponent, onMounted, reactive, ref } from "vue";
import { FileManagerModalOptions } from ".";
import { set, get, cloneDeep } from "lodash";
import FileManager from "@/views/file-manager/Main.vue";
import { helper } from "@/utils/helper";
import VueModal from "@/components/modal/Main.vue";

export default defineComponent({
  name: "FileManagerModal",
  components: { VueModal, FileManager },
  setup() {
    const visibleFileManager = ref(false);
    const modalRefId = helper.uuidv4();
    // region Reactive
    const defaultOptions: FileManagerModalOptions = {
      callback() {
        console.log("files - callback");
      },
    };

    const options = reactive<FileManagerModalOptions>({
      ...cloneDeep(defaultOptions),
    });
    // endregion Reactive

    // region Methods
    const show = (ctx: FileManagerModalOptions): void => {
      cash(`#${modalRefId}`).modal("show");
      Object.keys(defaultOptions).map((item) => {
        set(options, item, get(defaultOptions, item, null));
      });
      Object.keys(ctx).map((item) => {
        set(options, item, get(ctx, item, null));
      });
    };

    const hide = (): void => {
      cash(`#${modalRefId}`).modal("hide");
    };

    const onConfirm = (files: any[]): void => {
      if (typeof options.callback === "function") options.callback(files);
      hide();
    };

    onMounted(() => {
      cash(`#${modalRefId}`).modal("on.show", () => {
        visibleFileManager.value = true;
      });
      cash(`#${modalRefId}`).modal("on.hide", () => {
        visibleFileManager.value = false;
      });
    });

    return {
      modalRefId,
      options,
      visibleFileManager,
      show,
      hide,
      onConfirm,
    };
  },
});
