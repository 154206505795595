
import { defineComponent, inject, Ref } from "vue";
import Tippy from "@/components/tippy/Main.vue";
import { FileManagerRef } from "@/components/file-manager";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "TiptapImage",
  components: { Tippy },
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const fileManagerRef = inject<Ref<FileManagerRef>>(`fileManagerRef`);

    const openFileManager = () => {
      fileManagerRef?.value?.show({
        callback: (files) => {
          files.map((file) => {
            if (file.path) {
              props.editor
                .chain()
                .focus()
                .setImage({
                  src: helper.imagePath(file.path, "image", 1),
                })
                .run();
            }
          });
        },
      });
    };

    return {
      openFileManager,
    };
  },
});
