
import { computed, defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { MaterialProductInterface } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import { Select2Options } from "@/components/form-elements/select2";
import VueSelect from "@/components/form-elements/select/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { useStore } from "@/store";
import MaterialSalesInformation from "@/views/product-materials/create/components/SalesInformation.vue";
import { get } from "lodash";

export default defineComponent({
  name: "MaterialGeneralInformation",
  components: {
    MaterialSalesInformation,
    VueSelect2,
    Tippy,
    VueSelect,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => MaterialProductInterface,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const formSchemas = store.getters["order/forms"];

    const editableQuery = reactive<MaterialProductInterface>(
      toRaw(props.query)
    );

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const categorySelect2Options: Select2Options = {
      ajaxQuery: {
        material_category: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const selectedFormLine = computed(() => {
      let formLine: any = null;
      formSchemas.map((f) => {
        f.lines.data.map((l) => {
          if (
            !formLine &&
            editableQuery.material_category_id &&
            l.material_category_id * 1 ===
              editableQuery.material_category_id * 1
          ) {
            formLine = l;
          }
        });
      });
      return formLine;
    });

    const isMasterLine = computed(() => {
      return get(selectedFormLine.value, "master", "0") * 1 === 1;
    });

    const disabledCategory = computed(() => {
      return (
        !props.canEdit ||
        (props.query?.id &&
          isMasterLine.value &&
          store.getters["auth/isMerchant"])
      );
    });

    const onChangeCategory = (categoryId: any) => {
      if (!disabledCategory.value) {
        editableQuery.material_category_id = categoryId;
      }
    };

    const onChangeBarcode = (barcode: string) => {
      editableQuery.barcode = barcode;
    };

    return {
      editableQuery,
      merchantSelect2Options,
      categorySelect2Options,
      selectedFormLine,
      disabledCategory,
      onChangeBarcode,
      onChangeCategory,
    };
  },
});
