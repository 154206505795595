
import { computed, defineComponent, onMounted, ref } from "vue";
import FilterContainer from "@/components/filter-container/Main";
import VueInputContainer from "@/components/form-elements/input-container/Main";
import VueDatetimePicker from "@/components/form-elements/datetime-picker/Main";
import dayjs from "dayjs";
import { get } from "lodash";
import { useFilterRecords } from "@/utils/records/filter";
import { useFetchRecords } from "@/utils/records/fetch";
import Pagination from "@/components/pagination/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import VueInput from "@/components/form-elements/input/Main.vue";
import FilterModalContainer from "@/components/filter-container/FilterModal.vue";
import { helper } from "@/utils/helper";
import { useStore } from "@/store";
import { ficheService } from "@/services";

export default defineComponent({
  name: "CommissionInvoices",
  components: {
    FilterModalContainer,
    VueInput,
    VueSelect2,
    Pagination,
    VueDatetimePicker,
    VueInputContainer,
    FilterContainer,
  },
  setup() {
    const store = useStore();

    const presetRanges = ref([
      { label: "Bugün", range: [dayjs(), dayjs()] },
      { label: "Son 7 Gün", range: [dayjs().subtract(6, "day"), dayjs()] },
      { label: "Son 14 Gün", range: [dayjs().subtract(13, "day"), dayjs()] },
      {
        label: "Bu Ay",
        range: [dayjs().startOf("month"), dayjs()],
      },
      {
        label: "Geçen Ay",
        range: [
          dayjs().subtract(1, "month").startOf("month"),
          dayjs().subtract(1, "month").endOf("month"),
        ],
      },
      {
        label: "Son 3 Ay",
        range: [dayjs().subtract(2, "month").startOf("month"), dayjs()],
      },
      {
        label: "Bu Yıl",
        range: [dayjs().startOf("year"), dayjs()],
      },
    ]);

    const dateRange = computed({
      get() {
        return `${filterState.fields.from} - ${filterState.fields.to}`;
      },
      set(val: string) {
        const range = val.toString().split(" - ");
        filterState.fields.from = get(range, 0) || "";
        filterState.fields.to = get(range, 1) || "";
      },
    });

    const totalCommissionAmount = computed(() => {
      return helper.round(
        get(list.meta, "amounts.INVOICE_18_VERIFY.amount", "0") * 1 +
          get(list.meta, "amounts.INVOICE_18_REFUND.amount", "0") * 1
      );
    });

    const totalCommissionNumber = computed(() => {
      return (
        get(list.meta, "amounts.INVOICE_18_VERIFY.number", "0") * 1 +
        get(list.meta, "amounts.INVOICE_18_REFUND.number", "0") * 1
      );
    });

    const totalReflectionAmount = computed(() => {
      return helper.round(
        get(list.meta, "amounts.INVOICE_0_VERIFY.amount", "0") * 1 +
          get(list.meta, "amounts.INVOICE_0_REFUND.amount", "0") * 1
      );
    });

    const totalReflectionNumber = computed(() => {
      return (
        get(list.meta, "amounts.INVOICE_0_VERIFY.number", "0") * 1 +
        get(list.meta, "amounts.INVOICE_0_REFUND.number", "0") * 1
      );
    });
    // const totalAmounts = computed(() => {
    //   const commissionAmount = helper.round(
    //     get(list.meta, "amounts.INVOICE_18_VERIFY.amount", "0") * 1
    //   );
    //   const commissionNumber =
    //     get(list.meta, "amounts.INVOICE_18_VERIFY.number", "0") * 1;
    //   const refundCommissionAmount = helper.round(
    //     get(list.meta, "amounts.INVOICE_18_REFUND.amount", "0") * 1
    //   );
    //   const refundCommissionNumber =
    //     get(list.meta, "amounts.INVOICE_18_REFUND.number", "0") * 1;
    //
    //   const reflectionAmount = helper.round(
    //     get(list.meta, "amounts.INVOICE_0_VERIFY.amount", "0") * 1
    //   );
    //   const reflectionNumber =
    //     get(list.meta, "amounts.INVOICE_0_VERIFY.number", "0") * 1;
    //
    //   const refundReflectionAmount = helper.round(
    //     get(list.meta, "amounts.INVOICE_0_REFUND.amount", "0") * 1
    //   );
    //   const refundReflectionNumber =
    //     get(list.meta, "amounts.INVOICE_0_REFUND.number", "0") * 1;
    //
    //   return {
    //     commission_amount: helper.round(),
    //   };
    // });

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const { loading, list, fetch } = useFetchRecords({
      serviceMethod: ficheService.commissionInvoiceReport,
      formatted(item: any): any {
        const transactionTypes = get(item, "transaction_types") || [];
        return {
          ...item,
          transaction_types: transactionTypes,
          transaction_titles: transactionTypes
            .map((o: any) => {
              return store.getters["system/definition"]("transaction_types", o);
            })
            .filter((o: any) => !!o),
        };
      },
      /*
      selectionQuery: () => {
        return {
          source: "transaction",
          config: {
            "@page": 1,
            "@paginate": 30,
            "@order": "time:desc",
            "@func": ["withCount"],
            "@where": {
              transaction_type: [
                "INVOICE_18_VERIFY",
                "INVOICE_18_REFUND",
                "INVOICE_18",
                "INVOICE_0_VERIFY",
                "INVOICE_0_REFUND",
                "INVOICE_0",
              ],
            },
            "@select": [
              "id",
              "order_group_id",
              "transaction_type",
              "merchant_id",
              "amount",
              "description",
              "time",
              "status",
              "relation_id",
              "approved_at",
            ],
            merchant: {
              "@select": ["id", "title", "commercial_title"],
            },
            order: {
              "@select": [
                "order_group_id",
                "merchant_id",
                "customer_id",
                "invoice_address",
              ],
            },
          },
          filterFormat: {
            id: "@where.id",
            relation_id: "@where.relation_id",
            order_group_id: "@where.order_group_id",
            merchant_id: "@where.merchant_id",
            from: "@whereDateRange.time.from",
            to: "@whereDateRange.time.to",
          },
        };
      },
      */
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        from: "",
        to: "",
        merchant_id: "",
        order_group_id: "",
        id: "",
        relation_id: "",
      },
    });

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
    });

    return {
      loading,
      list,
      dateRange,
      filterState,
      filterCount,
      presetRanges,
      totalCommissionAmount,
      totalCommissionNumber,
      totalReflectionAmount,
      totalReflectionNumber,
      merchantSelect2Options,
      filterFunctions,
    };
  },
});
