
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { get, set } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  menuService,
  GeneralApiResponse,
  GetMenuResult,
  MenuInterface,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueButton from "@/components/form-elements/button/Main.vue";
import MenuGeneralInformation from "@/views/menus/create/components/GeneralInformation.vue";
import MenuNestedList from "@/views/menus/create/components/NestedList.vue";
import { helper } from "@/utils/helper";
import VueModal from "@/components/modal/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";

export default defineComponent({
  name: "MenuCreateOrUpdate",
  components: {
    VueInput,
    VueSelect2,
    VueSelect,
    VueInputContainer,
    VueModal,
    MenuNestedList,
    MenuGeneralInformation,
    VueButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const id = computed(() => get(route.params, "id"));
    const modalRefId = helper.uuidv4();
    const contentId = ref<string>("");
    const editPath = ref<any[]>([]);
    const editableValues = reactive({
      title: "",
      type: "1",
      link: "",
    });

    const contentSelect2Options: Select2Options = {
      ajaxQuery: {
        content: {
          "@select": {
            value: "id",
            text: "title",
            type: "type",
            parent: "parent",
            slug: "slug",
            header_slug: "header_slug",
          },
          "@func": ["withUrlSlug"],
          "@page": 1,
        },
      },
    };

    const convertList = (list: any[]): any[] => {
      return list.map((item) => {
        return {
          ...item,
          id: item.id || helper.uuidv4(),
          children: convertList(item.children || []),
          expanded: item.expanded || (item.children || []).length === 0,
        };
      });
    };

    const { loading, fetch } = useReadRecord<MenuInterface, GetMenuResult>({
      id: id.value,
      serviceMethod: menuService.show,
      success(result) {
        if (result.kind === "ok") {
          setQuery({
            ...result.data,
            content: convertList(result.data.content),
          });
        }
      },
      fail() {
        router.go(-1);
      },
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<MenuInterface, GeneralApiResponse<GetMenuResult>>({
        query: {
          title: "",
          type: "1",
          content: [],
        },
        storeServiceMethod: menuService.store,
        updateServiceMethod: menuService.update,
        success(result) {
          if (result.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "Menü Kaydedildi!",
            });
            router.go(-1);
          }
        },
      });

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) fetch(id.value);
    });

    const onAdd = () => {
      query.value.content.splice(0, 0, {
        id: helper.uuidv4(),
        title: "",
        type: "1",
        link: "",
        children: [],
        expanded: true,
      });
      onEdit(["content", 0]);
    };

    const onEdit = (path: any[]) => {
      editPath.value = path;
      editableValues.title = get(query.value, [...path, "title"]) || "";
      editableValues.type = get(query.value, [...path, "type"]) || "1";
      editableValues.link = get(query.value, [...path, "link"]) || "";
      openModal();
    };

    const openModal = () => {
      cash(`#${modalRefId}`).modal("show");
    };

    const closeModal = () => {
      cash(`#${modalRefId}`).modal("hide");
    };

    const changeContent = (ctx: any) => {
      if (ctx) {
        editableValues.title = get(ctx, "text") || editableValues.title;
        editableValues.link = get(ctx, "url_slug") || editableValues.link;
        contentId.value = "";
      }
    };

    const saveContent = () => {
      set(query.value, [...editPath.value, "title"], editableValues.title);
      set(query.value, [...editPath.value, "type"], editableValues.type);
      set(query.value, [...editPath.value, "link"], editableValues.link);
      closeModal();
    };

    onBeforeUnmount(() => {
      closeModal();
    });

    return {
      modalRefId,
      loading,
      processing,
      query,
      validationErrors,
      editableValues,
      contentId,
      contentSelect2Options,
      onSubmit,
      onAdd,
      onEdit,
      closeModal,
      changeContent,
      saveContent,
    };
  },
});
