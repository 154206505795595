
import { defineComponent } from "vue";

export default defineComponent({
  name: "FilterContainer",
  props: {
    onApply: { type: Function, default: null },
    onClear: { type: Function, default: null },
  },
});
