
import { defineComponent, onMounted } from "vue";
import { useFetchRecords } from "@/utils/records/fetch";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import { useFilterRecords } from "@/utils/records/filter";
import Pagination from "@/components/pagination/Main.vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import { useStore } from "@/store";
import { findIndex } from "lodash";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import { useRouter } from "vue-router";
import { useDeleteRecord } from "@/utils/records/delete";
import { useSubmitRecord } from "@/utils/records/submit";
import { authService, GetLoginResult, userService } from "@/services";
import FilterButton from "@/components/filter-container/Button.vue";

export default defineComponent({
  name: "UsersList",
  components: {
    FilterButton,
    VueSelect2,
    VueSelect,
    VueInput,
    VueInputContainer,
    FilterContainer,
    Pagination,
    Tippy,
    TransactionStatement,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const { loading, list, fetch } = useFetchRecords({
      selectionQuery: {
        source: "user",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "site",
            "name",
            "surname",
            "email",
            "gsm",
            "status",
            "email_verified_at",
            "type",
            "created_at",
          ],
          "@order": "id:desc",
          "@func": ["withCount"],
        },
        filterFormat: {
          q: "@where.q",
          id: "@where.id",
          name: "@where._name",
          surname: "@where._surname",
          email: "@where._email",
          type: "@where.type",
          gender: "@where.gender",
          status: "@where.status",
          merchant_id: "@where.merchant_id",
        },
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        id: "",
        name: "",
        surname: "",
        email: "",
        type: "",
        gender: "",
        status: "",
        merchant_id: "",
      },
    });

    const { deleteState, triggerDelete, removeDeleteError } = useDeleteRecord({
      serviceMethod: userService.delete,
      success(id: any) {
        const index = findIndex(list.data, { id });
        if (index > -1) list.data.splice(index, 1);
      },
    });

    const goToEdit = (id: any) => {
      router.push({ name: "users.edit", params: { id } });
    };

    const { processing: loginProcessing, submit: loginSubmit } =
      useSubmitRecord<Record<string, any>, GetLoginResult>({
        query: {},
        updateServiceMethod: authService.loginAs,
        success(result) {
          if (result.kind === "ok") {
            store.dispatch("auth/SET_TOKEN", result.meta.token || "");
            store.dispatch("auth/SET_PROFILE", result.data);
            router.push({ name: "welcome" });
          }
        },
      });

    const loginAs = (id: any) => loginSubmit(id);

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
    });

    return {
      loginProcessing,
      loading,
      list,
      filterState,
      filterFunctions,
      merchantSelect2Options,
      deleteState,
      filterCount,
      triggerDelete,
      removeDeleteError,
      goToEdit,
      loginAs,
    };
  },
});
