import { selectionService } from "@/services";
import { get } from "lodash";

export const MaterialDownloadList = async (
  categoryId: any,
  merchantId: any = null,
  condition: Record<string, any> = {}
): Promise<any> => {
  const result = await selectionService.fetch({
    product: {
      "@get": true,
      "@select": [
        "material_category_id",
        "merchant_id",
        "barcode",
        "item_code",
        "stock_code",
        "title",
        "currency",
        "price",
        "old_price",
        "merchant_price",
        "merchant_old_price",
        "vat_rate",
        "stock",
        "unit",
        "color",
        "color_code",
        "status",
        "min_width",
        "max_width",
        "min_height",
        "max_height",
      ],
      ...condition,
      "@where": {
        ...get(condition, "@where", {}),
        material_category_id: categoryId,
        merchant_id: merchantId,
      },
      "@func": ["excelFormat", "withPhotos", "priceIncludesVat"],
    },
  });

  let data: any[] = [];

  if (result.kind === "ok") {
    data = get(result.data, "product.data", []).map((item: any) => {
      return {
        material_category_id: get(item, "material_category_id") || "",
        merchant_id: get(item, "merchant_id") || "",
        barcode: get(item, "barcode") || "",
        item_code: get(item, "item_code") || "",
        stock_code: get(item, "stock_code") || "",
        title: get(item, "title") || "",
        currency: get(item, "currency") || "0",
        price_type: get(item, "price_type", "1"),
        price: (get(item, "price") || "0").toString().replace(/\./g, ","),
        old_price: (get(item, "old_price") || "0")
          .toString()
          .replace(/\./g, ","),
        merchant_price: (get(item, "merchant_price") || "0")
          .toString()
          .replace(/\./g, ","),
        merchant_old_price: (get(item, "merchant_old_price") || "0")
          .toString()
          .replace(/\./g, ","),
        vat_rate: get(item, "vat_rate") || "0",
        stock: get(item, "stock") || "0",
        unit: get(item, "unit"),
        color: get(item, "color") || "",
        color_code: get(item, "color_code") || "",
        photo_list: get(item, "photo_list") || "",
        status: get(item, "status"),
        min_width: get(item, "min_width") || "0",
        max_width: get(item, "max_width") || "0",
        min_height: get(item, "min_height") || "0",
        max_height: get(item, "max_height") || "0",
      };
    });
  }

  return data;
};
