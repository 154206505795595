import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import TiptapVueComponent from "@/components/assets/extensions/most-commented-products/Main.vue";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    mostCommentedProducts: {
      /**
       * Add an image
       */
      setMostCommentedProducts: () => ReturnType;
    };
  }
}

export const MostCommentedProducts = Node.create({
  name: "mostCommentedProducts",
  group: "block",
  atom: true,
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      label: {
        default: "En Çok Yorum Alan Ürünler",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'tiptap-vue-most-commented-products[data-type="draggable-item"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "tiptap-vue-most-commented-products",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-type": "draggable-item",
      }),
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(TiptapVueComponent);
  },

  addCommands() {
    return {
      setMostCommentedProducts:
        () =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
          });
        },
    };
  },
});
