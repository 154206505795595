import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white rounded-lg p-3" }
const _hoisted_2 = { class: "font-medium text-xs mb-1.5" }
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = { class: "font-medium text-xs mb-1.5" }
const _hoisted_5 = { class: "mt-3" }
const _hoisted_6 = { class: "font-medium text-xs mb-1.5" }
const _hoisted_7 = { class: "mt-3" }
const _hoisted_8 = { class: "font-medium text-xs mb-1.5" }
const _hoisted_9 = { class: "mt-3" }
const _hoisted_10 = { class: "font-medium text-xs mb-1.5" }
const _hoisted_11 = { class: "mt-3" }
const _hoisted_12 = { class: "font-medium text-xs mb-1.5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueInputContainer = _resolveComponent("VueInputContainer")!

  return (_openBlock(), _createBlock(_component_VueInputContainer, { class: "col-span-full sm:col-span-3" }, {
    label: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, " Telefon Ekranı (" + _toDisplayString(_ctx.editableQuery.xs) + ") ", 1),
          _withDirectives(_createElementVNode("input", {
            class: "form-slider",
            type: "range",
            min: "-1",
            max: "12",
            step: "1",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editableQuery.xs) = $event))
          }, null, 512), [
            [_vModelText, _ctx.editableQuery.xs]
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, " Tablet Ekranı (" + _toDisplayString(_ctx.editableQuery.sm) + ") ", 1),
          _withDirectives(_createElementVNode("input", {
            class: "form-slider",
            type: "range",
            min: "-1",
            max: "12",
            step: "1",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editableQuery.sm) = $event))
          }, null, 512), [
            [_vModelText, _ctx.editableQuery.sm]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, " Normal Ekran (" + _toDisplayString(_ctx.editableQuery.md) + ") ", 1),
          _withDirectives(_createElementVNode("input", {
            class: "form-slider",
            type: "range",
            min: "-1",
            max: "12",
            step: "1",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editableQuery.md) = $event))
          }, null, 512), [
            [_vModelText, _ctx.editableQuery.md]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, " Büyük Ekran (" + _toDisplayString(_ctx.editableQuery.lg) + ") ", 1),
          _withDirectives(_createElementVNode("input", {
            class: "form-slider",
            type: "range",
            min: "-1",
            max: "12",
            step: "1",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editableQuery.lg) = $event))
          }, null, 512), [
            [_vModelText, _ctx.editableQuery.lg]
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, " Geniş Ekran (" + _toDisplayString(_ctx.editableQuery.xl) + ") ", 1),
          _withDirectives(_createElementVNode("input", {
            class: "form-slider",
            type: "range",
            min: "-1",
            max: "12",
            step: "1",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editableQuery.xl) = $event))
          }, null, 512), [
            [_vModelText, _ctx.editableQuery.xl]
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, " Ekstra Geniş Ekran (" + _toDisplayString(_ctx.editableQuery["2xl"]) + ") ", 1),
          _withDirectives(_createElementVNode("input", {
            class: "form-slider",
            type: "range",
            min: "-1",
            max: "12",
            step: "1",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editableQuery['2xl']) = $event))
          }, null, 512), [
            [_vModelText, _ctx.editableQuery['2xl']]
          ])
        ])
      ])
    ]),
    _: 1
  }))
}