
import { defineComponent } from "vue";
import { quickUploadService } from "@/services";
import { MaterialProductExcel } from "@/utils/excel/material-product";
import QuickUpload from "@/views/quick-uploads/QuickUpload.vue";

export default defineComponent({
  name: "MaterialQuickUpload",
  components: {
    QuickUpload,
  },
  setup() {
    const excelUpload = new MaterialProductExcel(null);
    const method = quickUploadService.material;

    return {
      excelUpload,
      method,
    };
  },
});
