import { BaseExcel, PROMPT_NEW_LINE } from "@/utils/excel/base";
import { cloneDeep, get, kebabCase, set } from "lodash";
import { OrderFormInterface, selectionService } from "@/services";
import { useStore } from "@/store";
import { helper } from "@/utils/helper";
import ExcelJs from "exceljs";

export class ProductGroupExcel extends BaseExcel {
  formId: any;
  form: OrderFormInterface;
  relationToMaster: boolean;
  formLines: Array<{
    id: any;
    type: string;
    material_category_id: any;
    title: string;
    values: any[];
  }>;
  filters: Array<{ key: string; title: string; values: any[] }>;

  constructor(categoryId: any, merchantId: any, formId: any) {
    super(categoryId);
    const store = useStore();
    this.formId = formId;
    this.merchantId = merchantId;
    this.form = store.getters["order/form"](formId);
    this.relationToMaster = this.form.relation_to_master * 1 === 1;
    this.formLines = [];
    this.filters = [];
  }

  async process(): Promise<void> {
    this.wb = new ExcelJs.Workbook();
    this.sheetIndex = -1;
    this.sheets = {};
    await this.prepareOtherColumns();
    await this.prepareOrderForms();
    await this.prepareCategoryFilters();
    await this.setHeaderLetters();
    await this.preparePrimaryColumns();
    await this.setHeaderLetters();
    return await super.baseProcess();
  }

  private async prepareOrderForms(): Promise<void> {
    this.formLines = [];

    this.form.lines.data.map((line) => {
      if (line.master * 1 === 1 && this.relationToMaster) return false;
      if (
        ["product", "options", "forms"].indexOf(line.type) > -1 &&
        (line.required * 1 === 1 || line.select_first * 1 === 1)
      ) {
        this.formLines.push({
          id: line.id,
          type: line.type,
          material_category_id: line.material_category_id * 1,
          title: line.title,
          values: line.options || [],
        });
      }
    });

    const categoryIds = this.formLines
      .filter((o) => o.type === "product" && !!o.material_category_id)
      .map((o) => o.material_category_id);

    if (categoryIds.length > 0) {
      const productResult = await selectionService.fetch({
        product: {
          "@get": true,
          "@select": ["id", "merchant_id", "material_category_id", "barcode"],
          "@where": {
            merchant_id: this.merchantId,
            material_category_id: categoryIds,
            status: 1,
          },
        },
      });

      if (productResult.kind === "ok") {
        const products = get(productResult.data, "product.data", []);
        this.formLines.map((line) => {
          if (line.type === "product") {
            line.values = products
              .filter(
                (product: any) =>
                  product.material_category_id === line.material_category_id
              )
              .map((product: any) => {
                return {
                  value: product.id,
                  text: product.barcode,
                };
              });
          }
        });
      }
    }

    this.formLines.map((line) => {
      set(this.sheets, `other.headers.material_${line.id}`, {
        key: `material_${line.id}`,
        title: line.title,
        fill: "primary",
        rows: line.values,
      });
    });
  }

  private async prepareCategoryFilters(): Promise<void> {
    const filterKeys = [
      "filter_brand",
      "filter_size",
      "filter_color",
      "filter_1",
      "filter_2",
      "filter_3",
      "filter_4",
      "filter_5",
      "filter_6",
      "filter_7",
      "filter_8",
      "filter_9",
      "filter_10",
    ];

    const selectionConfig: Record<string, any> = {
      "@first": true,
      "@select": ["id", "filter_family_id"],
      "@where": {
        id: this.categoryId,
        status: 1,
      },
      family: {
        "@select": {},
      },
    };

    filterKeys.map((key) => {
      set(selectionConfig, `family.@select.${key}_id`, `${key}_id`);
      set(selectionConfig, `family.${key}`, {
        "@select": ["id", "title"],
        options: {
          "@select": ["filter_id", "slug", "title"],
        },
      });
    });

    const categoryResult = await selectionService.fetch({
      category: selectionConfig,
    });

    if (categoryResult.kind === "ok") {
      const family: Record<string, any> = get(
        categoryResult.data,
        "category.family"
      );
      filterKeys.map((key) => {
        const filter = get(family, `${key}`);
        if (filter) {
          const filterTitle = get(filter, `title`);
          const filterOptions: any[] = get(filter, `options`) || [];
          this.filters.push({
            key,
            title: filterTitle,
            values: filterOptions.map((o) => {
              const text = cloneDeep(o.title) || "";
              return {
                value: o.slug || kebabCase(helper.lowerCase(text)),
                text,
              };
            }),
          });
        }
      });
    }

    this.filters.map((filter) => {
      set(this.sheets, `other.headers.${filter.key}`, {
        key: filter.key,
        title: filter.title,
        fill: "primary",
        rows: filter.values,
      });
    });
  }

  private async prepareOtherColumns(): Promise<void> {
    this.sheets["other"] = {
      protected: true,
      title: "Ürün Özellik Bilgileri",
      state: "hidden",
      headers: {
        category_id: {
          key: "category_id",
          title: "Kategori",
          fill: "primary",
          rows: [this.categoryId ? this.categoryId.toString() : ""],
        },
        status: {
          key: "status",
          title: "Durum",
          fill: "primary",
          rows: [
            {
              value: "0",
              text: "Pasif",
            },
            {
              value: "1",
              text: "Aktif",
            },
            {
              value: "2",
              text: "Satışa Kapalı",
            },
          ],
        },
        can_return: {
          key: "can_return",
          title: "İade Garantisi",
          fill: "primary",
          rows: [
            {
              value: "0",
              text: "Hayır",
            },
            {
              value: "1",
              text: "Evet",
            },
          ],
        },
        price_type: {
          key: "price_type",
          title: "Fiyata KDV Dahil",
          fill: "primary",
          rows: [
            {
              value: "0",
              text: "Hayır",
            },
            {
              value: "1",
              text: "Evet",
            },
          ],
        },
      },
    };

    if (this.relationToMaster) {
      set(this.sheets, "other.headers", {
        ...get(this.sheets, "other.headers", {}),
        unit: {
          key: "unit",
          title: "Stok Birimi",
          fill: "primary",
          rows: [
            {
              value: "adet",
              text: "Adet",
            },
            {
              value: "m",
              text: "Metre",
            },
            {
              value: "m2",
              text: "Metrekare",
            },
          ],
        },
        vat_rate: {
          key: "vat_rate",
          title: "KDV Oranı",
          fill: "primary",
          rows: ["0", "1", "8", "10", "18", "20"],
        },
      });
    }
  }

  private async preparePrimaryColumns(): Promise<void> {
    const store = useStore();

    this.sheets["primary"] = {
      title: "Ürünlerinizi Buraya Ekleyin",
      headers: {
        status: {
          key: "status",
          title: "Ürün Satış Durumu",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "list",
            formulae: [this.getAddressOtherSheetField("status")],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "ÜRÜN SATIŞ DURUMU GİRİN",
            prompt: `${PROMPT_NEW_LINE}Ürünün satış durumunu listeden seçiniz.`,
          },
        },
        barcode: {
          key: "barcode",
          title: "Barkod",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "any",
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "BARKOD NUMARASI GİRİN",
            prompt: `${PROMPT_NEW_LINE}Ürünün üzerindeki barkod girilmelidir.${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Bir barkod yalnızca bir satırdaki ürün için kullanılabilir.`,
          },
        },
        item_code: {
          key: "item_code",
          title: "Stok Grup Kodu",
          fill: "primary",
          validation: {
            type: "any",
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "STOK GRUP KODU GİRİN",
            prompt: `${PROMPT_NEW_LINE}Buraya gireceğiniz kod ile ürünleri gruplarsınız.${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Ürün sayfasında aynı gruptaki ürünlerin farklı renk ve ebatlarına geçiş sağlanabilir.`,
          },
        },
        stock_code: {
          key: "stock_code",
          title: "Stok Kodu",
          fill: "primary",
          validation: {
            type: "any",
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "STOK KODU GİRİN",
            prompt: `${PROMPT_NEW_LINE}Bu alana kendi sisteminizdeki kodu yazarak takip edebilirsiniz.`,
          },
        },
        category_id: {
          key: "category_id",
          title: "Kategori",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "list",
            formulae: [this.getAddressOtherSheetField("category_id")],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "KATEGORİ GİRİN",
            prompt: `${PROMPT_NEW_LINE}Excel kategori bazlı oluşturulduğu için bütün satırlarda örnek olarak verilen değer bulunmalıdır.`,
          },
        },
        title: {
          key: "title",
          title: "Ürün Adı",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "any",
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "ÜRÜN ADI GİRİN",
            prompt:
              `${PROMPT_NEW_LINE}Müşterilerimiz ilk olarak ürün adını görür ve satın almak için ürün adını aratır.` +
              `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Bu nedenle ürün adının dikkatle girilmesi önemlidir.`,
          },
        },
        description: {
          key: "description",
          title: "Ürün Açıklaması",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "any",
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "ÜRÜN AÇIKLAMASI GİRİN",
            prompt:
              `${PROMPT_NEW_LINE}Yalnızla ürün detayı hakkında bilgi içermelidir.` +
              `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Tedarikçi ile ilgili bilgiler (telefon, adres, link, vb...) içermemelidir.`,
          },
        },
        can_return: {
          key: "can_return",
          title: "İade Garantisi Var Mı?",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "list",
            formulae: [this.getAddressOtherSheetField("can_return")],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "İADE GARANTİSİ GİRİN",
            prompt: `${PROMPT_NEW_LINE}İade garantisi olup olmadığını belirtiniz.`,
          },
        },
        min_production_day: {
          key: "min_production_day",
          title: "Minimum Üretim Günü",
          fill: "primary",
          color: "FFE76C6C",
          numberFormat: "0",
          validation: {
            type: "decimal",
            operator: "greaterThanOrEqual",
            formulae: [0],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "MİNİMUM ÜRETİM GÜNÜ GİRİN",
            prompt: `${PROMPT_NEW_LINE}Ürünü minimum kaç gün içinde üretip kargoya teslim edebileceğiniz günü yazınız.`,
          },
        },
        max_production_day: {
          key: "max_production_day",
          title: "Maksimum Üretim Günü",
          fill: "primary",
          color: "FFE76C6C",
          numberFormat: "0",
          validation: {
            type: "decimal",
            operator: "lessThanOrEqual",
            formulae: [14],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "MAKSİMUM ÜRETİM GÜNÜ GİRİN",
            prompt: `${PROMPT_NEW_LINE}Ürünü maksimum kaç gün içinde üretip kargoya teslim edebileceğiniz günü yazınız.`,
          },
        },
      },
    };

    if (this.relationToMaster) {
      set(this.sheets, "primary.headers", {
        ...get(this.sheets, "primary.headers", {}),
        price_type: {
          key: "price_type",
          title: "Fiyatlara KDV Dahil Mi?",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "list",
            formulae: [this.getAddressOtherSheetField("price_type")],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "",
            prompt: `${PROMPT_NEW_LINE}Girilen fiyatlara kdv dahil ise listeden EVET seçeneğini seçiniz.`,
          },
        },
        old_price: {
          key: "old_price",
          title: "Piyasa Satış Fiyatı",
          fill: "primary",
          color: "FFE76C6C",
          numberFormat: "0.00",
          validation: {
            type: "decimal",
            operator: "greaterThanOrEqual",
            formulae: [0],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "PİYASA SATIŞ FİYATINI GİRİN",
            prompt:
              `${PROMPT_NEW_LINE}Fiyat sonuna TL ibaresi konulmamalıdır.` +
              `${PROMPT_NEW_LINE}Kuruş değeri virgül ile ayrılarak araya boşluk bırakılmadan 2 hane olacak şekilde yazılmalıdır.` +
              `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Örnek: 19,80 veya 24`,
          },
        },
      });

      if (get(store.getters["auth/profile"], "use_merchant_price") === true) {
        set(this.sheets, "primary.headers", {
          ...get(this.sheets, "primary.headers", {}),
          merchant_price: {
            key: "merchant_price",
            title: "Satış Fiyatı",
            fill: "primary",
            color: "FFE76C6C",
            numberFormat: "0.00",
            validation: {
              type: "decimal",
              operator: "greaterThanOrEqual",
              formulae: [0],
              showErrorMessage: true,
              showInputMessage: true,
              promptTitle: "SİTENİZE SATIŞ FİYATINI GİRİN",
              prompt:
                `${PROMPT_NEW_LINE}Ürünü sitenizde'de satmak istediğiniz fiyatı giriniz.` +
                `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Fiyat sonuna TL ibaresi konulmamalıdır.` +
                `${PROMPT_NEW_LINE}Kuruş değeri virgül ile ayrılarak araya boşluk bırakılmadan 2 hane olacak şekilde yazılmalıdır.` +
                `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Örnek: 19,80 veya 24`,
            },
          },
        });
      }

      set(this.sheets, "primary.headers", {
        ...get(this.sheets, "primary.headers", {}),
        price: {
          key: "price",
          title: "Eniyiperde Satış Fiyatı",
          fill: "primary",
          color: "FFE76C6C",
          numberFormat: "0.00",
          validation: {
            type: "decimal",
            operator: "greaterThanOrEqual",
            formulae: [0],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "ENİYİPERDE SATIŞ FİYATINI GİRİN",
            prompt:
              `${PROMPT_NEW_LINE}Ürünü Eniyiperde'de satmak istediğiniz fiyatı giriniz.` +
              `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Fiyat sonuna TL ibaresi konulmamalıdır.` +
              `${PROMPT_NEW_LINE}Kuruş değeri virgül ile ayrılarak araya boşluk bırakılmadan 2 hane olacak şekilde yazılmalıdır.` +
              `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Örnek: 19,80 veya 24`,
          },
        },
        vat_rate: {
          key: "vat_rate",
          title: "KDV Oranı",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "list",
            formulae: [this.getAddressOtherSheetField("vat_rate")],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "KDV ORANI GİRİN",
            prompt: `${PROMPT_NEW_LINE}KDV değerini listeden seçiniz.`,
          },
        },
        stock: {
          key: "stock",
          title: "Stok Miktarı",
          fill: "primary",
          color: "FFE76C6C",
          numberFormat: "0",
          validation: {
            type: "decimal",
            operator: "greaterThanOrEqual",
            formulae: [0],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "STOK MİKTARINI GİRİN",
            prompt: `${PROMPT_NEW_LINE}Ürünün güncel satılabilir stok miktarını girin. ${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Minimum: 0`,
          },
        },
        unit: {
          key: "unit",
          title: "Stok Birimi",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "list",
            formulae: [this.getAddressOtherSheetField("unit")],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "STOK BİRİMİ GİRİN",
            prompt: `${PROMPT_NEW_LINE}Stok birimini listeden seçiniz.`,
          },
        },
      });
    }

    this.formLines.map((line) => {
      set(this.sheets, `primary.headers.material_${line.id}`, {
        key: `material_${line.id}`,
        title: line.title,
        fill: "info",
        color: "FFE76C6C",
        validation: {
          type: "list",
          formulae: [this.getAddressOtherSheetField(`material_${line.id}`)],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: `${helper.upperCase(line.title)} GİRİN`,
          prompt: `${PROMPT_NEW_LINE}Alt ürünün barkod numarasını giriniz.`,
        },
      });
    });

    this.filters.map((filter) => {
      set(this.sheets, `primary.headers.${filter.key}`, {
        key: filter.key,
        title: filter.title,
        fill: "info",
        validation: {
          type: "list",
          formulae: [this.getAddressOtherSheetField(filter.key)],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: `${helper.upperCase(filter.title)} GİRİN`,
          prompt: `${PROMPT_NEW_LINE}Ürünün özelliğini listeden seçiniz.`,
        },
      });
    });

    if (this.relationToMaster) {
      set(this.sheets, "primary.headers", {
        ...get(this.sheets, "primary.headers", {}),
        min_width: {
          key: "min_width",
          title: "Minimum En",
          fill: "primary",
          numberFormat: "0",
          validation: {
            type: "decimal",
            operator: "greaterThanOrEqual",
            formulae: [0],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "MİNİMUM EN GİRİN",
            prompt: `${PROMPT_NEW_LINE}Buraya sipariş edilebilir minimum en ölçüsünü giriniz.`,
          },
        },
        max_width: {
          key: "max_width",
          title: "Maksimum En",
          fill: "primary",
          numberFormat: "0",
          validation: {
            type: "decimal",
            operator: "greaterThanOrEqual",
            formulae: [0],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "MAKSİMUM EN GİRİN",
            prompt: `${PROMPT_NEW_LINE}Buraya sipariş edilebilir maksimum en ölçüsünü giriniz.`,
          },
        },
        min_height: {
          key: "min_height",
          title: "Minimum Boy",
          fill: "primary",
          numberFormat: "0",
          validation: {
            type: "decimal",
            operator: "greaterThanOrEqual",
            formulae: [0],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "MİNİMUM BOY GİRİN",
            prompt: `${PROMPT_NEW_LINE}Buraya sipariş edilebilir minimum boy ölçüsünü giriniz.`,
          },
        },
        max_height: {
          key: "max_height",
          title: "Maksimum Boy",
          fill: "primary",
          numberFormat: "0",
          validation: {
            type: "decimal",
            operator: "greaterThanOrEqual",
            formulae: [0],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "MAKSİMUM BOY GİRİN",
            prompt: `${PROMPT_NEW_LINE}Buraya sipariş edilebilir maksimum boy ölçüsünü giriniz.`,
          },
        },
      });
    }

    set(this.sheets, "primary.headers", {
      ...get(this.sheets, "primary.headers", {}),
      photo_list: {
        key: "photo_list",
        title: "Görsel Linki",
        fill: "primary",
        validation: {
          type: "any",
          formulae: [],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: "GÖRSEL LİNKİ GİRİN",
          prompt:
            `${PROMPT_NEW_LINE}Direkt ürün görsellerini içeren görsel linki eklenmelidir.` +
            `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Birden çok görsel linki girmek için linkler arasına virgül koyarak sıralayabilirsiniz.`,
        },
      },
    });
  }
}
