
import { computed, defineComponent, onMounted, ref } from "vue";
import Clipboard from "@/components/clipboard/Main.vue";
import { cloneDeep, get } from "lodash";
import { helper } from "@/utils/helper";
import Tippy from "@/components/tippy/Main.vue";
import {
  OrderLineInterface,
  OrderStateInterface,
  orderStatusService,
} from "@/services";
import dayjs from "dayjs";
import OrderLine from "@/views/orders/detail/components/merchant/OrderLine.vue";
import OrderCalculationLine from "@/views/orders/detail/components/merchant/OrderCalculationLine.vue";
import OrderStatusModal from "@/views/orders/detail/components/OrderStatusModal.vue";
import { useStore } from "@/store";
import VueModal from "@/components/modal/Main.vue";

export default defineComponent({
  name: "OrderMerchant",
  components: {
    VueModal,
    OrderStatusModal,
    OrderCalculationLine,
    OrderLine,
    Tippy,
    Clipboard,
  },
  props: {
    merchant: {
      type: Object,
      default: () => ({}),
    },
    reloadData: {
      type: Function,
      default: () => null,
    },
  },
  setup(props) {
    const store = useStore();
    const transitionableStates = ref<OrderStateInterface[]>([]);
    const trackingModalId = helper.uuidv4();

    const orders = computed<OrderLineInterface[]>(() =>
      get(props.merchant, "lines", [])
    );

    const orderGroupId = computed(() => get(orders.value, "0.order_group_id"));

    const activeState = computed<string>(() =>
      get(orders.value, "0.status", null)
    );

    const cargoLogo = computed<string>(() => {
      const cargoId = get(orders.value, "0.cargo_id");
      return store.getters["system/definition"](
        "cargo_firms",
        cargoId,
        "photo"
      );
    });

    const cargoURL = computed<string>(() => {
      const trackingNumber = get(orders.value, "0.cargo_tracking_number");
      const cargoId = get(orders.value, "0.cargo_id");
      const web = store.getters["system/definition"](
        "cargo_firms",
        cargoId,
        "web"
      );
      return `${web}${trackingNumber}`;
    });

    const statusInfo = computed(() => {
      const info = store.getters["system/definition"](
        "order_states",
        activeState.value,
        null
      );

      return {
        status_bg: get(info, "background"),
        status_text_color: helper.invertColor(get(info, "background")),
        status_title: get(info, "text"),
      };
    });

    const estimatedShippingDate = computed(() => {
      let lastTime = dayjs();
      orders.value.map((order) => {
        const estimatedTime = dayjs(order.estimated_shipping_date);
        if (estimatedTime > lastTime) lastTime = cloneDeep(estimatedTime);
      });
      return lastTime.format("YYYY-MM-DD");
    });

    const shipping = computed(() => {
      const { days, hours, minutes } = helper.diffTimeByNow(
        estimatedShippingDate.value
      );
      return {
        cargo_id: get(orders.value, "0.cargo_id"),
        tracking_number: get(orders.value, "0.cargo_tracking_number"),
        estimated_shipping_days: days,
        estimated_shipping_hours: hours,
        estimated_shipping_minutes: minutes,
      };
    });

    const trackings = computed(() => {
      return get(props.merchant, "trackings", []).map((tracking: any) => {
        const info = store.getters["system/definition"](
          "order_states",
          get(tracking, "status"),
          null
        );
        return {
          id: get(tracking, "id"),
          status: get(tracking, "status"),
          status_title: get(info, "text"),
          status_bg: get(info, "background"),
          status_text_color: helper.invertColor(get(info, "background")),
          message: get(tracking, "message"),
          created_by: get(tracking, "created_by"),
          created_at: get(tracking, "created_at"),
        };
      });
    });

    const fetchTransitionable = () => {
      orderStatusService
        .transitionable({
          order_group_id: get(orders.value, "0.order_group_id"),
          merchant_id: get(props.merchant, "id"),
        })
        .then((result) => {
          if (result.kind === "ok") {
            transitionableStates.value = result.data;
          }
        });
    };

    onMounted(() => {
      fetchTransitionable();
    });

    return {
      trackingModalId,
      orderGroupId,
      activeState,
      statusInfo,
      shipping,
      transitionableStates,
      orders,
      cargoLogo,
      cargoURL,
      trackings,
    };
  },
});
