import { ActionTree, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { ActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { MutationTypes } from "./mutation-types";
import { selectionService } from "@/services";
import { get } from "lodash";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.FETCH_ALL_CATEGORIES](ctx: AugmentedActionContext): void;
  [ActionTypes.SET_CATEGORIES](
    ctx: AugmentedActionContext,
    categories: any[]
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.FETCH_ALL_CATEGORIES]({ commit }) {
    selectionService
      .fetch(
        {
          category: {
            "@get": true,
            "@select": ["id", "title"],
            "@whereNotNull": ["parent_id"],
          },
        },
        true
      )
      .then((result) => {
        if (result.kind === "ok") {
          commit(
            MutationTypes.SET_CATEGORIES,
            get(result.data, "category.data", [])
          );
        }
      });
  },
  [ActionTypes.SET_CATEGORIES]({ commit }, categories: any[]) {
    commit(MutationTypes.SET_CATEGORIES, categories);
  },
};
