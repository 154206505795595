import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "color-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: "cursor-pointer",
    style: _normalizeStyle({
      color: _ctx.textColor,
      'border-color': _ctx.borderColor,
      'background-color': _ctx.backgroundColor,
      'background-image': _ctx.backgroundImage,
    })
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$_.get(_ctx.color, "title", "")), 1)
  ], 4))
}