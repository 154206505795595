import BaseImage from "@tiptap/extension-image";
import { mergeAttributes } from "@tiptap/core";

export const Image = BaseImage.extend({
  renderHTML(ctx: any) {
    return [
      "img",
      mergeAttributes(this.options.HTMLAttributes, ctx.HTMLAttributes),
    ];
  },
});
