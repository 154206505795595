import { GeneralApiResponse, GetMerchantResult, useAxios } from "@/services";

export * from "./api.types";

export const merchantService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetMerchantResult>>(
      `/api/merchants/${id}`
    ),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetMerchantResult>>(
      `/api/merchants`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetMerchantResult>>(
      `/api/merchants/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/merchants/${id}`),

  updateIban: (id: any, params: unknown) =>
    useAxios().post<GeneralApiResponse<GetMerchantResult>>(
      `/api/merchants/${id}/iban`,
      params
    ),

  sentToPaymentService: (id: any) =>
    useAxios().post<GeneralApiResponse<GetMerchantResult>>(
      `/api/payments/dealer/${id}`
    ),
};
