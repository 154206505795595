<template>
  <node-view-wrapper class="tiptap-disabled-block tiptap-vue-accordion">
    <div contenteditable="false">
      <span class="drag-handle label" draggable="true" data-drag-handle>
        {{ node.attrs.label }}
      </span>
    </div>
    <node-view-content class="mt-4" />
    <div contenteditable="false" class="mt-4" v-if="isEnabled">
      <button class="btn btn-sm btn-secondary" @click="add">
        Yeni Satır Ekle
      </button>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from "@tiptap/vue-3";
import { get } from "lodash";
export default {
  name: "TiptapVueAccordion",
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
  props: nodeViewProps,
  computed: {
    isEnabled() {
      return get(this.editor, "options.editable") || false;
    },
  },
  methods: {
    add() {
      const endPos = this.getPos() + this.node.content.size;
      this.editor.commandManager.chain().focus("start");
      this.editor.commandManager
        .chain()
        .focus(endPos)
        .insertContentAt(endPos, {
          type: "accordionItem",
          content: [{ type: "paragraph" }],
        })
        .run();
    },
  },
};
</script>
