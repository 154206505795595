
import { defineComponent, inject } from "vue";
import OrderFormLineMixin from "@/components/order/form";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";

export default defineComponent({
  name: "OrderFormProduct",
  components: { VueSelect2, VueInputContainer },
  mixins: [OrderFormLineMixin],
  setup() {
    const changeMasterProduct = inject<(product: any) => void>(
      "changeMasterProduct",
      () => null
    );
    return {
      changeMasterProduct,
    };
  },
  computed: {
    productSelect2Options(): Select2Options {
      return {
        chooseFirstOption: this.line.select_first * 1 === 1,
        ajaxQuery: {
          product: {
            "@select": {
              value: "id",
              text: "title",
              code: "id",
              item_code: "item_code",
              form_line_id: "form_line_id",
              min_width: "min_width",
              max_width: "max_width",
              min_height: "min_height",
              max_height: "max_height",
              horizontal_usage: "horizontal_usage",
            },
            "@where": {
              status: 1,
              material_category_id: this.line.material_category_id,
              merchant_id: this.query.merchant_id,
            },
            "@order": ["sq:desc"],
          },
        },
      };
    },
  },
  methods: {
    changeProduct(ctx: any) {
      if (this.isMaster) this.changeMasterProduct(ctx);
    },
  },
});
