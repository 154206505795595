import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import TiptapVueComponent from "./AccordionItem.vue";
// import { getNodeType } from "@tiptap/core/src/helpers/getNodeType";
// import { Node as ProseMirrorNode, NodeType } from "prosemirror-model";
// import { getSplittedAttributes } from "@tiptap/core/src/helpers/getSplittedAttributes";
// import { canSplit } from "prosemirror-transform";

// declare module "@tiptap/core" {
//   interface Commands<ReturnType> {
//     accordionItem: {
//       accordionSplitListItem: (typeOrName: string | NodeType) => ReturnType;
//     };
//   }
// }

export const AccordionItem = Node.create({
  name: "accordionItem",
  content: "block+",
  defining: true,
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      title: {
        default: "",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "tiptap-vue-accordion-item",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "tiptap-vue-accordion-item",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(TiptapVueComponent);
  },

  // addCommands() {
  //   return {
  //     accordionSplitListItem:
  //       (typeOrName) =>
  //       ({ tr, state, dispatch, editor }) => {
  //         const type = getNodeType(typeOrName, state.schema);
  //         const { $from, $to } = state.selection;
  //
  //         // eslint-disable-next-line
  //         // @ts-ignore
  //         const node: ProseMirrorNode = state.selection.node;
  //
  //         if (
  //           (node && node.isBlock) ||
  //           $from.depth < 2 ||
  //           !$from.sameParent($to)
  //         ) {
  //           return false;
  //         }
  //
  //         const grandParent = $from.node(-1);
  //
  //         console.log(type, grandParent, $from);
  //         if (grandParent.type !== type) {
  //           return false;
  //         }
  //
  //         const extensionAttributes = editor.extensionManager.attributes;
  //
  //         const nextType =
  //           $to.pos === $from.end()
  //             ? grandParent.contentMatchAt(0).defaultType
  //             : null;
  //
  //         const newTypeAttributes = getSplittedAttributes(
  //           extensionAttributes,
  //           grandParent.type.name,
  //           {}
  //         );
  //         const newNextTypeAttributes = getSplittedAttributes(
  //           extensionAttributes,
  //           $from.node().type.name,
  //           {}
  //         );
  //
  //         tr.delete($from.pos, $to.pos);
  //
  //         const types = nextType
  //           ? [
  //               { type, attrs: newTypeAttributes },
  //               { type: nextType, attrs: newNextTypeAttributes },
  //             ]
  //           : [{ type, attrs: newTypeAttributes }];
  //
  //         if (!canSplit(tr.doc, $from.pos, 2)) {
  //           return false;
  //         }
  //
  //         if (dispatch) {
  //           tr.split($from.pos, 2, types).scrollIntoView();
  //         }
  //
  //         return true;
  //       },
  //   };
  // },
});
