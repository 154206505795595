
import { computed, defineComponent } from "vue";
import { OrderLineInterface } from "@/services";
import { get } from "lodash";
import Tippy from "@/components/tippy/Main.vue";
import Clipboard from "@/components/clipboard/Main.vue";
import OrderFormLine from "@/views/orders/detail/components/merchant/OrderFormLine.vue";

export default defineComponent({
  name: "OrderLine",
  components: { OrderFormLine, Clipboard, Tippy },
  props: {
    orderLine: {
      type: Object as () => OrderLineInterface,
      default: () => ({}),
    },
  },
  setup(props) {
    const productGroup = computed(() => {
      const group = get(props.orderLine, "m_group");
      return {
        id: get(group, "id"),
        title: get(group, "title"),
        item_code: get(group, "item_code"),
        barcode: get(group, "barcode"),
        stock_code: get(group, "stock_code"),
        category_title: get(group, "category_title"),
        photo: get(group, "photo"),
        filters: get(group, "filters", []),
        can_refund: get(group, "can_refund", get(group, "can_return", 0)) === 1,
      };
    });

    const amounts = computed(() => {
      return {
        product_amount:
          get(props.orderLine, "net_total") + get(props.orderLine, "total_vat"),
        old_product_amount:
          get(props.orderLine, "old_net_total") +
          get(props.orderLine, "old_total_vat"),
      };
    });

    return {
      productGroup,
      amounts,
    };
  },
});
