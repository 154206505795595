
import { computed, defineComponent } from "vue";
import { Editor } from "@tiptap/vue-3";

export default defineComponent({
  name: "TiptapFontSize",
  props: {
    editor: {
      type: Object as () => Editor,
      required: true,
    },
  },
  setup(props) {
    const sizes = computed(() => {
      return [
        "",
        "8px",
        "10px",
        "11px",
        "12px",
        "14px",
        "16px",
        "18px",
        "20px",
        "24px",
        "28px",
        "32px",
        "36px",
        "40px",
        "44px",
        "48px",
        "52px",
      ];
    });

    const setSize = (val: string) => {
      if (val) props.editor.chain().focus().setFontSize(val).run();
      else props.editor.chain().focus().unsetFontSize().run();
    };

    return { sizes, setSize };
  },
});
