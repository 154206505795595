
import { computed, defineComponent } from "vue";
import Tippy from "@/components/tippy/Main.vue";

export default defineComponent({
  name: "Pagination",
  components: { Tippy },
  emits: ["update-page"],
  props: {
    current: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 15,
    },
    total: {
      type: Number,
      default: 1,
    },
    onRefresh: {
      type: Function,
      default: null,
    },
  },
  setup(props, { emit }) {
    const lastPage = computed<number>(() => {
      return Math.ceil(props.total / props.perPage);
    });

    const shownDataStartNumber = computed<number>(() => {
      return props.current * props.perPage - props.perPage + 1;
    });

    const shownDataEndNumber = computed<number>(() => {
      const val = props.current * props.perPage;
      if (val > props.total) return props.total;
      return val;
    });

    const numbersShown = computed<number[]>(() => {
      const arr = [];
      let start = props.current > 2 ? props.current - 1 : 1;
      let end =
        lastPage.value - props.current > 1 ? props.current + 1 : lastPage.value;

      if (props.current === lastPage.value)
        start = lastPage.value - 2 > 0 ? lastPage.value - 2 : start;

      if (props.current === 1)
        end =
          lastPage.value - props.current > 2
            ? props.current + 2
            : lastPage.value;
      if (end - start > 3) end = start + 3;
      for (let i = start; i <= end; i++) {
        arr.push(i);
      }
      return arr;
    });

    const clickButton = (page: number) => {
      if (props.current * 1 !== page) {
        emit("update-page", page);
      }
    };

    const prevButton = () => {
      if (props.current > 1) clickButton(props.current - 1);
    };

    const nextButton = () => {
      if (props.current < lastPage.value) clickButton(props.current + 1);
    };

    return {
      shownDataStartNumber,
      shownDataEndNumber,
      numbersShown,
      lastPage,
      clickButton,
      prevButton,
      nextButton,
    };
  },
});
