import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileManagerSelect = _resolveComponent("FileManagerSelect")!

  return (_openBlock(), _createBlock(_component_FileManagerSelect, {
    disabled: _ctx.disabled,
    multiple: _ctx.multiple,
    "model-value": _ctx.paths,
    "onFile:add": _ctx.onAddedFiles,
    "onFile:remove": _ctx.onRemovedFile,
    "onMove:end": _ctx.onMoveEnd
  }, null, 8, ["disabled", "multiple", "model-value", "onFile:add", "onFile:remove", "onMove:end"]))
}