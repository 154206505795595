
import { computed, defineComponent, reactive, toRaw } from "vue";
import { FormLine } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import Tippy from "@/components/tippy/Main.vue";

export default defineComponent({
  name: "OrderFormLineItem",
  components: { Tippy },
  props: {
    index: {
      type: Number,
      required: true,
    },
    query: {
      type: Object as () => FormLine,
      default: () => ({}),
    },
    lines: {
      type: Array as () => FormLine[],
      default: () => [],
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
    clone: {
      type: Function,
      default: () => null,
    },
    destroy: {
      type: Function,
      default: () => null,
    },
    edit: {
      type: Function,
      default: () => null,
    },
  },
  setup(props) {
    const editableQuery = reactive<FormLine>(toRaw(props.query));

    const hasSameCode = computed(() => {
      let has = false;
      props.lines?.map((l, i) => {
        if (i !== props.index && l.code === editableQuery.code) {
          has = true;
        }
      });
      return has;
    });

    const onClone = () => {
      props.clone(
        props.index,
        {
          ...props.query,
          title: props.query?.title + " Kopya",
        },
        true
      );
    };

    return {
      editableQuery,
      hasSameCode,
      onClone,
    };
  },
});
