<template>
  <node-view-wrapper
    class="tiptap-disabled-block tiptap-vue-category-container"
  >
    <div contenteditable="false">
      <span class="drag-handle label" draggable="true" data-drag-handle>
        {{ node.attrs.label }}
      </span>
    </div>
    <div class="tiptap-disabled-block-content">
      <VueInputContainer class="col-span-full">
        <template v-slot:label>
          Aşağıdaki listeden ilgili kategorileri seçin
        </template>
        <VueSelect2 v-model="categories" :options="categorySelect2Options" :disabled="!isEnabled" />
      </VueInputContainer>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-3";
import VueInputContainer from "@/components/form-elements/input-container/Main";
import VueSelect2 from "@/components/form-elements/select2/Main";
import { useStore } from "@/store";
import { cloneDeep, get, isArray, isNumber, isString } from "lodash";
export default {
  name: "TiptapVueCategoryContainer",
  components: {
    VueSelect2,
    VueInputContainer,
    NodeViewWrapper,
  },
  props: nodeViewProps,
  computed: {
    isEnabled() {
      return get(this.editor, "options.editable") || false;
    },
    categories: {
      get() {
        const value = get(this.node, "attrs.categories") || "";
        if (value && (isString(value) || isNumber(value))) {
          return value.toString().split(",");
        }
        return [];
      },
      set(val) {
        const value = cloneDeep(val);
        this.updateAttributes({
          categories: isArray(value) ? value.join(",") : "",
        });
      },
    },
    categorySelect2Options: () => ({
      multiple: true,
      isArray: true,
      data: useStore().getters["category/categories"].map((item) => ({
        id: item.id,
        value: item.id,
        text: item.title,
      })),
    }),
  },
};
</script>
