import {
  GeneralApiResponse,
  GetOrderGroupResult,
  GetOrderTransitionableStatesResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const orderService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetOrderGroupResult>>(
      `/api/orders/${id}`
    ),
};

export const orderStatusService = {
  submit: (params: unknown) =>
    useAxios().post<GeneralApiResponse<any>>(
      `/api/orders/update-status`,
      params
    ),
  transitionable: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetOrderTransitionableStatesResult>>(
      `/api/orders/transitionable-status`,
      params
    ),
};
