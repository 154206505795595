
import { defineComponent, onMounted, ref } from "vue";
import { selectionService } from "@/services";
import { get } from "lodash";
import { helper } from "@/utils/helper";
import { generateBlobRadius } from "@/utils/generate-blob-radius";

export default defineComponent({
  name: "PendingApprovalProductGroupWidget",
  setup() {
    const themeColor = "#ffcaf2";
    const invertThemeColor = helper.invertColor(themeColor);

    const blobRadius = generateBlobRadius();

    const loading = ref<boolean>(false);
    const count = ref<number>(0);

    const fetch = () => {
      loading.value = true;
      selectionService
        .fetch({
          count: {
            "@source": "product_group",
            "@count": true,
            "@where": {
              check_status: "1",
            },
          },
        })
        .then((result) => {
          if (result.kind === "ok") {
            count.value = get(result.data, "count") || 0;
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    onMounted(() => {
      fetch();
    });

    return {
      loading,
      count,
      themeColor,
      invertThemeColor,
      blobRadius,
    };
  },
});
