
import { defineComponent, inject, Ref } from "vue";
import {
  cloneDeep,
  findIndex,
  forEach,
  get,
  has,
  isEmpty,
  last,
  size,
  slice,
  uniq,
} from "lodash";
import OrderFormLineMixin from "@/components/order/form";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import SelectableColorItem from "@/components/order/form/fabric-color/SelectableItem.vue";
import regexColors from "@/utils/regex-colors";
import { ToastNotify } from "@/utils/toast-notify";
import { selectionService } from "@/services";
import CardBox from "@/components/order/form/fabric-color/CardBox.vue";

export default defineComponent({
  name: "OrderFormFabricColors",
  components: { CardBox, SelectableColorItem, VueInputContainer },
  mixins: [OrderFormLineMixin],
  data() {
    return {
      symmetric: true,
      openingType: "single",
      currentColor: null as any,
      colors: [] as any[],
      selectedColors: [] as any[],
    };
  },
  setup() {
    const selectedMasterProduct = inject<Ref>("getMasterProduct");
    return {
      selectedMasterProduct,
    };
  },
  computed: {
    merchantId() {
      return get(this.query, "merchant_id");
    },
    displayText() {
      return regexColors.display(this.lineValue);
    },
    selectedCodes() {
      const selected: string[] = this.selectedColors
        .filter((o) => o.code)
        .map((o) => o.code);
      return uniq(selected);
    },
    getVariables() {
      return this.variables(this.forms, this.formPath);
    },
    numberOfBoxes() {
      let nob = 0;
      const vars = this.getVariables;
      if (this.sumOfWidthInForm) {
        nob = this.sumOfWidthInForm / get(vars, "divide_by", 1);
        if (this.openingType === "double") {
          nob = Math.round(nob + get(vars, "double_round", 0));
          if (nob % 2) nob += get(vars, "double_mod", 0);
        } else {
          nob = Math.round(nob + get(vars, "single_round", 0));
        }
      }
      return nob > 0 ? nob : 0;
    },
    openingSideLineValue() {
      const index = findIndex(this.lines, { type: "openingside" });
      return get(this.lines, [index, "value"]);
    },
    openingLineValue() {
      const index = findIndex(this.lines, { type: "opening" });
      return get(this.lines, [index, "value"]);
    },
  },
  watch: {
    selectedMasterProduct() {
      this.fetchColors();
    },
    maxHeightInForm() {
      this.changeValueFromSelectedColors();
    },
    numberOfBoxes(val) {
      this.selectedColors = this.resizeOrClearColors(this.selectedColors, val);
      this.changeValueFromSelectedColors();
    },
    openingLineValue(val) {
      this.changeOpeningType(val);
    },
    openingSideLineValue(val) {
      this.changeOpeningType(val === "both" ? "double" : "single");
    },
  },
  beforeMount() {
    if (this.lineValue) {
      this.selectedColors = this.parseValue(this.lineValue);
      this.changeValueFromSelectedColors();
    }
    if (this.selectedMasterProduct) {
      this.fetchColors();
    }
  },
  methods: {
    toggleSymmetric() {
      this.symmetric = !this.symmetric;
    },
    pickColor(color: any) {
      this.currentColor = color;
    },
    changeOpeningType(type: string) {
      switch (type) {
        case "single":
        case "double":
          this.openingType = type;
          break;
        default:
          this.openingType = "single";
          break;
      }
    },
    clearColors() {
      this.selectedColors = [];
      this.onChangeValue("");
    },
    setColor(index: number) {
      const color = cloneDeep(this.currentColor);
      const code = get(this.currentColor, "code");
      if (!code || !color) return;

      for (let i = 0; i < this.numberOfBoxes; i++) {
        if (!has(this.selectedColors, [i, "code"])) {
          this.selectedColors[i] = color;
        }
      }

      this.selectedColors[index] = color;

      if (this.symmetric) {
        const number = this.numberOfBoxes - index - 1;
        this.selectedColors[number] = color;
      }

      this.changeValueFromSelectedColors();
    },
    changeValueFromSelectedColors() {
      const arr = this.checkColors(this.selectedColors, this.numberOfBoxes);
      this.onChangeValue(this.stringifyValue(arr, this.maxOfHeightInForm));
    },
    parseValue(data: string): any[] {
      const colors = data.split(";");
      const selectedColors: any[] = [];

      let fromLeft = 0;
      colors.map((color) => {
        const colorData: any[] = color.split("|");

        let keyStart: number = fromLeft;
        let keyEnd: number = fromLeft + get(colorData, [0], "0") * 1;
        for (let number = keyStart; number < keyEnd; number++) {
          selectedColors[number] = {
            code: colorData[1],
            title: colorData[2],
            item_code: colorData[3],
          };
        }
        fromLeft = keyEnd;
      });

      return selectedColors;
    },
    stringifyValue(data: any, maxHeightInForm: any): string {
      const str: string[] = [];
      forEach(data, (value) => {
        if (!isEmpty(value)) {
          const count = value.keyEnd - value.keyStart + 1;
          const code = get(value, "value.code", "-");
          const title = get(value, "value.title", "-");
          const itemCode = get(value, "value.item_code", "-");
          str.push(`${count}|${code}|${title}|${itemCode}|${maxHeightInForm}`);
        }
      });
      return str.join(";");
    },
    variables(forms: any, formPath: any[]) {
      return {
        divide_by: parseFloat(
          get(forms, [...formPath, "variables", "divide_by"], 10.5).toString()
        ),
        double_round: parseFloat(
          get(
            forms,
            [...formPath, "variables", "double_round"],
            -0.2
          ).toString()
        ),
        double_mod: parseFloat(
          get(forms, [...formPath, "variables", "double_mod"], -1).toString()
        ),
        single_round: parseFloat(
          get(
            forms,
            [...formPath, "variables", "single_round"],
            -0.6
          ).toString()
        ),
      };
    },
    resizeOrClearColors(colors: any[], numberOfBoxes: number): any[] {
      const isSymmetric = () => {
        let left, right;
        for (let i = 0; i < Math.floor(colors.length / 2); i++) {
          left = get(colors, [i, "code"], "");
          right = get(colors, [colors.length - 1 - i, "code"], "");
          if (left.toString() !== right.toString()) return false;
        }
        return true;
      };

      let selectedColors = cloneDeep(colors);

      // ortadaki rengi bul
      let previousNumber = colors.length;
      let currentNumber = cloneDeep(numberOfBoxes);
      let difference = previousNumber - currentNumber;
      let middleIndex = Math.floor((previousNumber - 1) / 2);
      let items = [];

      if (previousNumber === 0) {
        return selectedColors;
      }

      if (!isSymmetric()) {
        return [];
      }

      if (previousNumber > currentNumber) {
        middleIndex -= Math.floor(Math.abs(difference) / 2);
        selectedColors.splice(middleIndex, difference);
      } else {
        for (let i = 0; i < Math.abs(difference); i++) {
          items.push(get(selectedColors, [middleIndex]));
        }
        selectedColors.splice(middleIndex, 0, ...items);
      }

      ToastNotify({
        className: "warning",
        text: "Slayt sayısı değişti renkleri kontrol ediniz.",
      });

      return selectedColors;
    },
    checkColors(colors: any[], numberOfBoxes: number): any[] {
      let selectedColors = cloneDeep(colors);
      const data: any[] = [{}];
      let dataIndex = 0;

      let numberOfOldColors = size(colors);
      let numberOfNewColors = cloneDeep(numberOfBoxes);

      if (
        numberOfOldColors !== numberOfNewColors &&
        numberOfNewColors !== 0 &&
        numberOfOldColors !== 0
      ) {
        ToastNotify({
          className: "error",
          text: "Slayt sayısı tutmuyor",
        });

        if (numberOfOldColors > numberOfNewColors) {
          selectedColors = slice(selectedColors, 0, numberOfNewColors);
        } else {
          let listItem = last(selectedColors);
          if (listItem) {
            for (let i = 0; i < numberOfNewColors - numberOfOldColors; i++) {
              selectedColors.push(listItem);
            }
          }
        }
      }

      selectedColors.map((value, index) => {
        if (numberOfBoxes === 0) return;
        if (index + 1 > numberOfBoxes) {
          return;
        }

        if (index === 0) {
          data[dataIndex].keyStart = index + 1;
          data[dataIndex].keyEnd = index + 1;
          data[dataIndex].value = value;
        } else {
          let valueDataCode = get(data, [dataIndex, "value", "code"], null);
          let valueCode = get(value, "code", null);
          if (valueDataCode === valueCode && valueCode != null) {
            data[dataIndex].keyEnd = index + 1;
          } else {
            dataIndex++;
            data[dataIndex] = {};
            data[dataIndex].keyStart = index + 1;
            data[dataIndex].keyEnd = index + 1;
            data[dataIndex].value = value;
          }
        }
      });

      return data;
    },
    fetchColors() {
      if (!this.selectedMasterProduct) {
        this.currentColor = undefined;
        this.colors = [];
        return;
      }

      selectionService
        .fetch({
          product: {
            "@get": true,
            "@select": {
              value: "id",
              text: "title",
              title: "title",
              code: "id",
              item_code: "item_code",
              color: "color",
              color_code: "color_code",
            },
            "@where": {
              status: 1,
              material_category_id: get(
                this.selectedMasterProduct,
                "material_category_id"
              ),
              merchant_id: this.merchantId,
              item_code: get(this.selectedMasterProduct, "item_code"),
            },
            "@orWhere": {
              id: this.selectedCodes,
            },
            "@func": ["withCoverPhoto"],
          },
        })
        .then((result) => {
          if (result.kind === "ok") {
            let needsToRefreshValue = false;
            this.colors = get(result.data, "product.data", []);
            this.colors.map((color) => {
              if (color.code === get(this.selectedMasterProduct, "code")) {
                this.pickColor(color);
              }
              this.selectedColors.map((sc, scIndex) => {
                if (
                  sc.code &&
                  color.code &&
                  sc.code.toString() === color.code.toString()
                ) {
                  needsToRefreshValue = true;
                  this.selectedColors[scIndex] = cloneDeep(color);
                }
              });
            });

            if (needsToRefreshValue) {
              this.changeValueFromSelectedColors();
            }
          }
        });
    },
  },
});
