
import { computed, defineComponent } from "vue";
import { Editor } from "@tiptap/vue-3";
import Tippy from "@/components/tippy/Main.vue";
import VueColorPicker from "@/components/form-elements/color/Main.vue";

export default defineComponent({
  name: "TiptapTextColor",
  components: { VueColorPicker, Tippy },
  props: {
    editor: {
      type: Object as () => Editor,
      required: true,
    },
  },
  setup(props) {
    const textColor = computed<string>({
      get() {
        return props.editor.getAttributes("textStyle").color;
      },
      set(value: string) {
        if (value) props.editor.chain().focus().setColor(value).run();
        else props.editor.chain().focus().unsetColor().run();
      },
    });
    return { textColor };
  },
});
