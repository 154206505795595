
import { get } from "lodash";
import { computed, defineComponent } from "vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "SelectableColorItem",
  props: {
    color: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const colorCode = computed(() => get(props.color, "color_code", "#FFFFFF"));
    const textColor = computed(() => helper.invertColor(colorCode.value, true));
    const borderColor = computed(() => helper.invertColor(colorCode.value));
    const backgroundColor = computed(() => colorCode.value);
    const backgroundImage = computed<any>(() => {
      const url = get(props.color, "photo.avatar");
      return url ? `url(${url})` : null;
    });

    return {
      textColor,
      borderColor,
      backgroundColor,
      backgroundImage,
    };
  },
});
