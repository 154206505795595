
import { defineComponent, onMounted } from "vue";
import { GetTokenResults, GetUser, userService } from "@/services";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import { useDeleteRecord } from "@/utils/records/delete";
import { findIndex } from "lodash";
import { useFetchRecords } from "@/utils/records/fetch";

export default defineComponent({
  name: "UserActiveDevices",
  components: { Tippy, TransactionStatement },
  props: {
    userDetail: {
      type: Object as () => GetUser,
      default: () => ({}),
    },
  },
  setup(props) {
    const {
      loading,
      list: tokens,
      fetch,
    } = useFetchRecords<GetTokenResults>({
      serviceMethod: userService.tokens,
    });

    const { deleteState, triggerDelete, removeDeleteError } = useDeleteRecord({
      serviceMethod(id: any) {
        return userService.deleteToken(props.userDetail.id, id);
      },
      success(id: any) {
        const index = findIndex(tokens.data, { id });
        if (index > -1) tokens.data.splice(index, 1);
      },
    });

    onMounted(() => {
      fetch(props.userDetail.id);
    });

    return {
      loading,
      tokens,
      deleteState,
      triggerDelete,
      removeDeleteError,
    };
  },
});
