
import { computed, defineComponent, reactive, toRaw, provide, ref } from "vue";
import {
  FormLine,
  OrderFormInterface,
  ProductGroupInterface,
} from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import { cloneDeep, get, set } from "lodash";
import OrderFormSingle from "@/components/order/form/Single.vue";
import OrderFormMultiple from "@/components/order/form/Multiple.vue";
import OrderFormProduct from "@/components/order/form/Product.vue";
import OrderFormRules from "@/components/order/form/Rules.vue";
import OrderFormOptions from "@/components/order/form/Options.vue";
import OrderFormDirection from "@/components/order/form/Direction.vue";
import OrderFormFabricColor from "@/components/order/form/fabric-color/Main.vue";

export default defineComponent({
  name: "DetectOrderFormLines",
  components: {
    "form-line-single": OrderFormSingle,
    "form-line-multiple": OrderFormMultiple,
    "form-line-product": OrderFormProduct,
    "form-line-rules": OrderFormRules,
    "form-line-options": OrderFormOptions,
    "form-line-direction": OrderFormDirection,
    "form-line-fabric-color": OrderFormFabricColor,
  },
  props: {
    query: {
      type: Object as () => ProductGroupInterface,
      required: true,
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      required: true,
    },
    forms: {
      type: Object as () => { data: OrderFormInterface[] },
      default: () => ({
        data: [],
      }),
    },
    formPath: {
      type: Array as () => any[],
      default: () => [],
    },
  },
  setup(props) {
    const selectedMasterProduct = ref<any>(null);
    provide("getMasterProduct", selectedMasterProduct);
    provide("changeMasterProduct", (product: any) => {
      selectedMasterProduct.value = product;
    });

    const editableQuery = reactive<ProductGroupInterface>(toRaw(props.query));
    const editableForms = reactive<{ data: OrderFormInterface[] }>(
      toRaw(props.forms)
    );
    const lines = computed(() => {
      return get(props.forms, [...props.formPath, "lines", "data"], []);
    });

    const changeLineValue = (value: any, path: any[]) => {
      set(editableQuery.materials, [...path, "value"], cloneDeep(value));
      set(editableForms, [...path, "value"], cloneDeep(value));
    };
    const changeLine = (line: any, path: any[]) => {
      set(editableForms, path, cloneDeep(line));
    };

    const getComponentName = (line: FormLine) => {
      switch (line.type) {
        case "width":
        case "height":
        case "single":
          return "form-line-single";
        case "multiple":
          return "form-line-multiple";
        case "product":
          return "form-line-product";
        case "fabriccolor":
          return "form-line-fabric-color";
        case "forms":
        case "options":
        case "opening":
        case "openingside":
          return "form-line-options";
        case "rules":
          return "form-line-rules";
        case "direction":
          return "form-line-direction";
      }
    };

    return {
      lines,
      changeLine,
      changeLineValue,
      getComponentName,
    };
  },
});
