
import { computed, defineComponent, reactive, ref, toRaw } from "vue";
import { FicheInterface } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import { helper } from "@/utils/helper";
import Tippy from "@/components/tippy/Main.vue";
import VueModal from "@/components/modal/Main.vue";
import { cloneDeep, get } from "lodash";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { useDetectRelation } from "@/views/fiches/create/components/relation";
import { useEditTransactionLine } from "@/views/fiches/create/components/edit";

export default defineComponent({
  name: "FicheLines",
  components: { VueInput, VueSelect, VueInputContainer, VueModal, Tippy },
  props: {
    query: {
      type: Object as () => FicheInterface,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const modalRefId = helper.uuidv4();
    const editableQuery = reactive<FicheInterface>(toRaw(props.query));

    const lineIndex = ref<number>(-1);

    const detectRelationState = useDetectRelation();
    const editLineState = useEditTransactionLine();

    const selectedAmount = computed(() => {
      let amount = 0;
      detectRelationState.list.value.map((item) => {
        if (editLineState.query.value.relations.indexOf(item.id) > -1) {
          amount += item.amount;
        }
      });
      return helper.round(amount);
    });

    const selectAll = computed<boolean>({
      get: () =>
        editLineState.query.value.relations.length ===
        detectRelationState.list.value.length,
      set: (value) => {
        editLineState.query.value.relations = [];
        if (value) {
          editLineState.query.value.relations =
            detectRelationState.list.value.map((item) => item.id);
        }
      },
    });

    const originalLineState = computed(() => {
      return cloneDeep(get(editableQuery.lines, lineIndex.value) || {});
    });

    const onOpen = () => {
      editLineState.errors.value = [];
      detectRelationState.errors.value = [];
      detectRelationState.list.value = [];
      editLineState.reset();
      editLineState.setQuery({
        ...originalLineState.value,
        amount: Math.abs(originalLineState.value.amount || 0),
      });
      onChangeLineType(editLineState.query.value.transaction_type);
      cash(`#${modalRefId}`).modal("show");
    };

    const onClose = () => {
      cash(`#${modalRefId}`).modal("hide");
    };

    const onCreate = () => {
      lineIndex.value = -1;
      onOpen();
    };

    const onEdit = (index: number) => {
      lineIndex.value = index;
      onOpen();
    };

    const onSave = () => {
      if (
        !editLineState.query.value.amount ||
        editLineState.query.value.amount === "0"
      ) {
        editLineState.query.value.amount = Math.abs(selectedAmount.value);
      }
      editLineState.submit(selectedAmount.value, (data) => {
        if (lineIndex.value > -1) {
          editableQuery.lines[lineIndex.value] = {
            ...editableQuery.lines[lineIndex.value],
            ...cloneDeep(data),
            amount: Math.abs(helper.round(data.amount)),
          };
        } else {
          editableQuery.lines.splice(0, 0, cloneDeep(data));
        }
        onClose();
      });
    };

    const onDestroy = (index: number) => {
      editableQuery.lines.splice(index, 1);
    };

    const onChangeLineType = (val: any) => {
      detectRelationState.list.value = [];
      editLineState.setQuery({
        relations: [],
        transaction_type: val,
      });
      if (editLineState.isDetectRelationFiche.value) {
        detectRelationFiches();
      }
    };

    const detectRelationFiches = () => {
      detectRelationState
        .fetch(
          {
            transaction_type: editLineState.query.value.transaction_type,
            time: editableQuery.time,
            merchant_id: editableQuery.merchant_id,
            with: originalLineState.value.relations || [],
          },
          get(editableQuery.lines, `${lineIndex.value}.relations`, [])
        )
        .then((items) => {
          const fixed = originalLineState.value.relations || [];
          if (fixed.length > 0) {
            editLineState.query.value.relations = items.filter(
              (o) => fixed.indexOf(o) > -1
            );
          } else {
            editLineState.query.value.relations = items;
          }
        });
    };

    return {
      modalRefId,
      editableQuery,
      selectedAmount,
      selectAll,
      lineQuery: editLineState.query,
      relationList: detectRelationState.list,
      relationListLoading: detectRelationState.loading,
      isDetectRelationFiche: editLineState.isDetectRelationFiche,
      errors: computed(() => [
        ...detectRelationState.errors.value,
        ...editLineState.errors.value,
      ]),
      detectRelationFiches,
      onChangeLineType,
      onCreate,
      onEdit,
      onClose,
      onSave,
      onDestroy,
    };
  },
});
