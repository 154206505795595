import { BaseExcel } from "@/utils/excel/base";
import ExcelJs from "exceljs";

export class FileListExcel extends BaseExcel {
  constructor() {
    super(null);
  }

  async process(): Promise<void> {
    this.wb = new ExcelJs.Workbook();
    this.sheetIndex = -1;
    this.sheets = {};
    await this.preparePrimaryColumns();
    await this.setHeaderLetters();
    return await super.baseProcess();
  }

  private async preparePrimaryColumns(): Promise<void> {
    this.sheets["primary"] = {
      title: "Seçilen Dosyalar",
      headers: {
        tags: {
          key: "tags",
          title: "Dosya Etiketi",
          fill: "info",
        },
        name: {
          key: "name",
          title: "Dosya Adı",
          fill: "info",
        },
        path: {
          key: "path",
          title: "Dosya Linki",
          fill: "info",
        },
      },
    };
  }
}
