
import { defineComponent } from "vue";
import OrderFormLineMixin from "@/components/order/form";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueTextarea from "@/components/form-elements/textarea/Main.vue";

export default defineComponent({
  name: "OrderFormMultiple",
  components: { VueTextarea, VueInputContainer },
  mixins: [OrderFormLineMixin],
});
