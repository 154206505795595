
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  categoryService,
  CategoryInterface,
  GetCategoryResult,
} from "@/services";
import ContentSideNavigation from "@/views/contents/create/components/SideNavigation.vue";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import CategoryGeneralInformation from "./components/Information.vue";
import dayjs from "dayjs";
import ContentSeoInformation from "@/views/contents/create/components/SeoInformation.vue";
import VueButton from "@/components/form-elements/button/Main.vue";
import ContentAssets from "@/components/assets/Main.vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "CategoryCreateOrUpdate",
  components: {
    ContentAssets,
    VueButton,
    ContentSeoInformation,
    CategoryGeneralInformation,
    ContentSideNavigation,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const activeView = ref<string>("information");

    const id = computed(() => get(route.params, "id"));
    const componentRefreshKey = ref<string>(helper.uuidv4());

    const {
      data: category,
      loading,
      fetch,
    } = useReadRecord<CategoryInterface, GetCategoryResult>({
      id: id.value,
      serviceMethod: categoryService.show,
      success(result) {
        if (result.kind === "ok") {
          componentRefreshKey.value = helper.uuidv4();
          setQuery({
            ...result.data,
            content: {
              ...query.value.content,
              ...(get(result.data, "content") || {}),
            },
          });
        }
      },
      fail() {
        router.go(-1);
      },
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<CategoryInterface, GetCategoryResult>({
        query: {
          parent_id: get(route, "query.parent_id") || null,
          form_id: null,
          filter_family_id: null,
          google_category_id: "696",
          status: "1",
          title: "",
          content: {
            parent: "",
            type: "5",
            sq: "",
            status: "1",
            end_date: "",
            date: dayjs().format("YYYY-MM-DD"),
            slug: "",
            title: "",
            seo_title: "",
            seo_description: "",
            seo_keywords: "",
            header_slug: "",
            header_title: "",
            description: "",
            assets: [],
            workspaces: [],
            url_slug: "",
          },
        },
        storeServiceMethod: categoryService.store,
        updateServiceMethod: categoryService.update,
        success(result) {
          if (result?.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "Kategori Kaydedildi!",
            });
            if (!id.value) {
              router.replace({
                name: "categories.edit",
                params: {
                  id: result.data.id,
                },
              });
            } else {
              componentRefreshKey.value = helper.uuidv4();
              setQuery({
                ...result.data,
                content: {
                  ...query.value.content,
                  ...(get(result.data, "content") || {}),
                },
              });
            }
          }
        },
      });

    const changeView = (view: string) => {
      activeView.value = view;
    };

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) fetch(id.value);
    });

    return {
      loading,
      processing,
      query,
      activeView,
      category,
      validationErrors,
      componentRefreshKey,
      changeView,
      onSubmit,
    };
  },
});
