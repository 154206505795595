
import { defineComponent } from "vue";
import VueButton from "@/components/form-elements/button/Main.vue";
import { authService, GetLoginResult, PostLogin } from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import { getBrowser } from "@/utils/browser";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { set } from "lodash";

export default defineComponent({
  name: "LoginView",
  components: { VueInputContainer, VueInput, VueButton },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const { query, processing, validationErrors, submit } = useSubmitRecord<
      PostLogin,
      GetLoginResult
    >({
      query: {
        email: "",
        password: "",
        device_name: getBrowser(),
        content_manager: true,
      },
      storeServiceMethod: authService.login,
      success(result) {
        if (
          result?.kind === "ok" &&
          ["customer"].indexOf(result.data.type) === -1
        ) {
          store.dispatch("auth/SET_TOKEN", result.meta.token || "");
          store.dispatch("auth/SET_PROFILE", result.data);
          const query = {};
          if (route.query.redirect)
            set(query, "redirect", route.query.redirect);
          router.push({ name: "welcome", query });
        }
      },
    });

    return {
      processing,
      query,
      validationErrors,
      submit,
    };
  },
});
