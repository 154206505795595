import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import TiptapVueComponent from "@/components/assets/extensions/cover-photo/Main.vue";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    coverPhoto: {
      /**
       * Add an image
       */
      setCoverPhoto: (options?: any) => ReturnType;
    };
  }
}

export const CoverPhoto = Node.create({
  name: "coverPhoto",
  group: "block",
  draggable: true,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      title: {
        default: "",
      },
      photo: {
        default: "",
      },
      photo_mobile: {
        default: "",
      },
      label: {
        default: "Kapak Fotoğrafı",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'tiptap-vue-cover-photo[data-type="draggable-item"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "tiptap-vue-cover-photo",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-type": "draggable-item",
      }),
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(TiptapVueComponent);
  },

  addCommands() {
    return {
      setCoverPhoto:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },
});
