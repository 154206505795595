
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  GeneralApiResponse,
  GetMerchant,
  PostMerchant,
  GetMerchantResult,
  merchantService,
} from "@/services";
import MerchantSideNavigation from "@/views/merchant/create/components/SideNavigation.vue";
import MerchantGeneralInformation from "@/views/merchant/create/components/GeneralInformation.vue";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import MerchantInvoiceInformation from "@/views/merchant/create/components/InvoiceInformation.vue";
import MerchantBankInformation from "@/views/merchant/create/components/BankInformation.vue";
import VueButton from "@/components/form-elements/button/Main.vue";
import { ConfirmRef } from "@/components/confirm";

export default defineComponent({
  name: "MerchantCreateOrUpdate",
  components: {
    VueButton,
    MerchantBankInformation,
    MerchantInvoiceInformation,
    MerchantGeneralInformation,
    MerchantSideNavigation,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);

    const activeView = ref<string>("information");

    const id = computed(() => get(route.params, "id"));

    const {
      data: merchant,
      loading,
      fetch,
    } = useReadRecord<GetMerchant, GetMerchantResult>({
      id: id.value,
      serviceMethod: merchantService.show,
      success(result) {
        if (result.kind === "ok") {
          setQuery(result.data);
        }
      },
      fail() {
        router.go(-1);
      },
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<PostMerchant, GeneralApiResponse<GetMerchantResult>>({
        query: {
          code: "~",
          moka_dealer_id: "",
          commission_rate: "15",
          title: "",
          name_surname: "",
          gsm: "",
          email: "",
          form_ids: "",
          description: "",
          status: "1",

          type: "1",
          mersis_no: "",
          commercial_title: "",
          tax_office: "",
          tax_number: "",
          phone: "",
          fax: "",
          city: "",
          district: "",
          address: "",
          iban: [],
        },
        storeServiceMethod: merchantService.store,
        updateServiceMethod: merchantService.update,
        success(result) {
          if (result?.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "Mağazanız Kaydedildi!",
            });
            router.go(-1);
          }
        },
      });

    const changeView = (view: string) => {
      activeView.value = view;
    };

    const onSubmit = () => submit(id.value);

    const onSentToPaymentService = () => {
      confirmRef?.value?.show({
        text: "Mağaza bilgilerini ödeme servisine kaydetmek istediğinize emin misiniz?",
        icon: "feather-alert-circle",
        iconClass: "text-red-400",
        confirmText: "Evet",
        cancelText: "Hayır",
        callback: () => {
          processing.value = true;
          merchantService
            .sentToPaymentService(id.value)
            .then((result) => {
              if (result.kind === "ok") {
                setQuery(result.data);
              }
            })
            .finally(() => {
              processing.value = false;
            });
        },
      });
    };

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) fetch(id.value);
    });

    return {
      id,
      loading,
      processing,
      query,
      activeView,
      merchant,
      validationErrors,
      changeView,
      onSubmit,
      onSentToPaymentService,
    };
  },
});
