import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueModal = _resolveComponent("VueModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_VueModal, {
      id: _ctx.modalRefId,
      "data-backdrop": "",
      "slide-over": "",
      "body-class": "bg-gray-100",
      "dialog-class": "modal-xl"
    }, {
      body: _withCtx(() => [
        (_ctx.state.pageKey)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.state.pageKey), {
              key: _ctx.state.referenceKey,
              "quick-page": {
            referenceKey: _ctx.state.referenceKey,
            id: _ctx.state.id,
            data: _ctx.state.data,
            onSave: _ctx.onSave,
            onClose: _ctx.close,
          }
            }, null, 8, ["quick-page"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["id"])
  ]))
}