
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "GoogleSeoPreview",
  props: {
    title: {
      type: String,
      default: () => "",
    },
    description: {
      type: String,
      default: () => "",
    },
    url: {
      type: String,
      default: () => "",
    },
  },
  setup(props) {
    const previewTitle = computed(() => {
      if (props.title) return props.title;
      return "İçeriğinizi açıklayan, tıklamaya teşvik edecek bir metin girin";
    });
    const previewDescription = computed(() => {
      if (props.description) return props.description;
      return "Sayfanızla alakalı bilgi veren bir meta description etiketi girin. Sıralama faktörü olmasa da kullanıcıların sayfanıza tıklamasında oldukça etkilidir.";
    });

    return {
      previewTitle,
      previewDescription,
    };
  },
});
