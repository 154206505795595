import { get, has, unset, cloneDeep, forEach } from "lodash";

export class ValidationErrors {
  errors: any = {};

  constructor() {
    this.errors = {};
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  has(...args: any): boolean {
    const fields: string[] = [];
    for (let i = 0; i < args.length; i++) {
      // eslint-disable-next-line prefer-rest-params
      fields.push(args[i]);
    }

    const errorKeys = Object.keys(this.errors);

    let hasError = false;
    fields.map((o) => {
      errorKeys.map((key) => {
        if (o === key) {
          hasError = true;
        } else {
          const starIndex = o.indexOf("*");
          if (starIndex > -1 && key.indexOf(o.substr(0, starIndex)) > -1) {
            hasError = true;
          }
        }
      });
    });
    return hasError;
  }

  any(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get(...args: any): string[] | any {
    const fields: string[] = [];
    for (let i = 0; i < args.length; i++) {
      // eslint-disable-next-line prefer-rest-params
      fields.push(args[i]);
    }

    if (fields.length === 0) {
      return this.errors;
    }

    let errors: string[] = [];
    fields.map((field: string) => {
      if (has(this.errors, field)) {
        errors = errors.concat(get(this.errors, [field], []));
      }
    });
    return errors.length > 0 ? errors : null;
  }

  all(): string[] {
    const errors: string[] = [];
    forEach(this.errors, (fieldErrors: string[]) => {
      fieldErrors.map((error) => {
        errors.push(error);
      });
    });
    return errors;
  }

  first(field: string): string {
    if (field === null) {
      return "";
    }
    return get(this.errors, [field, 0], "");
  }

  record(errors: unknown): void {
    this.errors = errors;
  }

  clear(field?: string): void {
    let errors = cloneDeep(this.errors);
    if (field) unset(errors, field);
    else errors = {};
    this.errors = errors;
  }
}
