
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { findIndex, get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  GeneralApiResponse,
  productGroupService,
  ProductGroupInterface,
  GetProductGroupResult,
  OrderFormInterface,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueButton from "@/components/form-elements/button/Main.vue";
import ProductGroupGeneralInformation from "@/views/product-groups/create/components/GeneralInformation.vue";
import ProductGroupSideNavigation from "@/views/product-groups/create/components/SideNavigation.vue";
import FileManagerSelectGallery from "@/components/file-manager/SelectGallery.vue";
import MaterialSalesInformation from "@/views/product-materials/create/components/SalesInformation.vue";
import { useStore } from "@/store";
import ProductGroupFilters from "@/views/product-groups/create/components/Filters.vue";
import ProductGroupMaterials from "@/views/product-groups/create/components/Materials.vue";
import ContentAssets from "@/components/assets/Main.vue";
import { helper } from "@/utils/helper";
import VideoList from "@/components/videos/Main.vue";

export default defineComponent({
  name: "ProductGroupCreateOrUpdate",
  components: {
    VideoList,
    ContentAssets,
    ProductGroupMaterials,
    ProductGroupFilters,
    MaterialSalesInformation,
    FileManagerSelectGallery,
    ProductGroupSideNavigation,
    ProductGroupGeneralInformation,
    VueButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const activeView = ref<string>("information");
    const componentRefreshKey = ref<string>(helper.uuidv4());

    const id = computed(() => get(route.params, "id"));
    const getSiteUrl = computed(() => {
      if (!query.value.url_slug) return "javascript:;";
      return query.value.url_slug;
    });

    const canEdit = computed(() => {
      return store.getters["auth/hasPermissionWithMerchant"]("products.edit");
    });

    const { data, loading, fetch } = useReadRecord<
      ProductGroupInterface,
      GetProductGroupResult
    >({
      id: id.value,
      serviceMethod: productGroupService.show,
      success(result) {
        if (result.kind === "ok") {
          // if (!result.data.workspaces || result.data.workspaces.length === 0) {
          //   result.data.workspaces = convertAssetsToWorkspace([
          //     {
          //       type: "text",
          //       text: result.data.text,
          //     },
          //   ]);
          // }
          setQuery(result.data);
          componentRefreshKey.value = helper.uuidv4();
        }
      },
      fail() {
        router.go(-1);
      },
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<
        ProductGroupInterface,
        GeneralApiResponse<GetProductGroupResult>
      >({
        query: {
          id: "",
          form_id: get(route.query, "form_id") || "",
          category_id: get(route.query, "category_id") || "",
          merchant_id: store.getters["auth/isMerchant"]
            ? store.getters["auth/merchantId"]
            : "",
          barcode: "",
          item_code: "",
          stock_code: "",
          can_return: "0",
          min_production_day: "3",
          max_production_day: "5",
          status: "1",
          check_status: "0",
          materials: {
            data: [],
          },
          filter_brand: "",
          filter_size: "",
          filter_color: "",
          filter_1: "",
          filter_2: "",
          filter_3: "",
          filter_4: "",
          filter_5: "",
          filter_6: "",
          filter_7: "",
          filter_8: "",
          filter_9: "",
          filter_10: "",
          is_feature: "0",
          title: "",
          description: "",
          text: "",
          min_width: "0",
          max_width: "0",
          min_height: "0",
          max_height: "0",
          vat_rate: "18",
          old_price: "0",
          price: "0",
          merchant_price: "0",
          merchant_old_price: "0",
          currency: "0",
          stock: "0",
          unit: "adet",
          url_slug: "",
          price_calculation_rules: {},
          photos: {
            data: [],
          },
          feedbacks: [],
          workspaces: [],
          videos: [],
        },
        storeServiceMethod: productGroupService.store,
        updateServiceMethod: productGroupService.update,
        success(result) {
          if (result?.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "Ürün Kaydedildi!",
            });
            if (!id.value) {
              router.go(-1);
            } else {
              setQuery(result.data);
              componentRefreshKey.value = helper.uuidv4();
            }
          }
        },
      });

    const selectedForm = computed<OrderFormInterface>(() => {
      return store.getters["order/form"](query.value.form_id);
    });

    const isRelationToMaterialProduct = computed(() => {
      return get(selectedForm.value, "relation_to_master", "0") * 1 === 1;
    });

    const selectedFormLine = computed(() => {
      const lines = get(selectedForm.value, "lines.data", []);
      const index = findIndex(lines, { master: 1 });
      return get(lines, index);
    });

    const hasMultipleSite = computed(() => {
      const sites = store.state.system.sites.filter((o) => {
        return (
          get(o, "driver") === "eniyiperde" ||
          get(o, "merchant_id") === get(query.value, "merchant_id")
        );
      });

      return sites.length > 1;
    });

    const changeView = (view: string) => {
      activeView.value = view;
    };

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) fetch(id.value);
    });

    return {
      loading,
      processing,
      query,
      data,
      activeView,
      validationErrors,
      isRelationToMaterialProduct,
      selectedFormLine,
      getSiteUrl,
      canEdit,
      componentRefreshKey,
      hasMultipleSite,
      changeView,
      onSubmit,
    };
  },
});
