
import { defineComponent, onMounted, reactive, ref, toRaw } from "vue";
import { ProductGroupInterface, selectionService } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import { findIndex, get, set } from "lodash";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";

export default defineComponent({
  name: "ProductGroupFilters",
  components: { VueSelect2, VueInputContainer },
  props: {
    query: {
      type: Object as () => ProductGroupInterface,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const editableQuery = reactive<ProductGroupInterface>(toRaw(props.query));
    const filters = ref<Record<string, any>>({});
    const loading = ref<boolean>(false);

    const keys = [
      "filter_brand",
      "filter_color",
      "filter_size",
      "filter_1",
      "filter_2",
      "filter_3",
      "filter_4",
      "filter_5",
      "filter_6",
      "filter_7",
      "filter_8",
      "filter_9",
      "filter_10",
    ];

    const fetch = () => {
      const config = {
        "@first": true,
        "@select": ["id", "filter_family_id"],
        "@where": {
          id: editableQuery.category_id,
        },
        family: {
          "@select": ["*"],
        },
      };

      keys.map((key) => {
        set(config, `family.${key}`, {
          "@select": ["*"],
          options: {
            "@select": ["*"],
          },
        });
      });

      loading.value = true;
      selectionService
        .fetch({
          category: config,
        })
        .then((result) => {
          if (result.kind === "ok") {
            filters.value = {};
            const family = get(result.data, "category.family");
            keys.map((key) => {
              const filter = get(family, key);
              if (filter) {
                filters.value[key] = filter;
              }
              const options = get(filter, `options`, []);
              const filterValuesString: string =
                get(editableQuery, key, "") || "";
              let filterValues: string[] = filterValuesString
                .toString()
                .split(",");
              filterValues = filterValues.filter((o) => {
                return findIndex(options, { slug: o }) > -1;
              });

              set(editableQuery, key, filterValues.join(","));
            });
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    onMounted(() => {
      fetch();
    });

    return {
      loading,
      editableQuery,
      filters,
    };
  },
});
