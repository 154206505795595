
import { defineComponent, ref } from "vue";
import { ColorPicker as VueColorPicker } from "vue-color-kit";
import { get } from "lodash";

type Props = {
  color: string;
  top?: number;
  left?: number;
  onChange(color: any): void;
};

export type ColorPickerRef = {
  show(props: Props): void;
};

export default defineComponent({
  name: "ColorPicker",
  components: { VueColorPicker },
  setup() {
    const visible = ref<boolean>(false);
    const color = ref<string>("");
    const top = ref<number>(0);
    const left = ref<number>(0);
    const callback = ref<(val: any) => void>((val) => {
      console.log(val);
    });

    const colorsDefault = [
      "#ED9005",
      "#0E1822",
      "#5e5e5e",
      "#FFB243",
      "#2DD4BF",
      "#F47365",
      "#FF1900",
      "#6EFF2A",
      "#00BEFF",
      "#2E81FF",
      "#5D61FF",
      "#FC3CAD",
      "#BF3DCE",
    ];

    const show = (props: Props) => {
      color.value = props.color;
      top.value = props.top || 0;
      left.value = props.left || 0;
      callback.value = props.onChange;
      visible.value = true;
    };

    const close = () => {
      visible.value = false;
    };

    const onChange = (color: any) => {
      const red: number = get(color, "rgba.r");
      const green: number = get(color, "rgba.g");
      const blue: number = get(color, "rgba.b");
      const alpha: number = get(color, "rgba.a", 1);
      if (alpha < 1) callback.value(`rgba(${red},${green},${blue},${alpha})`);
      else callback.value(get(color, "hex"));
    };

    return {
      visible,
      color,
      top,
      left,
      colorsDefault,
      show,
      close,
      onChange,
    };
  },
});
