import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { DefinitionsInterface, State as LocalState } from "./state";
import { get, findIndex, cloneDeep } from "lodash";

const LOGO = {
  eniyiperde: require("@/assets/images/eniyiperde.png"),
  perdemarkt: require("@/assets/images/perdemarkt.png"),
  brillant: require("@/assets/images/brillant.png"),
};

// Getters types
export type Getters = {
  site: (
    state: LocalState,
    getters: Getters,
    rootState: RootState,
    rootGetters: any
  ) => any;
  logo(state: LocalState, getters: Getters): () => any;
  getSite(state: LocalState): (val: any, field: any) => any;
  definitions(state: LocalState): (key: keyof DefinitionsInterface) => any;
  definition(
    state: LocalState
  ): (key: keyof DefinitionsInterface, value: any, field?: any) => any;
  printTemplate(state: LocalState): (source?: any, id?: any) => any;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  site: (state, getters, rootState, rootGetters): number => {
    if (rootGetters["auth/isMerchant"] && rootGetters["auth/isSiteAdmin"])
      return rootGetters["auth/getSiteKey"];
    return ((window.localStorage.getItem("siteKey") as any) || 1) * 1;
  },
  logo: (state: LocalState, getters: Getters) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return get(LOGO, getters["getSite"](getters["site"], "driver")) || "";
  },
  getSite:
    (state) =>
    (key, field = null) => {
      const index = findIndex(state.sites, { key });
      if (field) return get(state.sites, `${index}.${field}`);
      return get(state.sites, `${index}`);
    },
  definitions: (state) => (key) => {
    return get(state.definitions, key, []);
  },
  definition:
    (state) =>
    (key, value, field: any = "text") => {
      const definitions = get(state.definitions, key, []);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const index = findIndex(definitions, { value });
      if (field) return get(definitions, `${index}.${field}`);
      return get(definitions, `${index}`);
    },
  printTemplate:
    (state) =>
    (source = null, id = null) => {
      let templates = cloneDeep(state.print_templates);

      if (source) {
        templates = templates.filter((o) => o.type === source);
      }

      if (id) {
        templates = templates.filter((o) => o.id === id);

        return get(templates, 0);
      }

      return templates;
    },
};
