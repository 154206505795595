
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { helper } from "@/utils/helper";
import Tippy from "@/components/tippy/Main.vue";

export default defineComponent({
  name: "PdfViewer",
  components: { Tippy },
  setup() {
    const modalId = helper.uuidv4();
    const iframeRef = ref<HTMLIFrameElement>();

    const open = (url: string) => {
      changeIframeUrl(url);
      cash(`#${modalId}`).modal("show");
    };

    const close = () => {
      changeIframeUrl("");
      cash(`#${modalId}`).modal("hide");
    };

    const changeIframeUrl = (url: string) => {
      if (iframeRef.value) {
        iframeRef.value.src = url ? `${url}#toolbar=1&statusbar=#000` : "";
        if (url) {
          iframeRef.value?.focus();
        }
      }
    };

    const printContentFrameOnly = function (event: any) {
      if ((event.ctrlKey || event.metaKey) && event.key === "p") {
        event.preventDefault();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.frames.documentIframe.print();
      }
    };

    onBeforeUnmount(() => {
      window.removeEventListener("keydown", printContentFrameOnly, true);
      close();
    });

    onMounted(() => {
      window.addEventListener("keydown", printContentFrameOnly, true);
    });

    return {
      modalId,
      iframeRef,
      open,
      close,
    };
  },
});
