
import { computed, defineComponent, onMounted, ref } from "vue";
import FilterContainer from "@/components/filter-container/Main";
import VueInputContainer from "@/components/form-elements/input-container/Main";
import VueDatetimePicker from "@/components/form-elements/datetime-picker/Main";
import dayjs from "dayjs";
import { get } from "lodash";
import { useFilterRecords } from "@/utils/records/filter";
import { useFetchRecords } from "@/utils/records/fetch";
import Pagination from "@/components/pagination/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import VueInput from "@/components/form-elements/input/Main.vue";
import FilterModalContainer from "@/components/filter-container/FilterModal.vue";
import { helper } from "@/utils/helper";
import { useRoute, useRouter } from "vue-router";
import VueSelect from "@/components/form-elements/select/Main.vue";

export default defineComponent({
  name: "CommissionInvoices",
  components: {
    VueSelect,
    FilterModalContainer,
    VueInput,
    VueSelect2,
    Pagination,
    VueDatetimePicker,
    VueInputContainer,
    FilterContainer,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const presetRanges = ref([
      { label: "Bugün", range: [dayjs(), dayjs()] },
      { label: "Son 7 Gün", range: [dayjs().subtract(6, "day"), dayjs()] },
      { label: "Son 14 Gün", range: [dayjs().subtract(13, "day"), dayjs()] },
      {
        label: "Bu Ay",
        range: [dayjs().startOf("month"), dayjs()],
      },
      {
        label: "Geçen Ay",
        range: [
          dayjs().subtract(1, "month").startOf("month"),
          dayjs().subtract(1, "month").endOf("month"),
        ],
      },
      {
        label: "Son 3 Ay",
        range: [dayjs().subtract(2, "month").startOf("month"), dayjs()],
      },
      {
        label: "Bu Yıl",
        range: [dayjs().startOf("year"), dayjs()],
      },
    ]);

    const dateRange = computed({
      get() {
        return `${filterState.fields.from} - ${filterState.fields.to}`;
      },
      set(val: string) {
        const range = val.toString().split(" - ");
        filterState.fields.from = get(range, 0) || "";
        filterState.fields.to = get(range, 1) || "";
      },
    });

    const headerTitle = computed(() => {
      return get(route.meta, "title");
    });

    const transactionVerifyKey = computed(() => {
      switch (route.name) {
        case "fiches.receipts":
          return "RECEIPT_VERIFY";
        case "fiches.moneys":
          return "MONEY_VERIFY";
        default:
          return "";
      }
    });

    const transactionRefundKey = computed(() => {
      switch (route.name) {
        case "fiches.receipts":
          return "RECEIPT_REFUND";
        case "fiches.moneys":
          return "MONEY_REFUND";
        default:
          return "";
      }
    });

    const transactionClosingFicheKey = computed(() => {
      switch (route.name) {
        case "fiches.receipts":
          return "RECEIPT";
        case "fiches.moneys":
          return "MONEY";
        default:
          return "";
      }
    });

    const isOnlyClosingFiches = computed(() => {
      return filterState.fields.list === "only-closing-fiche";
    });

    const transactionTypes = computed(() => {
      if (isOnlyClosingFiches.value) return [transactionClosingFicheKey.value];
      return [
        transactionVerifyKey.value,
        transactionRefundKey.value,
        transactionClosingFicheKey.value,
      ];
    });

    const totalAmount = computed(() => {
      const amount =
        get(
          list.data,
          `0.open_fiche_amounts.${transactionVerifyKey.value}.amount`,
          "0"
        ) *
          1 +
        get(
          list.data,
          `0.open_fiche_amounts.${transactionRefundKey.value}.amount`,
          "0"
        ) *
          1;
      return Math.abs(helper.round(amount));
    });

    const totalNumber = computed(() => {
      return (
        get(
          list.data,
          `0.open_fiche_amounts.${transactionVerifyKey.value}.number`,
          "0"
        ) *
          1 +
        get(
          list.data,
          `0.open_fiche_amounts.${transactionRefundKey.value}.number`,
          "0"
        ) *
          1
      );
    });

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const { loading, list, fetch } = useFetchRecords({
      selectionQuery: () => {
        return {
          source: "transaction",
          config: {
            "@page": 1,
            "@paginate": 30,
            "@order": "time:desc",
            "@func": ["withCount"],
            "@where": {
              transaction_type: transactionTypes.value,
            },
            "@select": [
              "id",
              "order_group_id",
              "fiche_type",
              "fiche_id",
              "transaction_type",
              "merchant_id",
              "amount",
              "description",
              "time",
              "status",
              "relation_id",
              "approved_at",
            ],
            merchant: {
              "@select": ["id", "title", "commercial_title"],
            },
            order: {
              "@select": [
                "order_group_id",
                "merchant_id",
                "customer_id",
                "invoice_address",
              ],
            },
          },
          filterFormat: {
            id: "@where.id",
            relation_id: "@where.relation_id",
            order_group_id: "@where.order_group_id",
            merchant_id: "@where.merchant_id",
            from: "@whereDateRange.time.from",
            to: "@whereDateRange.time.to",
          },
        };
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        from: "",
        to: "",
        merchant_id: "",
        order_group_id: "",
        id: "",
        relation_id: "",
        list: "",
      },
    });

    const isVerify = (type: string) => {
      return (
        [
          "INVOICE_0_VERIFY",
          "INVOICE_18_VERIFY",
          "MONEY_VERIFY",
          "RECEIPT_VERIFY",
        ].indexOf(type) > -1
      );
    };

    const isRefund = (type: string) => {
      return (
        [
          "INVOICE_0_REFUND",
          "INVOICE_18_REFUND",
          "MONEY_REFUND",
          "RECEIPT_REFUND",
        ].indexOf(type) > -1
      );
    };

    const isVerification = (type: string) => {
      return ["INVOICE_0", "INVOICE_18", "MONEY", "RECEIPT"].indexOf(type) > -1;
    };

    const goToFiche = (row: any) => {
      if (row.fiche_type === "fiche") {
        return router.push({
          name: "fiches.edit",
          params: { id: row.fiche_id },
        });
      }
    };

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
    });

    return {
      headerTitle,
      loading,
      list,
      dateRange,
      filterState,
      filterCount,
      presetRanges,
      totalAmount,
      totalNumber,
      merchantSelect2Options,
      filterFunctions,
      isOnlyClosingFiches,
      goToFiche,
      isVerify,
      isRefund,
      isVerification,
    };
  },
});
