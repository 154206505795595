
import { defineComponent, onMounted, ref } from "vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import Pagination from "@/components/pagination/Main.vue";
import { useRouter } from "vue-router";
import { findIndex } from "lodash";
import { useFetchRecords } from "@/utils/records/fetch";
import { useFilterRecords } from "@/utils/records/filter";
import { useDeleteRecord } from "@/utils/records/delete";
import { productTabService } from "@/services";
import VueSelect from "@/components/form-elements/select/Main.vue";
import FilterButton from "@/components/filter-container/Button.vue";
import { useStore } from "@/store";
import VueModal from "@/components/modal/Main.vue";
import { helper } from "@/utils/helper";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import ContentAssets from "@/components/assets/Main.vue";

export default defineComponent({
  name: "ProductTabs",
  components: {
    ContentAssets,
    VueSelect2,
    VueModal,
    FilterButton,
    VueSelect,
    Pagination,
    Tippy,
    TransactionStatement,
    VueInput,
    VueInputContainer,
    FilterContainer,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const modalRefId = helper.uuidv4();
    const detail = ref<any>(null);

    const { loading, list, fetch } = useFetchRecords({
      selectionQuery: {
        source: "product_tab",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "title",
            "category_id",
            "can_customizable",
            "type",
            "status",
            "workspaces",
          ],
          "@order": ["id:desc"],
          "@func": ["withCount", "withMerchantCounts"],
        },
        filterFormat: {
          q: "@where.q",
          title: "@where._title",
          status: "@where.status",
          can_customizable: "@where.can_customizable",
          category_id: "@customWhere.category_id",
          type: "@where.type",
        },
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        title: "",
        status: "",
        can_customizable: "",
        category_id: "",
        type: "",
      },
    });

    const { deleteState, triggerDelete, removeDeleteError } = useDeleteRecord({
      serviceMethod: productTabService.delete,
      success(id: any) {
        const index = findIndex(list.data, { id });
        if (index > -1) list.data.splice(index, 1);
      },
    });

    const goToEdit = (id: any) => {
      router.push({ name: "product-tabs.edit", params: { id } });
    };

    const goToMerchantList = (id: any) => {
      router.push({
        name: "product-tabs.merchants",
        query: { product_tab_id: id },
      });
    };

    const getFormTitle = (formId: any) => {
      if (!formId) return "Tüm Sipariş Formları";

      const titles: string[] = [];
      formId
        .toString()
        .split(",")
        .map((o: any) => {
          const title: string = store.getters["order/form"](o, "title");
          if (title) titles.push(title);
        });

      return titles.join(", ");
    };

    const getCategoryTitle = (categoryId: any) => {
      if (!categoryId) return "Tüm Kategoriler";

      const titles: string[] = [];
      categoryId
        .toString()
        .split(",")
        .map((o: any) => {
          const title: string = store.getters["category/category"](o, "title");
          if (title) titles.push(title);
        });

      return titles.join(", ");
    };

    const openDetail = (row: any) => {
      if (store.getters["auth/hasPermission"]("contents.edit")) {
        goToEdit(row.id);
        return;
      }
      detail.value = row;
      cash(`#${modalRefId}`).modal("show");
    };

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
    });

    return {
      loading,
      list,
      filterState,
      filterFunctions,
      deleteState,
      filterCount,
      modalRefId,
      detail,
      triggerDelete,
      removeDeleteError,
      goToEdit,
      goToMerchantList,
      getFormTitle,
      getCategoryTitle,
      openDetail,
    };
  },
});
