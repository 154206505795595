
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import dayjs from "dayjs";
import { cloneDeep, get, set } from "lodash";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueDatetimePicker from "@/components/form-elements/datetime-picker/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import MerchantOrderReport from "@/views/merchant-reports/components/MerchantOrderReport.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import base64Encode, { helper } from "@/utils/helper";
import MerchantProductReport from "@/views/merchant-reports/components/MerchantProductReport.vue";

export default defineComponent({
  name: "MerchantReport",
  components: {
    MerchantProductReport,
    MerchantOrderReport,
    VueSelect2,
    VueDatetimePicker,
    VueInputContainer,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const fromDate = dayjs().startOf("month").format("YYYY-MM-DD");
    const toDate = dayjs().format("YYYY-MM-DD");
    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": {
            value: "id",
            text: "title",
            commercial_title: "commercial_title",
            point: "point",
            reviews_count: "reviews_count",
          },
        },
      },
    };
    const presetRanges = [
      { label: "Bugün", range: [dayjs(), dayjs()] },
      { label: "Son 7 Gün", range: [dayjs().subtract(6, "day"), dayjs()] },
      { label: "Son 14 Gün", range: [dayjs().subtract(13, "day"), dayjs()] },
      {
        label: "Bu Ay",
        range: [dayjs().startOf("month"), dayjs()],
      },
      {
        label: "Geçen Ay",
        range: [
          dayjs().subtract(1, "month").startOf("month"),
          dayjs().subtract(1, "month").endOf("month"),
        ],
      },
      {
        label: "Önceki Ay",
        range: [
          dayjs().subtract(2, "month").startOf("month"),
          dayjs().subtract(2, "month").endOf("month"),
        ],
      },
      {
        label: "Son 3 Ay",
        range: [dayjs().subtract(2, "month").startOf("month"), dayjs()],
      },
      {
        label: "Son 6 Ay",
        range: [dayjs().subtract(5, "month").startOf("month"), dayjs()],
      },
      {
        label: "Bu Yıl",
        range: [dayjs().startOf("year"), dayjs()],
      },
    ];

    const activeView = ref<string>("order");
    const selectedMerchant = ref<any>(null);
    const filterState = reactive({
      from: get(route, "query.from") || cloneDeep(fromDate),
      to: get(route, "query.to") || cloneDeep(toDate),
      merchant_id: get(route, "query.merchant_id") || "",
    });

    const dateRange = computed(() => {
      return `${filterState.from} - ${filterState.to}`;
    });

    const componentRenderKey = computed(() => {
      return helper.base64Encode(JSON.stringify(cloneDeep(filterState)));
    });

    const changeView = (view: string) => {
      activeView.value = view;
    };

    const prepareRouteQuery = () => {
      const query = {
        from: filterState.from,
        to: filterState.to,
      };
      if (filterState.merchant_id) {
        set(query, "merchant_id", filterState.merchant_id);
      }
      return query;
    };

    const changeMerchant = (value: any) => {
      selectedMerchant.value = value;
      filterState.merchant_id = (get(value, "id") || "").toString();
      router.replace({ query: prepareRouteQuery() });
    };

    const changeDateRange = (value: string) => {
      const range = value.toString().split(" - ");
      filterState.from = get(range, 0) || fromDate;
      filterState.to = get(range, 1) || toDate;
      router.replace({ query: prepareRouteQuery() });
    };

    onMounted(() => {
      if (store.getters["auth/isMerchant"]) {
        filterState.merchant_id = store.getters["auth/merchantId"];
      }
    });

    return {
      activeView,
      dateRange,
      presetRanges,
      merchantSelect2Options,
      selectedMerchant,
      filterState,
      componentRenderKey,
      changeView,
      changeMerchant,
      changeDateRange,
    };
  },
});
