import { createStore } from "vuex";

import * as AuthModule from "./auth";
import * as SideMenuModule from "./side-menu";
import * as SystemModule from "./system";
import * as OrderModule from "./order";
import * as CategoryModule from "./category";

export type State = {
  auth: AuthModule.State;
  sideMenu: SideMenuModule.State;
  system: SystemModule.State;
  order: OrderModule.State;
  category: CategoryModule.State;
};

export type Store = AuthModule.Store<Pick<State, "auth">> &
  SideMenuModule.Store<Pick<State, "sideMenu">> &
  SystemModule.Store<Pick<State, "system">> &
  OrderModule.Store<Pick<State, "order">> &
  CategoryModule.Store<Pick<State, "category">>;

export const store = createStore({
  modules: {
    auth: AuthModule.auth,
    sideMenu: SideMenuModule.sideMenu,
    system: SystemModule.system,
    order: OrderModule.order,
    category: CategoryModule.category,
  },
});

export function useStore(): Store {
  return store as Store;
}

export default store;
