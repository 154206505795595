const loadProfile = () => {
  const data = window.localStorage.getItem("profile") || "";
  try {
    return JSON.parse(new Buffer(data, "base64").toString("utf-8"));
  } catch (e) {
    return data;
  }
};

export const state = {
  token: window.localStorage.getItem("token") || "",
  profile: loadProfile(),
  visibleSplashScreen: true,
};

// Declare state
export type State = typeof state;
