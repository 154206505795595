
import { defineComponent, onMounted } from "vue";
import { GetUser } from "@/services";
import { useReadRecord } from "@/utils/records/read";
import UserMerchantBankInformation from "@/views/user/create/components/MerchantBankInformation.vue";

export default defineComponent({
  name: "UserMerchantInformation",
  components: {
    UserMerchantBankInformation,
  },
  props: {
    userDetail: {
      type: Object as () => GetUser,
      default: () => ({}),
    },
  },
  setup(props) {
    const {
      loading,
      data: merchantDetail,
      fetch,
    } = useReadRecord<
      {
        id: any;
        code: any;
        title: any;
        commercial_title: any;
        name_surname: any;
        email: any;
      },
      any
    >({
      selectionQuery: {
        source: "merchant",
        config: {
          "@select": [
            "id",
            "code",
            "title",
            "commercial_title",
            "name_surname",
            "email",
            "gsm",
            "phone",
            "tax_office",
            "tax_number",
            "mersis_no",
            "address",
            "district",
            "city",
            "description",
            "commission_rate",
            "status",
            "reviews_count",
            "point",
            "iban",
          ],
        },
      },
    });

    onMounted(() => {
      fetch(props.userDetail.merchant_id);
    });

    return {
      loading,
      merchantDetail,
    };
  },
});
