import { RouteRecordRaw, RouterView } from "vue-router";
import FilterFamilies from "@/views/filter-families/list/Main.vue";
import FilterFamilyCreate from "@/views/filter-families/create/Main.vue";
import Filters from "@/views/filters/list/Main.vue";
import FilterCreate from "@/views/filters/create/Main.vue";

const route: Array<RouteRecordRaw> = [
  {
    path: "filters",
    component: RouterView,
    children: [
      {
        path: "",
        name: "filters",
        component: Filters,
        meta: {
          title: "Filtre Listesi",
          roles: [],
          permissions: ["filters.list"],
        },
      },
      {
        path: "create",
        name: "filters.create",
        component: FilterCreate,
        meta: {
          title: "Filtre Ekle",
          roles: [],
          permissions: ["filters.create"],
        },
      },
      {
        path: "edit/:id",
        name: "filters.edit",
        component: FilterCreate,
        meta: {
          title: "Filtre Düzenle",
          roles: [],
          permissions: ["filters.edit"],
        },
      },
    ],
  },
  {
    path: "filter-families",
    component: RouterView,
    children: [
      {
        path: "",
        name: "filter.families",
        component: FilterFamilies,
        meta: {
          title: "Filtre Grubu Listesi",
          roles: [],
          permissions: ["filters.list"],
        },
      },
      {
        path: "create",
        name: "filter.families.create",
        component: FilterFamilyCreate,
        meta: {
          title: "Filtre Grubu Ekle",
          roles: [],
          permissions: ["filters.create"],
        },
      },
      {
        path: "edit/:id",
        name: "filter.families.edit",
        component: FilterFamilyCreate,
        meta: {
          title: "Filtre Grubu Düzenle",
          roles: [],
          permissions: ["filters.edit"],
        },
      },
    ],
  },
];

export default route;
