import { RouteRecordRaw, RouterView } from "vue-router";
import Baskets from "@/views/baskets/list/Main.vue";

const route: Array<RouteRecordRaw> = [
  {
    path: "baskets",
    component: RouterView,
    children: [
      {
        path: "",
        name: "baskets",
        component: Baskets,
        meta: {
          title: "Sepette Bekleyenler",
          roles: [],
          permissions: ["orders.list"],
        },
      },
    ],
  },
];

export default route;
