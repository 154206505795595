import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = {
  key: 0,
  class: "col-span-full w-full mt-2 pl-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "label-container", {}, () => [
      (_ctx.$slots.label)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["mb-1.5 inline-block", {
          'text-red-400': _ctx.validationErrors && _ctx.validationErrors.length > 0,
          [_ctx.labelClass]: true,
        }])
          }, [
            _renderSlot(_ctx.$slots, "label")
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.validationErrors && _ctx.validationErrors.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validationErrors, (error, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "text-red-500 mt-1 text-sm"
            }, _toDisplayString(_ctx.$_.get(error, "$message", error)), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}