
import { defineComponent, reactive, ref, toRaw } from "vue";
import { FormLine, GeneralApiResponse, OrderFormInterface } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import { cloneDeep, get } from "lodash";
import OrderFormLineItem from "@/views/order-forms/create/components/FormLineItem.vue";
import { helper } from "@/utils/helper";
import OrderFormLineEditModal from "@/views/order-forms/create/components/FormLineEditModal.vue";
import Tippy from "@/components/tippy/Main.vue";
import Draggable from "vuedraggable";

export default defineComponent({
  name: "OrderFormLines",
  components: { Tippy, OrderFormLineEditModal, OrderFormLineItem, Draggable },
  props: {
    query: {
      type: Object as () => OrderFormInterface,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const formLineEditModal = ref();
    const editableQuery = reactive<OrderFormInterface>(toRaw(props.query));

    const openEditFormModal = (index = -1) => {
      formLineEditModal.value.open(index);
    };

    const add = (
      index = -1,
      data: FormLine,
      clone = false
    ): GeneralApiResponse<any> => {
      const originalData = get(editableQuery.lines, `data.${index}`, {});
      const q: FormLine = {
        id: null,
        ...originalData,
        ...cloneDeep(data),
        uuid: helper.uuidv4(),
      };

      if (index === -1) editableQuery.lines.data.push(q);
      else if (clone) editableQuery.lines.data.splice(index + 1, 0, q);
      else editableQuery.lines.data[index] = q;

      return { kind: "ok" };
    };

    const destroy = (index = -1) => {
      editableQuery.lines.data.splice(index, 1);
    };

    return {
      editableQuery,
      formLineEditModal,
      openEditFormModal,
      add,
      destroy,
    };
  },
});
