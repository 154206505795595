
import { computed, defineComponent } from "vue";
import { findIndex, get } from "lodash";

export default defineComponent({
  name: "TransactionStatement",
  props: {
    tag: { required: true },
    processing: { type: Boolean, default: () => false },
    rowId: { required: true },
    transactionIds: { type: Array, default: () => [] },
    pendingTransactionIds: { type: Array, default: () => [] },
    errors: { type: Array, default: () => [] },
    removeError: { type: Function, default: () => null },
  },
  setup(props) {
    const waiting = computed(() => {
      return (
        props.processing &&
        props.pendingTransactionIds?.indexOf(props.rowId) > -1
      );
    });

    const processingRecord = computed(() => {
      return (
        props.processing && props.transactionIds?.indexOf(props.rowId) > -1
      );
    });

    const error = computed(() => {
      const index = findIndex(props.errors, { id: props.rowId });
      return get(props.errors, `${index}.error`, "");
    });

    const showing = computed(() => {
      return waiting.value || processingRecord.value || !!error.value;
    });

    return {
      waiting,
      processingRecord,
      showing,
      error,
    };
  },
});
