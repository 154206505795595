
import { defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { PriceCalculationRule } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import VueSelect from "@/components/form-elements/select/Main.vue";

export default defineComponent({
  name: "PriceCalculationRuleGeneralInformation",
  components: {
    VueSelect,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => PriceCalculationRule,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableQuery = reactive<PriceCalculationRule>(toRaw(props.query));

    return {
      editableQuery,
    };
  },
});
