import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import utils from "./utils";
// import globalComponents from "./components";

import "./libs";
import "./assets/sass/app.scss";

const app = createApp(App).use(store).use(router);

utils(app);
// globalComponents(app);

app.config.unwrapInjectedRef = true;

app.mount("#app");
