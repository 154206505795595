
import { defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { Content } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import VueTextarea from "@/components/form-elements/textarea/Main.vue";
import GoogleSeoPreview from "@/components/google-seo-preview/Main.vue";
import Tippy from "@/components/tippy/Main.vue";

export default defineComponent({
  name: "ContentSeoInformation",
  components: {
    Tippy,
    GoogleSeoPreview,
    VueTextarea,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => Content,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableQuery = reactive<Content>(toRaw(props.query));

    return { editableQuery };
  },
});
