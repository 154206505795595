
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import { cloneDeep, findIndex, get } from "lodash";
import Tippy from "@/components/tippy/Main.vue";
import { FormLine, formService, OrderFormInterface } from "@/services";
import { useRouter } from "vue-router";

interface CustomOrderFormInterface extends OrderFormInterface {
  required_material_product?: boolean;
}

export default defineComponent({
  name: "ProductGroupForms",
  components: { Tippy },
  setup() {
    const router = useRouter();
    const store = useStore();

    const formSchemas = ref<Array<CustomOrderFormInterface>>([]);
    const forms = ref<Array<CustomOrderFormInterface>>([]);

    const recursiveFormLines = (formId: any, required = true): FormLine[] => {
      const index = findIndex(formSchemas.value, { id: formId });
      const lines: FormLine[] = get(
        formSchemas.value,
        `${index}.lines.data`,
        []
      );
      let arr: FormLine[] = [];
      lines.map((line) => {
        if (line.type === "product") {
          arr.push({
            ...line,
            required:
              required && line.required * 1 === 1 && line.products_count === 0,
          });
        } else if (line.type === "forms") {
          line.options.map((option, optionIndex) => {
            arr = [
              ...arr,
              ...recursiveFormLines(option.value * 1, optionIndex === 0),
            ];
          });
        }
      });
      return arr;
    };

    const prepareForms = () => {
      formSchemas.value = store.getters["order/forms"];
      forms.value = cloneDeep(formSchemas.value).filter(
        (form) => form.is_fixed * 1 === 0
      );

      forms.value.map((form) => {
        form.lines.data = recursiveFormLines(form.id).filter((line) => {
          return (
            line.type === "product" &&
            (form.relation_to_master * 1 === 0 ||
              line.master * 1 === 0 ||
              line.form_id !== form.id)
          );
        });

        form.lines.data.map((line) => {
          if (line.type === "product" && line.required)
            form.required_material_product = true;
        });
      });
    };

    const goToProductGroups = (form: any) => {
      if (form.required_material_product) return;

      router.push({
        name: "product-groups.list",
        query: {
          form_id: form.id,
        },
      });
    };

    const goToProducts = (line: FormLine) => {
      if (line.type !== "product") return;

      router.push({
        name: "material-products",
        query: {
          category_id: line.material_category_id,
        },
      });
    };

    const fetchAllForms = () => {
      formService.fetch().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("order/SET_FORMS", result.data);
          prepareForms();
        }
      });
    };

    onMounted(() => {
      prepareForms();
      fetchAllForms();
    });

    return {
      forms,
      goToProductGroups,
      goToProducts,
    };
  },
});
