import { ref } from "vue";
import { ValidationErrors } from "@/utils/records/validation-errors";
import { GeneralApiProblem, GeneralApiResponse } from "@/services";
import { get, has, set, unset, cloneDeep, forEach } from "lodash";
import { ToastNotify } from "@/utils/toast-notify";

type Props<T, R> = {
  query: T;
  removeKeysIfEmptyValue?: string[];
  storeServiceMethod?(params: any): Promise<GeneralApiResponse<R>>;
  updateServiceMethod?(id: any, params: any): Promise<GeneralApiResponse<R>>;
  success?(result: GeneralApiResponse<R>): void;
  fail?(error: GeneralApiProblem): void;
  finish?(result: GeneralApiResponse<R>): void;
  formatRequestBeforeSubmit?(request: T): T;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSubmitRecord<T extends Record<string, any>, R>(
  props: Props<T, R>
) {
  const originalQuery = cloneDeep(props.query);
  const processing = ref<boolean>(false);
  const query = ref<T>(cloneDeep(props.query));
  const validationErrors = ref<ValidationErrors>(new ValidationErrors());

  const reset = () => setQuery(originalQuery);
  const setQuery = (params: Record<string, any>) => {
    forEach(query.value, (val: any, key: string) => {
      if (has(params, key)) {
        set(query.value, key, get(params, key));
      }
    });
  };
  const submit = async (recordId?: any): Promise<void> => {
    if (processing.value) return;
    processing.value = true;
    validationErrors.value.clear();
    let request = cloneDeep(query.value);
    if (
      props.removeKeysIfEmptyValue &&
      props.removeKeysIfEmptyValue.length > 0
    ) {
      props.removeKeysIfEmptyValue.map((k) => {
        if (!get(request, k)) unset(request, k);
      });
    }

    if (props.formatRequestBeforeSubmit) {
      request = await props.formatRequestBeforeSubmit(request);
    }

    let result: GeneralApiResponse<R> | null = null;
    if (recordId && props.updateServiceMethod)
      result = await props.updateServiceMethod(recordId, request);
    if (!recordId && props.storeServiceMethod)
      result = await props.storeServiceMethod(request);

    processing.value = false;
    if (result) {
      if (result.kind === "ok" && props.success) props.success(result);
      if (result.kind !== "ok") {
        ToastNotify({ text: result.message, className: "error" });
        if (result.kind === "validation") {
          validationErrors.value.record(result.fields);
        }
        if (props.fail) props.fail(result);
      }
      if (props.finish) props.finish(result);
    }
  };

  return {
    processing,
    query,
    validationErrors,
    reset,
    setQuery,
    submit,
  };
}
