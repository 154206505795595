
import { defineComponent } from "vue";
import { ValidationErrors } from "@/utils/records/validation-errors";
import { MaterialProductInterface } from "@/services";

export default defineComponent({
  name: "MaterialSideNavigation",
  props: {
    query: {
      type: Object as () => MaterialProductInterface,
      required: true,
    },
    changeView: {
      type: Function,
      default: () => null,
    },
    activeView: {
      type: String,
      default: () => null,
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
});
