
import { computed, defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { ProductGroupInterface } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import { Select2Options } from "@/components/form-elements/select2";
import VueSelect from "@/components/form-elements/select/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { get } from "lodash";
import ProductGroupDescription from "./TiptapDescription.vue";

export default defineComponent({
  name: "ProductGroupGeneralInformation",
  components: {
    ProductGroupDescription,
    VueSelect2,
    Tippy,
    VueSelect,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => ProductGroupInterface,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const editableQuery = reactive<ProductGroupInterface>(toRaw(props.query));

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const categorySelect2Options = computed(() => ({
      ajaxQuery: {
        category: {
          "@select": { value: "id", text: "title" },
          "@where": {
            form_id: editableQuery.form_id,
          },
          "@func": ["withOnlyLeaf"],
        },
      },
      dropdownAutoWidth: true,
      templateResult(obj: any): any {
        return `
        <div>${get(obj, "text", "")}</div>
        <div class="text-sm font-medium text-indigo-700 max-w-sm">${get(
          obj,
          "ancestor_title",
          ""
        )}</div>
        `;
      },
    }));

    const sameDayDelivery = computed(() => {
      return (
        editableQuery.min_production_day * 1 === 0 &&
        editableQuery.max_production_day * 1 === 0
      );
    });

    const nextDayDelivery = computed(() => {
      return (
        editableQuery.min_production_day * 1 === 1 &&
        editableQuery.max_production_day * 1 === 1
      );
    });

    const changeProductionDay = (day: number) => {
      if (props.canEdit) {
        editableQuery.min_production_day = day;
        editableQuery.max_production_day = day;
        if (day > 1) editableQuery.max_production_day++;
      }
    };

    const onChangeForm = (formId: any) => {
      if (!props.query?.id) {
        editableQuery.category_id = null;
        editableQuery.form_id = formId;
      }
    };

    const onChangeBarcode = (barcode: string) => {
      editableQuery.barcode = barcode;
    };

    return {
      editableQuery,
      merchantSelect2Options,
      categorySelect2Options,
      sameDayDelivery,
      nextDayDelivery,
      onChangeBarcode,
      changeProductionDay,
      onChangeForm,
    };
  },
});
