import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState } from "./state";
import { PrintTemplateInterface, SettingsInterface } from "@/services";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_SETTINGS](state: S, settings: SettingsInterface): void;
  [MutationTypes.SET_SITES](state: S, sites: any[]): void;
  [MutationTypes.SET_PROCESSING](state: S, uuid: string): void;
  [MutationTypes.REMOVE_PROCESSING](state: S, uuid: string): void;
  [MutationTypes.SET_PRINT_TEMPLATES](
    state: S,
    templates: PrintTemplateInterface[]
  ): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_SETTINGS](state: LocalState, settings: SettingsInterface) {
    state.sites = settings.sites;
    state.definitions = {
      ...state.definitions,
      ...settings.definitions,
    };
  },
  [MutationTypes.SET_SITES](state: LocalState, sites: any[]) {
    state.sites = sites;
  },
  [MutationTypes.SET_PROCESSING](state: LocalState, uuid: string) {
    if (state.processing.indexOf(uuid) === -1) state.processing.push(uuid);
  },
  [MutationTypes.REMOVE_PROCESSING](state: LocalState, uuid: string) {
    const index = state.processing.indexOf(uuid);
    if (index > -1) state.processing.splice(index, 1);
  },
  [MutationTypes.SET_PRINT_TEMPLATES](state: LocalState, templates) {
    state.print_templates = templates;
  },
};
