
import { defineComponent } from "vue";

export default defineComponent({
  name: "VueInputContainer",
  props: {
    labelClass: { type: String, default: () => "" },
    validationErrors: {
      type: Array,
      default: () => null,
    },
  },
});
