
import { computed, defineComponent, inject, Ref } from "vue";
import Draggable from "vuedraggable";
import Tippy from "@/components/tippy/Main.vue";
import { ConfirmRef } from "@/components/confirm";

export default defineComponent({
  name: "MenuNestedList",
  components: {
    Tippy,
    Draggable,
  },
  props: {
    list: {
      type: Array as () => Array<any>,
      default: () => [],
    },
    path: {
      type: Array as () => Array<any>,
      default: () => ["content"],
    },
    onEdit: {
      type: Function,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const editableList = computed<any[]>({
      get: () => props.list,
      set: (val) => emit("update:list", val),
    });

    const onDelete = (index: number): void => {
      confirmRef?.value?.show({
        title: "Seçilen menüyü silmek istediğinize emin misiniz?",
        icon: "feather-alert-circle",
        iconClass: "text-red-400",
        confirmText: "Evet",
        cancelText: "Hayır",
        callback: () => {
          editableList.value.splice(index, 1);
        },
      });
    };

    return {
      editableList,
      onDelete,
    };
  },
});
