
import { defineComponent, ref, watch } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import {
  PasswordCheckService,
  PasswordCheckStrength,
} from "@/utils/password-strength";

export default defineComponent({
  name: "VuePassword",
  components: { VueInput, VueInputContainer },
  props: {
    modelValue: { default: () => "" },
    errors: {
      type: Array,
      required: true,
    },
    visibleStrength: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const hidden = ref<boolean>(true);
    const strength = ref<PasswordCheckStrength>();

    const passwordCheckStrength = new PasswordCheckService();
    watch(
      () => props.modelValue,
      (val) => {
        strength.value = passwordCheckStrength.checkPasswordStrength(val);
      }
    );

    return {
      hidden,
      strength,
    };
  },
});
