
import { computed, defineComponent, inject, Ref } from "vue";
import {
  GeneralApiResponse,
  OrderStateInterface,
  OrderStatusQuery,
  orderStatusService,
} from "@/services";
import VueModal from "@/components/modal/Main.vue";
import { helper } from "@/utils/helper";
import Tippy from "@/components/tippy/Main.vue";
import { get } from "lodash";
import { useStore } from "@/store";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueTextarea from "@/components/form-elements/textarea/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueButton from "@/components/form-elements/button/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import { ConfirmRef } from "@/components/confirm";

export default defineComponent({
  name: "OrderStatusModal",
  components: {
    VueSelect,
    VueButton,
    VueInput,
    VueTextarea,
    VueInputContainer,
    Tippy,
    VueModal,
  },
  props: {
    orderGroupId: {
      required: true,
    },
    merchantId: {
      required: true,
    },
    activeState: {
      type: String,
      required: true,
    },
    states: {
      type: Array as () => OrderStateInterface[],
      default: () => [],
    },
    reloadData: {
      type: Function,
      default: () => null,
    },
  },
  setup(props) {
    const store = useStore();
    const modalRefId = helper.uuidv4();
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);

    const statusInfo = computed(() => {
      const info = store.getters["system/definition"](
        "order_states",
        props.activeState,
        null
      );

      return {
        background: get(info, "background"),
        text_color: helper.invertColor(get(info, "background")),
        text: get(info, "text"),
      };
    });

    const newStatusInfo = computed(() => {
      const info = store.getters["system/definition"](
        "order_states",
        query.value.status,
        null
      );

      return {
        background: get(info, "background"),
        text_color: helper.invertColor(get(info, "background")),
        text: get(info, "text"),
      };
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<OrderStatusQuery, GeneralApiResponse<any>>({
        query: {
          order_group_id: props.orderGroupId,
          merchant_id: [props.merchantId],
          status: "",
          message: "",
          shipping: {
            firm_id: "",
            tracking_number: "",
          },
        },
        storeServiceMethod: orderStatusService.submit,
        success(result) {
          if (result.kind === "ok") {
            cash(`#${modalRefId}`).modal("hide");
            ToastNotify({
              className: "success",
              text: "Sipariş Durumu Güncelledi",
            });
            props.reloadData();
          }
        },
      });

    const openStatusModal = (status: OrderStateInterface) => {
      if (!store.getters["auth/hasPermissionWithMerchant"]("orders.edit"))
        return;

      setQuery({
        order_group_id: props.orderGroupId,
        merchant_id: [props.merchantId],
        status: status.value,
        message: "",
        shipping: {
          firm_id: "",
          tracking_number: "",
        },
      });
      validationErrors.value.clear();
      cash(`#${modalRefId}`).modal("show");
    };

    const onSubmit = () => {
      if (!store.getters["auth/hasPermissionWithMerchant"]("orders.edit"))
        return;

      if (query.value.status === "CANCELLED") {
        confirmRef?.value?.show({
          title: "Siparişinizi iptal etmek istiyor musunuz?",
          text: "Siparişinizi iptal ettiğiniz anda müşteriye para iadesi yapılacaktır bu yüzden <b>geri dönüşü olmayacaktır.</b>",
          textClass: "text-red-500",
          icon: "feather-alert-circle",
          iconClass: "text-red-400",
          confirmText: "Evet",
          cancelText: "Hayır",
          callback: () => {
            submit();
          },
        });
      } else {
        submit();
      }
    };

    return {
      modalRefId,
      statusInfo,
      newStatusInfo,
      processing,
      query,
      validationErrors,
      openStatusModal,
      onSubmit,
    };
  },
});
