
import { defineComponent, onMounted } from "vue";
import { useFetchRecords } from "@/utils/records/fetch";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import { useFilterRecords } from "@/utils/records/filter";
import Pagination from "@/components/pagination/Main.vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import { findIndex } from "lodash";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import { useRouter } from "vue-router";
import { useDeleteRecord } from "@/utils/records/delete";
import { formService } from "@/services";
import FilterButton from "@/components/filter-container/Button.vue";

export default defineComponent({
  name: "OrderFormList",
  components: {
    FilterButton,
    VueSelect,
    VueInput,
    VueInputContainer,
    FilterContainer,
    Pagination,
    Tippy,
    TransactionStatement,
  },
  setup() {
    const router = useRouter();

    const { loading, list, fetch } = useFetchRecords({
      selectionQuery: {
        source: "form",
        config: {
          "@page": 1,
          "@select": ["id", "title", "sq", "is_fixed", "status"],
          "@order": "sq:asc",
          "@func": ["withCount"],
        },
        filterFormat: {
          q: "@where.q",
          title: "@where._title",
          is_fixed: "@where.is_fixed",
          status: "@where.status",
        },
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        id: "",
        title: "",
        status: "",
        is_fixed: "",
      },
    });

    const { deleteState, triggerDelete, removeDeleteError } = useDeleteRecord({
      serviceMethod: formService.delete,
      success(id: any) {
        const index = findIndex(list.data, { id });
        if (index > -1) list.data.splice(index, 1);
      },
    });

    const goToEdit = (id: any) => {
      router.push({ name: "order-forms.edit", params: { id } });
    };

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
    });

    return {
      loading,
      list,
      filterState,
      filterFunctions,
      deleteState,
      filterCount,
      triggerDelete,
      removeDeleteError,
      goToEdit,
    };
  },
});
