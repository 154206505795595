<template>
  <node-view-wrapper class="tiptap-disabled-block tiptap-vue-cover-photo">
    <div contenteditable="false">
      <span class="drag-handle label" draggable="true" data-drag-handle>
        {{ node.attrs.label }}
      </span>
    </div>
    <div class="tiptap-disabled-block-content">
      <VueInputContainer class="col-span-full">
        <template v-slot:label>
          <div class="flex items-center leading-none">
            <span>Görsel Adı</span>
            <Tippy
              class="ml-1.5"
              content="Arama motorları (Google) için anlamlı bir isim girmelisiniz!"
            >
              <i class="feather-help-circle text-xl"></i>
            </Tippy>
          </div>
        </template>
        <VueInput v-model="title" :disabled="!isEnabled" />
      </VueInputContainer>
      <VueInputContainer class="col-span-full">
        <template v-slot:label>Görsel</template>
        <FileManagerSelect v-model="photo" :disabled="!isEnabled" />
      </VueInputContainer>
      <VueInputContainer class="col-span-full">
        <template v-slot:label>
          <div class="flex items-center leading-none">
            <span>Görsel (Telefonlar İçin)</span>
            <Tippy
              class="ml-1.5"
              content="Telefonlar için farklı bir resim göstermek için burayı doldurunuz."
            >
              <i class="feather-help-circle text-xl"></i>
            </Tippy>
          </div>
        </template>
        <FileManagerSelect v-model="photo_mobile" :disabled="!isEnabled" />
      </VueInputContainer>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-3";
import VueInputContainer from "@/components/form-elements/input-container/Main";
import Tippy from "@/components/tippy/Main";
import VueInput from "@/components/form-elements/input/Main";
import FileManagerSelect from "@/components/file-manager/Select";
import { get } from "lodash";
export default {
  name: "TiptapVueCategoryContainer",
  components: {
    FileManagerSelect,
    VueInput,
    Tippy,
    VueInputContainer,
    NodeViewWrapper,
  },
  props: nodeViewProps,
  computed: {
    isEnabled() {
      return get(this.editor, "options.editable") || false;
    },
    title: {
      get() {
        return get(this.node, "attrs.title") || "";
      },
      set(value) {
        this.updateAttributes({
          title: value || "",
        });
      },
    },
    photo: {
      get() {
        return get(this.node, "attrs.photo") || "";
      },
      set(value) {
        this.updateAttributes({
          photo: value || "",
        });
      },
    },
    photo_mobile: {
      get() {
        return get(this.node, "attrs.photo_mobile") || "";
      },
      set(value) {
        this.updateAttributes({
          photo_mobile: value || "",
        });
      },
    },
  },
};
</script>
