
import { defineComponent, reactive, toRaw } from "vue";
import { Filter } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import VueButton from "@/components/form-elements/button/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueColorPicker from "@/components/form-elements/color/Main.vue";
import Draggable from "vuedraggable";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "FilterOptions",
  components: {
    VueColorPicker,
    VueInput,
    VueInputContainer,
    Tippy,
    VueButton,
    Draggable,
  },
  props: {
    query: {
      type: Object as () => Filter,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableQuery = reactive<Filter>(toRaw(props.query));

    const addOption = () => {
      editableQuery.options.data.push({
        uuid: helper.uuidv4(),
        title: "",
        slug: "",
        color_code: "",
      });
    };

    const deleteOption = (index: number) => {
      editableQuery.options.data.splice(index, 1);
    };

    return {
      editableQuery,
      addOption,
      deleteOption,
    };
  },
});
