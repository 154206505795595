import { App } from "vue";
import lodash from "./lodash";
import helper from "./helper";
import orderObject from "./order-objects";

export default (app: App): void => {
  app.use(lodash);
  app.use(helper);
  app.use(orderObject);
};
