import { inject, reactive, Ref } from "vue";
import {
  GeneralApiProblem,
  GeneralApiResponse,
  MassApiResponse,
} from "@/services";
import { ToastNotify } from "@/utils/toast-notify";
import { ConfirmRef } from "@/components/confirm";
import { helper } from "@/utils/helper";
import { useStore } from "@/store";
import { chunk, cloneDeep, flattenDeep } from "lodash";

type Props = {
  text?: string;
  serviceMethod(id: any): Promise<GeneralApiResponse<any>>;
  massServiceMethod?(id: any): Promise<GeneralApiResponse<MassApiResponse>>;
  success?(id: any, result?: GeneralApiResponse<any>): void;
  fail?(id: any, error?: GeneralApiProblem): void;
  finish?(id: any, result?: GeneralApiResponse<any>): void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDeleteRecord(props: Props) {
  const store = useStore();
  const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
  const processingUuid = helper.uuidv4();

  const state = reactive<Record<string, any>>({
    chunks: [],
    transactionIds: [],
    pendingTransactionIds: [],
    errors: [],
    processing: false,
  });

  const destroyNextRecords = async (): Promise<void> => {
    state.transactionIds = state.chunks.shift() || [];
    state.pendingTransactionIds = flattenDeep(state.chunks);
    if (state.transactionIds.length > 0) {
      state.processing = true;
      if (state.transactionIds.length === 1)
        await singleDestroyRecord(state.transactionIds[0]);
      else if (state.transactionIds.length > 1)
        await massDestroyRecords(state.transactionIds);
      state.processing = false;
      destroyNextRecords().finally();
    } else {
      store.dispatch("system/REMOVE_PROCESSING", processingUuid);
    }
  };

  const singleDestroyRecord = async (id: any) => {
    const result = await props.serviceMethod(id);
    if (result) {
      if (result.kind === "ok" && props.success) props.success(id, result);
      if (result.kind !== "ok") {
        ToastNotify({ text: result.message, className: "error" });
        state.errors.push({ id, error: result.message });
        if (props.fail) props.fail(id, result);
      }
      if (props.finish) props.finish(id, result);
    }
  };

  const massDestroyRecords = async (ids: any[]) => {
    if (props.massServiceMethod) {
      const result = await props.massServiceMethod(ids);
      if (result) {
        if (result.kind === "ok") {
          state.errors = [...state.errors, ...result.data.error];
          result.data.success.map((item: any) => {
            if (props.success) props.success(item, result);
          });
          result.data.error.map((item: any) => {
            if (props.fail) props.fail(item.id);
          });
        } else {
          ToastNotify({ text: result.message, className: "error" });
        }
        if (props.finish) props.finish(ids, result);
      }
    }
  };

  const triggerDelete = (ids: any[]): void => {
    confirmRef?.value?.show({
      title: "Emin misiniz?",
      text:
        props.text || `Seçilen ${ids.length} satırı silmek istiyor musunuz?`,
      icon: "feather-alert-circle",
      iconClass: "text-red-400",
      confirmText: "Evet",
      cancelText: "Hayır",
      callback: () => {
        state.transactionIds = [];
        state.pendingTransactionIds = [];
        state.errors = [];
        state.processing = false;
        state.chunks = chunk(cloneDeep(ids), 20);
        store.dispatch("system/SET_PROCESSING", processingUuid);
        destroyNextRecords().finally();
      },
    });
  };

  const removeDeleteError = (id: any) => {
    state.errors = state.errors.filter((o: any) => o.id !== id);
  };

  return {
    deleteState: state,
    triggerDelete,
    removeDeleteError,
  };
}
