import regexColors from "./regex-colors";
import { get, isNull, isNumber, isString, isUndefined } from "lodash";
import { App } from "vue";

const orderObjects = (object: any, data: any): any => {
  if (isUndefined(object)) return null;
  if (isNull(object)) return null;
  if (get(object, "type", null) === "photo") return "";
  if (get(data, "code", null) === "FabricColor")
    return regexColors.display(object);
  if (isNumber(object)) return object;
  if (isString(object)) return object;

  return "Obje görüntülenemiyor";
};

const install = (app: App): void => {
  app.config.globalProperties.$orderObjects = orderObjects;
};

export { install as default, orderObjects };
