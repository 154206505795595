import { RouteRecordRaw, RouterView } from "vue-router";
import PriceCalculationRules from "@/views/price-calculation-rules/list/Main.vue";
import PriceCalculationRuleCreate from "@/views/price-calculation-rules/create/Main.vue";

const route: Array<RouteRecordRaw> = [
  {
    path: "price-calculation-rules",
    component: RouterView,
    children: [
      {
        path: "",
        name: "price-calculation-rules",
        component: PriceCalculationRules,
        meta: {
          title: "Fiyat Hesap Kuralları",
        },
      },
      {
        path: "create",
        name: "price-calculation-rules.create",
        component: PriceCalculationRuleCreate,
        meta: {
          title: "Fiyat Hesap Kuralı Ekle",
        },
      },
      {
        path: "edit/:id",
        name: "price-calculation-rules.edit",
        component: PriceCalculationRuleCreate,
        meta: {
          title: "Fiyat Hesap Kuralını Düzenle",
        },
      },
    ],
  },
];

export default route;
