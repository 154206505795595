
import { defineComponent, onBeforeUnmount, onMounted } from "vue";

export default defineComponent({
  name: "LayoutLogin",
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("error-page")
        .removeClass("main")
        .removeClass("welcome")
        .addClass("login");
    });
    onBeforeUnmount(() => {
      cash("body").removeClass("login");
    });
  },
});
