
import { defineComponent } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { useSubmitRecord } from "@/utils/records/submit";
import { GetUser, GetUserResult, PostUser, userService } from "@/services";
import VueSelect from "@/components/form-elements/select/Main.vue";
import VueButton from "@/components/form-elements/button/Main.vue";
import { ToastNotify } from "@/utils/toast-notify";
import { useRouter } from "vue-router";
import VuePassword from "@/components/form-elements/password/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "PersonalInformation",
  components: {
    VueSelect2,
    VuePassword,
    VueButton,
    VueSelect,
    VueInput,
    VueInputContainer,
  },
  props: {
    userDetail: {
      type: Object as () => GetUser,
      default: () => ({}),
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    isMyProfile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();

    let removeKeysIfEmptyValue: any[] = [];
    if (props.userDetail.id) {
      removeKeysIfEmptyValue = ["password"];
    }

    const { query, processing, validationErrors, submit } = useSubmitRecord<
      PostUser,
      GetUserResult
    >({
      query: {
        type: props.userDetail.type || "3",
        merchant_id: props.userDetail.merchant_id || "",
        name: props.userDetail.name || "",
        surname: props.userDetail.surname || "",
        email: props.userDetail.email || "",
        gsm: props.userDetail.gsm || "",
        gender: props.userDetail.gender || "0",
        password: "",
        send_sms: props.userDetail.send_sms || "1",
        send_email: props.userDetail.send_email || "1",
        send_notification: props.userDetail.send_notification || "1",
        status: props.userDetail.status || "1",
        roles: props.userDetail.roles || [],
      },
      removeKeysIfEmptyValue,
      storeServiceMethod: userService.store,
      updateServiceMethod: userService.update,
      success(result) {
        if (result?.kind === "ok") {
          ToastNotify({
            className: "success",
            text: "Kullanıcı Kaydedildi!",
          });
          if (!props.userDetail.id) {
            router.replace({
              name: "users.edit",
              params: { id: result.data.id },
            });
          } else {
            emit("update:user-detail", result.data);
          }
        }
      },
    });

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const roleSelect2Options: Select2Options = {
      multiple: true,
      isArray: true,
      data: store.getters["system/definitions"]("roles").map((role: any) => {
        return { id: role.value, text: role.text };
      }),
    };

    const onSubmit = () => {
      submit(props.userDetail.id);
    };

    return {
      query,
      processing,
      validationErrors,
      merchantSelect2Options,
      roleSelect2Options,
      onSubmit,
    };
  },
});
