import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "mt-2 text-xs font-medium text-gray-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueSelect2 = _resolveComponent("VueSelect2")!
  const _component_VueInputContainer = _resolveComponent("VueInputContainer")!

  return (_openBlock(), _createBlock(_component_VueInputContainer, {
    "validation-errors": _ctx.validationErrors.get(_ctx.validationPath)
  }, {
    label: _withCtx(() => [
      (_ctx.isRequired)
        ? (_openBlock(), _createElementBlock("b", _hoisted_1, "*"))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.lineTitle), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_VueSelect2, {
          "model-value": _ctx.lineValue,
          "onUpdate:modelValue": _ctx.onChangeValue,
          "onUpdate:selectedItem": _ctx.changeProduct,
          "has-error": _ctx.validationErrors.has(_ctx.validationPath),
          options: _ctx.productSelect2Options
        }, null, 8, ["model-value", "onUpdate:modelValue", "onUpdate:selectedItem", "has-error", "options"]),
        (_ctx.lineDescription)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.lineDescription), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["validation-errors"]))
}