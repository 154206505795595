<template>
  <a
    href="javascript:;"
    class="btn btn-secondary relative text-lg leading-none"
  >
    <i class="feather-filter"></i>
    <span
      class="bg-red-500 text-white rounded-full px-1.5 py-1 leading-none text-xs absolute top-0.5 right-0.5"
      v-if="count > 0"
    >
      {{ count }}
    </span>
  </a>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FilterButton",
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
});
</script>
