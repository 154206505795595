
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  OrderFormInterface,
  GetFormResult,
  formService,
  GeneralApiResponse,
  FormLine,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueButton from "@/components/form-elements/button/Main.vue";
import OrderFormGeneralInformation from "@/views/order-forms/create/components/GeneralInformation.vue";
import OrderFormLines from "@/views/order-forms/create/components/FormLines.vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "OrderFormCreateOrUpdate",
  components: { OrderFormLines, OrderFormGeneralInformation, VueButton },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const id = computed(() => get(route.params, "id"));
    const { loading, fetch } = useReadRecord<OrderFormInterface, GetFormResult>(
      {
        id: id.value,
        serviceMethod: formService.show,
        success(result) {
          if (result.kind === "ok") {
            const lines = get(result.data, "lines.data", []).map(
              (l: FormLine) => ({
                ...l,
                uuid: helper.uuidv4(),
              })
            );
            setQuery({ ...result.data, lines: { data: lines } });
          }
        },
        fail() {
          router.go(-1);
        },
      }
    );

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<OrderFormInterface, GeneralApiResponse<GetFormResult>>({
        query: {
          title: "",
          sq: "0",
          is_fixed: "0",
          auto_calculate: "0",
          relation_to_master: "0",
          is_multiple_case: "0",
          status: "1",
          lines: {
            data: [],
          },
        },
        storeServiceMethod: formService.store,
        updateServiceMethod: formService.update,
        success(result) {
          if (result?.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "Sipariş Formu Kaydedildi!",
            });
            router.go(-1);
          }
        },
      });

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) fetch(id.value);
    });

    return {
      loading,
      processing,
      query,
      validationErrors,
      onSubmit,
    };
  },
});
