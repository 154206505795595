import { computed, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { set, cloneDeep, isArray, get, forEach, isEmpty } from "lodash";
import { IsCompare } from "@/utils/isCompare";

type Props = {
  fetch(query?: Record<string, any>): Promise<void>;
  fields: Record<string, any>;
  withoutRoute?: boolean;
};

export type FilterState = {
  show: boolean;
  fields: Record<string, any>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useFilterRecords(props: Props) {
  const route = useRoute();
  const router = useRouter();

  const originalFields: Record<string, any> = {
    page: 1,
    ...cloneDeep(props.fields),
  };

  const state = reactive<FilterState>({
    show: false,
    fields: cloneDeep(originalFields),
  });

  const filterCount = computed(() => {
    let count = 0;
    forEach(state.fields, (val, key) => {
      const cVal = cloneDeep(get(route.query, key));
      const oVal = get(originalFields, key, cVal);
      if (
        ((Array.isArray(cVal) && !isEmpty(cVal)) ||
          (!Array.isArray(cVal) && cVal)) &&
        cVal !== oVal &&
        ["page"].indexOf(key) === -1
      ) {
        count++;
      }
    });
    return count;
  });

  const changePage = (page: number) => {
    state.fields.page = page;
    if (!props.withoutRoute)
      router.push({ query: formattedQuery(true) }).finally();
    else props.fetch(formattedQuery()).finally();
  };

  const applyFilter = () => {
    state.fields.page = 1;
    if (!props.withoutRoute)
      router.push({ query: formattedQuery(true) }).finally();
    else props.fetch(formattedQuery()).finally();
  };

  const clearFilter = () => {
    state.fields = cloneDeep(originalFields);
    if (!props.withoutRoute)
      router.push({ query: formattedQuery(true) }).finally();
    else props.fetch(formattedQuery()).finally();
  };

  const getRouteMergeFields = (): Record<string, any> => {
    const obj: Record<string, any> = {};
    forEach(cloneDeep(state.fields), (value, key) => {
      const originalValue = cloneDeep(get(originalFields, `${key}`, ""));
      if (!get(route.query, key)) {
        value = originalValue;
      } else {
        if (isArray(originalValue) && !isArray(get(route.query, key))) {
          value = [get(route.query, key)];
        } else {
          value = get(route.query, key);
        }
      }
      set(obj, key, value);
    });
    return obj;
  };

  const formattedQuery = (withSameOriginal = true): Record<string, any> => {
    const obj: Record<string, any> = {};
    forEach(state.fields, (value, key) => {
      if (
        isArray(value) &&
        value.length > 0 &&
        (withSameOriginal || !IsCompare(value, get(originalFields, key)))
      ) {
        set(obj, key, value);
      }

      if (
        !isArray(value) &&
        value !== "" &&
        value !== null &&
        value !== undefined &&
        (withSameOriginal || value !== get(originalFields, key))
      ) {
        set(obj, key, value);
      }
    });
    return obj;
  };

  if (!props.withoutRoute) {
    state.fields = getRouteMergeFields();
    watch(
      () => route.query,
      () => {
        state.fields = getRouteMergeFields();
        props.fetch(formattedQuery()).finally();
      },
      {
        flush: "post",
        deep: true,
      }
    );
  }

  return {
    filterState: state,
    filterCount,
    filterFunctions: {
      applyFilter,
      clearFilter,
      changePage,
      formattedQuery,
    },
  };
}
