import {
  PrintTemplateInterface,
  SettingsDefinitionsInterface,
  SettingsSiteInterface,
  ValueTextInterface,
} from "@/services";

export interface DefinitionsInterface extends SettingsDefinitionsInterface {
  assets: Record<string, any>[];
  units: ValueTextInterface[];
  order_form_types: ValueTextInterface[];
  form_line_types: (ValueTextInterface & { code: string })[];
}

export type State = {
  sites: SettingsSiteInterface[];
  definitions: DefinitionsInterface;
  processing: string[];
  print_templates: PrintTemplateInterface[];
};

export const state: State = {
  sites: [],
  definitions: {
    cdn_image: "",
    cdn_file: "",
    cdn_static: "",
    user_types: [],
    merchant_types: [],
    merchant_application_types: [],
    content_types: [],
    cargo_firms: [],
    order_states: [],
    google_category_types: [],
    transaction_types: [],
    menu_types: [],
    permissions: [],
    roles: [],
    units: [
      {
        value: "adet",
        text: "Adet",
      },
      {
        value: "m",
        text: "M",
      },
      {
        value: "m2",
        text: "M²",
      },
    ],
    order_form_types: [
      {
        value: 0,
        text: "Sipariş Formu",
      },
      {
        value: 1,
        text: "Üst Bilgi Formu",
      },
      {
        value: 2,
        text: "Tamir Formu",
      },
      {
        value: 3,
        text: "Gizli Form",
      },
    ],
    form_line_types: [
      {
        value: "product",
        text: "Ürün",
        code: "XmlCode",
      },
      {
        value: "piece",
        text: "Adet",
        code: "BulkQty",
      },
      {
        value: "piecedecimal",
        text: "Adet (Ondalık)",
        code: "BulkQty",
      },
      {
        value: "qty",
        text: "Miktar",
        code: "Qty",
      },
      {
        value: "width",
        text: "En",
        code: "Width",
      },
      {
        value: "height",
        text: "Boy",
        code: "Height",
      },
      {
        value: "widthmeter",
        text: "En (Metre)",
        code: "WidthMeter",
      },
      {
        value: "heightmeter",
        text: "Boy (Metre)",
        code: "HeightMeter",
      },
      {
        value: "fabriccolor",
        text: "Renk Seçimi",
        code: "FabricColor",
      },
      // {
      //   value: "fabriccolorcodes",
      //   text: "Renk Seçim Kodlar",
      //   code: "FabricColorCodes",
      // },
      {
        value: "photo",
        text: "Fotoğraf",
        code: "Photo",
      },
      {
        value: "opening",
        text: "Açılma Şekli",
        code: "Opening",
      },
      {
        value: "openingside",
        text: "Açılma Tarafı",
        code: "OpeningSide",
      },
      {
        value: "options",
        text: "Seçim",
        code: "Selection",
      },
      {
        value: "direction",
        text: "Yön",
        code: "Direction",
      },
      {
        value: "forms",
        text: "Form",
        code: "Form",
      },
      {
        value: "rules",
        text: "Kural",
        code: "RuleChanger",
      },
      {
        value: "single",
        text: "Kısa Açıklama",
        code: "Description",
      },
      {
        value: "multiple",
        text: "Uzun Açıklama",
        code: "Description",
      },
    ],
    assets: [
      {
        value: "cover",
        text: "Kapak Resmi",
        fields: {
          title: "",
          photo: "",
          photo_mobile: "",
        },
      },
      {
        value: "photo",
        text: "Resim",
        fields: {
          title: "",
          photo: "",
          photo_mobile: "",
        },
      },
      {
        value: "photo-gallery",
        text: "Resim Galerisi",
        fields: {
          title: "",
          photos: [],
        },
      },
      {
        value: "image-slider",
        text: "Kayan Resim Alanı",
        fields: {
          children: [],
        },
        defaultChildFields: {
          content: "",
          content_url: "",
          photo: "",
          photo_mobile: "",
        },
      },
      {
        value: "most-commented-products",
        text: "En Çok Yorum Alan Ürünler",
      },
      {
        value: "accordion",
        text: "Akordiyon",
        fields: {
          children: [],
        },
        defaultChildFields: {
          title: "",
          text: "",
        },
      },
      {
        value: "category-container",
        text: "Ürün Kategorisi",
        fields: {
          categories: [],
        },
      },
      {
        value: "category-promotion",
        text: "Kategori Tanıtım Satırı",
        fields: {
          title: "",
          text: "",
          content: "",
          content_url: "",
          source: "products",
          products: [],
          categories: [],
        },
      },
      {
        value: "video",
        text: "Video",
        fields: {
          title: "",
          id: "",
          url: "",
          type: "youtube",
          visible: "all",
        },
      },
    ],
  },
  processing: [],
  print_templates: [],
};
