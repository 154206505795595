import { GeneralApiResponse, GetCategoryResult, useAxios } from "@/services";

export * from "./api.types";

export const categoryService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetCategoryResult>>(
      `/api/categories/${id}`
    ),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetCategoryResult>>(
      `/api/categories`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetCategoryResult>>(
      `/api/categories/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/categories/${id}`),
};
