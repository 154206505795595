import { get } from "lodash";
import {
  GetLoginResult,
  GetProfileResult,
  useAxios,
  GetForgotPasswordResult,
  GeneralApiResponse,
} from "@/services";

export * from "./api.types";

export const authService = {
  profile: () =>
    useAxios().get<GeneralApiResponse<GetProfileResult>>(`/api/auth/me`),

  login: async (
    params: unknown
  ): Promise<GeneralApiResponse<GetLoginResult>> => {
    const result = await useAxios({ responseField: "" }).post<GetLoginResult>(
      `/api/auth/login`,
      params
    );
    if (result.kind !== "ok") return result;
    return {
      kind: "ok",
      data: get(result.data, "data", null),
      meta: get(result.data, "meta", null),
    };
  },

  loginAs: async (id: unknown): Promise<GeneralApiResponse<GetLoginResult>> => {
    const result = await useAxios({ responseField: "" }).post<GetLoginResult>(
      `/api/auth/login-as/${id}`
    );
    if (result.kind !== "ok") return result;
    return {
      kind: "ok",
      data: get(result.data, "data", null),
      meta: get(result.data, "meta", null),
    };
  },

  forgotPassword: (params: unknown) =>
    useAxios({ responseField: "" }).post<
      GeneralApiResponse<GetForgotPasswordResult>
    >(`/api/auth/forgot-password`, params),

  logout: () =>
    useAxios({ responseField: "" }).post<GeneralApiResponse<any>>(
      `/api/auth/logout`
    ),
};
