
import { computed, defineComponent, onMounted, provide, ref, watch } from "vue";
import md5 from "blueimp-md5";
import { useStore } from "@/store";
import { helper } from "@/utils/helper";
import { get, findIndex } from "lodash";
import { Breadcrumb } from "@/utils/breadcrumbs";
import { RouteLocationMatched, useRoute } from "vue-router";

export default defineComponent({
  name: "TopNavigation",
  setup() {
    const route = useRoute();
    const store = useStore();
    const profile = store.getters["auth/profile"];

    const searchDropdown = ref(false);

    const sites = computed(() => store.state.system.sites);
    const activeSiteTitle = computed(() => {
      const index = findIndex(sites.value, {
        key: store.getters["system/site"],
      });
      return get(sites.value, `${index}.title`);
    });

    const id = computed(() => get(profile, "id"));
    const nameSurname = computed(() => helper.formatNameSurname(profile));
    const email = computed(() => get(profile, "email"));
    const gravatar = computed(() => {
      return `https://secure.gravatar.com/avatar/${md5(
        profile?.email
      )}?size=256`;
    });

    const breadcrumbs = ref<Breadcrumb[]>([]);
    provide("breadcrumbs", (newVal: Breadcrumb[]) => {
      breadcrumbs.value = newVal;
    });

    const showSearchDropdown = () => {
      searchDropdown.value = true;
    };

    const hideSearchDropdown = () => {
      searchDropdown.value = false;
    };

    const setBreadcrumbs = () => {
      const arr: RouteLocationMatched[] = [];
      route.matched.map((item) => {
        if (findIndex(arr, { path: item.path }) === -1 && item.name) {
          arr.push(item);
        }
      });

      breadcrumbs.value = arr.map((item, index) => ({
        to: item.path,
        title: item.meta?.title || item.name,
        active: index === arr.length - 1,
      }));
    };

    const logout = () => {
      store.dispatch("auth/LOGOUT", undefined);
    };

    const changeSiteKey = (key: any) => {
      if (key * 1 !== store.getters["system/site"]) {
        store.dispatch("system/SET_SITE", key);
        window.location.reload();
      }
    };

    watch(
      () => route.name,
      () => setBreadcrumbs()
    );

    onMounted(() => {
      setBreadcrumbs();
    });

    return {
      id,
      nameSurname,
      email,
      gravatar,
      searchDropdown,
      breadcrumbs,
      sites,
      activeSiteTitle,
      showSearchDropdown,
      hideSearchDropdown,
      logout,
      changeSiteKey,
    };
  },
});
