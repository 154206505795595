import { AxiosError } from "axios";
import _ from "lodash";

export type PaginationMeta = {
  current_page?: number;
  total: number;
  last_page?: number;
  from?: number;
  to?: number;
  per_page?: number;
};

export type MassApiResponse = {
  data: {
    success: any[];
    error: { id: any; uuid?: string; text: string }[];
  };
};

export type GeneralApiProblem =
  /**
   * Times up.
   */
  | { kind: "timeout"; message?: string; temporary: true }
  /**
   * Cannot connect to the server for some reason.
   */
  | { kind: "cannot-connect"; message?: string; temporary: true }
  /**
   * The server experienced a problem. Any 5xx error.
   */
  | { kind: "server"; message?: string }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: "unauthorized"; message?: string }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { kind: "forbidden"; message?: string }
  /**
   * Unable to find that resource.  This is a 404.
   */
  | { kind: "not-found"; message?: string }
  /**
   *  This is a 422.
   */
  | {
      kind: "validation";
      message?: string;
      fields: Record<string, string[]> | null;
    }
  /**
   * All other 4xx series errors.
   */
  | { kind: "rejected"; message?: string }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | { kind: "unknown"; temporary: true; message?: string }
  /**
   * The data we received is not in the expected format.
   */
  | { kind: "bad-data"; message?: string }
  | { kind: "too-many"; message: string; reset: any };

export type GeneralApiResponse<T> = (T & { kind: "ok" }) | GeneralApiProblem;

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param error The api response.
 */
export function getGeneralApiProblem(error: AxiosError): GeneralApiProblem {
  switch (error.response?.status) {
    case 401:
      return {
        kind: "unauthorized",
        message: _.get(error, "response.data.message", ""),
      };
    case 403:
      return {
        kind: "forbidden",
        message: _.get(error, "response.data.message", ""),
      };
    case 404:
      return {
        kind: "not-found",
        message: _.get(error, "response.data.message", ""),
      };
    case 422:
      return {
        kind: "validation",
        message: _.get(error, "response.data.message", ""),
        fields: _.get(error, "response.data.errors", []),
      };
    case 429:
      return {
        kind: "too-many",
        message: _.get(error, "response.data.message", ""),
        reset: _.get(error, "response.headers.x-ratelimit-reset", ""),
      };
    default:
      return {
        kind: "rejected",
        message: _.get(error, "response.data.message", error.message),
      };
  }
}
