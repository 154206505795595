import { BaseExcel } from "@/utils/excel/base";
import ExcelJs from "exceljs";

export class MerchantOrderReport extends BaseExcel {
  constructor() {
    super(null);
    this.defaultWorksheetProperties = {};
    this.defaultColumnStyle.alignment.wrapText = true;
  }

  async process(): Promise<void> {
    this.wb = new ExcelJs.Workbook();
    this.sheetIndex = -1;
    this.sheets = {};
    await this.preparePrimaryColumns();
    await this.setHeaderLetters();
    return await super.baseProcess();
  }

  private async preparePrimaryColumns(): Promise<void> {
    this.sheets["primary"] = {
      title: "Sipariş Detayı",
      headers: {
        created_at: {
          key: "created_at",
          title: "Sipariş Tarih",
          fill: "primary",
        },
        order_group_id: {
          key: "order_group_id",
          title: "Sipariş No",
          fill: "primary",
        },
        name_surname: {
          key: "name_surname",
          title: "Müşteri Adı",
          fill: "primary",
        },
        status: {
          key: "status",
          title: "Sipariş Durumu",
          fill: "primary",
        },
        completed_at: {
          key: "completed_at",
          title: "Tamamlanma Tarih",
          fill: "primary",
        },
        installment: {
          key: "installment",
          title: "Taksit Sayısı",
          fill: "primary",
          numberFormat: "0",
        },
        product_amount: {
          key: "product_amount",
          title: "Ürün Tutarı",
          fill: "info",
          numberFormat: "0.00",
        },
        cargo_amount: {
          key: "cargo_amount",
          title: "Kargo Bedeli",
          fill: "info",
          numberFormat: "0.00",
        },
        installment_interest_amount: {
          key: "installment_interest_amount",
          title: "Taksit Vade Farkı",
          fill: "info",
          numberFormat: "0.00",
        },
        total_amount: {
          key: "total_amount",
          title: "Fatura Tutarı",
          fill: "info",
          numberFormat: "0.00",
        },
        eip_amount: {
          key: "eip_commission_rate",
          title: "Pazaryeri Komisyon Tutarı",
          fill: "danger",
          numberFormat: "0.00",
        },
        bank_amount: {
          key: "bank_amount",
          title: "Banka Komisyon Tutarı",
          fill: "danger",
          numberFormat: "0.00",
        },
        total_commission_amount: {
          key: "total_commission_amount",
          title: "Toplam Komisyon Tutarı",
          fill: "primary",
          numberFormat: "0.00",
        },
        merchant_amount: {
          key: "merchant_amount",
          title: "Mağazaya Ödenen Tutar",
          fill: "primary",
          numberFormat: "0.00",
        },
      },
    };
  }
}
