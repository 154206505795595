
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueDatetimePicker from "@/components/form-elements/datetime-picker/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import dayjs from "dayjs";
import { clone, cloneDeep, forEach, get, values } from "lodash";
import FilterContainer from "@/components/filter-container/Main.vue";
import SummaryWidget from "@/views/reports/SummaryWidget.vue";
import Chart from "@/components/chart/Main.vue";
import {
  GeneralStatisticReport,
  GetGeneralStatisticReportResult,
  reportService,
} from "@/services";
import { useFilterRecords } from "@/utils/records/filter";
import { useReadRecord } from "@/utils/records/read";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "StatisticReport",
  components: {
    Chart,
    SummaryWidget,
    FilterContainer,
    VueSelect,
    VueDatetimePicker,
    VueInputContainer,
  },
  setup() {
    const fromDate = dayjs().startOf("year").format("YYYY-MM-DD");
    const toDate = dayjs().format("YYYY-MM-DD");

    const presetRanges = ref([
      { label: "Bugün", range: [dayjs(), dayjs()] },
      { label: "Son 7 Gün", range: [dayjs().subtract(6, "day"), dayjs()] },
      { label: "Son 14 Gün", range: [dayjs().subtract(13, "day"), dayjs()] },
      {
        label: "Bu Ay",
        range: [dayjs().startOf("month"), dayjs()],
      },
      {
        label: "Geçen Ay",
        range: [
          dayjs().subtract(1, "month").startOf("month"),
          dayjs().subtract(1, "month").endOf("month"),
        ],
      },
      {
        label: "Son 3 Ay",
        range: [dayjs().subtract(2, "month").startOf("month"), dayjs()],
      },
      {
        label: "Bu Yıl",
        range: [dayjs().startOf("year"), dayjs()],
      },
    ]);

    const datasetColors = ["#6366f1", "#54c7cb", "#f16363"];
    const defaultDataset = {
      label: "",
      data: [],
      tension: 0.3,
      borderWidth: 2,
      borderDash: [2, 2],
      borderColor: "#6366f1",
      backgroundColor: "#6366f1",
      pointBorderColor: "#6366f1",
      pointRadius: 2,
      pointHoverRadius: 6,
    };

    const defaultChartObject = {
      color: "#a4e0e3",
      label: "",
      description: "",
      primaryValue: 0,
      secondaryValue: 0,
      otherValue: 0,
      secondaryTitle: "",
      otherTitle: "",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            type: "linear",
            display: true,
            position: "left",
          },
        },
      },
    };

    const activeView = ref("general");

    const charts = reactive<GeneralStatisticReport>({
      grossMerchandiseValue: {
        ...cloneDeep(defaultChartObject),
        label: "Sipariş Tutarı",
        description:
          "Bu grafik, ödemesi başarılı bir şekilde gerçekleşen siparişler baz alınarak hazırlanmıştır.",
        abbr: "GMV",
      },
      grossBasketValue: {
        ...cloneDeep(defaultChartObject),
        label: "Sepet Değeri",
        description:
          "Bu grafik, kullanıcıların sepete ekleyip fakat sipariş vermediği ürünler baz alınarak hazırlanmıştır.",
        abbr: "GBV",
        color: "#ad8a8a",
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              type: "linear",
              display: true,
              position: "left",
            },
            y2: {
              type: "linear",
              display: true,
              position: "right",
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      },
      averageOrderValue: {
        ...cloneDeep(defaultChartObject),
        label: "Ortalama Sipariş Tutarı",
        abbr: "AOV",
        color: "#ffba6e",
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              type: "linear",
              display: true,
              position: "left",
            },
            y2: {
              type: "linear",
              display: true,
              position: "right",
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      },
      netMerchandiseValue: {
        ...cloneDeep(defaultChartObject),
        label: "Komisyon Tutarı",
        color: "#ffe081",
        abbr: "NMV",
      },
      profitMerchandiseValue: {
        ...cloneDeep(defaultChartObject),
        label: "Kâr - Zarar",
        color: "#c3f8ab",
      },
      averageRevenuePerAccountAction: {
        ...cloneDeep(defaultChartObject),
        label: "Müşteri Başına Ortalama Gelir",
        abbr: "ARPA",
        color: "#d9b0ff",
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              type: "linear",
              display: true,
              position: "left",
            },
            y2: {
              type: "linear",
              display: true,
              position: "right",
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      },
      averageProfitPerAccountAction: {
        ...cloneDeep(defaultChartObject),
        label: "Müşteri Başına Ortalama Kâr",
        abbr: "APPA",
        color: "#b0ffed",
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              type: "linear",
              display: true,
              position: "left",
            },
            y2: {
              type: "linear",
              display: true,
              position: "right",
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
      },
      customerAcquisitionCost: {
        ...cloneDeep(defaultChartObject),
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              type: "linear",
              display: true,
              position: "left",
            },
            y2: {
              type: "linear",
              display: true,
              position: "right",
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        },
        label: "Müşteri Maliyeti",
        abbr: "CAC",
        color: "#ef9494",
      },
      averageOrderFrequency: {
        ...cloneDeep(defaultChartObject),
        label: "Ortalama Sipariş Sıklık Oranı",
        abbr: "AOFR",
        color: "#ffc0e6",
      },
    });

    const dateRange = computed({
      get() {
        return `${filterState.fields.from} - ${filterState.fields.to}`;
      },
      set(val: string) {
        const range = val.toString().split(" - ");
        filterState.fields.from = get(range, 0) || fromDate;
        filterState.fields.to = get(range, 1) || toDate;
      },
    });

    const isSelectableDayPeriod = computed(() => {
      const f = dayjs(filterState.fields.from);
      const t = dayjs(filterState.fields.to);

      return t.diff(f, "day") <= 31;
    });

    const isSelectableWeekPeriod = computed(() => {
      const f = dayjs(filterState.fields.from);
      const t = dayjs(filterState.fields.to);

      return t.diff(f, "week") >= 1;
    });

    const isSelectableMonthPeriod = computed(() => {
      const f = dayjs(filterState.fields.from);
      const t = dayjs(filterState.fields.to);

      return t.diff(f, "month") >= 1;
    });

    const isSelectableYearPeriod = computed(() => {
      const f = dayjs(filterState.fields.from);
      const t = dayjs(filterState.fields.to);

      return t.diff(f, "year") >= 1;
    });

    const { loading, fetch } = useReadRecord<
      GeneralStatisticReport,
      GetGeneralStatisticReportResult
    >({
      id: true,
      serviceMethod: reportService.statistics,
      success(result) {
        if (result.kind === "ok") {
          forEach(charts, (chart, key) => {
            const data = get(result.data, key);

            chart.data.labels = get(data, "labels", []);

            chart.primaryValue = get(data, "datasets.0.total", 0);
            chart.secondaryValue = get(data, "datasets.1.total", 0);
            chart.otherValue = get(data, "datasets.2.total", 0);
            chart.secondaryTitle = get(data, "datasets.1.title", "");
            chart.otherTitle = get(data, "datasets.2.title", "");

            if (
              [
                "averageOrderValue",
                "averageRevenuePerAccountAction",
                "averageProfitPerAccountAction",
                "averageOrderFrequency",
                "customerAcquisitionCost",
              ].indexOf(key) > -1
            ) {
              chart.primaryValue = get(data, "datasets.0.average", 0);
              chart.secondaryValue = get(data, "datasets.1.average", 0);
              chart.otherValue = get(data, "datasets.2.average", 0);
            }

            if (get(data, "datasets.0.value_type") === "money") {
              chart.primaryValue = helper.currency(chart.primaryValue);
            }
            if (get(data, "datasets.1.value_type") === "money") {
              chart.secondaryValue = helper.currency(chart.secondaryValue);
            }
            if (get(data, "datasets.2.value_type") === "money") {
              chart.otherValue = helper.currency(chart.otherValue);
            }

            chart.data.datasets = get(data, "datasets", []).map(
              (d: any, i: number) => {
                return {
                  ...cloneDeep(defaultDataset),
                  borderDash: i > 0 ? [2, 2] : [0, 0],
                  borderColor: datasetColors[i],
                  backgroundColor: datasetColors[i],
                  pointBorderColor: datasetColors[i],
                  label: get(d, "title") || "",
                  data: values(get(d, "data", {})),
                  type: get(d, "type") || get(defaultDataset, "type", "line"),
                  yAxisID: get(d, "yAxis") || "y",
                };
              }
            );
          });
        }
      },
    });

    const { filterState, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        from: clone(fromDate),
        to: clone(toDate),
        period: "month",
      },
    });

    const changeView = (value: string) => {
      activeView.value = value;
    };

    const applyFilter = () => {
      const whitelist = [];
      if (isSelectableYearPeriod.value) whitelist.push("year");
      if (isSelectableMonthPeriod.value) whitelist.push("month");
      if (isSelectableWeekPeriod.value) whitelist.push("week");
      if (isSelectableDayPeriod.value) whitelist.push("day");

      if (whitelist.indexOf(filterState.fields.period) === -1) {
        filterState.fields.period = get(whitelist, 0, "day");
      }

      filterFunctions.applyFilter();
    };

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
    });

    return {
      loading,
      activeView,
      dateRange,
      filterState,
      presetRanges,
      charts,
      isSelectableDayPeriod,
      isSelectableWeekPeriod,
      isSelectableMonthPeriod,
      isSelectableYearPeriod,
      changeView,
      applyFilter,
    };
  },
});
