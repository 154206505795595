
import { defineComponent, onMounted, onBeforeUnmount, ref } from "vue";
import { useFetchRecords } from "@/utils/records/fetch";
import { useFilterRecords } from "@/utils/records/filter";
import Pagination from "@/components/pagination/Main.vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import { useStore } from "@/store";
import { get, set } from "lodash";
import Clipboard from "@/components/clipboard/Main.vue";
import { helper } from "@/utils/helper";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import { useRouter } from "vue-router";
import VueSelect from "@/components/form-elements/select/Main.vue";
import VueModal from "@/components/modal/Main.vue";
import FilterButton from "@/components/filter-container/Button.vue";
import VueDatetimePicker from "@/components/form-elements/datetime-picker/Main.vue";

export default defineComponent({
  name: "Orders",
  components: {
    VueDatetimePicker,
    FilterButton,
    VueModal,
    VueSelect,
    VueSelect2,
    Clipboard,
    Tippy,
    VueInput,
    VueInputContainer,
    FilterContainer,
    Pagination,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const stateModalId = helper.uuidv4();

    const statesGroup = {
      pending_payment: {
        value: ["PENDING_PAYMENT"],
        count: 0,
      },
      in_process: {
        value: [
          "NEW_ORDER",
          "PREPARING",
          "COMPLETION_NOTIFICATION",
          "DELIVERED",
          "OBJECTION",
          "SHIPPING",
        ],
        count: 0,
      },
      completed: { value: ["COMPLETED"], count: 0 },
      cancelled: {
        value: ["CANCELLED", "CANCEL_REQUEST", "REFUND_REQUEST"],
        count: 0,
      },
      custom: { value: [], count: 0 },
    };

    const formattedList = ref<Record<string, any>[]>([]);

    const { loading, list, fetch } = useFetchRecords({
      selectionQuery: () => {
        const where: Record<string, any> = {
          status: get(
            statesGroup,
            `${filterState.fields.active_state}.value`,
            []
          ),
        };
        if (!store.getters["auth/isMerchant"]) {
          set(where, "site", store.getters["system/site"]);
        }
        if (filterState.fields.active_state === "custom") {
          where.status = filterState.fields.status;
        }
        return {
          source: "order_group",
          config: {
            "@paginate": 30,
            "@page": 1,
            "@select": {
              site: "site",
              status: "status",
              order_group_id: "order_group_id",
              customer_id: "customer_id",
              merchant_id: "merchant_id",
              shipping_date: "shipping_date",
              cargo_id: "cargo_id",
              cargo_tracking_number: "cargo_tracking_number",
              created_at: "created_at",
              shipping_address: "shipping_address",
            },
            "@order": "id:desc",
            "@func": ["withCount"],
            "@where": {
              ...where,
            },
            merchant: {
              "@select": ["title", "commission_rate"],
            },
            customer: {
              "@select": ["name", "surname", "email"],
            },
          },
          filterFormat: {
            q: "@where.q",
            order_group_id: "@where.order_group_id",
            merchant_id: "@where.merchant_id",
            email: "@whereHas.customer._email",
            recipient: "@customWhere.recipient",
            recipient_phone: "@customWhere.recipient_phone",
            form_id: "@customWhere.form_id",
            date_range: "@customWhere.date_range",
          },
        };
      },
      success() {
        set(
          statesGroup,
          `${filterState.fields.active_state}.count`,
          list.meta.total || 0
        );
        formattedList.value = list.data.map((item: any) => {
          const { days, hours, minutes } = helper.diffTimeByNow(
            get(item, "estimated_shipping_date")
          );

          const statusInfo = store.getters["system/definition"](
            "order_states",
            item.status,
            null
          );
          return {
            ...item,
            status_bg: get(statusInfo, "background"),
            status_text_color: helper.invertColor(
              get(statusInfo, "background")
            ),
            status_title: get(statusInfo, "text"),
            estimated_shipping_days: days,
            estimated_shipping_hours: hours,
            estimated_shipping_minutes: minutes,
          };
        });
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        order_group_id: "",
        merchant_id: "",
        email: "",
        recipient: "",
        recipient_phone: "",
        form_id: "",
        date_range: "",
        active_state: "in_process",
        status: [],
      },
    });

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const goToDetail = (id: any) => {
      router.push({ name: "orders.detail", params: { id } });
    };

    const cargoURL = (order: any) => {
      const trackingNumber = get(order, "cargo_tracking_number");
      const cargoId = get(order, "cargo_id");
      const web = store.getters["system/definition"](
        "cargo_firms",
        cargoId,
        "web"
      );
      return `${web}${trackingNumber}`;
    };

    const cargoPhoto = (order: any) => {
      const cargoId = get(order, "cargo_id");
      return store.getters["system/definition"](
        "cargo_firms",
        cargoId,
        "photo"
      );
    };

    const getFormTitle = (formIds: string): string[] => {
      const titles: string[] = [];

      formIds
        .toString()
        .split(",")
        .map((id) => {
          const title = store.getters["order/form"](id, "title");
          if (title) titles.push(title);
        });

      return titles;
    };

    const changeStates = (state: string) => {
      filterState.fields.active_state = state;
      filterState.fields.status = [];
      filterFunctions.applyFilter();
    };

    onBeforeUnmount(() => {
      if (cash(".side-nav").hasClass("side-nav--simple"))
        cash(".side-nav").removeClass("side-nav--simple");
    });

    onMounted(() => {
      if (!cash(".side-nav").hasClass("side-nav--simple"))
        cash(".side-nav").addClass("side-nav--simple");

      fetch(filterFunctions.formattedQuery());
    });

    return {
      stateModalId,
      loading,
      list,
      formattedList,
      filterState,
      filterFunctions,
      merchantSelect2Options,
      filterCount,
      statesGroup,
      getFormTitle,
      goToDetail,
      cargoPhoto,
      cargoURL,
      changeStates,
    };
  },
});
