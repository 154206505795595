
import { defineComponent, onMounted, ref } from "vue";
import { useFetchRecords } from "@/utils/records/fetch";
import { useFilterRecords } from "@/utils/records/filter";
import Pagination from "@/components/pagination/Main.vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { helper } from "@/utils/helper";
import VueModal from "@/components/modal/Main.vue";
import FilterButton from "@/components/filter-container/Button.vue";
import Clipboard from "@/components/clipboard/Main.vue";

export default defineComponent({
  name: "UsersList",
  components: {
    Clipboard,
    FilterButton,
    VueModal,
    VueInput,
    VueInputContainer,
    FilterContainer,
    Pagination,
  },
  setup() {
    const modalRefId = helper.uuidv4();
    const detail = ref<any>(null);

    const { loading, list, fetch } = useFetchRecords({
      selectionQuery: {
        source: "application",
        config: {
          "@page": 1,
          "@select": {
            name_surname: "name_surname",
            email: "email",
            phone: "phone",
            title: "title",
            application_type: "application_type",
            type: "type",
            company_commercial_title: "company_commercial_title",
            tax_number: "tax_number",
            status: "status",
            address: "address",
            city: "city",
            district: "district",
            web: "web",
            created_at: "created_at",
          },
          "@order": "id:desc",
          "@func": ["withCount"],
        },
        filterFormat: {
          q: "@where.q",
          id: "@where.id",
          title: "@where._title",
          email: "@where._email",
          phone: "@where._phone",
        },
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        id: "",
        title: "",
        email: "",
        phone: "",
      },
    });

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
    });

    const openDetail = (row: any) => {
      detail.value = row;
      cash(`#${modalRefId}`).modal("show");
    };

    return {
      modalRefId,
      detail,
      loading,
      list,
      filterState,
      filterFunctions,
      filterCount,
      openDetail,
    };
  },
});
