
import { computed, defineComponent, onBeforeMount, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { MaterialProductInterface, OrderFormInterface } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import VueSelect from "@/components/form-elements/select/Main.vue";
import { useStore } from "@/store";
import { cloneDeep, findIndex, get } from "lodash";
import { Select2Options } from "@/components/form-elements/select2";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import VueColorPicker from "@/components/form-elements/color/Main.vue";

export default defineComponent({
  name: "MaterialSalesInformation",
  components: {
    VueColorPicker,
    VueSelect2,
    VueSelect,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => MaterialProductInterface,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
    formId: {
      default: () => null,
    },
    formLineId: {
      default: () => null,
    },
    firstWhite: {
      type: Boolean,
      default: () => false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const formSchemas = store.getters["order/forms"];

    const editableQuery = reactive<MaterialProductInterface>(
      toRaw(props.query)
    );

    const visibleMerchantPrice = computed(() => {
      let response = false;
      store.state.system.sites.map((o) => {
        if (
          editableQuery.merchant_id &&
          get(o, "merchant_id", "0") * 1 === editableQuery.merchant_id * 1
        ) {
          response = get(o, "use_merchant_price") || false;
        }
      });
      return response;
    });

    const selectedForm = computed<OrderFormInterface>(() => {
      const index = findIndex(formSchemas, { id: props.formId });
      return get(formSchemas, `${index}`);
    });

    const priceCalculationRules = computed(() => {
      const rows: Array<{ id: any; title: any; select2: Select2Options }> = [];
      if (selectedForm.value) {
        selectedForm.value.lines.data.map((line) => {
          if (line.type === "rules") {
            rows.push({
              id: line.id,
              title: line.title,
              select2: {
                data: line.options.map((o) => ({ id: o.value, text: o.text })),
                multiple: true,
              },
            });
          }
        });
      }
      return rows;
    });

    onBeforeMount(() => {
      if (selectedForm.value) {
        const priceCalculationRuleFields: Record<string, any> = {};
        selectedForm.value.lines.data.map((line) => {
          if (line.type === "rules") {
            priceCalculationRuleFields[`l${line.id}`] =
              get(editableQuery, `price_calculation_rules.l${line.id}`) || "";
          }
        });
        editableQuery.price_calculation_rules = cloneDeep(
          priceCalculationRuleFields
        );
      }
    });

    return {
      editableQuery,
      priceCalculationRules,
      visibleMerchantPrice,
    };
  },
});
