import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState } from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_CATEGORIES](state: S, categories: any[]): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_CATEGORIES](state: LocalState, categories: any[]) {
    state.categories = categories;
  },
};
