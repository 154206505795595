
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import { GetUser, GetUserResult, userService } from "@/services";
import PersonalInformation from "@/views/user/create/components/PersonalInformation.vue";
import UserSideNavigation from "@/views/user/create/components/SideNavigation.vue";
import ChangePassword from "@/views/user/create/components/ChangePassword.vue";
import UserMerchantInformation from "@/views/user/create/components/MerchantInformation.vue";
import UserActiveDevices from "@/views/user/create/components/ActiveDevices.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "UserCreateOrUpdate",
  components: {
    UserActiveDevices,
    UserMerchantInformation,
    ChangePassword,
    UserSideNavigation,
    PersonalInformation,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const activeView = ref<string>("personal-information");

    const id = computed<any>(() => get(route.params, "id"));
    const routeView = computed<string>(
      () => get(route.query, "view", "") as string
    );

    const isMyProfile = computed(() => {
      return id.value * 1 === store.getters["auth/profile"].id * 1;
    });

    const canChangePassword = computed(() => {
      return isMyProfile.value || store.getters["auth/isAdmin"];
    });

    const canEdit = computed(() => {
      return (
        isMyProfile.value ||
        store.getters["auth/hasPermissionWithSiteAdmin"](route.name)
      );
    });

    const {
      data: userDetail,
      setData,
      loading,
      fetch,
    } = useReadRecord<GetUser, GetUserResult>({
      id: id.value,
      serviceMethod: userService.show,
      fail() {
        router.go(-1);
      },
    });

    const changeView = (view: string) => {
      if (
        [
          "personal-information",
          "change-password",
          "merchant-information",
          "active-devices",
        ].indexOf(view) > -1
      ) {
        activeView.value = view;
      }
    };

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    watch(routeView, (val) => changeView(val), { flush: "post" });
    onMounted(() => {
      changeView(routeView.value);
      if (id.value) fetch(id.value);
    });

    return {
      loading,
      activeView,
      userDetail,
      changeView,
      fetch,
      setData,
      canChangePassword,
      canEdit,
      isMyProfile,
    };
  },
});
