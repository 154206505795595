
import { computed, defineComponent, inject, Ref, ref } from "vue";
import { FormLine, MaterialCategoryInterface } from "@/services";
import VueModal from "@/components/modal/Main.vue";
import { helper } from "@/utils/helper";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { useSubmitRecord } from "@/utils/records/submit";
import { get, set } from "lodash";
import VueSelect from "@/components/form-elements/select/Main.vue";
import { useStore } from "@/store";
import VueTiptap from "@/components/tiptap/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import { QuickPageInterface } from "@/components/quick-page";
import QuickPage from "@/components/quick-page/Main.vue";
import { FileManagerRef } from "@/components/file-manager";
import Draggable from "vuedraggable";

export default defineComponent({
  name: "OrderFormLineEditModal",
  components: {
    QuickPage,
    VueSelect2,
    VueTiptap,
    VueSelect,
    VueInput,
    VueInputContainer,
    VueModal,
    Draggable,
  },
  props: {
    lines: {
      type: Array as () => FormLine[],
      default: () => [],
    },
    save: {
      type: Function,
      default: () => null,
    },
  },
  setup(props) {
    const store = useStore();

    const quickPageRef = ref<QuickPageInterface>();
    const modalRefId = helper.uuidv4();
    const selected = ref(-1);

    const { query, validationErrors, submit, setQuery, reset } =
      useSubmitRecord<FormLine, any>({
        query: {
          title: "",
          show_all_selections: 0,
          required: 0,
          select_first: 0,
          master: 0,
          min_value: 0,
          form_description: "",
          description: "",
          code: "",
          type: "",
          material_category_id: null,
          variables: [],
          options: [],
        },
        storeServiceMethod: async (params) => {
          return await props.save(selected.value, params);
        },
        success: () => close(),
      });

    const materialCategorySelect2Options: Select2Options = {
      ajaxQuery: {
        material_category: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const disabledRequiredField = computed(() => {
      return isMaster.value || get(query.value, "select_first", "0") * 1 === 1;
    });

    const isMaster = computed(() => {
      return get(query.value, "master", "0") * 1 === 1;
    });

    const visibleOptions = computed(() => {
      return (
        ["options", "opening", "openingside"].indexOf(
          get(query.value, "type")
        ) > -1
      );
    });

    const visibleFormOptions = computed(() => {
      return ["forms"].indexOf(get(query.value, "type")) > -1;
    });

    const formSelect2Options: Select2Options = {
      ajaxQuery: {
        form: {
          "@select": {
            value: "id",
            text: "title",
          },
          "@order": "sq:asc",
          "@where": {
            is_fixed: 3,
          },
        },
      },
    };

    const open = (index = -1) => {
      selected.value = index;
      reset();
      setQuery(get(props.lines, index, {}));
      cash(`#${modalRefId}`).modal("show");
    };

    const close = () => {
      selected.value = -1;
      reset();
      cash(`#${modalRefId}`).modal("hide");
    };

    const changeTypeField = () => {
      if (query.value.type !== "product") {
        query.value.master = 0;
        query.value.material_category_id = null;
      }
      if (query.value.type === "openingside") {
        query.value.options = [
          {
            uuid: helper.uuidv4(),
            value: "left",
            text: "Sol",
            photo: "",
          },
          {
            uuid: helper.uuidv4(),
            value: "right",
            text: "Sağ",
            photo: "",
          },
          {
            uuid: helper.uuidv4(),
            value: "both",
            text: "Her İki Yana",
            photo: "",
          },
        ];
      }
      if (query.value.type === "forms") {
        query.value.options = [];
      }
      resetCode();
    };

    const changeMasterField = () => {
      if (get(query.value, "master", "0") * 1 === 1) {
        query.value.show_all_selections = 0;
      }
      checkRequiredField();
    };

    const resetCode = () => {
      query.value.code = store.getters["system/definition"](
        "form_line_types",
        query.value.type,
        "code"
      );
      if (!query.value.title) {
        query.value.title = store.getters["system/definition"](
          "form_line_types",
          query.value.type,
          "text"
        );
      }
    };

    const checkRequiredField = () => {
      if (
        get(query.value, "master", "0") * 1 === 1 ||
        get(query.value, "select_first", "0") * 1 === 1
      ) {
        query.value.required = 1;
      }
    };

    const openMaterialCategoryQuickPage = () => {
      if (quickPageRef.value) {
        quickPageRef.value.open({
          id: query.value.material_category_id,
          data: {
            title: query.value.title,
          },
          pageKey: "material-category-create",
          callback: (result: MaterialCategoryInterface) => {
            query.value.material_category_id = result.id;
          },
        });
      }
    };

    const addOption = () => {
      query.value.options.push({
        uuid: helper.uuidv4(),
        value: "",
        text: "",
        photo: "",
      });
    };

    const removeOption = (index: number) => {
      query.value.options.splice(index, 1);
    };

    const fileManagerRef = inject<Ref<FileManagerRef>>(`fileManagerRef`);
    const openFileManager = (option: any) => {
      set(option, "photo", "");
      fileManagerRef?.value?.show({
        callback: (files) => {
          const paths = files.map((file) => file.path);
          if (paths.length > 0) {
            set(option, "photo", get(paths, 0));
          }
        },
      });
    };

    return {
      quickPageRef,
      modalRefId,
      query,
      validationErrors,
      disabledRequiredField,
      isMaster,
      materialCategorySelect2Options,
      visibleOptions,
      visibleFormOptions,
      formSelect2Options,
      open,
      close,
      submit,
      resetCode,
      checkRequiredField,
      changeTypeField,
      changeMasterField,
      openMaterialCategoryQuickPage,
      addOption,
      removeOption,
      openFileManager,
    };
  },
});
