
import { defineComponent, onMounted, reactive, ref } from "vue";
import Tippy from "@/components/tippy/Main.vue";
import { useFetchRecords } from "@/utils/records/fetch";
import Pagination from "@/components/pagination/Main.vue";
import { useFilterRecords } from "@/utils/records/filter";
import VueInput from "@/components/form-elements/input/Main.vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import FMListLayoutItem from "@/views/file-manager/components/ListLayoutItem.vue";
import { useDeleteRecord } from "@/utils/records/delete";
import { fileService } from "@/services";
import { cloneDeep, findIndex } from "lodash";
import EditFile from "@/views/file-manager/components/EditFile.vue";
import UploadFiles from "@/views/file-manager/components/UploadFiles.vue";
import FilterButton from "@/components/filter-container/Button.vue";
import { helper } from "@/utils/helper";
import { FileListExcel } from "@/utils/excel/file-list";
import { FileDownloadList } from "@/views/file-manager/download-list";

export default defineComponent({
  name: "FileManager",
  components: {
    FilterButton,
    UploadFiles,
    EditFile,
    FMListLayoutItem,
    VueInputContainer,
    FilterContainer,
    VueInput,
    Pagination,
    Tippy,
  },
  props: {
    callback: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const preparingExcel = ref<boolean>(false);
    const state = reactive<Record<string, any>>({
      layout: "list",
      edit: -1,
    });

    const fetchState = useFetchRecords({
      selectionQuery: {
        source: "file",
        config: {
          "@page": 1,
          "@paginate": 60,
          "@select": {
            id: "id",
            name: "name",
            tags: "tags",
            merchant_id: "merchant_id",
            extension: "extension",
            size: "size",
            width: "width",
            height: "height",
            ratio: "ratio",
            path: "path",
            updated_at: "updated_at",
          },
          "@order": "id:desc",
          "@func": ["withCount"],
        },
        filterFormat: {
          q: "@where.q",
        },
        withoutQueue: true,
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch: fetchState.fetch,
      withoutRoute: true,
      fields: {
        q: window.localStorage.getItem("fmLastSearch") || "",
      },
    });

    const { deleteState, triggerDelete, removeDeleteError } = useDeleteRecord({
      serviceMethod: fileService.destroy,
      massServiceMethod: fileService.massDestroy,
      success(id: any) {
        const index = findIndex(fetchState.list.data, { id });
        if (index > -1) fetchState.list.data.splice(index, 1);
      },
    });

    onMounted(() => {
      fetchState.fetch(filterFunctions.formattedQuery()).then();
    });

    const openEditModal = (index = -1) => {
      state.edit = index;
    };

    const selectFiles = () => {
      const files = fetchState.list.data.filter(
        (o) => fetchState.selectedRows.value.indexOf(o.id) > -1
      );
      props.callback(cloneDeep(files));
    };

    const downloadToExcel = async (allDownload = false) => {
      preparingExcel.value = true;
      setTimeout(async () => {
        let rows = cloneDeep(fetchState.list.data).filter(
          (o) => fetchState.selectedRows.value.indexOf(o.id) > -1
        );

        if (allDownload) {
          rows = await FileDownloadList(
            fetchState.getFilterValues(filterFunctions.formattedQuery())
          );
        }

        const filename = `dosyalariniz_${helper.formatDate(
          new Date(),
          "DD_MM_YYYY_HH_mm"
        )}`;

        const productGroupExcel = new FileListExcel();
        await productGroupExcel.process();
        productGroupExcel.setRows(rows, "primary");
        await productGroupExcel.export(filename);

        preparingExcel.value = false;
      });
    };

    const applyFilter = () => {
      window.localStorage.setItem("fmLastSearch", filterState.fields.q || "");
      filterFunctions.applyFilter();
    };

    const clearFilter = () => {
      window.localStorage.removeItem("fmLastSearch");
      filterFunctions.clearFilter();
    };

    return {
      state,
      loading: fetchState.loading,
      selectAll: fetchState.selectAll,
      selectedRows: fetchState.selectedRows,
      list: fetchState.list,
      deleteState,
      filterState,
      filterFunctions,
      filterCount,
      preparingExcel,
      applyFilter,
      clearFilter,
      triggerDelete,
      removeDeleteError,
      openEditModal,
      selectFiles,
      downloadToExcel,
    };
  },
});
