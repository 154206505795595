
import { computed, defineComponent, ref } from "vue";
import { get, has, isString, cloneDeep } from "lodash";
import { helper } from "@/utils/helper";
import { debounce } from "debounce";

export default defineComponent({
  name: "VueInput",
  props: {
    modelValue: { default: () => "" },
    modelModifiers: { default: () => ({}) },
    type: { type: String, default: () => "text" },
    hasError: {
      type: Boolean,
      default: () => false,
    },
    counter: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const inputRef = ref<HTMLInputElement>();

    const stringLength = computed<number>(() => {
      if (props.modelValue) return props.modelValue.toString().length;
      return 0;
    });

    let changeValue = (e: any): void => {
      let str: string = cloneDeep(get(e, "target.value", ""));
      if (has(props.modelModifiers, "capitalize") && isString(str)) {
        str = helper.capitalizeEachWords(str);
      }
      if (has(props.modelModifiers, "uppercase") && isString(str)) {
        str = helper.upperCase(str);
      }
      emit("update:modelValue", str);
    };

    if (has(props.modelModifiers, "lazy")) {
      changeValue = debounce(changeValue, 500);
    }

    const onKeypress = (e: KeyboardEvent) => {
      if (!has(props.modelModifiers, "numeric")) return;
      const keysAllowed: string[] = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ".",
      ];
      const keyPressed: string = e.key;

      if (!keysAllowed.includes(keyPressed)) {
        e.preventDefault();
      }
    };

    const onFocus = () => {
      if (
        inputRef.value &&
        inputRef.value?.type !== "email" &&
        typeof inputRef.value.setSelectionRange === "function"
      ) {
        inputRef.value?.setSelectionRange(0, stringLength.value);
      }
    };

    return {
      changeValue,
      onKeypress,
      onFocus,
      stringLength,
      inputRef,
    };
  },
});
