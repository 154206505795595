import {
  GeneralApiResponse,
  GetMaterialCategoryResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const materialCategoryService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetMaterialCategoryResult>>(
      `/api/material-categories/${id}`
    ),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetMaterialCategoryResult>>(
      `/api/material-categories`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetMaterialCategoryResult>>(
      `/api/material-categories/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(
      `/api/material-categories/${id}`
    ),
};
