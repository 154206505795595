import {
  GeneralApiResponse,
  GetAccountingReportResult,
  GetDetectRelationsResult,
  GetFicheResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const ficheService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetFicheResult>>(`/api/fiches/${id}`),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetFicheResult>>(`/api/fiches`, params),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetFicheResult>>(
      `/api/fiches/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/fiches/${id}`),

  detectRelations: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetDetectRelationsResult>>(
      `/api/fiches/detect-relations`,
      params
    ),

  accountingReport: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetAccountingReportResult>>(
      `/api/fiches/accounting-report`,
      params
    ),

  commissionInvoiceReport: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetAccountingReportResult>>(
      `/api/fiches/report/commission-invoice`,
      params
    ),

  receiptReport: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetAccountingReportResult>>(
      `/api/fiches/report/receipt`,
      params
    ),
};
