<template>
  <node-view-wrapper class="t-accordion-item">
    <div class="t-accordion-header flex items-center" contenteditable="false">
      <div class="flex-shrink-0 flex">
        <a
          href="javascript:;"
          class="tiptap-accordion-button drag-handle"
          draggable="true"
          data-drag-handle
        >
          <i class="feather-move text-xl"></i>
        </a>
        <a
          href="javascript:;"
          class="tiptap-accordion-button text-red-500"
          @click="deleteItem"
          v-if="isEnabled"
        >
          <i class="feather-trash-2 text-xl"></i>
        </a>
        <a
          href="javascript:;"
          class="tiptap-accordion-button"
          @click="toggleVisible"
        >
          <i
            class="feather-arrow-right text-2xl inline-block transition-all ease-in-out"
            :class="{ 'transform rotate-90': !!isVisible }"
          ></i>
        </a>
      </div>
      <VueSelect2
        class="flex-1 rounded-none border-0 focus:ring-0"
        v-model="content"
        :options="contentSelect2Options"
        @update:selected-item="(val) => changeContent(val)"
        :disabled="!isEnabled"
      />
    </div>
    <div class="t-accordion-collapse grid gap-3" v-if="isVisible">
      <VueInputContainer class="col-span-full">
        <template v-slot:label>Görsel</template>
        <FileManagerSelect v-model="photo" :disabled="!isEnabled" />
      </VueInputContainer>
      <VueInputContainer class="col-span-full">
        <template v-slot:label>
          <div class="flex items-center leading-none">
            <span>Görsel (Telefonlar İçin)</span>
            <Tippy
              class="ml-1.5"
              content="Telefonlar için farklı bir resim göstermek için burayı doldurunuz."
            >
              <i class="feather-help-circle text-xl"></i>
            </Tippy>
          </div>
        </template>
        <FileManagerSelect v-model="photo_mobile" :disabled="!isEnabled" />
      </VueInputContainer>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-3";
import { getNodeType } from "@tiptap/core/src/helpers/getNodeType";
import VueInputContainer from "@/components/form-elements/input-container/Main";
import VueSelect2 from "@/components/form-elements/select2/Main";
import FileManagerSelect from "@/components/file-manager/Select";
import Tippy from "@/components/tippy/Main";
import { get } from "lodash";
export default {
  name: "TiptapVueAccordion",
  components: {
    Tippy,
    FileManagerSelect,
    VueSelect2,
    VueInputContainer,
    NodeViewWrapper,
  },
  props: nodeViewProps,
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    isEnabled() {
      return get(this.editor, "options.editable") || false;
    },
    content: {
      get() {
        return get(this.node, "attrs.content") || "";
      },
      set(value) {
        this.updateAttributes({
          content: value || "",
        });
      },
    },
    content_url: {
      get() {
        return get(this.node, "attrs.content_url") || "";
      },
      set(value) {
        this.updateAttributes({
          content_url: value || "",
        });
      },
    },
    photo: {
      get() {
        return get(this.node, "attrs.photo") || "";
      },
      set(value) {
        this.updateAttributes({
          photo: value || "",
        });
      },
    },
    photo_mobile: {
      get() {
        return get(this.node, "attrs.photo_mobile") || "";
      },
      set(value) {
        this.updateAttributes({
          photo_mobile: value || "",
        });
      },
    },
    contentSelect2Options() {
      return {
        placeholder: "Tıklanıldığında Açılacak İçerik Sayfası",
        ajaxQuery: {
          content: {
            "@select": {
              value: "id",
              text: "title",
              type: "type",
              parent: "parent",
              slug: "slug",
              header_slug: "header_slug",
            },
            "@func": ["withUrlSlug"],
            "@page": 1,
          },
        },
      };
    },
  },
  methods: {
    toggleVisible() {
      if (!this.isVisible) this.visibleState();
      else this.isVisible = false;
    },
    visibleState() {
      this.isVisible = true;
      this.$nextTick(() => {
        this.editor.commandManager
          .chain()
          .setNodeSelection(this.getPos())
          .focus()
          .run();
      });
    },
    deleteItem() {
      this.editor.commandManager
        .chain()
        .setNodeSelection(this.getPos())
        .focus()
        .run();

      let permission = true;
      const state = this.editor.state;
      const type = getNodeType("imageSlider", state.schema);
      const $pos = state.tr.selection.$anchor;

      for (let depth = $pos.depth; depth > 0; depth -= 1) {
        const node = $pos.node(depth);

        if (node.type === type && node.childCount < 2) {
          permission = false;
        }
      }

      if (permission) {
        this.deleteNode();
      }
    },
    changeContent(content) {
      const url = get(content, "url_slug");
      if (url !== this.node.attrs.content_url) {
        this.updateAttributes({
          content_url: url,
        });
        this.visibleState();
      }
    },
  },
};
</script>
