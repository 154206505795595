
import { defineComponent } from "vue";
import VuePassword from "@/components/form-elements/password/Main.vue";
import { useSubmitRecord } from "@/utils/records/submit";
import {
  GeneralApiResponse,
  GetUser,
  PostChangePassword,
  userService,
} from "@/services";
import { ToastNotify } from "@/utils/toast-notify";
import VueButton from "@/components/form-elements/button/Main.vue";

export default defineComponent({
  name: "ChangePassword",
  components: { VueButton, VuePassword },
  props: {
    userDetail: {
      type: Object as () => GetUser,
      default: () => ({}),
    },
  },
  setup(props) {
    const { query, processing, validationErrors, submit } = useSubmitRecord<
      PostChangePassword,
      GeneralApiResponse<any>
    >({
      query: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      storeServiceMethod: (params: any) =>
        userService.changePassword(props.userDetail.id, params),
      success(result) {
        if (result?.kind === "ok") {
          ToastNotify({
            className: "success",
            text: "Şifre Değiştirildi!",
          });
        }
      },
    });

    const onSubmit = () => submit();

    return {
      query,
      processing,
      validationErrors,
      onSubmit,
    };
  },
});
