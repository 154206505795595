import { jsPDF } from "jspdf";
import JsBarcode from "jsbarcode";
// import { callAddFont } from "./helvetica-tr-normal";
import { cloneDeep, get, set, sumBy, sortBy } from "lodash";
import { helper } from "../helper";
import NunitoRegular from "@/assets/fonts/Nunito-Regular.js";
// import RalewayRegular from "@/assets/fonts/Raleway-Regular.js";

import KEYS from "./keys";

export default function (config) {
  const instance = new CreatePdf(config);
  return {
    prepare: () => instance.prepare(),
    save: () => instance.save(),
    output: () => instance.output(),
  };
}

class CreatePdf {
  constructor(config) {
    const { templateSettings = {}, pages = [] } = cloneDeep(config);
    this.templateSettings = templateSettings;
    this.elements = [];
    this.data = cloneDeep(config.data);
    this.keys = get(cloneDeep(KEYS), [templateSettings.source, "children"], []);
    const width =
      get(pages, "0.width", 0) * this.templateSettings.mmToPxMultiplier;
    const height =
      get(pages, "0.height", 0) * this.templateSettings.mmToPxMultiplier;
    let format = get(pages, "0.pageType", "A4");
    if (format === "custom") {
      format = [width, height];
      set(pages, "0.orientation", width > height ? "landscape" : "portrait");
    }
    const newPages = [];
    pages.map((page) => {
      if (page.create_each_lines * 1 === 1) {
        const lines = get(this.data, "lines", []);
        let lineNumber = 1;
        lines.map((line, lineIndex) => {
          for (let i = 0; i < line.count; i++) {
            newPages.push({
              ...cloneDeep(page),
              data_line_index: cloneDeep(lineIndex),
              line_number: lineNumber++,
            });
          }
        });
      } else {
        newPages.push(cloneDeep(page));
      }
    });
    this.pages = newPages;
    this.doc = new jsPDF({
      unit: "px",
      format,
      orientation: get(pages, "0.orientation", "") || "portrait",
    });

    this.doc.addFileToVFS("Nunito-Regular.ttf", NunitoRegular);
    this.doc.addFont("Nunito-Regular.ttf", "Nunito-Regular", "normal");
    this.doc.setFont("Nunito-Regular");

    // this.doc.addFileToVFS("Raleway-Regular.ttf", RalewayRegular);
    // this.doc.addFont("Raleway-Regular.ttf", "Raleway-Regular", "normal");
    // this.doc.setFont("Raleway-Regular");

    this.currentPageIndex = 0;
    this.copyPageNumber = 1;
    this.howToCreatePageCount = 1;
  }

  getPage() {
    return get(this.pages, [this.currentPageIndex], null);
  }

  getData(page = null) {
    if (!page) page = this.getPage();
    if (page.create_each_lines * 1 === 1) {
      return get(this.data, `lines.${page.data_line_index}`, null);
    }
    return this.data;
  }

  prepare() {
    return new Promise((ok) => {
      this.createPages()
        .then(() => ok())
        .catch((e) => {
          console.error(e);
        });
    });
  }

  createPages() {
    return new Promise((ok) => {
      const page = this.getPage();
      if (!page) return null;
      this.elements = cloneDeep(page.elements);
      this.elements = sortBy(this.elements, (o) => o.backgroundBox);
      this.fillTheElementsData();
      this.howToCreatePageCount = this.howToCreatePage(
        page.height * this.templateSettings.mmToPxMultiplier
      );
      this.preparePlacementElement();
      this.copyPageNumber = 1;
      this.copyCreatePage()
        .then(() => {
          if (++this.currentPageIndex < this.pages.length)
            this.createPages()
              .then(() => ok())
              .catch((e) => {
                console.error(e);
              });
          else ok();
        })
        .catch((e) => {
          console.error(e);
        });
    });
  }

  copyCreatePage() {
    return new Promise((ok) => {
      const page = get(this.pages, [this.currentPageIndex], null);
      if (!page) return ok();
      if (this.currentPageIndex > 0 || this.copyPageNumber > 1) {
        const width =
          get(page, "width", 0) * this.templateSettings.mmToPxMultiplier;
        const height =
          get(page, "height", 0) * this.templateSettings.mmToPxMultiplier;
        let format = get(page, "pageType", "A4");
        if (format === "custom") {
          format = [width, height];
          set(page, "orientation", width > height ? "landscape" : "portrait");
        }
        this.doc.addPage(format, get(page, "orientation", "") || "portrait");
      }

      this.elements.map((element, elementIndex) => {
        this.prepareElement(element)
          .then(() => {
            if (this.elements.length === elementIndex + 1) {
              if (++this.copyPageNumber <= this.howToCreatePageCount)
                this.copyCreatePage()
                  .then(() => ok())
                  .catch((e) => {
                    console.error(e);
                  });
              else ok();
            }
          })
          .catch((e) => {
            console.error(e);
          });
      });
    });
  }

  regexFillData(str, replace) {
    const regex = new RegExp(
      "(?:{{[ ]*)([a-zA-Z0-9_İŞĞÜÖÇöçşğüı-]+|(calc)\\(([a-zA-Z0-9İŞĞÜÖÇöçşğüı_\\-()\\/\\=.<>'\",*+ ]+)\\))(?:[| ]*)([a-zA-Z0-9]*)(?:[ ]*}})",
      "gi"
    );

    let m;
    let newStr = cloneDeep(str);

    while ((m = regex.exec(str)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      let replacedVal = replace(m[1]);
      switch (m[4]) {
        case "date":
          replacedVal = helper.formatDate(replacedVal);
          break;
        case "money":
          replacedVal = helper.round(replacedVal, 2);
          break;
      }
      newStr = newStr.toString().replace(m[0], replacedVal);
    }
    return newStr;
  }

  getRealKey(key = "", keys = [], sourceKey = "") {
    let found = "";
    keys.map((item) => {
      if (item.key === key) {
        found = item.value;
        // console.log(found)
        if (typeof found === "function") {
          found = found(
            sourceKey ? get(this.getData(), sourceKey) : this.getData()
          );
          // console.log('function', found)
        } else if (item.content_replace) {
          found = found.replace(
            "{replace}",
            get(
              this.getData(),
              sourceKey
                ? `${sourceKey}.${item.content_replace.value}`
                : item.content_replace.value,
              ""
            )
          );
        }
        found = sourceKey ? `${sourceKey}.${found}` : found;
      }
      if (!found && item.children && Array.isArray(item.children)) {
        found = this.getRealKey(key, item.children, sourceKey);
      }
    });
    return found;
  }

  fillTheTableRow(row) {
    const sourceKeys = row.source.toString().split(".*.");
    const preparedRows = [];
    const recursive = (sourceKeyIndex, parentKey = "") => {
      if (parentKey) parentKey += "." + sourceKeys[sourceKeyIndex];
      if (!parentKey) parentKey = sourceKeys[sourceKeyIndex];
      let foundData = get(this.data, parentKey, []);
      if (sourceKeyIndex >= sourceKeys.length - 1) {
        return foundData.map((r, rIndex) => {
          r.sourceKey = cloneDeep(parentKey + "." + rIndex);
          return r;
        });
      }
      let rsp = [];
      foundData.map((item, itemIndex) => {
        rsp = [
          ...rsp,
          ...recursive(sourceKeyIndex + 1, parentKey + "." + itemIndex),
        ];
      });
      return rsp;
    };
    const linesForTable = recursive(0);
    linesForTable.map((line) => {
      const cloneRow = cloneDeep(row);
      cloneRow.columns.map((column) => {
        column.value = this.regexFillData(column.value, (key) => {
          let value = cloneDeep(
            get(
              this.getData(),
              `${this.getRealKey(key, this.keys, line.sourceKey)}`,
              "-"
            )
          );
          if (value === "-" && key === "line_size") {
            const sizes = [
              get(
                this.getData(),
                `${this.getRealKey("line_width", this.keys, line.sourceKey)}`,
                "-"
              ),
              get(
                this.getData(),
                `${this.getRealKey("line_height", this.keys, line.sourceKey)}`,
                "-"
              ),
            ].filter((o) => !!o);

            value = sizes.join("x");
          }
          if (key === "line_seq_no") value = value * 1 + 1;
          return value || "-";
        });
      });
      preparedRows.push(cloneDeep(cloneRow));
    });
    return preparedRows;
  }

  fillTheElementsData() {
    this.elements.map((element) => {
      let preparedRows = [];
      let isFixed = true;
      element.rows.map((row) => {
        if (element.isTable && row.source) {
          isFixed = false;
          preparedRows = [...preparedRows, ...this.fillTheTableRow(row)];
        } else {
          row.columns.map((column) => {
            column.value = this.regexFillData(column.value, (key) => {
              const data = key === "total_piece" ? this.data : this.getData();
              let value = cloneDeep(
                get(data, this.getRealKey(key, this.keys), "-")
              );
              if (value === "-" && key === "line_size") {
                const sizes = [
                  get(data, this.getRealKey("line_width", this.keys)),
                  get(data, this.getRealKey("line_height", this.keys)),
                ].filter((o) => !!o);

                value = sizes.join("x");
              }
              if (key === "line_seq_no") value = this.getPage().line_number;
              if (
                [
                  "total_vat_1",
                  "total_vat_8",
                  "total_vat_10",
                  "total_vat_18",
                  "total_vat_20",
                ].indexOf(key) > -1
              ) {
                let sumTotal = 0;
                const lines = get(this.data, "lines", []);
                lines.map((line) => {
                  const calculation = get(line, "calculation", []);
                  sumTotal += sumBy(calculation, (o) => {
                    if ("total_vat_1" === key && o.vat_rate * 1 === 1)
                      return o.total_vat * 1;
                    else if ("total_vat_8" === key && o.vat_rate * 1 === 8)
                      return o.total_vat * 1;
                    else if ("total_vat_10" === key && o.vat_rate * 1 === 10)
                      return o.total_vat * 1;
                    else if ("total_vat_18" === key && o.vat_rate * 1 === 18)
                      return o.total_vat * 1;
                    else if ("total_vat_20" === key && o.vat_rate * 1 === 20)
                      return o.total_vat * 1;
                    return 0;
                  });
                });
                value = sumTotal;
              }
              return value || "-";
            });
          });
          preparedRows.push(row);
        }
      });
      element.rows = preparedRows;
      element.isFixed = isFixed;
    });
  }

  howToCreatePage(pageHeight) {
    let maxPageCount = 1;
    this.elements.map((element) => {
      if (element.isTable && !element.isFixed) {
        const top = this.scaleDimension(get(element, `y`, 0));
        const elementMaxHeight = this.scaleDimension(
          get(element, "maxHeight", 0)
        );
        let maxHeight = pageHeight - top;
        if (elementMaxHeight > 0 && elementMaxHeight < maxHeight)
          maxHeight = elementMaxHeight;
        let tableHeight = 0;
        let prevPageCount = 1;
        const newRows = [];
        element.rows.map((row, rowIndex) => {
          tableHeight += this.scaleDimension(get(row, `height`, 0));
          let pageCount = Math.ceil(tableHeight / maxHeight);
          set(row, "showPageNumber", cloneDeep(pageCount));
          if (prevPageCount < pageCount) {
            newRows.push({
              ...element.rows[0],
              showPageNumber: cloneDeep(pageCount),
            });
          }
          newRows.push(row);
          prevPageCount = cloneDeep(pageCount);
        });
        element.rows = newRows;
        let pageCount = Math.ceil(tableHeight / maxHeight);
        if (pageCount > maxPageCount) maxPageCount = pageCount;
      }
    });
    return maxPageCount;
  }

  preparePlacementElement() {
    this.elements.map((element) => {
      if (element.placementPage === "first") element.showPageNumber = 1;
      else if (element.placementPage === "last")
        element.showPageNumber = this.howToCreatePageCount;
    });
  }

  prepareElement(element) {
    return new Promise((ok) => {
      if (
        !element.showPageNumber ||
        element.showPageNumber === this.copyPageNumber
      ) {
        if (!element.isTable) this.prepareTextBox(element);
        else this.prepareTable(element);
      }
      ok();
    });
  }

  scaleDimension(val = 0) {
    let format = get(this.getPage(), "pageType", "A4");
    if (format === "custom") return val * 1;
    return val / this.doc.internal.scaleFactor;
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  prepareBarcodeCell(column, height, left, top) {
    const width = this.scaleDimension(get(column, "width", 0));
    const text = get(column, "value", "");
    const canvas = document.createElement("canvas");
    canvas.width = width - 2;
    canvas.height = height - 2;
    JsBarcode(canvas, text, {
      height: height - 2,
      background: get(column, "backgroundColor", "#ffffff"),
      lineColor: get(column, "color", "#000000"),
      displayValue: false,
      margin: 3,
    });
    this.doc.addImage(
      canvas.toDataURL("image/jpeg"),
      "JPEG",
      left + 1,
      top + 1,
      width - 2,
      height - 2
    );
  }

  prepareCell(column, height, left, top) {
    const type = get(column, "type", "");
    let width = this.scaleDimension(cloneDeep(get(column, "width", 0)));
    const text = get(column, "value") || "";
    const border = get(column, "border", 1) * 1;
    const borderRadius = get(column, "borderRadius", 0) * 1;
    // const isBold = get(column, 'font.weight', 'normal') === 'bold'
    if (border === 1) {
      this.doc.setDrawColor(0, 0, 0);
    }
    const backgroundColor = this.hexToRgb(
      get(column, "backgroundColor", "#ffffff")
    );
    const color = this.hexToRgb(get(column, "color", "#000000"));
    this.doc.setFillColor(
      backgroundColor.r,
      backgroundColor.g,
      backgroundColor.b
    );
    if (borderRadius > 0) {
      this.doc.roundedRect(
        left,
        top,
        width,
        height,
        borderRadius,
        borderRadius,
        "F" + (border === 1 ? "D" : "")
      );
    } else {
      this.doc.rect(left, top, width, height, "F" + (border === 1 ? "D" : ""));
    }

    if (type === "barcode")
      return this.prepareBarcodeCell(column, height, left, top);

    height -= 2;
    width -= 2;
    top += 1;
    this.doc.setTextColor(color.r, color.g, color.b);

    const recursiveFitText = (fontSize) => {
      this.doc.setFontSize(fontSize);
      const lineHeight = this.doc.getLineHeight();
      let paddingTopForText = 0;
      const paddingLeftForText = 2;
      const textWidth = this.doc.getTextWidth(text);
      const textRowsCount = Math.ceil(textWidth / width);
      const textHeight = textRowsCount * lineHeight;
      let textTop = top;
      let textLeft = left + paddingLeftForText;
      if (get(column, "hAlign", "center") === "center") {
        textLeft = left + width / 2;
      }
      if (get(column, "hAlign", "center") === "right") {
        textLeft = left + width;
      }
      if (get(column, "vAlign", "middle") === "middle") {
        textTop += (height - textHeight) / 2;
        paddingTopForText = lineHeight * 0.5;
      }
      if (get(column, "vAlign", "middle") === "bottom") {
        textTop += height - textHeight;
        paddingTopForText = lineHeight;
      }
      if (textHeight > height + paddingTopForText)
        return recursiveFitText(fontSize - 0.5);
      return { textLeft, textTop, paddingTopForText, paddingLeftForText };
    };
    const { textLeft, textTop, paddingTopForText, paddingLeftForText } =
      recursiveFitText(this.scaleDimension(get(column, "font.size", 12)));

    this.doc.text(text, textLeft, textTop + paddingTopForText, {
      maxWidth: width,
      align: get(column, "hAlign", "center"),
      baseline: get(column, "vAlign", "middle"),
    });
  }

  prepareTextBox(element) {
    const top = this.scaleDimension(cloneDeep(get(element, `y`, 0)));
    let left = this.scaleDimension(cloneDeep(get(element, `x`, 0)));
    const height = this.scaleDimension(
      cloneDeep(get(element, `rows.0.height`, 0))
    );
    const columns = get(element, `rows.0.columns`, []);
    columns.map((column) => {
      const width = this.scaleDimension(get(column, "width", 0));
      this.prepareCell(column, height, left, top);
      left += width;
    });
  }

  prepareTable(element) {
    let top = this.scaleDimension(cloneDeep(get(element, `y`, 0)));
    let left = this.scaleDimension(cloneDeep(get(element, `x`, 0)));
    const rows = get(element, `rows`, []);
    rows.map((row) => {
      if (!row.showPageNumber || this.copyPageNumber === row.showPageNumber) {
        let ceilLeft = cloneDeep(left);
        const height = this.scaleDimension(get(row, "height", 0));
        row.columns.map((column) => {
          this.prepareCell(column, height, ceilLeft, top);
          ceilLeft += this.scaleDimension(get(column, "width", 0));
        });
        top += height;
      }
    });
  }

  save() {
    this.doc.save(this.templateSettings.title + ".pdf");
  }

  output() {
    const blob = new Blob([this.doc.output("blob")], {
      type: "application/pdf",
    });
    return URL.createObjectURL(blob);
  }
}
