import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState } from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_TOKEN](state: S, token: string): void;
  [MutationTypes.SET_PROFILE](state: S, profile: any): void;
  [MutationTypes.SET_SPLASH_SCREEN](state: S, data: boolean): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_TOKEN](state: LocalState, token: string) {
    state.token = token;
  },
  [MutationTypes.SET_PROFILE](state: LocalState, profile: any) {
    state.profile = profile;
  },
  [MutationTypes.SET_SPLASH_SCREEN](state: LocalState, data: boolean) {
    state.visibleSplashScreen = data;
  },
};
