
import { defineComponent, onBeforeUnmount, reactive, watch } from "vue";
import {useEditor, EditorContent, BubbleMenu, Extensions} from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Paragraph from "@tiptap/extension-paragraph";
import Heading from "@tiptap/extension-heading";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import BulletList from "@tiptap/extension-bullet-list";
import { Color } from "@tiptap/extension-color";
import { FontSize } from "./extensions/font-size";
import { Image } from "./extensions/image";
import TiptapBold from "@/components/tiptap/menus/Bold.vue";
import TiptapUnderline from "@/components/tiptap/menus/Underline.vue";
import TiptapStrike from "@/components/tiptap/menus/Strike.vue";
import TiptapTextAlign from "@/components/tiptap/menus/TextAlign.vue";
import TiptapParagraph from "@/components/tiptap/menus/Paragraph.vue";
import TiptapItalic from "@/components/tiptap/menus/Italic.vue";
import TiptapFontSize from "@/components/tiptap/menus/FontSize.vue";
import TiptapTextColor from "@/components/tiptap/menus/TextColor.vue";
import TiptapBulletList from "@/components/tiptap/menus/BulletList.vue";
import TiptapOrderedList from "@/components/tiptap/menus/OrderedList.vue";
import TiptapImage from "@/components/tiptap/menus/Image.vue";

export default defineComponent({
  name: "VueTiptap",
  components: {
    TiptapImage,
    TiptapOrderedList,
    TiptapBulletList,
    TiptapTextColor,
    TiptapFontSize,
    TiptapItalic,
    TiptapParagraph,
    TiptapTextAlign,
    TiptapStrike,
    TiptapUnderline,
    TiptapBold,
    EditorContent,
    BubbleMenu,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    extra: {
      type: Boolean,
      default: () => false,
    },
    image: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const linkState = reactive({
      value: "",
      show: false,
    });

    let extensions = [
      StarterKit.configure({
        paragraph: false,
        heading: false,
        listItem: false,
        orderedList: false,
        bulletList: false,
      }),
      Placeholder.configure({
        placeholder: ({ node }: any) => {
          if (node.type.name === "heading") {
            return "Lütfen buraya başlığınızı yazın...";
          }

          return "Lütfen buraya metninizi yazın...";
        },
      }),
      Underline,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Paragraph.configure({
        HTMLAttributes: {
          class: "tiptap-paragraph",
        },
      }),
      TextStyle,
    ];

    if (props.extra) {
      extensions = [
        ...extensions,
        BulletList.configure({
          HTMLAttributes: {
            class: "tiptap-bullet-list",
          },
        }),
        OrderedList.configure({
          HTMLAttributes: {
            class: "tiptap-ordered-list",
          },
        }),
        ListItem,
        Heading.configure({
          levels: [1, 2, 3],
          HTMLAttributes: {
            class: "tiptap-heading",
          },
        }),
        Color.configure({
          types: ["textStyle"],
        }),
        FontSize.configure({
          types: ["textStyle"],
        }),
        Link.configure({
          openOnClick: false,
          HTMLAttributes: {
            class: "tiptap-link",
          },
        }),
      ];
    }

    if (props.image) {
      extensions = [
        ...extensions,
        Image.configure({
          inline: true,
          HTMLAttributes: {
            class: "tiptap-image",
          },
        }),
      ];
    }

    const editor = useEditor({
      content: props.modelValue,
      extensions: extensions as Extensions,
      onUpdate: () => {
        emit("update:modelValue", editor.value?.getHTML());
      },
    });

    watch(
      () => props.modelValue,
      (value) => {
        const isSame = editor.value?.getHTML() === value;

        if (isSame) {
          return;
        }

        editor.value?.commands.setContent(value, false);
      }
    );

    const insertLinkOpenTippy = () => {
      linkState.show = true;
      linkState.value = editor.value?.getAttributes("link").href;
    };

    const insertLink = () => {
      if (!linkState.value) return unsetLink();
      editor.value?.commands.setLink({ href: linkState.value });
      editor.value?.commands.focus(editor.value?.state.selection.to);
      linkState.value = "";
      linkState.show = false;
    };

    const unsetLink = () => {
      editor.value?.commands.unsetLink();
      editor.value?.commands.focus(editor.value?.state.selection.to);
      linkState.value = "";
      linkState.show = false;
    };

    onBeforeUnmount(() => {
      editor.value?.destroy();
    });

    return {
      editor,
      linkState,
      insertLinkOpenTippy,
      insertLink,
      unsetLink,
    };
  },
});
