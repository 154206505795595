
import { onMounted, ref, inject, watch, defineComponent } from "vue";
import { helper as $h } from "@/utils/helper";
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  PieController,
  ArcElement,
} from "chart.js";

Chart.register(
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  ArcElement,
  PieController
);

export default defineComponent({
  name: "Chart",
  props: {
    type: {
      type: String as () => "line" | "pie" | "doughnut" | "bar",
      default: "line",
      validator: (value: string) => {
        return ["line", "pie", "doughnut", "bar"].indexOf(value) !== -1;
      },
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    width: {
      default: "100%",
    },
    height: {
      default: 0,
    },
    refKey: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const chartRef = ref();

    const init = () => {
      const canvas = chartRef.value?.getContext("2d");
      const chart = new Chart(canvas, {
        type: props.type,
        data: $h.toRaw(props.data),
        options: {
          plugins: {
            legend: {},
          },
          ...props.options,
        },
      });

      watch(
        props.data,
        () => {
          chart.data = $h.toRaw(props.data);
          chart.update();
        },
        { flush: "post" }
      );

      watch(
        props.options,
        () => {
          chart.options = props.options;
          chart.update();
        },
        { flush: "post" }
      );

      // Attach ChartJs instance
      chartRef.value.instance = chart;
    };

    const setSize = () => {
      if (props.width) {
        cash(chartRef.value).attr({
          width: props.width,
        });
      }

      if (props.height) {
        cash(chartRef.value).attr({
          height: props.height,
        });
      }
    };

    const bindInstance = () => {
      if (props.refKey) {
        const bind = inject(`bind[${props.refKey}]`);
        if (typeof bind === "function") {
          bind(chartRef.value);
        }
      }
    };

    onMounted(() => {
      bindInstance();
      setSize();
      init();
    });

    return {
      chartRef,
    };
  },
});
