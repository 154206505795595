
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  GeneralApiResponse,
  GetMaterialCategoryResult,
  MaterialCategoryInterface,
  materialCategoryService,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueButton from "@/components/form-elements/button/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { quickSubPage, QuickSubPageInterface } from "@/components/quick-page";

export default defineComponent({
  name: "MaterialCategoryCreateOrUpdate",
  components: {
    VueSelect2,
    VueSelect,
    VueInput,
    VueInputContainer,
    VueButton,
  },
  props: {
    quickPage: {
      type: Object as () => QuickSubPageInterface,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const quickPage = quickSubPage(props.quickPage);
    const id = computed(() => quickPage.id(get(route.params, "id")));

    const {
      data: merchant,
      loading,
      fetch,
    } = useReadRecord<MaterialCategoryInterface, GetMaterialCategoryResult>({
      id: id.value,
      serviceMethod: materialCategoryService.show,
      success(result) {
        if (result.kind === "ok") {
          setQuery(result.data);
        }
      },
      fail() {
        if (!quickPage.enabled) {
          router.go(-1);
        } else {
          quickPage.onClose();
        }
      },
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<
        MaterialCategoryInterface,
        GeneralApiResponse<GetMaterialCategoryResult>
      >({
        query: {
          title: "",
          rule_id: "",
          status: "1",
        },
        storeServiceMethod: materialCategoryService.store,
        updateServiceMethod: materialCategoryService.update,
        success(result) {
          if (result.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "Materyal Kategori Kaydedildi!",
            });
            if (!quickPage.enabled) {
              router.go(-1);
            } else {
              quickPage.onSave(result.data);
            }
          }
        },
      });

    const priceCalculationRuleSelect2Options: Select2Options = {
      ajaxQuery: {
        rule: {
          "@select": { value: "id", text: "name" },
        },
      },
    };

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (quickPage.enabled) setQuery(quickPage.data);

      if (id.value) fetch(id.value);
    });

    return {
      loading,
      processing,
      query,
      merchant,
      validationErrors,
      priceCalculationRuleSelect2Options,
      onSubmit,
    };
  },
});
