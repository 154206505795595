
import { defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { Content } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import { Select2Options } from "@/components/form-elements/select2";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";

export default defineComponent({
  name: "ContentSectionInformation",
  components: {
    VueSelect2,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => Content,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableQuery = reactive<Content>(toRaw(props.query));

    const parentContentSelect2Options: Select2Options = {
      ajaxQuery: {
        content: {
          "@select": {
            value: "parent",
            text: "header_title",
            type: "type",
            header_title: "header_title",
            header_slug: "header_slug",
          },
          "@func": ["withHeaderUrlSlug"],
          "@where": { type: ["3"] },
          "@whereNot": { parent: null },
          "@group": ["parent"],
          "@page": 1,
        },
      },
    };

    const changeParent = (ctx: Record<string, any>) => {
      if (ctx) {
        editableQuery.header_slug = ctx.header_slug;
        editableQuery.header_title = ctx.header_title;
      }
    };

    return { editableQuery, parentContentSelect2Options, changeParent };
  },
});
