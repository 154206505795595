
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  GeneralApiResponse,
  GetPriceCalculationRuleResult,
  PriceCalculationRule,
  priceCalculationRuleService,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueButton from "@/components/form-elements/button/Main.vue";
import PriceCalculationRuleGeneralInformation from "@/views/price-calculation-rules/create/components/GeneralInformation.vue";
import PriceCalculationRuleAfterCalculation from "@/views/price-calculation-rules/create/components/AfterCalculation.vue";
import PriceCalculationRuleWidthCalculation from "@/views/price-calculation-rules/create/components/WidthCalculation.vue";
import PriceCalculationRuleHeightCalculation from "@/views/price-calculation-rules/create/components/HeightCalculation.vue";
import PriceCalculationRuleWidthHeightCalculation from "@/views/price-calculation-rules/create/components/WidthHeightCalculation.vue";

export default defineComponent({
  name: "PriceCalculationRuleCreateOrUpdate",
  components: {
    PriceCalculationRuleWidthHeightCalculation,
    PriceCalculationRuleHeightCalculation,
    PriceCalculationRuleWidthCalculation,
    PriceCalculationRuleAfterCalculation,
    PriceCalculationRuleGeneralInformation,
    VueButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const id = computed(() => get(route.params, "id"));

    const {
      data: merchant,
      loading,
      fetch,
    } = useReadRecord<PriceCalculationRule, GetPriceCalculationRuleResult>({
      id: id.value,
      serviceMethod: priceCalculationRuleService.show,
      success(result) {
        if (result.kind === "ok") {
          setQuery(result.data);
        }
      },
      fail() {
        router.go(-1);
      },
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<
        PriceCalculationRule,
        GeneralApiResponse<GetPriceCalculationRuleResult>
      >({
        query: {
          name: "",
          type: "qty",
          width_min: "0",
          width_max: "0",
          width_increment: "0",
          width_greater: "0-0",
          width_add: "0",
          height_min: "0",
          height_max: "0",
          height_increment: "0",
          height_greater: "0-0",
          height_add: "0",
          widthxheight_min: "0",
          widthxheight_max: "0",
          widthxheight_increment: "0",
          widthxheight_greater: "0-0:0-0",
          after_rule: "0",
          use_master: "1",
        },
        storeServiceMethod: priceCalculationRuleService.store,
        updateServiceMethod: priceCalculationRuleService.update,
        success(result) {
          if (result.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "Fiyat Hesap Kuralı Kaydedildi!",
            });
            router.go(-1);
          }
        },
      });

    const visibleWidthCalculation = computed(
      () =>
        (query.value.type === "width" || query.value.type === "widthxheight") &&
        query.value.use_master * 1 === 0
    );

    const visibleHeightCalculation = computed(
      () =>
        (query.value.type === "height" ||
          query.value.type === "widthxheight") &&
        query.value.use_master * 1 === 0
    );

    const visibleWidthHeightCalculation = computed(
      () => visibleWidthCalculation.value && visibleHeightCalculation.value
    );

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) fetch(id.value);
    });

    return {
      loading,
      processing,
      query,
      merchant,
      validationErrors,
      visibleWidthCalculation,
      visibleHeightCalculation,
      visibleWidthHeightCalculation,
      onSubmit,
    };
  },
});
