
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useFetchRecords } from "@/utils/records/fetch";
import { useFilterRecords } from "@/utils/records/filter";
import Pagination from "@/components/pagination/Main.vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import { helper } from "@/utils/helper";
import {
  GetMaterialProductResult,
  ProductCloneInterface,
  productGroupService,
  selectionService,
} from "@/services";
import { findIndex, forEach, get, isNumber, set } from "lodash";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import FilterButton from "@/components/filter-container/Button.vue";
import Tippy from "@/components/tippy/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import Clipboard from "@/components/clipboard/Main.vue";
import { useRoute, useRouter } from "vue-router";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useDeleteRecord } from "@/utils/records/delete";
import { ProductGroupDownloadList } from "@/views/product-groups/list/download-list";
import { ProductGroupExcel } from "@/utils/excel/product-group";
import { useStore } from "@/store";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueModal from "@/components/modal/Main.vue";
import VueButton from "@/components/form-elements/button/Main.vue";

export default defineComponent({
  name: "ProductGroupList",
  components: {
    VueButton,
    VueModal,
    TransactionStatement,
    Clipboard,
    VueSelect,
    VueInput,
    Tippy,
    FilterButton,
    VueSelect2,
    VueInputContainer,
    FilterContainer,
    Pagination,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const cloneModalRefId = helper.uuidv4();
    const processing = ref<boolean>(false);
    const preparingExcel = ref<boolean>(false);
    const priceKey =
      get(store.getters["auth/profile"], "use_merchant_price") === true
        ? "merchant_price"
        : "price";

    const counts = reactive({
      loading: false,
      active: 0,
      passive: 0,
      pending: 0,
      cancelled: 0,
    });

    const formId = computed(() => {
      return get(filterState.fields, "form_id");
    });

    const categoryId = computed(() => {
      return get(filterState.fields, "category_id");
    });

    const tempCounts = computed(() => {
      if (!filterCount.value) return counts;
      const obj: Record<string, any> = {
        loading: counts.loading || loading.value,
      };

      forEach(counts, (countVal, countKey) => {
        if (isNumber(countVal)) {
          set(
            obj,
            countKey,
            filterState.fields.status === countKey ? list.meta.total : 0
          );
        }
      });
      return obj;
    });

    const { loading, list, fetch, getFilterValues } = useFetchRecords({
      selectionQuery: () => ({
        source: "product_group",
        config: {
          "@page": 1,
          "@select": [
            "title",
            "barcode",
            "merchant_id",
            "category_id",
            "product_id",
            "status",
            "point",
          ],
          "@order": "id:desc",
          "@func": ["withCount", "withCoverPhoto"],
          merchant: {
            "@select": ["id", "title"],
          },
          category: {
            "@select": ["id", "title", "form_id"],
          },
          product: {
            "@select": ["id", "price", "merchant_price", "vat_rate"],
          },
        },
        filterFormat: {
          q: "@where.q",
          id: "@where._id",
          title: "@where._title",
          barcode: "@where._barcode",
          stock_code: "@where._stock_code",
          item_code: "@where._item_code",
          merchant_id: "@where.merchant_id",
          form_id: "@where.form_id",
          category_id: "@where.category_id",
          can_return: "@where.can_return",
          status: "@customWhere.status",
        },
      }),
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        id: "",
        title: "",
        barcode: "",
        stock_code: "",
        item_code: "",
        merchant_id: "",
        form_id: get(route.query, "form_id") || "",
        category_id: "",
        can_return: "",
        status: "active",
      },
    });

    const { deleteState, triggerDelete, removeDeleteError } = useDeleteRecord({
      serviceMethod: productGroupService.delete,
      success(id: any) {
        const index = findIndex(list.data, { id });
        if (index > -1) {
          list.data.splice(index, 1);
          set(
            counts,
            filterState.fields.status,
            get(counts, filterState.fields.status, 0) * 1 - 1
          );
        }
      },
    });

    const {
      query: cloneQuery,
      processing: cloneProcessing,
      submit: cloneSubmit,
      validationErrors: cloneValidationErrors,
    } = useSubmitRecord<ProductCloneInterface, GetMaterialProductResult>({
      query: {
        id: "",
        original_barcode: "",
        barcode: "",
        merchant_id: "",
        category_id: "",
      },
      updateServiceMethod: productGroupService.clone,
      success(result) {
        if (result?.kind === "ok") {
          cash(`#${cloneModalRefId}`).modal("hide");
          ToastNotify({
            className: "success",
            text: "Kopya Ürün Oluşturuldu!",
          });
          goToEdit(result.data.id);
        }
      },
    });

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const categorySelect2Options = computed<Select2Options>(() => ({
      ajaxQuery: {
        category: {
          "@select": { value: "id", text: "title" },
          "@where": {
            form_id: formId.value,
          },
          "@func": ["withOnlyLeaf"],
        },
      },
      dropdownAutoWidth: true,
      templateResult(obj: any): any {
        return `
        <div>${get(obj, "text", "")}</div>
        <div class="text-sm font-medium text-indigo-700 max-w-sm">${get(
          obj,
          "ancestor_title",
          ""
        )}</div>
        `;
      },
    }));

    const merchantId = computed(() => {
      if (store.getters["auth/isMerchant"])
        return store.getters["auth/merchantId"];
      return filterState.fields.merchant_id;
    });

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
      readTotalCounts();
    });

    const readTotalCounts = () => {
      counts.loading = true;
      selectionService
        .fetch(
          {
            active: {
              "@source": "product_group",
              "@count": true,
              "@where": {
                status: "1",
                check_status: "0",
                form_id: formId.value,
              },
            },
            passive: {
              "@source": "product_group",
              "@count": true,
              "@where": {
                status: "0",
                check_status: "0",
                form_id: formId.value,
              },
            },
            pending: {
              "@source": "product_group",
              "@count": true,
              "@where": {
                check_status: "1",
                form_id: formId.value,
              },
            },
            cancelled: {
              "@source": "product_group",
              "@count": true,
              "@where": {
                check_status: "2",
                form_id: formId.value,
              },
            },
          },
          true
        )
        .then((result) => {
          if (result.kind === "ok") {
            counts.active = get(result.data, "active") || 0;
            counts.passive = get(result.data, "passive") || 0;
            counts.pending = get(result.data, "pending") || 0;
            counts.cancelled = get(result.data, "cancelled") || 0;
          }
        })
        .finally(() => {
          counts.loading = false;
        });
    };

    const changeFilterStatus = (status: any) => {
      filterState.fields.status = status;
      filterFunctions.applyFilter();
    };

    const goToEdit = (id: any) => {
      router.push({
        name: "product-groups.edit",
        params: {
          id,
        },
      });
    };

    const downloadToExcel = (withRows = false, withCondition = false) => {
      if (!categoryId.value || !merchantId.value) return;

      preparingExcel.value = true;
      setTimeout(async () => {
        let rows: any[] = [];
        if (withRows) {
          rows = await ProductGroupDownloadList(
            categoryId.value,
            merchantId.value,
            withCondition
              ? getFilterValues(filterFunctions.formattedQuery())
              : {}
          );
        }

        const filename = `urunleriniz_${helper.formatDate(
          new Date(),
          "DD_MM_YYYY_HH_mm"
        )}`;

        const productGroupExcel = new ProductGroupExcel(
          categoryId.value,
          merchantId.value,
          filterState.fields.form_id
        );
        await productGroupExcel.process();
        productGroupExcel.setRows(rows, "primary");
        await productGroupExcel.export(filename);

        preparingExcel.value = false;
      }, 100);
    };

    const changeForm = (val: any) => {
      filterState.fields.form_id = val;
      filterState.fields.category_id = "";
    };

    const openCloneModal = (row: any) => {
      const barcode = get(row, "barcode");
      const time = new Date().getTime();

      cloneQuery.value.id = get(row, "id");
      cloneQuery.value.original_barcode = barcode;
      cloneQuery.value.barcode = `${barcode}-${time}`;
      cloneQuery.value.merchant_id = get(row, "merchant_id");
      cloneQuery.value.category_id = get(row, "category_id");

      cash(`#${cloneModalRefId}`).modal("show");
    };

    const saveClone = () => {
      cloneSubmit(cloneQuery.value.id);
    };

    return {
      cloneModalRefId,
      loading,
      list,
      filterState,
      filterFunctions,
      processing,
      merchantSelect2Options,
      categorySelect2Options,
      filterCount,
      counts,
      tempCounts,
      deleteState,
      preparingExcel,
      merchantId,
      cloneQuery,
      cloneProcessing,
      cloneValidationErrors,
      priceKey,
      saveClone,
      openCloneModal,
      triggerDelete,
      removeDeleteError,
      changeFilterStatus,
      goToEdit,
      downloadToExcel,
      changeForm,
    };
  },
});
