import * as Select2 from "select2";
import { get, set, forEach, cloneDeep } from "lodash";
import { selectionService } from "@/services";
import cash from "cash-dom";

interface Select2Options {
  ajaxQuery?: any;
  chooseFirstOption?: boolean;
  withoutQueue?: boolean;
  dropdownAutoWidth?: boolean;

  data?: Select2.DataFormat[];
  tags?: boolean;
  multiple?: boolean;
  isArray?: boolean;
  placeholder?: string;
  templateResult?(obj: any): any;
  templateSelection?(obj: any): any;
}

interface Props {
  options: Select2Options;
  modelValue: any;
}

const DEFAULT_OPTIONS: Select2.Options = {
  width: "100%",
  allowClear: true,
  multiple: false,
  tags: false,
  minimumInputLength: 0,
  maximumSelectionLength: 0,
  minimumResultsForSearch: 0,
  tokenSeparators: [","],
  placeholder: "Seçiniz",
  escapeMarkup: (markup: any) => {
    return markup;
  },
};

const configureAjax = (query: any): Select2.AjaxOptions => {
  return {
    delay: 500,
    processResults: (rsp = []) => {
      const data: any[] = [];
      let more = false;
      forEach(rsp, (item: any) => {
        get(item, "data", []).map((dataItem: any) => {
          const obj = {
            ...dataItem,
            id: get(dataItem, "value", get(dataItem, "id", "")),
            value: get(dataItem, "value", get(dataItem, "id", "")),
            text: get(dataItem, "text", ""),
            disabled: false,
          };
          data.push(obj);
        });
        if (!more)
          more = get(item, "data", []).length === get(item, "per_page", 15);
      });
      return {
        results: data,
        pagination: {
          more,
        },
      };
    },
    transport: (params, success, failure): void => {
      selectionService
        .fetch(
          prepareQuery({
            query: query,
            q: get(params, "data.q", ""),
            page: get(params, "data.page", 1) * 1,
          })
        )
        .then((result) => {
          if (result.kind === "ok" && typeof success === "function") {
            success(result.data);
          }
        })
        .catch(() => {
          if (typeof failure === "function") failure();
        });
    },
  };
};

const prepareQuery = (props: {
  query: Record<string, any>;
  q?: any;
  value?: any;
  page?: number;
  single?: boolean;
}) => {
  const query = cloneDeep(props.query);
  forEach(query, (item) => {
    if (typeof item["@where"] === "undefined") set(item, "@where", {});
    if (props.q) set(item["@where"], "q", props.q);
    if (props.single) {
      let columnKey = "id";
      if (typeof item["@select"].value !== "undefined")
        columnKey = item["@select"].value;
      set(item["@where"], columnKey, props.value);
      set(item, "@first", true);
    } else {
      set(item, "@page", props.page || 1);
    }
  });
  return query;
};

const fetchSingleRecord = async (
  el: HTMLElement,
  props: Props
): Promise<any> => {
  const result = await selectionService.fetch(
    prepareQuery({
      query: props.options.ajaxQuery || {},
      value: props.modelValue,
      single: true,
    }),
    props.options.withoutQueue || false
  );

  if (result.kind === "ok") {
    const key = get(Object.keys(result.data), 0);
    const data = get(result.data, `${key}`);
    if (data) {
      const value = get(data, "value", get(data, "id", ""));
      let text = get(data, "text", "");
      if (props.options.templateSelection) {
        text = props.options.templateSelection(data);
      }
      const newOption = new Option(text, value, false, false);
      jQuery(el).append(newOption).val(value).trigger("change");
    }

    return data;
  }

  return null;
};

const init = (
  el: HTMLElement,
  props: Props,
  emit: (event: string, ...args: unknown[]) => void
): void => {
  const valueArray = props.modelValue
    ? props.modelValue.toString().split(",")
    : [];
  const options = cloneDeep(DEFAULT_OPTIONS);

  if (props.options.data) {
    options.data = props.options.data;
  }
  if (props.options.multiple || props.options.tags) {
    options.allowClear = false;
    if (props.options.multiple) options.multiple = true;
    if (props.options.tags) options.tags = true;
  }
  if (props.options.templateSelection) {
    options.templateSelection = props.options.templateSelection;
  }
  if (props.options.templateResult) {
    options.templateResult = props.options.templateResult;
  }
  if (props.options.dropdownAutoWidth) {
    options.dropdownAutoWidth = props.options.dropdownAutoWidth;
  }
  if (props.options.placeholder) {
    options.placeholder = props.options.placeholder;
  }

  if (props.options.ajaxQuery) {
    options.ajax = configureAjax(props.options.ajaxQuery);
    if (props.modelValue || props.options.chooseFirstOption) {
      fetchSingleRecord(el, props)
        .then((ctx: any) => {
          const propsModelValue = props.modelValue || "";
          const ctxValue = get(ctx, "value") || get(ctx, "id") || "";
          emit("update:selected-item", ctx || null);
          if (propsModelValue !== ctxValue) {
            emit("update:modelValue", ctxValue);
          }
        })
        .finally();
    }
  }

  const emitValue = (value: any, ctx?: any) => {
    emit("update:modelValue", value);
    emit("update:selected-item", ctx || null);
  };

  jQuery(el)
    .select2(options)
    .on("select2:open", () => {
      setTimeout(() => {
        let searchInput = cash(el)
          .parent()
          .find(".select2-search__field")
          .first();

        if (!searchInput || searchInput.length === 0) {
          searchInput = cash(".select2-container--open")
            .find("input.select2-search__field")
            .first();
        }

        if (searchInput && searchInput.length > 0) searchInput.trigger("focus");
      }, 100);
    })
    .on("select2:select", (e: any) => {
      const htmlOptions: HTMLOptionElement[] = get(e, "target.children", []);
      const values: any[] = [];
      Array.prototype.map.call(htmlOptions, (htmlOption: HTMLOptionElement) => {
        if (htmlOption.selected) values.push(htmlOption.value);
      });
      const newValue = props.options.isArray ? values : values.join(",");
      if (newValue !== props.modelValue) {
        emitValue(newValue, e.params.data);
      }
    })
    .on("select2:unselect", (e) => {
      const htmlOptions: HTMLOptionElement[] = get(e, "target.children", []);
      const values: any[] = [];
      Array.prototype.map.call(htmlOptions, (htmlOption: HTMLOptionElement) => {
        if (htmlOption.selected) values.push(htmlOption.value);
      });
      emitValue(props.options.isArray ? values : values.join(","));
    })
    .val(valueArray)
    .trigger("change");
};

const reInit = (
  el: HTMLElement,
  props: Props,
  emit: (event: string, ...args: unknown[]) => void
): void => {
  destroy(el);
  init(el, props, emit);
};

const destroy = (el: HTMLElement): void => {
  jQuery(el).select2().off("select2:select").off("select2:select");
  jQuery(el).select2("destroy");
};

export { init, reInit, destroy, Select2Options };
