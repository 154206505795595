
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  Ref,
  ref,
} from "vue";
import { useFetchRecords } from "@/utils/records/fetch";
import { useFilterRecords } from "@/utils/records/filter";
import Pagination from "@/components/pagination/Main.vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { helper } from "@/utils/helper";
import VueModal from "@/components/modal/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import { commentService, selectionService } from "@/services";
import { findIndex, forEach, get, set } from "lodash";
import VueButton from "@/components/form-elements/button/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import { useRoute } from "vue-router";
import FilterButton from "@/components/filter-container/Button.vue";
import { ConfirmRef } from "@/components/confirm";
import { ToastNotify } from "@/utils/toast-notify";

export default defineComponent({
  name: "CommentsList",
  components: {
    FilterButton,
    VueSelect2,
    VueButton,
    Tippy,
    VueModal,
    VueInput,
    VueInputContainer,
    FilterContainer,
    Pagination,
  },
  setup() {
    const route = useRoute();

    const modalRefId = helper.uuidv4();
    const detail = ref<any>(null);
    const processing = ref<boolean>(false);
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);

    const commentCounts = reactive({
      loading: false,
      waiting: 0,
      approved: 0,
      cancelled: 0,
    });

    const { loading, list, fetch } = useFetchRecords({
      selectionQuery: {
        source: "comment",
        config: {
          "@page": 1,
          "@select": [
            "name_surname",
            "color",
            "size",
            "product_group_item_code",
            "city",
            "user_id",
            "merchant_id",
            "order_group_id",
            "product_group_id",
            "product_message",
            "product_rate",
            "merchant_rate_1",
            "merchant_rate_2",
            "merchant_rate_3",
            "show_name",
            "is_purchased",
            "status",
            "created_at",
          ],
          "@order": "id:desc",
          "@func": ["withCount"],
          user: {
            "@select": ["id", "email", "name", "surname"],
          },
          merchant: {
            "@select": ["id", "title"],
          },
          product: {
            "@select": [
              "id",
              "title",
              "barcode",
              "form_id",
              "category_id",
              "status",
            ],
          },
        },
        filterFormat: {
          q: "@where.q",
          name_surname: "@where._name_surname",
          merchant_id: "@where.merchant_id",
          product_id: "@where.product_group_id",
          barcode: "@whereHas.product._barcode",
          is_purchased: "@where.barcode",
          status: "@where.status",
          color: "@where._color",
          size: "@where._size",
          city: "@where._city",
          order_group_id: "@where.order_group_id",
        },
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        merchant_id: "",
        name_surname: "",
        product_id: "",
        barcode: "",
        is_purchased: "",
        color: "",
        size: "",
        city: "",
        order_group_id: "",
        status: "0",
      },
    });

    const modalAttrs = computed(() => {
      const attrs: Record<string, any> = {};
      if (processing.value) set(attrs, "data-backdrop", true);
      return attrs;
    });

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const disableFilterStatus = computed(() => {
      let disabled = false;
      forEach(route.query, (val, key) => {
        if (val && ["status", "page"].indexOf(key) === -1) disabled = true;
      });
      return disabled;
    });

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
      readTotalCounts();
    });

    const readTotalCounts = () => {
      commentCounts.loading = true;
      selectionService
        .fetch(
          {
            waitingCommentCounts: {
              "@source": "comment",
              "@count": true,
              "@where": {
                status: "0",
              },
            },
            approvedCommentCounts: {
              "@source": "comment",
              "@count": true,
              "@where": {
                status: "1",
              },
            },
            cancelledCommentCounts: {
              "@source": "comment",
              "@count": true,
              "@where": {
                status: "2",
              },
            },
          },
          true
        )
        .then((result) => {
          if (result.kind === "ok") {
            commentCounts.waiting =
              get(result.data, "waitingCommentCounts") || 0;
            commentCounts.approved =
              get(result.data, "approvedCommentCounts") || 0;
            commentCounts.cancelled =
              get(result.data, "cancelledCommentCounts") || 0;
          }
        })
        .finally(() => {
          commentCounts.loading = false;
        });
    };

    const openDetail = (row: any) => {
      detail.value = row;
      cash(`#${modalRefId}`).modal("show");
    };

    const changeFilterStatus = (status: any) => {
      filterState.fields.status = status;
      filterFunctions.applyFilter();
    };

    const onChangeStatus = (status: any) => {
      if (processing.value) return;

      confirmRef?.value?.show({
        title: "Yorumun durumunu değiştirmek istiyor musunuz?",
        icon: "feather-alert-circle",
        iconClass: "text-red-400",
        confirmText: "Evet",
        cancelText: "Hayır",
        callback: () => {
          processing.value = true;
          commentService
            .status(detail.value.id, { status })
            .then((result) => {
              if (result.kind === "ok") {
                detail.value.status = result.data.status;
                const index = findIndex(list.data, { id: detail.value.id });
                set(list.data, `${index}.status`, result.data.status);
              } else {
                ToastNotify({
                  text: result.message,
                  className: "error",
                });
              }
            })
            .finally(() => {
              processing.value = false;
            });
        },
      });
    };

    return {
      modalRefId,
      detail,
      loading,
      list,
      filterState,
      filterFunctions,
      commentCounts,
      processing,
      modalAttrs,
      merchantSelect2Options,
      disableFilterStatus,
      filterCount,
      openDetail,
      changeFilterStatus,
      onChangeStatus,
    };
  },
});
