
import { defineComponent } from "vue";
import OrderFormLineMixin from "@/components/order/form";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";

export default defineComponent({
  name: "OrderFormSingle",
  components: { VueInput, VueInputContainer },
  mixins: [OrderFormLineMixin],
});
