import {
  GeneralApiResponse,
  GetMaterialProductResult,
  GetProductGroupResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const productService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetMaterialProductResult>>(
      `/api/products/${id}`
    ),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetMaterialProductResult>>(
      `/api/products`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetMaterialProductResult>>(
      `/api/products/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/products/${id}`),

  clone: (id: any, params: unknown) =>
    useAxios().post<GeneralApiResponse<GetMaterialProductResult>>(
      `/api/products/${id}/clone`,
      params
    ),
};

export const productGroupService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetProductGroupResult>>(
      `/api/product-groups/${id}`
    ),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetProductGroupResult>>(
      `/api/product-groups`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetProductGroupResult>>(
      `/api/product-groups/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/product-groups/${id}`),

  clone: (id: any, params: unknown) =>
    useAxios().post<GeneralApiResponse<GetMaterialProductResult>>(
      `/api/product-groups/${id}/clone`,
      params
    ),

  changeCheckStatus: (id: any, params: unknown) =>
    useAxios().post<GeneralApiResponse<any>>(
      `/api/product-groups/${id}/check-status`,
      params
    ),
};
