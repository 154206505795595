import {
  RouteLocationNormalized,
  RouteRecordRaw,
  RouterView,
} from "vue-router";
import Users from "@/views/user/list/Main.vue";
import UserCreate from "@/views/user/create/Main.vue";

import Roles from "@/views/user-roles/list/Main.vue";
import RoleCreate from "@/views/user-roles/create/Main.vue";
import { Store } from "@/store";
import { get } from "lodash";

const route: Array<RouteRecordRaw> = [
  {
    path: "users",
    component: RouterView,
    children: [
      {
        path: "",
        name: "users",
        component: Users,
        meta: {
          title: "Kullanıcı Listesi",
          roles: [],
          permissions: ["users.list"],
        },
      },
      {
        path: "create",
        name: "users.create",
        component: UserCreate,
        meta: {
          title: "Kullanıcı Ekle",
          roles: [],
          permissions: ["users.create"],
        },
      },
      {
        path: "edit/:id",
        name: "users.edit",
        component: UserCreate,
        meta: {
          title: "Kullanıcı Düzenle",
          roles: [],
          permissions: [],
          checkPermission: (store: Store, to: RouteLocationNormalized) => {
            return (
              ((get(to.params, "id") || 0) as any) * 1 ===
                store.getters["auth/profile"].id * 1 ||
              store.getters["auth/hasPermissionWithSiteAdmin"]("users.show")
            );
          },
        },
      },
    ],
  },
  {
    path: "roles",
    component: RouterView,
    children: [
      {
        path: "",
        name: "roles",
        component: Roles,
        meta: {
          title: "Kullanıcı Rol Listesi",
          roles: ["admin"],
          permissions: [],
        },
      },
      {
        path: "create",
        name: "roles.create",
        component: RoleCreate,
        meta: {
          title: "Kullanıcı Rolü Ekle",
          roles: ["admin"],
          permissions: [],
        },
      },
      {
        path: "edit/:id",
        name: "roles.edit",
        component: RoleCreate,
        meta: {
          title: "Kullanıcı Rolü Düzenle",
          roles: ["admin"],
          permissions: [],
        },
      },
    ],
  },
];

export default route;
