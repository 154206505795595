import {
  GeneralApiResponse,
  GetFileResult,
  GetFolderResult,
  GetGenerateSignedUrl,
  MassApiResponse,
  useAxios,
} from "@/services";

export * from "./api.types";

export const fileService = {
  generateSignedUrl: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetGenerateSignedUrl>>(
      `/api/files/generate-signed-url`,
      params
    ),

  signedUrl: (params: unknown) =>
    useAxios().post<GeneralApiResponse<any>>(`/api/files/signed-url`, params),

  fetch: (params: unknown) =>
    useAxios().get<GeneralApiResponse<GetFileResult>>(`/api/files`, {
      data: params,
    }),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetFileResult>>(`/api/files`, params),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetFileResult>>(
      `/api/files/${id}`,
      params
    ),

  destroy: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/files/${id}`),

  massDestroy: (id: any) =>
    useAxios().post<GeneralApiResponse<MassApiResponse>>(
      `/api/files/mass-destroy`,
      { id }
    ),
};

export const folderService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetFolderResult>>(`/api/folders/${id}`),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetFolderResult>>(
      `/api/folders`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetFolderResult>>(
      `/api/folders/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/folders/${id}`),
};
