import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "mt-2 text-xs font-medium text-gray-600"
}
const _hoisted_3 = { class: "fabric--color" }
const _hoisted_4 = { class: "colors" }
const _hoisted_5 = { class: "text-center text-lg" }
const _hoisted_6 = { class: "ml-1" }
const _hoisted_7 = { class: "color--holders" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "text-center mt-4" }
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectableColorItem = _resolveComponent("SelectableColorItem")!
  const _component_CardBox = _resolveComponent("CardBox")!
  const _component_VueInputContainer = _resolveComponent("VueInputContainer")!

  return (_openBlock(), _createBlock(_component_VueInputContainer, {
    "validation-errors": _ctx.validationErrors.get(_ctx.validationPath)
  }, {
    label: _withCtx(() => [
      (_ctx.isRequired)
        ? (_openBlock(), _createElementBlock("b", _hoisted_1, "*"))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.lineTitle), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_ctx.lineDescription)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.lineDescription), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color) => {
              return (_openBlock(), _createBlock(_component_SelectableColorItem, {
                key: `f-color-${color.id}`,
                color: color,
                class: _normalizeClass({ picked: color.code === _ctx.currentColor.code }),
                onClick: ($event: any) => (_ctx.pickColor(color))
              }, null, 8, ["color", "class", "onClick"]))
            }), 128))
          ]),
          (_ctx.colors.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("a", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSymmetric && _ctx.toggleSymmetric(...args))),
                    class: _normalizeClass(["cursor-pointer", { 'text-red-400': !_ctx.symmetric }])
                  }, [
                    _createElementVNode("span", _hoisted_6, [
                      _createTextVNode(" Simetrik Renklendirme: "),
                      _createElementVNode("b", null, _toDisplayString(_ctx.symmetric ? "Aktif" : "Pasif"), 1)
                    ])
                  ], 2)
                ]),
                _createElementVNode("ul", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberOfBoxes, (box, boxIndex) => {
                    return (_openBlock(), _createBlock(_component_CardBox, {
                      key: `f-color-box-${box}`,
                      "box-index": boxIndex,
                      color: _ctx.selectedColors[boxIndex],
                      class: _normalizeClass({
                stripe: _ctx.selectedColors.length < 1,
              }),
                      onClick: ($event: any) => (_ctx.setColor(boxIndex))
                    }, null, 8, ["box-index", "color", "class", "onClick"]))
                  }), 128))
                ]),
                _createElementVNode("div", {
                  class: "text-xs text-center",
                  innerHTML: _ctx.displayText
                }, null, 8, _hoisted_8),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("button", {
                    tabindex: "-1",
                    class: "button bg-gray-300",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearColors && _ctx.clearColors(...args))),
                    disabled: _ctx.selectedColors.length === 0
                  }, " Temizle ", 8, _hoisted_10)
                ])
              ], 64))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["validation-errors"]))
}