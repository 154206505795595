
import { computed, defineComponent, inject, Ref, ref } from "vue";
import { useStore } from "@/store";
import { orderService, PrintTemplateInterface } from "@/services";
import { cloneDeep, get } from "lodash";
import CreatePdf from "@/utils/print/createPdf";

export default defineComponent({
  name: "PrintDocument",
  props: {
    sourceId: {
      default: null,
    },
    source: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const pdfViewerRef = inject<Ref>(`pdfViewerRef`);

    const processing = ref<boolean>(false);
    const templates = computed<PrintTemplateInterface[]>(() => {
      return store.getters["system/printTemplate"](props.source);
    });

    const onPressPrint = (index: number) => {
      onPressPreview(index);
    };

    const onPressPreview = (index: number) => {
      const template = get(templates.value, index);
      createPDF(template);
    };

    const createPDF = async (template: PrintTemplateInterface) => {
      const { settings, pages, type } = template;
      processing.value = true;
      const result = await fetchData(type);
      const instance = CreatePdf({
        templateSettings: settings,
        pages: pages,
        data: get(result, "data") || {},
      });

      instance
        .prepare()
        .then(() => {
          const url = instance.output();
          pdfViewerRef?.value.open(url);
        })
        .finally(() => {
          processing.value = false;
        });
    };

    const fetchData = async (type: string) => {
      switch (type) {
        case "order":
          return await fetchOrders();
      }
    };

    const fetchOrders = async () => {
      const result = await orderService.show(props.sourceId);

      let lines: any[] = [];
      if (result.kind === "ok") {
        const merchants = cloneDeep(get(result.data, "merchants", []));
        merchants.map((merchantGroup: any) => {
          lines = [...lines, ...get(merchantGroup, "lines", [])];
        });

        return {
          data: {
            ...result.data,
            lines: cloneDeep(lines),
          },
        };
      }

      return null;
    };

    return {
      templates,
      onPressPrint,
      onPressPreview,
    };
  },
});
