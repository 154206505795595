
import { computed, defineComponent } from "vue";
import md5 from "blueimp-md5";
import { get } from "lodash";
import { GetUser } from "@/services";

export default defineComponent({
  name: "UserSideNavigation",
  props: {
    userDetail: {
      type: Object as () => GetUser,
      default: () => ({}),
    },
    changeView: {
      type: Function,
      default: () => null,
    },
    activeView: {
      type: String,
      default: () => null,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canChangePassword: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const gravatar = computed(() => {
      return `https://secure.gravatar.com/avatar/${md5(
        get(props.userDetail, "email", "")
      )}?size=256`;
    });

    return {
      gravatar,
    };
  },
});
