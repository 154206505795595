import { selectionService } from "@/services";
import { get } from "lodash";

export const FileDownloadList = async (
  condition: Record<string, any> = {}
): Promise<any> => {
  const result = await selectionService.fetch({
    file: {
      "@get": true,
      "@select": ["tags", "name", "path"],
      ...condition,
    },
  });

  let data: any[] = [];

  if (result.kind === "ok") {
    data = get(result.data, "file.data", []).map((item: any) => {
      return {
        path: get(item, "path") || "",
        name: get(item, "name") || "",
        tags: get(item, "tags") || "",
      };
    });
  }

  return data;
};
