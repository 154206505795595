import {
  GeneralApiResponse,
  GetPrintTemplateListResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const printTemplateService = {
  fetch: () =>
    useAxios().get<GeneralApiResponse<GetPrintTemplateListResult>>(
      `/api/print-templates`
    ),
};
