import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LayoutDefault from "@/layouts/default/Main.vue";
import LayoutLogin from "@/layouts/login/Main.vue";
import LoginView from "@/views/auth/login/Main.vue";
import ForgotPasswordView from "@/views/auth/forgot-password/Main.vue";
import NotFoundView from "@/views/error-page/Main.vue";
import Welcome from "@/views/welcome/Main.vue";

import DashboardView from "@/views/dashboard/Main.vue";

import { useStore } from "@/store";
import { ToastNotify } from "@/utils/toast-notify";
import { get, set } from "lodash";

import users from "@/router/users";
import merchants from "@/router/merchants";
import filters from "@/router/filters";
import priceCalculationRules from "@/router/price-calculation-rules";
import orders from "@/router/orders";
import categories from "@/router/categories";
import materialCategories from "@/router/material-categories";
import others from "@/router/others";
import products from "@/router/products";
import contents from "@/router/contents";
import fiches from "@/router/fiches";
import menus from "@/router/menus";
import baskets from "@/router/baskets";
import definitions from "@/router/definitions";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: LayoutDefault,
    children: [
      {
        path: "",
        name: "dashboard",
        component: DashboardView,
      },
      ...users,
      ...merchants,
      ...filters,
      ...priceCalculationRules,
      ...orders,
      ...categories,
      ...materialCategories,
      ...contents,
      ...products,
      ...others,
      ...fiches,
      ...menus,
      ...baskets,
      ...definitions,
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/auth",
    component: LayoutLogin,
    children: [
      {
        path: "login",
        name: "login",
        component: LoginView,
      },
      {
        path: "forgot-password",
        name: "forgot.password",
        component: ForgotPasswordView,
      },
    ],
    meta: { requiresGuest: true },
  },
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome,
    meta: { requiresAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "page.notfound",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const store = useStore();

  const query = {};
  if (to.fullPath && ["/", "/welcome"].indexOf(to.fullPath) === -1)
    set(query, "redirect", to.fullPath);

  if (store.getters["auth/token"]) {
    if (
      store.getters["auth/visibleSplashScreen"] &&
      to.name !== "page.notfound" &&
      to.name !== "welcome"
    )
      return next({ name: "welcome" });

    if (to.meta.requiresGuest === true) return next({ name: "dashboard" });
  } else {
    if (to.meta.requiresAuth === true) {
      return next({ name: "login", query });
    }
  }

  let valid = true;

  const roles: any[] = get(to, "meta.roles") || [];
  const permissions: any[] = get(to, "meta.permissions") || [];

  if (
    roles.length > 0 &&
    roles.indexOf(store.getters["auth/getRoleType"]) === -1
  ) {
    valid = false;
  }

  if (valid && store.getters["auth/isStaff"] && permissions.length > 0) {
    valid = false;
    permissions.map((p) => {
      if (store.getters["auth/hasPermission"](p)) valid = true;
    });
  }

  if (
    valid &&
    typeof to.meta.checkPermission === "function" &&
    !to.meta.checkPermission(store, to)
  ) {
    valid = false;
  }

  if (!valid) {
    ToastNotify({
      text: "Bu sayfayı görmek için yetkiniz yok!",
      className: "error",
    });
  }

  return next(valid);
});

export default router;
