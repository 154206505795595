
import { defineComponent } from "vue";
import { Editor } from "@tiptap/vue-3";
import Tippy from "@/components/tippy/Main.vue";

export default defineComponent({
  name: "TiptapBulletList",
  components: { Tippy },
  props: {
    editor: {
      type: Object as () => Editor,
      required: true,
    },
  },
});
