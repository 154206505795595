// Action enums
export enum ActionTypes {
  SET_SETTINGS = "SET_SETTINGS",
  SET_SITES = "SET_SITES",
  SET_SITE = "SET_SITE",
  DETECT_SITE = "DETECT_SITE",
  SET_PROCESSING = "SET_PROCESSING",
  REMOVE_PROCESSING = "REMOVE_PROCESSING",
  SET_PRINT_TEMPLATES = "SET_PRINT_TEMPLATES",
}
