
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRaw,
} from "vue";
import {
  FormLine,
  MaterialFormLineInterface,
  OrderFormInterface,
  ProductGroupInterface,
} from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import { helper } from "@/utils/helper";
import { cloneDeep, findIndex, get, has, set } from "lodash";
import { useStore } from "@/store";
import DetectOrderFormLines from "@/components/order/form/Main.vue";

export default defineComponent({
  name: "ProductGroupMaterials",
  components: { DetectOrderFormLines },
  props: {
    query: {
      type: Object as () => ProductGroupInterface,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const ready = ref<boolean>(false);

    const editableQuery = reactive<ProductGroupInterface>(toRaw(props.query));
    const hiddenFormLineTypes = ["piece", "piecedecimal", "single", "multiple"];
    const notSelectableMaterials = ref<boolean>(true);
    const forms = ref<{ data: OrderFormInterface[] }>({ data: [] });

    const orderForm = computed(() => {
      return store.getters["order/form"](editableQuery.form_id);
    });

    const relationToMaster = computed(() => {
      return get(orderForm.value, "relation_to_master", "0") * 1 === 1;
    });

    const orderFormLines = computed<FormLine[]>(() => {
      return get(orderForm.value, "lines.data", []);
    });

    const prepareForm = () => {
      const form = cloneDeep<OrderFormInterface>(orderForm.value);
      if (!form) return;

      form.uuid = helper.uuidv4();

      const lines: MaterialFormLineInterface[] = cloneDeep(
        get(editableQuery.materials, "data.0.lines.data", []).map(
          (line: MaterialFormLineInterface) => ({
            ...line,
            id: line.id * 1,
          })
        )
      );

      form.lines.data = orderFormLines.value.map((formLine) => {
        const index = findIndex(lines, { id: formLine.id * 1 });

        let value = get(lines, `${index}.value`, "");
        if (formLine.type === "piece") value = "1";
        else if (formLine.type === "width") value = value || "100";
        else if (formLine.type === "height") value = value || "200";

        let isVisible = hiddenFormLineTypes.indexOf(formLine.type) === -1;
        if (relationToMaster.value && formLine.master * 1 === 1) {
          isVisible = false;
        }

        if (isVisible) notSelectableMaterials.value = false;

        return {
          ...formLine,
          uuid: helper.uuidv4(),
          is_visible: isVisible,
          value,
          others: get(lines, `${index}.others`) || null,
        };
      });

      forms.value.data.push(form);
    };

    const prepareMaterialsQueryFromForm = () => {
      editableQuery.materials.data = [];
      recursiveMapForms(forms.value);
    };

    const recursiveMapForms = (
      recursiveForms: { data: OrderFormInterface[] },
      path: any[] = []
    ) => {
      recursiveForms.data.map((form, formIndex) => {
        if (get(recursiveForms, [...path, "data", formIndex, "id"], 0) === 0)
          return null;

        set(
          editableQuery.materials,
          [...path, "data", formIndex, "id"],
          get(recursiveForms, [...path, "data", formIndex, "id"])
        );

        const lines = get(
          recursiveForms,
          [...path, "data", formIndex, "lines", "data"],
          []
        ) as FormLine[];

        let counter = 0;
        lines.map((line, lineIndex) => {
          if (has(line, "id")) {
            set(
              editableQuery.materials,
              [...path, "data", formIndex, "lines", "data", counter++],
              {
                value: line.value,
                id: line.id,
              }
            );
          }
          //
          if (
            line.type === "forms" &&
            get(line, ["forms", "data"], []).length > 0
          )
            recursiveMapForms(recursiveForms, [
              ...path,
              "data",
              formIndex,
              "lines",
              "data",
              lineIndex,
              "forms",
              "data",
            ]);
        });
      });
    };

    onMounted(() => {
      prepareForm();
      prepareMaterialsQueryFromForm();
      setTimeout(() => {
        ready.value = true;
      }, 50);
    });

    return {
      editableQuery,
      ready,
      forms,
      notSelectableMaterials,
    };
  },
});
