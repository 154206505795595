import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileManager = _resolveComponent("FileManager")!
  const _component_VueModal = _resolveComponent("VueModal")!

  return (_openBlock(), _createBlock(_component_VueModal, {
    id: _ctx.modalRefId,
    "slide-over": "",
    "dialog-class": "modal-xl"
  }, {
    body: _withCtx(() => [
      (_ctx.visibleFileManager)
        ? (_openBlock(), _createBlock(_component_FileManager, {
            key: 0,
            callback: _ctx.onConfirm
          }, null, 8, ["callback"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["id"]))
}