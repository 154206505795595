
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { helper } from "../../utils/helper";

export default defineComponent({
  name: "VueModal",
  props: {
    id: {
      default: () => helper.uuidv4(),
    },
    slideOver: {
      type: Boolean,
      default: false,
    },
    hiddenCloseButton: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      default: "",
    },
    bodyClass: {
      default: "",
    },
    footerClass: {
      default: "",
    },
    dialogClass: {
      default: "",
    },
    alwaysShowSlots: {
      type: Boolean,
      default: false,
    },
    onVisibleSlots: {
      type: Function,
      default: () => null,
    },
  },
  setup(props) {
    const visibleSlots = ref<boolean>(false);

    onMounted(() => {
      cash(`#${props.id}`).modal("on.show", () => {
        visibleSlots.value = true;
      });
      cash(`#${props.id}`).modal("on.hide", () => {
        visibleSlots.value = false;
      });
    });

    onBeforeUnmount(() => {
      cash(`#${props.id}`).modal("hide");
    });

    watch(
      visibleSlots,
      () => {
        props.onVisibleSlots();
      },
      { flush: "post" }
    );

    return {
      visibleSlots,
    };
  },
});
