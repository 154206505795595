import { GeneralApiResponse, GetCommentResult, useAxios } from "@/services";

export * from "./api.types";

export const commentService = {
  status: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetCommentResult>>(
      `/api/comments/${id}/status`,
      params
    ),
};
