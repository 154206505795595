
import { defineComponent } from "vue";
import OrderFormLineMixin from "@/components/order/form";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import { cloneDeep, get } from "lodash";

export default defineComponent({
  name: "OrderFormRules",
  components: { VueSelect, VueInputContainer },
  mixins: [OrderFormLineMixin],
  data() {
    return {
      defaultVariables: ["Sağ", "Sol"],
      options: [] as any[],
    };
  },
  computed: {
    caseCount() {
      return this.widthValues.length;
    },
  },
  watch: {
    widthValues: {
      handler() {
        this.process();
      },
      deep: true,
    },
  },
  beforeMount() {
    this.process();
  },
  methods: {
    process() {
      this.options = cloneDeep(this.defaultVariables.slice(0));
      this.prepareDirectionOptions();
      if (this.options.indexOf(this.lineValue) === -1) {
        this.onChangeValue(get(this.options, 0));
      }
    },
    prepareDirectionOptions(): void {
      const args = this.defaultVariables.slice(0);
      const r: string[][] = [];
      for (let i = 0; i < this.caseCount; i++) {
        r.push(args);
      }
      this.options = this.cartesian(r);
    },
    cartesian(args: string[][]): string[] {
      const r: string[] = [],
        max: number = args.length - 1;

      const helper = (arr: any, i: number, k: number) => {
        for (let j = 0, l = args[i].length; j < l; j++) {
          let a = arr.slice(0); // clone arr
          a.push(args[i][l - j - 1]);
          if (i === max) {
            a = a.join("+");
            if (j > k || j === k) {
              // sıralamaya soldan bağladığımız için sağ seçeneği bir kez geldikten sonra bir daha sol gelmemesi için bu filtreyi kullanıyoruz
              r.push(a);
            }
          } else {
            if (j > k || j === k) {
              // eğer j büyükse işleyelim ki alt sorgularda küçük jler diziye katılmasın
              k = j;
              helper(a, i + 1, k);
            }
          }
        }
      };

      if (max > -1) {
        helper([], 0, 0);
      }
      return r;
    },
  },
});
