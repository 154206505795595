import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid gap-y-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lines, (line, lineIndex) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponentName(line)), {
        key: line.uuid,
        query: _ctx.query,
        forms: _ctx.forms,
        "form-path": [..._ctx.formPath, 'lines', 'data', lineIndex],
        "validation-errors": _ctx.validationErrors,
        "onUpdate:lineValue": _ctx.changeLineValue,
        "onUpdate:line": _ctx.changeLine,
        class: _normalizeClass({ hidden: !line.is_visible })
      }, null, 40, ["query", "forms", "form-path", "validation-errors", "onUpdate:lineValue", "onUpdate:line", "class"]))
    }), 128))
  ]))
}