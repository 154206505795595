
import { defineComponent, inject, ref, Ref } from "vue";
import { get, has, cloneDeep } from "lodash";
import { debounce } from "debounce";
import { ColorPickerRef } from "@/components/color-picker/Main.vue";

export default defineComponent({
  name: "VueColorPicker",
  props: {
    modelValue: { default: () => "" },
    modelModifiers: { default: () => ({}) },
    inputClass: { default: () => "" },
    placeholder: { default: () => "" },
    hasError: {
      type: Boolean,
      default: () => false,
    },
    isButton: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const inputGroupRef = ref<HTMLElement>();
    const colorPickerRef = inject<Ref<ColorPickerRef>>(`colorPickerRef`);

    let changeValue = (e: any): void => {
      emit("update:modelValue", cloneDeep(get(e, "target.value", "")));
    };

    if (has(props.modelModifiers, "lazy")) {
      changeValue = debounce(changeValue, 500);
    }

    const openColorPicker = () => {
      const bodyRect = document.body.getBoundingClientRect();
      const clientRect = inputGroupRef.value?.getBoundingClientRect() || {};
      const top = get(clientRect, "bottom", 0) - bodyRect.top;
      let left = get(clientRect, "left", 0);

      if (left + 250 > bodyRect.width) {
        left -= left + 250 - bodyRect.width;
      }

      if (colorPickerRef?.value && !props.disabled) {
        colorPickerRef.value.show({
          color: props.modelValue,
          left,
          top,
          onChange: (color: any) => {
            emit("update:modelValue", color);
          },
        });
      }
    };

    return {
      inputGroupRef,
      changeValue,
      openColorPicker,
    };
  },
});
