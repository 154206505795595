
import { defineComponent, onMounted, ref } from "vue";
import { useFetchRecords } from "@/utils/records/fetch";
import { useFilterRecords } from "@/utils/records/filter";
import Tippy from "@/components/tippy/Main.vue";
import Pagination from "@/components/pagination/Main.vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import VueModal from "@/components/modal/Main.vue";
import { helper } from "@/utils/helper";
import FilterButton from "@/components/filter-container/Button.vue";

export default defineComponent({
  name: "Payments",
  components: {
    FilterButton,
    VueModal,
    VueSelect,
    VueInput,
    VueInputContainer,
    FilterContainer,
    Pagination,
    Tippy,
  },
  setup() {
    const modalRefId = helper.uuidv4();
    const detail = ref<any>(null);

    const { loading, list, fetch } = useFetchRecords({
      selectionQuery: {
        source: "payment",
        config: {
          "@page": 1,
          "@select": {
            site: "site",
            bin: "bin",
            last_four: "last_four",
            name: "name",
            ratio: "ratio",
            bank_ratio: "bank_ratio",
            remote_code: "remote_code",
            order_group_id: "order_group_id",
            amount: "amount",
            card_code: "card_code",
            method: "method",
            bank_code: "bank_code",
            is_foreign_card: "is_foreign_card",
            installment: "installment",
            status: "status",
            created_at: "created_at",
            user_id: "user_id",
          },
          "@order": "id:desc",
          "@func": ["withCount"],
          user: {
            "@select": ["name", "surname", "email"],
          },
        },
        filterFormat: {
          q: "@where.q",
          id: "@where.id",
          order_group_id: "@where.order_group_id",
          bin: "@where._bin",
          last_four: "@where._last_four",
          name: "@where._name",
          installment: "@where.installment",
          is_foreign_card: "@where.is_foreign_card",
          status: "@where.status",
          method: "@where.method",
          card_code: "@where.card_code",
          bank_code: "@where.bank_code",
        },
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        id: "",
        order_group_id: "",
        bin: "",
        last_four: "",
        name: "",
        installment: "",
        is_foreign_card: "",
        status: "",
        user_id: "",
        remote_code: "",
        method: "",
        card_code: "",
        bank_code: "",
      },
    });

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
    });

    const openDetail = (row: any) => {
      detail.value = row;
      cash(`#${modalRefId}`).modal("show");
    };

    return {
      modalRefId,
      detail,
      loading,
      list,
      filterState,
      filterFunctions,
      filterCount,
      openDetail,
    };
  },
});
