import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";

import "select2/dist/js/select2";
import "./accordion";
import "./dropdown";
import "./modal";
import "./tab";

Chart.register(
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
);
