
import { defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { Filter } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import VueTextarea from "@/components/form-elements/textarea/Main.vue";

export default defineComponent({
  name: "FilterGeneralInformation",
  components: {
    VueTextarea,
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => Filter,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableQuery = reactive<Filter>(toRaw(props.query));

    return {
      editableQuery,
    };
  },
});
