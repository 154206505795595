
import { computed, defineComponent } from "vue";
import { cloneDeep } from "lodash";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import { helper } from "@/utils/helper";
import { fileService } from "@/services";
import { useStore } from "@/store";

export default defineComponent({
  name: "FMListLayoutItem",
  components: { Tippy, TransactionStatement },
  props: {
    modelValue: { type: Array, default: () => [] },
    index: { type: Number, required: true },
    file: { type: Object, required: true },
    deleteState: { type: Object, required: true },
    triggerDelete: { type: Function, required: true },
    removeDeleteError: { type: Function, required: true },
    openEditModal: { type: Function, required: true },
    callback: { default: null },
  },
  setup(props, { emit }) {
    const store = useStore();

    const selected = computed({
      get() {
        return props.modelValue?.indexOf(props.file.id) > -1;
      },
      set(val) {
        const selectedItems = cloneDeep(props.modelValue || []);
        const index = selectedItems.indexOf(props.file.id);
        if (val && index === -1) selectedItems.push(props.file.id);
        if (!val && index > -1) selectedItems.splice(index, 1);
        emit("update:modelValue", selectedItems);
      },
    });

    const openOriginalFile = (path: string) => {
      if (!store.getters["auth/hasPermission"]("files.show")) {
        return window.open(helper.imagePath(path, "large"), "_blank");
      }

      fileService
        .signedUrl({
          path,
        })
        .then((result) => {
          if (result.kind === "ok") {
            window.open(result.data, "_blank");
          }
        });
    };

    const isImage = computed(
      () => ["jpg", "jpeg", "png", "gif"].indexOf(props.file.extension) > -1
    );

    return {
      selected,
      isImage,
      openOriginalFile,
    };
  },
});
