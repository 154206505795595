
import { defineComponent } from "vue";
import PendingApprovalCommentWidget from "@/views/dashboard/components/PendingApprovalComment.vue";
import PendingApprovalProductGroupWidget from "@/views/dashboard/components/PendingApprovalProductGroup.vue";
import UnapprovedProductGroupWidget from "@/views/dashboard/components/UnapprovedProductGroup.vue";
import PassiveProductGroupWidget from "@/views/dashboard/components/PassiveProductGroup.vue";
import PendingApprovalProductTabWidget from "@/views/dashboard/components/PendingApprovalProductTab.vue";
import NewOrderWidget from "@/views/dashboard/components/NewOrder.vue";
import InProcessOrdersWidget from "@/views/dashboard/components/InProcessOrders.vue";
import UnapprovedProductTabWidget from "@/views/dashboard/components/UnapprovedProductTab.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "DashboardView",
  components: {
    UnapprovedProductTabWidget,
    NewOrderWidget,
    PendingApprovalProductTabWidget,
    PassiveProductGroupWidget,
    UnapprovedProductGroupWidget,
    PendingApprovalProductGroupWidget,
    PendingApprovalCommentWidget,
    InProcessOrdersWidget,
  },
  setup() {
    const store = useStore();

    const hasPermission = (key: string) => {
      return store.getters["auth/hasPermissionWithMerchant"](key);
    };

    return { hasPermission };
  },
});
