import { RouteRecordRaw, RouterView } from "vue-router";
import Orders from "@/views/orders/list/Main.vue";
import OrderDetail from "@/views/orders/detail/Main.vue";
import OrderForms from "@/views/order-forms/list/Main.vue";
import OrderFormCreate from "@/views/order-forms/create/Main.vue";

const route: Array<RouteRecordRaw> = [
  {
    path: "orders",
    component: RouterView,
    children: [
      {
        path: "",
        name: "orders",
        component: Orders,
        meta: {
          title: "Sipariş Listesi",
          roles: [],
          permissions: ["orders.list"],
        },
      },
      {
        path: "detail/:id",
        name: "orders.detail",
        component: OrderDetail,
        meta: {
          title: "Sipariş Detayı",
          roles: [],
          permissions: ["orders.show"],
        },
      },
    ],
  },
  {
    path: "order-forms",
    component: RouterView,
    children: [
      {
        path: "",
        name: "order-forms",
        component: OrderForms,
        meta: {
          title: "Sipariş Form Listesi",
          roles: [],
          permissions: ["forms.list"],
        },
      },
      {
        path: "create",
        name: "order-forms.create",
        component: OrderFormCreate,
        meta: {
          title: "Sipariş Formu Ekle",
          roles: [],
          permissions: ["forms.create"],
        },
      },
      {
        path: "edit/:id",
        name: "order-forms.edit",
        component: OrderFormCreate,
        meta: {
          title: "Sipariş Formu Düzenle",
          roles: [],
          permissions: ["forms.edit"],
        },
      },
    ],
  },
];

export default route;
