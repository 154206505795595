import { isString } from "lodash";

const regexFour = /^([0-9]+)\|(.+)\|(.+)\|([0-9.]+)$/gi;
const regexFive = /^([0-9]+)\|(.+)\|(.+)\|(.+)\|([0-9.]+)$/gi;

export default {
  display(str: any): string {
    if (!isString(str)) return str;

    const str_array = str.split(";");
    for (let i = 0; i < str_array.length; i++) {
      if (regexFive.test(str_array[i])) {
        // artık işler böyle yürüyor
        str_array[i] = str_array[i].replace(
          regexFive,
          "<span class='whitespace-nowrap'>$1x <b>$3</b></span>"
        );
      } else if (regexFour.test(str_array[i])) {
        // artık işler böyle yürüyor
        str_array[i] = str_array[i].replace(
          regexFour,
          "<span class='whitespace-nowrap'>$1x <b>$3</b></span>"
        );
      }
    }
    return str_array.join(", ");
  },
};
