
import { computed, defineComponent, onMounted, ref } from "vue";
import { get } from "lodash";
import { OrderGroupInterface } from "@/services";
import Clipboard from "@/components/clipboard/Main.vue";
import OrderDistributions from "@/views/orders/detail/components/distributions/Distributions.vue";
import VueModal from "@/components/modal/Main.vue";
import { helper } from "@/utils/helper";
import Tippy from "@/components/tippy/Main.vue";
import { useStore } from "@/store";
import router from "@/router";
import { ToastNotify } from "@/utils/toast-notify";

export default defineComponent({
  name: "OrderSideNavigation",
  components: { Tippy, VueModal, OrderDistributions, Clipboard },
  props: {
    orderDetail: {
      type: Object as () => OrderGroupInterface,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();
    const modalRefId = helper.uuidv4();
    const visibleTransactions = ref<boolean>(false);

    const shipment = computed(() => ({
      name_surname: get(props.orderDetail, "shipping_address.name_surname"),
      phone: get(props.orderDetail, "shipping_address.phone"),
      address: `${get(props.orderDetail, "shipping_address.address")} ${get(
        props.orderDetail,
        "shipping_address.district"
      )} / ${get(props.orderDetail, "shipping_address.city")}`,
      postal_code: get(props.orderDetail, "shipping_address.postal_code"),
    }));

    const invoice = computed(() => ({
      name_surname: get(props.orderDetail, "invoice_address.name_surname"),
      phone: get(props.orderDetail, "invoice_address.phone"),
      email: get(props.orderDetail, "customer.email"),
      address: `${get(props.orderDetail, "invoice_address.address")} ${get(
        props.orderDetail,
        "invoice_address.district"
      )} / ${get(props.orderDetail, "invoice_address.city")}`,
      is_company:
        get(props.orderDetail, "invoice_address.is_company", 0) * 1 === 1,
      company_title: get(props.orderDetail, "invoice_address.company_title"),
      tax_info: `${get(props.orderDetail, "invoice_address.tax_office")} ${get(
        props.orderDetail,
        "invoice_address.tax_number"
      )}`,
    }));

    const visiblePaymentLogs = computed(() => {
      if (store.getters["auth/hasPermission"]("payments.list")) return true;
      return (
        store.getters["auth/isSiteAdmin"] &&
        props.orderDetail?.site === store.getters["auth/getSiteKey"]
      );
    });

    onMounted(() => {
      cash(`#${modalRefId}`).modal("on.show", () => {
        visibleTransactions.value = true;
      });
      cash(`#${modalRefId}`).modal("on.hide", () => {
        visibleTransactions.value = false;
      });
    });

    const openTransactions = () => {
      cash(`#${modalRefId}`).modal("show");
    };

    const goToUserDetail = (id: any) => {
      if (store.getters["auth/hasPermission"]("users.show")) {
        router.push({
          name: "users.edit",
          params: { id },
        });
      }
    };

    const copyCompanyInformationForBayiloji = () => {
      const title =
        (invoice.value.is_company
          ? get(props.orderDetail, "invoice_address.company_title")
          : get(props.orderDetail, "invoice_address.name_surname")) || "";

      try {
        helper.copyToClipboard(
          JSON.stringify({
            name_surname: get(props.orderDetail, "invoice_address.name_surname") || "",
            company: title,
            company_commercial_title: title,
            address: get(props.orderDetail, "invoice_address.address") || "",
            district: get(props.orderDetail, "invoice_address.district") || "",
            city: get(props.orderDetail, "invoice_address.city") || "",
            phone: get(props.orderDetail, "invoice_address.phone") || "",
            email: get(props.orderDetail, "customer.email") || "",
            gsm: get(props.orderDetail, "invoice_address.phone") || "",
            group: "Eniyiperde.com",
            tax_office: invoice.value.is_company
              ? get(props.orderDetail, "invoice_address.tax_office") || ""
              : "",
            tax_number: invoice.value.is_company
              ? get(props.orderDetail, "invoice_address.tax_number") || ""
              : "11111111111",
            country:
              get(props.orderDetail, "invoice_address.country") || "TÜRKİYE",
            postal_zone:
              get(props.orderDetail, "invoice_address.postal_code") || "",
          })
        );

        ToastNotify({
          className: "info",
          text: "Firma bilgileri bilgisayara kopyalandı!",
        });
      } catch (e) {
        console.log(e);
      }
    };

    return {
      modalRefId,
      visibleTransactions,
      shipment,
      invoice,
      visiblePaymentLogs,
      openTransactions,
      goToUserDetail,
      copyCompanyInformationForBayiloji,
    };
  },
});
