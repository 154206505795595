
import { computed, defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { PriceCalculationRule } from "@/services";
import { ValidationErrors } from "@/utils/records/validation-errors";
import { get } from "lodash";

export default defineComponent({
  name: "PriceCalculationRuleWidthHeightCalculation",
  components: {
    VueInput,
    VueInputContainer,
  },
  props: {
    query: {
      type: Object as () => PriceCalculationRule,
      default: () => ({}),
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableQuery = reactive<PriceCalculationRule>(toRaw(props.query));

    const greater1 = computed({
      get() {
        const e = editableQuery.widthxheight_greater.toString().split(":");
        const eWidth = get(e, 0, "");
        return get(eWidth.toString().split("-"), 0, 0);
      },
      set(variable: any) {
        const e = editableQuery.widthxheight_greater.toString().split(":");
        const eWidth = get(e, 0, "").toString().split("-");
        const eHeight = get(e, 1, "").toString().split("-");
        editableQuery.widthxheight_greater = `${variable || 0}-${get(
          eWidth,
          1,
          0
        )}:${get(eHeight, 0, 0)}-${get(eHeight, 1, 0)}`;
      },
    });

    const greater2 = computed({
      get() {
        const e = editableQuery.widthxheight_greater.toString().split(":");
        const eWidth = get(e, 0, "");
        return get(eWidth.toString().split("-"), 1, 0);
      },
      set(variable: any) {
        const e = editableQuery.widthxheight_greater.toString().split(":");
        const eWidth = get(e, 0, "").toString().split("-");
        const eHeight = get(e, 1, "").toString().split("-");
        editableQuery.widthxheight_greater = `${get(eWidth, 0, 0)}-${
          variable || 0
        }:${get(eHeight, 0, 0)}-${get(eHeight, 1, 0)}`;
      },
    });

    const greater3 = computed({
      get() {
        const e = editableQuery.widthxheight_greater.toString().split(":");
        const eHeight = get(e, 1, "");
        return get(eHeight.toString().split("-"), 0, 0);
      },
      set(variable: any) {
        const e = editableQuery.widthxheight_greater.toString().split(":");
        const eWidth = get(e, 0, "").toString().split("-");
        const eHeight = get(e, 1, "").toString().split("-");
        editableQuery.widthxheight_greater = `${get(eWidth, 0, 0)}-${get(
          eWidth,
          1,
          0
        )}:${variable || 0}-${get(eHeight, 1, 0)}`;
      },
    });

    const greater4 = computed({
      get() {
        const e = editableQuery.widthxheight_greater.toString().split(":");
        const eHeight = get(e, 1, "");
        return get(eHeight.toString().split("-"), 1, 0);
      },
      set(variable: any) {
        const e = editableQuery.widthxheight_greater.toString().split(":");
        const eWidth = get(e, 0, "").toString().split("-");
        const eHeight = get(e, 1, "").toString().split("-");
        editableQuery.widthxheight_greater = `${get(eWidth, 0, 0)}-${get(
          eWidth,
          1,
          0
        )}:${get(eHeight, 0, 0)}-${variable || 0}`;
      },
    });

    return {
      editableQuery,
      greater1,
      greater2,
      greater3,
      greater4,
    };
  },
});
