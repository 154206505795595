import {
  GeneralApiResponse,
  GetUserResult,
  GetUserRoleResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const userService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetUserResult>>(`/api/users/${id}`),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetUserResult>>(`/api/users`, params),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetUserResult>>(
      `/api/users/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/users/${id}`),

  changePassword: (id: any, params: unknown) =>
    useAxios().post<GeneralApiResponse<GetUserResult>>(
      `/api/users/${id}/change-password`,
      params
    ),

  tokens: (id: any) =>
    useAxios().get<GeneralApiResponse<any>>(`/api/users/${id}/tokens`),

  deleteToken: (userId: any, id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(
      `/api/users/${userId}/tokens/${id}`
    ),
};

export const userRoleService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetUserRoleResult>>(`/api/roles/${id}`),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetUserRoleResult>>(
      `/api/roles`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetUserRoleResult>>(
      `/api/roles/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/roles/${id}`),
};
