import { GeneralApiResponse, GetMenuResult, useAxios } from "@/services";

export * from "./api.types";

export const menuService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetMenuResult>>(`/api/menus/${id}`),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetMenuResult>>(`/api/menus`, params),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetMenuResult>>(
      `/api/menus/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/menus/${id}`),
};
