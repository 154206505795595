import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tippy = _resolveComponent("Tippy")!

  return (_openBlock(), _createBlock(_component_Tippy, {
    tag: _ctx.tag,
    href: "javascript:;",
    content: "Kopyalandı!",
    "trigger-click": "",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$h.copyToClipboard(_ctx.text)), ["prevent","stop"]))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["tag"]))
}