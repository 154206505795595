
import { defineComponent } from "vue";
import { get, has, cloneDeep } from "lodash";
import { debounce } from "debounce";

export default defineComponent({
  name: "VueSelect",
  props: {
    modelValue: { default: () => "" },
    modelModifiers: { default: () => ({}) },
    hasError: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    let changeValue = (e: any): void => {
      emit("update:modelValue", cloneDeep(get(e, "target.value", "")));
    };

    if (has(props.modelModifiers, "lazy")) {
      changeValue = debounce(changeValue, 500);
    }

    return {
      changeValue,
    };
  },
});
