import { RouteRecordRaw, RouterView } from "vue-router";
import MaterialCategories from "@/views/material-categories/list/Main.vue";
import MaterialCategoryCreate from "@/views/material-categories/create/Main.vue";

const route: Array<RouteRecordRaw> = [
  {
    path: "material-categories",
    component: RouterView,
    children: [
      {
        path: "",
        name: "material-categories",
        component: MaterialCategories,
        meta: {
          title: "Materyal Kategori Listesi",
          roles: [],
          permissions: ["categories.list"],
        },
      },
      {
        path: "create",
        name: "material-categories.create",
        component: MaterialCategoryCreate,
        meta: {
          title: "Materyal Kategori Ekle",
          roles: [],
          permissions: ["categories.create"],
        },
      },
      {
        path: "edit/:id",
        name: "material-categories.edit",
        component: MaterialCategoryCreate,
        meta: {
          title: "Materyal Kategori Düzenle",
          roles: [],
          permissions: ["categories.edit"],
        },
      },
    ],
  },
];

export default route;
