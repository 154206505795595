
import { defineComponent, onMounted, ref } from "vue";
import { useFetchRecords } from "@/utils/records/fetch";
import { useFilterRecords } from "@/utils/records/filter";
import Pagination from "@/components/pagination/Main.vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import { useStore } from "@/store";
import Clipboard from "@/components/clipboard/Main.vue";
import { helper } from "@/utils/helper";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import FilterButton from "@/components/filter-container/Button.vue";
import { basketService } from "@/services";
import VueDatetimePicker from "@/components/form-elements/datetime-picker/Main.vue";

export default defineComponent({
  name: "Baskets",
  components: {
    VueDatetimePicker,
    FilterButton,
    VueSelect2,
    Clipboard,
    Tippy,
    VueInput,
    VueInputContainer,
    FilterContainer,
    Pagination,
  },
  setup() {
    const stateModalId = helper.uuidv4();

    const formattedList = ref<Record<string, any>[]>([]);

    const { loading, list, fetch } = useFetchRecords({
      serviceMethod: basketService.fetch,
      success() {
        formattedList.value = list.data.map((item: any) => {
          return {
            ...item,
          };
        });
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        merchant_id: "",
        user_id: "",
        email: "",
        name_surname: "",
        product_id: "",
        barcode: "",
        date_range: "",
      },
    });

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const goToDetail = (id: any) => {
      // router.push({ name: "orders.detail", params: { id } });
    };

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
    });

    return {
      stateModalId,
      loading,
      list,
      formattedList,
      filterState,
      filterFunctions,
      merchantSelect2Options,
      filterCount,
      goToDetail,
    };
  },
});
