import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex overflow-hidden" }
const _hoisted_2 = {
  key: 0,
  class: "fixed left-0 top-0 w-full h-full bg-gray-100 bg-opacity-20",
  style: {"z-index":"99999"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileMenu = _resolveComponent("MobileMenu")!
  const _component_SideNavigation = _resolveComponent("SideNavigation")!
  const _component_TopNavigation = _resolveComponent("TopNavigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Confirm = _resolveComponent("Confirm")!
  const _component_FileManagerModal = _resolveComponent("FileManagerModal")!
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _component_PdfViewer = _resolveComponent("PdfViewer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MobileMenu),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_SideNavigation, { class: "side--navigation" }),
      _createElementVNode("div", {
        class: _normalizeClass([{ 'content--dashboard': _ctx.dashboardLayout }, "content"])
      }, [
        _createVNode(_component_TopNavigation),
        _createVNode(_component_router_view)
      ], 2)
    ]),
    _createVNode(_component_Confirm, { ref: "confirmRef" }, null, 512),
    _createVNode(_component_FileManagerModal, { ref: "fileManagerRef" }, null, 512),
    _createVNode(_component_ColorPicker, {
      ref: "colorPickerRef",
      class: "absolute",
      theme: "light"
    }, null, 512),
    _createVNode(_component_PdfViewer, { ref: "pdfViewerRef" }, null, 512),
    (_ctx.$store.state.system.processing.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}