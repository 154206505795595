import {
  GeneralApiResponse,
  GetFormResult,
  GetFormsResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const formService = {
  fetch: () => useAxios().get<GeneralApiResponse<GetFormsResult>>(`/api/forms`),

  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetFormResult>>(`/api/forms/${id}`),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetFormResult>>(`/api/forms`, params),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetFormResult>>(
      `/api/forms/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/forms/${id}`),
};
