import { RouteLocationNormalizedLoaded as Route, Router } from "vue-router";
import Velocity from "velocity-animate";
import { cloneDeep, get } from "lodash";
import { Store } from "@/store";

// Setup side menu
type Menu = {
  icon: string;
  pageName: string;
  title: string;
  active?: boolean;
  activeDropdown?: boolean;
  ignore?: boolean;
  subMenu?: Array<Menu | string>;
  permission?: () => boolean;
};

const findActiveMenu = (
  subMenu: Array<Menu | string>,
  route: Route
): boolean => {
  let match = false;
  subMenu.forEach((item) => {
    if (typeof item !== "string") {
      if (item.pageName === route.name && !get(item, "ignore", false)) {
        match = true;
      } else if (!match && item.subMenu) {
        match = findActiveMenu(item.subMenu, route);
      }
    }
  });
  return match;
};

const hasPermission = (item: Menu, store: Store): boolean => {
  const roles: any[] = get(item, "roles") || [];
  const permissions: any[] = get(item, "permissions") || [];

  if (
    roles.length > 0 &&
    roles.indexOf(store.getters["auth/getRoleType"]) === -1
  )
    return false;

  if (store.getters["auth/isStaff"] && permissions.length > 0) {
    let can = false;
    permissions.map((p) => {
      if (store.getters["auth/hasPermission"](p)) can = true;
    });
    return can;
  }

  return true;
};

const nestedMenu = (
  menu: Array<Menu | string>,
  route: Route,
  store: Store
): Array<Menu | string> => {
  const newMenus: Array<Menu | string> = [];
  cloneDeep(menu).forEach((item, key) => {
    if (typeof item === "string") {
      newMenus.push(item);
    } else if (hasPermission(item, store)) {
      let menuItem = menu[key] as Menu;
      menuItem.active =
        (item.pageName === route.name ||
          (item.subMenu && findActiveMenu(item.subMenu, route))) &&
        !item.ignore;

      if (item.subMenu) {
        menuItem.activeDropdown = findActiveMenu(item.subMenu, route);
        menuItem = {
          ...menuItem,
          subMenu: nestedMenu(item.subMenu, route, store),
        };
      }

      if (!item.subMenu || (menuItem.subMenu || []).length > 0) {
        newMenus.push(menuItem);
      }
    }
  });

  return newMenus;
};

const linkTo = (menu: Menu, router: Router, event: Event): void => {
  if (menu.subMenu) {
    menu.activeDropdown = !menu.activeDropdown;
  } else {
    event.preventDefault();
    router.push({
      name: menu.pageName,
    });
  }
};

const enter = (el: HTMLElement, done: () => void): void => {
  Velocity(el, "slideDown", { duration: 300 }, { complete: done });
};

const leave = (el: HTMLElement, done: () => void): void => {
  Velocity(el, "slideUp", { duration: 300 }, { complete: done });
};

export { Menu, nestedMenu, linkTo, enter, leave };
