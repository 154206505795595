import { RouteRecordRaw, RouterView } from "vue-router";
import Contents from "@/views/contents/list/Main.vue";
import ContentCreate from "@/views/contents/create/Main.vue";

const route: Array<RouteRecordRaw> = [
  {
    path: "contents",
    component: RouterView,
    children: [
      {
        path: "",
        name: "contents",
        component: Contents,
        meta: {
          title: "İçerik Listesi",
          roles: [],
          permissions: ["contents.list"],
        },
      },
      {
        path: "create",
        name: "contents.create",
        component: ContentCreate,
        meta: {
          title: "İçerik Ekle",
          roles: [],
          permissions: ["contents.create"],
        },
      },
      {
        path: "edit/:id",
        name: "contents.edit",
        component: ContentCreate,
        meta: {
          title: "İçerik Düzenle",
          roles: [],
          permissions: ["contents.edit"],
        },
      },
    ],
  },
];

export default route;
