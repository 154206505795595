import { ActionTree, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { ActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { MutationTypes } from "./mutation-types";
import { cloneDeep, findIndex, get } from "lodash";
import { PrintTemplateInterface, SettingsInterface } from "@/services";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.SET_SETTINGS](
    ctx: AugmentedActionContext,
    settings: SettingsInterface
  ): void;
  [ActionTypes.SET_SITES](ctx: AugmentedActionContext, sites: any[]): void;
  [ActionTypes.SET_SITE](ctx: AugmentedActionContext, site: any): void;
  [ActionTypes.DETECT_SITE](ctx: AugmentedActionContext, profile: any): void;
  [ActionTypes.SET_PROCESSING](ctx: AugmentedActionContext, uuid: string): void;
  [ActionTypes.REMOVE_PROCESSING](
    ctx: AugmentedActionContext,
    uuid: string
  ): void;
  [ActionTypes.SET_PRINT_TEMPLATES](
    ctx: AugmentedActionContext,
    templates: PrintTemplateInterface[]
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.SET_SETTINGS]({ commit }, settings: SettingsInterface) {
    commit(MutationTypes.SET_SETTINGS, settings);
  },
  [ActionTypes.SET_SITES]({ commit }, sites: any[]) {
    commit(MutationTypes.SET_SITES, sites);
  },
  [ActionTypes.SET_SITE](ctx, site: any) {
    window.localStorage.setItem("siteKey", site);
  },
  [ActionTypes.DETECT_SITE]({ getters, dispatch, state }, profile) {
    let sites = cloneDeep(state.sites);
    if (get(profile, "type") !== "admin") {
      const key = get(profile, "site_admin.key", "1") * 1;
      sites = sites.filter((item: any) => item.key === key || item.key === 1);
    }

    dispatch(ActionTypes.SET_SITES, sites);

    const index = findIndex(sites, { key: getters["site"] });
    if (index === -1) {
      dispatch(ActionTypes.SET_SITE, get(sites, `${0}.key`, 1));
    }
  },
  [ActionTypes.SET_PROCESSING]({ commit }, uuid: string) {
    commit(MutationTypes.SET_PROCESSING, uuid);
  },
  [ActionTypes.REMOVE_PROCESSING]({ commit }, uuid: string) {
    commit(MutationTypes.REMOVE_PROCESSING, uuid);
  },
  [ActionTypes.SET_PRINT_TEMPLATES]({ commit }, templates) {
    commit(MutationTypes.SET_PRINT_TEMPLATES, templates);
  },
};
