import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    class: _normalizeClass(["form-control", { 'border-red-200 bg-red-100': _ctx.hasError }]),
    value: _ctx.modelValue,
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeValue && _ctx.changeValue(...args))),
    autocomplete: "off"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 42, _hoisted_1))
}