import MaterialCategoryCreateOrUpdate from "@/views/material-categories/create/Main.vue";

export const components = {
  "material-category-create": MaterialCategoryCreateOrUpdate,
};

export type QuickPageComponents = keyof typeof components;

export interface QuickPageOpenProps {
  pageKey: QuickPageComponents;
  id?: any;
  data?: Record<string, any>;
  callback?(result: any): void;
}

export interface QuickPageInterface {
  open(options: QuickPageOpenProps): void;
}

export interface QuickSubPageInterface {
  referenceKey?: string;
  id?: any;
  data?: Record<string, any>;
  onSave?(result: any): void;
  onClose?(): void;
}

export const quickSubPage = (props: QuickSubPageInterface) => {
  const onSave = (result: any) => {
    if (typeof props.onSave === "function") props.onSave(result);
  };
  const onClose = () => {
    if (typeof props.onClose === "function") props.onClose();
  };
  return {
    enabled: !!props.referenceKey,
    id: (id: any) => {
      if (props.referenceKey) return props.id || null;
      return id;
    },
    data: props.data || {},
    onSave,
    onClose,
  };
};
