import Dropzone, { DropzoneFile, DropzoneOptions } from "dropzone";
import { fileService, FileType } from "@/services";
import { get, findIndex, forEach, isString } from "lodash";
import { helper } from "@/utils/helper";

const previewTemplate = `<div class="dz-preview dz-file-preview">
              <div class="dz-container">
                <div class="dz-image">
                    <img data-dz-thumbnail />
                </div>
                <div class="dz-details">
                    <div class="dz-filename"><span data-dz-name></span></div>
                    <div class="dz-size"><span data-dz-size></span></div>
                </div>
              </div>
              <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
              <div class="dz-error-message"><span data-dz-errormessage></span></div>
              <div class="dz-waiting">
                <i class="feather-loader inline-block spin-5 mr-1.5"></i>
                <span>Dosya sunucuya yüklemek için hazırlanıyor</span>
              </div>
              <div class="dz-completing">
                <i class="feather-loader inline-block spin-5 mr-1.5"></i>
                <span>Dosya yüklemesi tamamlanıyor</span>
              </div>
          </div>
      `;

const getRatio = (width: number, height: number): any => {
  if (!height || !width) return null;
  switch (helper.round(width / height, 1)) {
    case 1:
      return "1:1";
    case 1.3:
      return "4:3";
    case 1.5:
      return "3:2";
    case 1.7:
    case 1.8:
      return "16:9";
    default:
      return width + ":" + height;
  }
};

const init = (
  el: HTMLElement,
  options: DropzoneOptions,
  uploading: () => void,
  uploaded: (file: FileType) => void,
  completed: () => void
): void => {
  Dropzone.autoDiscover = false;

  const signedFiles: Record<string, any>[] = [];

  el.dropzone = new Dropzone(el, {
    autoProcessQueue: false,
    maxFilesize: 10,
    thumbnailWidth: 150,
    addRemoveLinks: true,
    uploadMultiple: false,
    previewTemplate,
    dictCancelUpload: `<i class="feather-x-circle"></i>`,
    dictRemoveFile: `<i class="feather-x-circle"></i>`,

    ...options,

    url: (files) => {
      const index = findIndex(signedFiles, {
        fileUUID: files[0]?.upload?.uuid,
      });
      return get(signedFiles, [index, "signedURL"], "");
    },
    method: "PUT",
    parallelUploads: 1,

    sending(file: Dropzone.DropzoneFile, xhr: XMLHttpRequest) {
      const _send = xhr.send;
      xhr.send = function () {
        _send.call(xhr, file);
      };
    },

    addedfiles(files: Dropzone.DropzoneFile[]) {
      const uploadFileUUID: any[] = [];
      forEach(files, (f) => {
        if (f) uploadFileUUID.push(f.upload?.uuid);
      });
      fileService
        .generateSignedUrl({
          count: files.length,
        })
        .then((result) => {
          if (result.kind === "ok") {
            result.data.map((signed, index) => {
              signedFiles.push({
                fileUUID: uploadFileUUID[index],
                signedUUID: signed.uuid,
                signedURL: signed.url,
              });
            });

            uploading();
            el.dropzone.processQueue();
          }
        });
    },

    success(file: DropzoneFile) {
      const index = findIndex(signedFiles, {
        fileUUID: file.upload?.uuid,
      });
      const uuid = get(signedFiles, [index, "signedUUID"], "");
      let storeParams: Record<string, any> = {};
      try {
        const dataParams = cash(el).data("params");
        if (isString(dataParams))
          storeParams = JSON.parse(cash(el).data("params"));
        else storeParams = dataParams;
      } catch (e) {
        console.error(e);
      }
      fileService
        .store({
          ...storeParams,
          uuid,
          name: file.name,
          size: file.size,
          width: get(file, "width"),
          height: get(file, "height"),
          ratio: getRatio(get(file, "width"), get(file, "height")),
        })
        .then((result) => {
          signedFiles.splice(index, 1);
          if (result.kind === "ok") {
            el.dropzone.removeFile(file);
            uploaded(result.data);
            if (signedFiles.length > 0) el.dropzone.processQueue();
            else completed();
          } else {
            cash(file.previewElement)
              .addClass("dz-error")
              .find(".dz-error-message")
              .text(result.message);
          }
        });
    },
  });
};

const reInit = (
  el: HTMLElement,
  options: DropzoneOptions,
  uploading: () => void,
  uploaded: (file: FileType) => void,
  completed: () => void
): void => {
  if (el.dropzone) el.dropzone.destroy();
  init(el, options, uploading, uploaded, completed);
};

export { init, reInit };
