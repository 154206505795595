import { ref } from "vue";
import { ficheService, TransactionInterface } from "@/services";
import { forEach } from "lodash";

export const useDetectRelation = () => {
  const loading = ref<boolean>(false);
  const list = ref<TransactionInterface[]>([]);
  const errors = ref<string[]>([]);

  const fetch = async (
    params: {
      transaction_type: any;
      time: any;
      merchant_id: any;
      with: any[];
    },
    selected: any[]
  ): Promise<any[]> => {
    errors.value = [];
    let newSelected: any[] = [];
    loading.value = true;

    const result = await ficheService.detectRelations(params);
    if (result.kind === "ok") {
      list.value = result.data;
      newSelected = result.data.filter((item) => {
        return selected.length === 0 || selected.indexOf(item.id) > -1;
      });
    } else {
      if (result.kind === "validation") {
        forEach(result.fields, (err) => {
          err.map((e) => {
            errors.value.push(e);
          });
        });
      } else {
        errors.value.push(result.message || "");
      }
    }

    loading.value = false;

    return newSelected.map((item) => {
      return item.id;
    });
  };

  return {
    loading,
    list,
    errors,
    fetch,
  };
};
