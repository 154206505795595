
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref,
  Ref,
  toRaw,
} from "vue";
import { cloneDeep, findIndex, get, set } from "lodash";
import { helper } from "@/utils/helper";
import { ConfirmRef } from "@/components/confirm";
import Draggable from "vuedraggable";
import ContentAssetTiptap from "@/components/assets/AssetTiptap.vue";
import Tippy from "@/components/tippy/Main.vue";
import VueModal from "@/components/modal/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueSelect from "@/components/form-elements/select/Main.vue";
import VueColorPicker from "@/components/form-elements/color/Main.vue";
import WorkspaceResponsiveFields from "@/components/assets/WorkspaceResponsiveFields.vue";
import { ToastNotify } from "@/utils/toast-notify";
import { useStore } from "@/store";

export default defineComponent({
  name: "ContentAssets",
  components: {
    WorkspaceResponsiveFields,
    VueColorPicker,
    VueSelect,
    VueInputContainer,
    VueModal,
    Tippy,
    Draggable,
    ContentAssetTiptap,
  },
  props: {
    query: {
      type: Object,
      default: () => ({}),
    },
    hiddenToolbar: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    tiptapClass: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const modalRefId = helper.uuidv4();
    const editableQuery = reactive(toRaw(props.query));
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);

    const editWorkspaceId = ref<string>("");
    const editWorkspace = ref<Record<string, any>>({});
    const visibleWorkspace = ref<string[]>([]);

    const assetTypes = computed(() => {
      return store.getters["system/definitions"]("assets").filter((o: any) => {
        return (
          !props.hiddenToolbar ||
          ["photo", "photo-gallery", "video"].indexOf(o.value) > -1
        );
      });
    });

    const responsiveFields = {
      xs: -1,
      sm: -1,
      md: -1,
      lg: -1,
      xl: -1,
      "2xl": -1,
    };

    const defaultWorkspace = {
      uuid: "",
      backgroundColor: "",
      box: "normal",
      colSpan: cloneDeep({ ...responsiveFields, xs: 12 }),
      colStart: cloneDeep(responsiveFields),
      pl: cloneDeep(responsiveFields),
      pr: cloneDeep(responsiveFields),
      pt: cloneDeep(responsiveFields),
      pb: cloneDeep(responsiveFields),
      value: {
        type: "doc",
        content: [
          {
            attrs: { textAlign: "left" },
            type: "paragraph",
          },
        ],
      },
    };

    const addWorkspace = () => {
      const uuid = helper.uuidv4();
      editableQuery.workspaces.push({
        ...defaultWorkspace,
        uuid,
      });
      visibleWorkspace.value = [uuid];
    };

    const cloneWorkspace = (uuid: string) => {
      const index = findIndex(editableQuery.workspaces, { uuid });
      if (index > -1) {
        const uuid = helper.uuidv4();
        editableQuery.workspaces.splice(index + 1, 0, {
          ...cloneDeep(editableQuery.workspaces[index]),
          uuid,
        });
        visibleWorkspace.value = [uuid];
      }
    };

    const deleteWorkspace = (uuid: string) => {
      confirmRef?.value?.show({
        text: "Seçilen çalışma alanını silmek istediğinize emin misiniz?",
        icon: "feather-alert-circle",
        iconClass: "text-red-400",
        confirmText: "Evet",
        cancelText: "Hayır",
        callback: () => {
          const index = findIndex(editableQuery.workspaces, { uuid });
          if (index > -1) {
            editableQuery.workspaces.splice(index, 1);
          }
        },
      });
    };

    const onEditWorkspace = (id: string) => {
      const index = findIndex(editableQuery.workspaces, { uuid: id });
      if (index > -1) {
        editWorkspaceId.value = id;
        editWorkspace.value = get(editableQuery.workspaces, index);
        cash(`#${modalRefId}`).modal("show");
      }
    };

    const saveWorkspace = () => {
      const index = findIndex(editableQuery.workspaces, {
        uuid: editWorkspaceId.value,
      });
      if (index > -1) {
        set(editableQuery.workspaces, index, editWorkspace.value);
      }
      cash(`#${modalRefId}`).modal("hide");
    };

    const copySettings = (uuid: string) => {
      const index = findIndex(editableQuery.workspaces, { uuid });
      if (index > -1) {
        const { backgroundColor, box, colSpan, colStart, pl, pr, pt, pb } =
          cloneDeep(get(editableQuery.workspaces, index));

        window.localStorage.setItem(
          "asset_settings",
          JSON.stringify({
            backgroundColor,
            box,
            colSpan,
            colStart,
            pl,
            pr,
            pt,
            pb,
          })
        );

        ToastNotify({
          className: "info",
          text: "Çalışma Alanı Ayarları Kopyalandı!",
        });
      }
    };

    const pasteSettings = (uuid: string) => {
      try {
        const index = findIndex(editableQuery.workspaces, { uuid });
        if (index > -1) {
          const settings = JSON.parse(
            window.localStorage.getItem("asset_settings") || "{}"
          );

          if (Object.keys(settings).length > 0) {
            editableQuery.workspaces[index] = {
              ...editableQuery.workspaces[index],
              backgroundColor:
                get(settings, "backgroundColor") ||
                editableQuery.workspaces[index].backgroundColor,
              box: get(settings, "box") || editableQuery.workspaces[index].box,
              colSpan:
                get(settings, "colSpan") ||
                editableQuery.workspaces[index].colSpan,
              colStart:
                get(settings, "colStart") ||
                editableQuery.workspaces[index].colStart,
              pl: get(settings, "pl") || editableQuery.workspaces[index].pl,
              pr: get(settings, "pr") || editableQuery.workspaces[index].pr,
              pt: get(settings, "pt") || editableQuery.workspaces[index].pt,
              pb: get(settings, "pb") || editableQuery.workspaces[index].pb,
            };

            ToastNotify({
              className: "success",
              text: "Çalışma Alanı Ayarları Değiştirildi!",
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    const toggleVisibleWorkspace = (uuid: string) => {
      const index = visibleWorkspace.value.indexOf(uuid);
      if (index === -1) visibleWorkspace.value.push(uuid);
      else visibleWorkspace.value.splice(index, 1);
    };

    onMounted(() => {
      // if (
      //   editableQuery.assets &&
      //   (!editableQuery.workspaces || editableQuery.workspaces.length === 0)
      // ) {
      //   editableQuery.workspaces = convertAssetsToWorkspace(
      //     editableQuery.assets
      //   );
      // }
      if (editableQuery.workspaces.length === 0) {
        addWorkspace();
      }
      visibleWorkspace.value.push(get(editableQuery.workspaces, "0.uuid"));
    });

    return {
      assetTypes,
      modalRefId,
      editableQuery,
      editWorkspace,
      visibleWorkspace,
      addWorkspace,
      cloneWorkspace,
      deleteWorkspace,
      onEditWorkspace,
      saveWorkspace,
      toggleVisibleWorkspace,
      copySettings,
      pasteSettings,
    };
  },
});
