import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";
import { findIndex, get } from "lodash";

// Getters types
export type Getters = {
  categories: (state: LocalState) => any[];
  category(state: LocalState): (id: any, field?: any) => any;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  categories: (state): any[] => {
    return state.categories;
  },
  category:
    (state) =>
    (id, field: any = null) => {
      const index = findIndex(state.categories, { id: id * 1 });
      if (field) return get(state.categories, `${index}.${field}`);
      return get(state.categories, `${index}`);
    },
};
