
import { computed, defineComponent } from "vue";
import { get } from "lodash";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "CardBox",
  props: {
    boxIndex: {
      type: Number,
      required: true,
    },
    color: {
      required: true,
    },
  },
  setup(props) {
    const colorCode = computed(() => get(props.color, "color_code", "#FFFFFF"));
    const textColor = computed(() => helper.invertColor(colorCode.value, true));
    const borderColor = computed(() => helper.invertColor(colorCode.value));
    const backgroundColor = computed(() => colorCode.value);
    const backgroundImage = computed<any>(() => {
      const url = get(props.color, "photo.avatar");
      return url ? `url(${url})` : null;
    });

    const boxTitle = computed(() => {
      return `${props.boxIndex < 9 ? "0" : ""}${props.boxIndex + 1}`;
    });

    return {
      textColor,
      borderColor,
      backgroundColor,
      backgroundImage,
      boxTitle,
    };
  },
});
