import { random } from "lodash";

export const generateBlobRadius = (): string => {
  const percentage1 = random(25, 75);
  const percentage2 = random(25, 75);
  const percentage3 = random(25, 75);
  const percentage4 = random(25, 75);
  const percentage11 = 100 - percentage1;
  const percentage21 = 100 - percentage2;
  const percentage31 = 100 - percentage3;
  const percentage41 = 100 - percentage4;
  return `${percentage1}% ${percentage11}% ${percentage21}% ${percentage2}% / ${percentage3}% ${percentage4}% ${percentage41}% ${percentage31}%`;
};
