
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import {
  GeneralApiResponse,
  productService,
  MaterialProductInterface,
  GetMaterialProductResult,
} from "@/services";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import VueButton from "@/components/form-elements/button/Main.vue";
import MaterialGeneralInformation from "@/views/product-materials/create/components/GeneralInformation.vue";
import MaterialSideNavigation from "@/views/product-materials/create/components/SideNavigation.vue";
import FileManagerSelectGallery from "@/components/file-manager/SelectGallery.vue";
import { useStore } from "@/store";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "MaterialCreateOrUpdate",
  components: {
    FileManagerSelectGallery,
    MaterialSideNavigation,
    MaterialGeneralInformation,
    VueButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const activeView = ref<string>("information");
    const componentRefreshKey = ref<string>(helper.uuidv4());

    const id = computed(() => get(route.params, "id"));

    const canEdit = computed(() => {
      return store.getters["auth/hasPermissionWithMerchant"]("products.edit");
    });

    const { loading, fetch } = useReadRecord<
      MaterialProductInterface,
      GetMaterialProductResult
    >({
      id: id.value,
      serviceMethod: productService.show,
      success(result) {
        if (result.kind === "ok") {
          setQuery(result.data);
          componentRefreshKey.value = helper.uuidv4();
        }
      },
      fail() {
        router.go(-1);
      },
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<
        MaterialProductInterface,
        GeneralApiResponse<GetMaterialProductResult>
      >({
        query: {
          id: "",
          title: "",
          status: "1",
          material_category_id: get(route.query, "category_id") || "",
          merchant_id: "",
          barcode: "",
          item_code: "",
          stock_code: "",
          color: "",
          color_code: "",
          min_width: "0",
          max_width: "0",
          min_height: "0",
          max_height: "0",
          vat_rate: "18",
          old_price: "0",
          price: "0",
          merchant_price: "0",
          merchant_old_price: "0",
          currency: "0",
          stock: "0",
          unit: "adet",
          horizontal_usage: "0",
          price_calculation_rules: {},
          photos: {
            data: [],
          },
        },
        storeServiceMethod: productService.store,
        updateServiceMethod: productService.update,
        success(result) {
          if (result?.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "Alt Ürün Kaydedildi!",
            });
            if (!id.value) {
              router.go(-1);
            } else {
              setQuery(result.data);
              componentRefreshKey.value = helper.uuidv4();
            }
          }
        },
      });

    const changeView = (view: string) => {
      activeView.value = view;
    };

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) fetch(id.value);
    });

    return {
      loading,
      processing,
      query,
      activeView,
      validationErrors,
      canEdit,
      componentRefreshKey,
      changeView,
      onSubmit,
    };
  },
});
