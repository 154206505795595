
import { ref, defineComponent, watch, computed } from "vue";
import { init, reInit, destroy, Select2Options } from "./index";
import cash from "cash-dom";

export default defineComponent({
  name: "VueSelect2",
  directives: {
    select: {
      mounted(el, { value }) {
        init(el, value.props, value.emit);
      },
      unmounted(el) {
        destroy(el);
      },
    },
  },
  props: {
    options: {
      type: Object as () => Select2Options,
      default: () => ({}),
    },
    modelValue: {
      default: () => "",
    },
  },
  setup(props, context) {
    const selectRef = ref<HTMLElement>();

    watch(
      computed(() => props.modelValue),
      (val) => {
        if (selectRef.value && val !== cash(selectRef.value).val()) {
          const valueArray = val ? val.toString().split(",") : [];
          jQuery(selectRef.value).val(valueArray).trigger("change");
        }
      },
      { flush: "post" }
    );

    watch(
      computed(() => props.options),
      (val) => {
        if (selectRef.value) {
          reInit(
            selectRef.value,
            { modelValue: props.modelValue, options: val },
            context.emit
          );
        }
      },
      { deep: true, flush: "post" }
    );

    return {
      selectRef,
      props,
      ...context,
    };
  },
});
