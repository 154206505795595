
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { cloneDeep, findIndex, get } from "lodash";
import FileManagerSelect from "@/components/file-manager/Select.vue";

export default defineComponent({
  name: "FileManagerSelectGallery",
  components: { FileManagerSelect },
  props: {
    modelValue: {
      type: Array as () => Record<string, any>[],
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selected = ref<Record<string, any>[]>([]);

    const setSelected = () => {
      selected.value = cloneDeep(props.modelValue);
    };

    const isImage = computed(() => {
      return true;
    });

    const paths = computed(() => {
      return selected.value
        .map((item) => get(item, "path", ""))
        .filter((item) => !!item);
    });

    onMounted(() => {
      setSelected();
    });

    watch(() => props.modelValue, setSelected, { flush: "post" });

    const onAddedFiles = (items: Record<string, any>[]) => {
      if (!props.multiple) selected.value = [];
      selected.value = [...selected.value, ...items];
      emit("update:model-value", selected.value);
    };

    const onRemovedFile = (path: string) => {
      const index = findIndex(selected.value, { path });
      selected.value.splice(index, 1);
      emit("update:model-value", selected.value);
    };

    const onMoveEnd = (paths: string[]) => {
      const sortSelected: Record<string, any>[] = [];
      paths.map((path) => {
        const index = findIndex(selected.value, { path });
        if (index > -1) {
          sortSelected.push(get(selected.value, index));
        }
      });
      selected.value = sortSelected;
      emit("update:model-value", selected.value);
    };

    return {
      selected,
      isImage,
      paths,
      onAddedFiles,
      onRemovedFile,
      onMoveEnd,
    };
  },
});
