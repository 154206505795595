
import { defineComponent, onMounted, reactive, ref } from "vue";
import { reportService } from "@/services";
import { get } from "lodash";
import Chart from "@/components/chart/Main.vue";
import Clipboard from "@/components/clipboard/Main.vue";
import { helper } from "@/utils/helper";
import { ToastNotify } from "@/utils/toast-notify";
import Tippy from "@/components/tippy/Main.vue";
import { MerchantOrderReport } from "@/utils/excel/merchant-order-report";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";

export default defineComponent({
  name: "MerchantOrderReport",
  components: { VueInputContainer, Tippy, Clipboard, Chart },
  props: {
    from: {
      required: true,
    },
    to: {
      required: true,
    },
    merchantId: {
      required: true,
    },
  },
  setup(props) {
    const loading = ref<boolean>(false);
    const preparingExcel = ref<boolean>(false);
    const includeCancelledOrder = ref<boolean>(false);
    const state = reactive<any>({
      summary: {},
      list: [],
    });

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
        },
      },
    };

    const chartData = reactive({
      labels: ["Aktif Sipariş Tutarı", "İptal & İade Tutarı"],
      datasets: [
        {
          label: "Aktif Siparişler",
          data: [] as any,
          backgroundColor: ["#54c7cb", "#ec7474"],
        },
      ],
    });

    const fetch = async () => {
      loading.value = true;
      const result = await reportService.merchantOrder({
        from: props.from || "",
        to: props.to || "",
        merchant_id: props.merchantId || "",
        without_cancelled: !includeCancelledOrder.value,
      });

      if (result.kind === "ok") {
        state.summary = result.data.summary;
        state.list = result.data.list.map((o) => {
          return { ...o, visible_detail: false };
        });
        chartData.datasets[0].data = [
          get(result.data.summary, "active.total_amount", 0),
          get(result.data.summary, "passive.total_amount", 0),
        ];
      } else {
        ToastNotify({ text: result.message, className: "error" });
      }

      loading.value = false;
    };

    const changeIncludeCancelledOrder = () => {
      fetch();
    };

    const copyCompanyInformationForBayiloji = (row: any) => {
      const invoiceAddress = get(row, "invoice_address");
      const customer = get(row, "customer");

      const isCompany = get(invoiceAddress, "is_company", "0") * 1;
      const title =
        (isCompany
          ? get(invoiceAddress, "company_title")
          : get(invoiceAddress, "name_surname")) || "";

      try {
        helper.copyToClipboard(
          JSON.stringify({
            name_surname: get(invoiceAddress, "name_surname") || "",
            company: title,
            company_commercial_title: title,
            address: get(invoiceAddress, "address") || "",
            district: get(invoiceAddress, "district") || "",
            city: get(invoiceAddress, "city") || "",
            phone: get(invoiceAddress, "phone") || "",
            email: get(customer, "email") || "",
            gsm: get(invoiceAddress, "phone") || "",
            group: "Eniyiperde.com",
            tax_office: isCompany
              ? get(invoiceAddress, "tax_office") || ""
              : "",
            tax_number: isCompany
              ? get(invoiceAddress, "tax_number") || ""
              : "11111111111",
            country: get(invoiceAddress, "country") || "TÜRKİYE",
            postal_zone: get(invoiceAddress, "postal_code") || "",
          })
        );

        ToastNotify({
          className: "info",
          text: "Firma bilgileri bilgisayara kopyalandı!",
        });
      } catch (e) {
        console.log(e);
      }
    };

    const downloadToExcel = async () => {
      preparingExcel.value = true;
      setTimeout(async () => {
        const rows = state.list.map((o: any) => {
          return {
            order_group_id: o.order_group_id,
            name_surname: get(o, "shipping_address.name_surname", ""),
            created_at: get(o, "created_at", ""),
            completed_at: get(o, "completed_at", ""),
            status: get(o, "status", ""),
            installment: get(o, "distribution.installment", "") || 1,
            product_amount: (get(o, "distribution.product_amount") || "0") * 1,
            cargo_amount: (get(o, "distribution.cargo_amount") || "0") * 1,
            installment_interest_amount:
              (get(o, "distribution.installment_interest_amount") || "0") * 1,
            total_amount: (get(o, "distribution.total_amount") || "0") * 1,
            eip_amount: (get(o, "distribution.eip_amount") || "0") * 1,
            bank_amount: (get(o, "distribution.bank_amount") || "0") * 1,
            total_commission_amount:
              (get(o, "distribution.total_commission_amount") || "0") * 1,
            merchant_amount:
              (get(o, "distribution.merchant_amount") || "0") * 1,
          };
        });
        const filename = `siparis_performans_raporu_${helper.formatDate(
          new Date(),
          "DD_MM_YYYY_HH_mm"
        )}`;

        console.log(rows);

        const excel = new MerchantOrderReport();
        await excel.process();
        excel.setRows(rows, "primary");
        await excel.export(filename);

        preparingExcel.value = false;
      });
    };

    onMounted(() => {
      fetch();
    });

    return {
      loading,
      state,
      chartOptions,
      chartData,
      preparingExcel,
      includeCancelledOrder,
      changeIncludeCancelledOrder,
      downloadToExcel,
      copyCompanyInformationForBayiloji,
    };
  },
});
