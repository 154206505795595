import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState } from "./state";
import { OrderFormInterface } from "@/services";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_FORMS](state: S, forms: OrderFormInterface[]): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_FORMS](state: LocalState, forms: OrderFormInterface[]) {
    state.forms = forms;
  },
};
