import { ref } from "vue";
import {
  GeneralApiProblem,
  GeneralApiResponse,
  selectionService,
} from "@/services";
import { get, has, set, unset, cloneDeep, forEach } from "lodash";
import { ToastNotify } from "@/utils/toast-notify";
import { ComputedRef } from "vue";

type SelectionQuery = {
  source: string;
  config: Record<string, any> | ComputedRef<Record<string, any>>;
};

type Props<R> = {
  id?: any;
  selectionQuery?: (() => SelectionQuery) | SelectionQuery;
  serviceMethod?(id: any): Promise<GeneralApiResponse<R>>;
  success?(result: GeneralApiResponse<R>): void;
  fail?(error: GeneralApiProblem): void;
  finish?(result: GeneralApiResponse<R>): void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useReadRecord<T extends Record<string, any>, R>(
  props: Props<R>
) {
  const loading = ref<boolean>(!!props.id);
  const data = ref<T | Record<string, any>>({});

  const setData = (value: T) => {
    forEach(value, (v, k) => {
      set(data.value, k, v);
    });
  };

  const fetch = async (id?: any) => {
    if (id || props.id) {
      if (!props.serviceMethod) {
        await fetchThroughSelection(id);
      } else {
        loading.value = true;
        const result = await props.serviceMethod(id || props.id);
        if (result.kind !== "ok") {
          ToastNotify({ text: result.message, className: "error" });
          if (props.fail) props.fail(result);
        } else {
          data.value = get(result, "data");
          if (props.success) props.success(result);
        }
        loading.value = false;
      }
    }
  };

  const fetchThroughSelection = async (id?: any) => {
    if (props.selectionQuery) {
      const selectionQuery =
        typeof props.selectionQuery === "function"
          ? props.selectionQuery()
          : props.selectionQuery;
      let config = cloneDeep(selectionQuery.config);
      if (has(selectionQuery.config, "effect")) {
        config = cloneDeep(selectionQuery.config.value);
      }
      unset(config, "@get");
      unset(config, "@page");
      unset(config, "@paginate");

      set(config, "@first", true);
      set(config, "@where.id", id || props.id);

      const params = {
        [selectionQuery.source]: config,
      };
      loading.value = true;
      const result = await selectionService.fetch(params);
      try {
        if (result.kind !== "ok") {
          ToastNotify({ text: result.message, className: "error" });
          if (props.fail) props.fail(result);
        } else {
          data.value = get(result.data, `${selectionQuery.source}`);
          if (props.success) props.success(result);
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    }
  };

  return {
    data,
    loading,
    selectionQuery: props?.selectionQuery,
    fetch,
    setData,
  };
}
