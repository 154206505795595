
import { defineComponent, onMounted } from "vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import Pagination from "@/components/pagination/Main.vue";
import { useRouter } from "vue-router";
import { findIndex, set } from "lodash";
import { useFetchRecords } from "@/utils/records/fetch";
import { useFilterRecords } from "@/utils/records/filter";
import { useDeleteRecord } from "@/utils/records/delete";
import { productTabMerchantService } from "@/services";
import VueSelect from "@/components/form-elements/select/Main.vue";
import FilterButton from "@/components/filter-container/Button.vue";
import { useStore } from "@/store";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";

export default defineComponent({
  name: "ProductTabs",
  components: {
    VueSelect2,
    FilterButton,
    VueSelect,
    Pagination,
    Tippy,
    TransactionStatement,
    VueInputContainer,
    FilterContainer,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const merchantSelect2Options: Select2Options = {
      ajaxQuery: {
        merchant: {
          "@select": { value: "id", text: "title" },
        },
      },
    };

    const productTabSelect2Options: Select2Options = {
      ajaxQuery: {
        product_tab: {
          "@select": { value: "id", text: "title" },
          "@where": { can_customizable: 1 },
        },
      },
    };

    const { loading, list, fetch } = useFetchRecords({
      selectionQuery: {
        source: "product_tab_merchant",
        config: {
          "@page": 1,
          "@select": ["id", "product_tab_id", "merchant_id", "status"],
          "@order": "id:desc",
          "@func": ["withCount"],
          merchant: {
            "@select": ["id", "title"],
          },
          product_tab: {
            "@select": ["id", "title", "category_id"],
          },
        },
        filterFormat: {
          q: "@where.q",
          product_tab_id: "@where.product_tab_id",
          merchant_id: "@where.merchant_id",
          status: "@where.status",
        },
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        status: "",
        product_tab_id: "",
        merchant_id: "",
      },
    });

    const { deleteState, triggerDelete, removeDeleteError } = useDeleteRecord({
      serviceMethod: productTabMerchantService.delete,
      success(id: any) {
        const index = findIndex(list.data, { id });
        if (index > -1) list.data.splice(index, 1);
      },
    });

    const goToEdit = (id: any) => {
      router.push({
        name: "product-tabs.merchants.edit",
        params: { id },
      });
    };

    const goToCreate = () => {
      const query: Record<string, any> = {};
      if (filterState.fields.product_tab_id) {
        set(query, "product_tab_id", filterState.fields.product_tab_id);
      }
      if (filterState.fields.merchant_id) {
        set(query, "merchant_id", filterState.fields.merchant_id);
      }
      router.push({
        name: "product-tabs.merchants.create",
        query,
      });
    };

    const getFormTitle = (formId: any) => {
      if (!formId) return "Tüm Sipariş Formları";

      const titles: string[] = [];
      formId
        .toString()
        .split(",")
        .map((o: any) => {
          const title: string = store.getters["order/form"](o, "title");
          if (title) titles.push(title);
        });

      return titles.join(", ");
    };

    const getCategoryTitle = (categoryId: any) => {
      if (!categoryId) return "Tüm Kategoriler";

      const titles: string[] = [];
      categoryId
        .toString()
        .split(",")
        .map((o: any) => {
          const title: string = store.getters["category/category"](o, "title");
          if (title) titles.push(title);
        });

      return titles.join(", ");
    };

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
    });

    return {
      loading,
      list,
      filterState,
      filterFunctions,
      deleteState,
      filterCount,
      merchantSelect2Options,
      productTabSelect2Options,
      triggerDelete,
      removeDeleteError,
      goToEdit,
      goToCreate,
      getFormTitle,
      getCategoryTitle,
    };
  },
});
