import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.editor.chain().focus().setTextAlign(_ctx.align).run()), ["prevent"])),
    class: _normalizeClass({ 'is-active': _ctx.editor.isActive({ textAlign: _ctx.align }) })
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(`editor-align-${_ctx.align}`)
    }, null, 2)
  ], 2))
}