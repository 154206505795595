import {
  GeneralApiResponse,
  GetCachesResult,
  GetSettingsResult,
  MassApiResponse,
  useAxios,
} from "@/services";

export * from "./api.types";

export const settingsService = {
  fetch: () =>
    useAxios().get<GeneralApiResponse<GetSettingsResult>>(`/api/settings`),
};

export const cacheService = {
  fetch: () =>
    useAxios().get<GeneralApiResponse<GetCachesResult>>(`/api/caches`),

  destroy: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/caches/${id}`),

  massDestroy: (id: any) =>
    useAxios().post<GeneralApiResponse<MassApiResponse>>(
      `/api/caches/mass-destroy`,
      { id }
    ),
};
