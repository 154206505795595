import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "py-6 lg:py-14 lg:px-12 grid grid-cols-12 gap-6" }
const _hoisted_2 = {
  key: 0,
  class: "col-span-full sm:col-span-6 lg:col-span-4"
}
const _hoisted_3 = {
  key: 1,
  class: "col-span-full sm:col-span-6 lg:col-span-4"
}
const _hoisted_4 = {
  key: 2,
  class: "col-span-full sm:col-span-6 lg:col-span-4"
}
const _hoisted_5 = {
  key: 3,
  class: "col-span-full sm:col-span-6 lg:col-span-4"
}
const _hoisted_6 = {
  key: 4,
  class: "col-span-full sm:col-span-6 lg:col-span-4"
}
const _hoisted_7 = {
  key: 5,
  class: "col-span-full sm:col-span-6 lg:col-span-4"
}
const _hoisted_8 = {
  key: 6,
  class: "col-span-full sm:col-span-6 lg:col-span-4"
}
const _hoisted_9 = {
  key: 7,
  class: "col-span-full sm:col-span-6 lg:col-span-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewOrderWidget = _resolveComponent("NewOrderWidget")!
  const _component_InProcessOrdersWidget = _resolveComponent("InProcessOrdersWidget")!
  const _component_PendingApprovalCommentWidget = _resolveComponent("PendingApprovalCommentWidget")!
  const _component_PendingApprovalProductGroupWidget = _resolveComponent("PendingApprovalProductGroupWidget")!
  const _component_UnapprovedProductGroupWidget = _resolveComponent("UnapprovedProductGroupWidget")!
  const _component_PassiveProductGroupWidget = _resolveComponent("PassiveProductGroupWidget")!
  const _component_PendingApprovalProductTabWidget = _resolveComponent("PendingApprovalProductTabWidget")!
  const _component_UnapprovedProductTabWidget = _resolveComponent("UnapprovedProductTabWidget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasPermission('orders.list'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_NewOrderWidget)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasPermission('orders.list'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_InProcessOrdersWidget)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasPermission('comments.list'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_PendingApprovalCommentWidget)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasPermission('products.list'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_PendingApprovalProductGroupWidget)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasPermission('products.list'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_UnapprovedProductGroupWidget)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasPermission('products.list'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_PassiveProductGroupWidget)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasPermission('contents.list'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_PendingApprovalProductTabWidget)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasPermission('contents.list'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_UnapprovedProductTabWidget)
        ]))
      : _createCommentVNode("", true)
  ]))
}