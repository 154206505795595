
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { get } from "lodash";
import { useReadRecord } from "@/utils/records/read";
import { Content, GetContentResult, contentService } from "@/services";
import ContentSideNavigation from "@/views/contents/create/components/SideNavigation.vue";
import { useSubmitRecord } from "@/utils/records/submit";
import { ToastNotify } from "@/utils/toast-notify";
import ContentGeneralInformation from "@/views/contents/create/components/Information.vue";
import dayjs from "dayjs";
import ContentSeoInformation from "@/views/contents/create/components/SeoInformation.vue";
import VueButton from "@/components/form-elements/button/Main.vue";
import ContentSectionInformation from "@/views/contents/create/components/SectionInformation.vue";
import ContentAssets from "@/components/assets/Main.vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "ContentCreateOrUpdate",
  components: {
    ContentAssets,
    ContentSectionInformation,
    VueButton,
    ContentSeoInformation,
    ContentGeneralInformation,
    ContentSideNavigation,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const activeView = ref<string>("information");
    const componentRefreshKey = ref<string>(helper.uuidv4());

    const id = computed(() => get(route.params, "id"));
    const getSiteUrl = computed(() => {
      if (!query.value.url_slug) return "javascript:;";
      return query.value.url_slug;
    });

    const {
      data: merchant,
      loading,
      fetch,
    } = useReadRecord<Content, GetContentResult>({
      id: id.value,
      serviceMethod: contentService.show,
      success(result) {
        if (result.kind === "ok") {
          componentRefreshKey.value = helper.uuidv4();
          setQuery(result.data);
        }
      },
      fail() {
        router.go(-1);
      },
    });

    const { query, processing, validationErrors, submit, setQuery } =
      useSubmitRecord<Content, GetContentResult>({
        query: {
          parent: "",
          type: "2",
          category_id: "",
          sq: "",
          status: "1",
          end_date: "",
          date: dayjs().format("YYYY-MM-DD"),
          slug: "",
          title: "",
          seo_title: "",
          seo_description: "",
          seo_keywords: "",
          header_slug: "",
          header_title: "",
          description: "",
          assets: [],
          workspaces: [],
          url_slug: "",
        },
        storeServiceMethod: contentService.store,
        updateServiceMethod: contentService.update,
        success(result) {
          if (result?.kind === "ok") {
            ToastNotify({
              className: "success",
              text: "İçerik Kaydedildi!",
            });
            if (!id.value) {
              router.go(-1);
            } else {
              componentRefreshKey.value = helper.uuidv4();
              setQuery(result.data);
            }
          }
        },
      });

    const changeView = (view: string) => {
      activeView.value = view;
    };

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) fetch(id.value);
    });

    return {
      loading,
      processing,
      query,
      activeView,
      merchant,
      validationErrors,
      getSiteUrl,
      componentRefreshKey,
      changeView,
      onSubmit,
    };
  },
});
