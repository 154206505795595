
import { defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "App",
  computed: {
    visibleSplashScreen: () => useStore().state.auth.visibleSplashScreen,
  },
  watch: {
    visibleSplashScreen(val) {
      if (val) this.$router.push({ name: "welcome" });
    },
  },
});
