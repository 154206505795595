
import { computed, defineComponent } from "vue";
import { OrderGroupInterface } from "@/services";
import { get } from "lodash";
import OrderMerchantDistribution from "@/views/orders/detail/components/distributions/MerchantDistribution.vue";

export default defineComponent({
  name: "OrderDistributions",
  components: { OrderMerchantDistribution },
  props: {
    orderDetail: {
      type: Object as () => OrderGroupInterface,
      default: () => ({}),
    },
  },
  setup(props) {
    const merchants = computed(() => {
      return get(props.orderDetail, "merchants", []);
    });

    return {
      merchants,
    };
  },
});
