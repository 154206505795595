<template>
  <node-view-wrapper
    class="tiptap-disabled-block tiptap-vue-category-container"
  >
    <div contenteditable="false">
      <span class="drag-handle label" draggable="true" data-drag-handle>
        {{ node.attrs.label }}
      </span>
    </div>
    <div class="tiptap-disabled-block-content">
      <VueInputContainer class="col-span-full">
        <template v-slot:label>Başlık</template>
        <VueInput v-model="title" :disabled="!isEnabled" />
      </VueInputContainer>
      <VueInputContainer class="col-span-full">
        <template v-slot:label>Metin</template>
        <VueTextarea v-model="text" :disabled="!isEnabled" />
      </VueInputContainer>
      <VueInputContainer class="col-span-full">
        <template v-slot:label>
          Tıklanıldığında Açılacak İçerik Sayfası
        </template>
        <VueSelect2
          v-model="content"
          :options="contentSelect2Options"
          @update:selected-item="(val) => changeContent(val)"
          :disabled="!isEnabled"
        />
      </VueInputContainer>
      <VueInputContainer class="col-span-full">
        <template v-slot:label>Ürün Listesi</template>
        <VueSelect v-model="source" :disabled="!isEnabled">
          <option value="products">Barkodları Girilecek Ürünler</option>
          <option value="categories">Seçilecek Kategorilerden Ürünler</option>
        </VueSelect>
      </VueInputContainer>
      <VueInputContainer class="col-span-full" v-if="source === 'categories'">
        <template v-slot:label>
          Aşağıdaki listeden ilgili kategorileri seçin
        </template>
        <VueSelect2
          v-model="categories"
          :options="categorySelect2Options"
          :disabled="!isEnabled"
        />
      </VueInputContainer>
      <VueInputContainer
        class="col-span-full"
        v-else-if="source === 'products'"
      >
        <template v-slot:label>
          Aşağıdaki listeye ürünlerin barkod numaralarını ekleyin
        </template>
        <VueSelect2
          v-model="products"
          :options="productSelect2Options"
          :disabled="!isEnabled"
        />
      </VueInputContainer>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-3";
import VueInputContainer from "@/components/form-elements/input-container/Main";
import VueSelect2 from "@/components/form-elements/select2/Main";
import { useStore } from "@/store";
import { cloneDeep, get, isArray, isNumber, isString } from "lodash";
import VueInput from "@/components/form-elements/input/Main";
import VueTextarea from "@/components/form-elements/textarea/Main";
import VueSelect from "@/components/form-elements/select/Main";
export default {
  name: "TiptapVueCategoryContainer",
  components: {
    VueSelect,
    VueTextarea,
    VueInput,
    VueSelect2,
    VueInputContainer,
    NodeViewWrapper,
  },
  props: nodeViewProps,
  computed: {
    isEnabled() {
      return get(this.editor, "options.editable") || false;
    },
    title: {
      get() {
        return get(this.node, "attrs.title") || "";
      },
      set(value) {
        this.updateAttributes({
          title: value || "",
        });
      },
    },
    text: {
      get() {
        return get(this.node, "attrs.text") || "";
      },
      set(value) {
        this.updateAttributes({
          text: value || "",
        });
      },
    },
    source: {
      get() {
        return get(this.node, "attrs.source") || "";
      },
      set(value) {
        this.updateAttributes({
          source: value || "",
        });
      },
    },
    content: {
      get() {
        return get(this.node, "attrs.content") || "";
      },
      set(value) {
        this.updateAttributes({
          content: value || "",
        });
      },
    },
    products: {
      get() {
        const value = get(this.node, "attrs.products") || "";
        if (value && (isString(value) || isNumber(value))) {
          return value.toString().split(",");
        }
        return [];
      },
      set(val) {
        const value = cloneDeep(val);
        this.updateAttributes({
          products: isArray(value) ? value.join(",") : "",
        });
      },
    },
    categories: {
      get() {
        const value = get(this.node, "attrs.categories") || "";
        if (value && (isString(value) || isNumber(value))) {
          return value.toString().split(",");
        }
        return [];
      },
      set(val) {
        const value = cloneDeep(val);
        this.updateAttributes({
          categories: isArray(value) ? value.join(",") : "",
        });
      },
    },
    contentSelect2Options: () => ({
      ajaxQuery: {
        content: {
          "@select": {
            value: "id",
            text: "title",
            type: "type",
            parent: "parent",
            slug: "slug",
            header_slug: "header_slug",
          },
          "@func": ["withUrlSlug"],
          "@page": 1,
        },
      },
    }),
    categorySelect2Options: () => ({
      multiple: true,
      isArray: true,
      data: useStore().getters["category/categories"].map((item) => ({
        id: item.id,
        value: item.id,
        text: item.title,
      })),
    }),
    productSelect2Options() {
      let selected = this.products;
      if (!isArray(selected)) selected = [];
      return {
        multiple: true,
        tags: true,
        isArray: true,
        data: selected.map((item) => ({
          id: item,
          value: item,
          text: item,
        })),
      };
    },
  },
  methods: {
    changeContent(content) {
      const url = get(content, "url_slug");
      if (url !== this.node.attrs.content_url) {
        this.updateAttributes({
          content_url: url,
        });
      }
    },
  },
};
</script>
