import { RouteRecordRaw, RouterView } from "vue-router";

import StockGroupCodeDefinitions from "@/views/definitions/stock-group-codes/list/Main.vue";
import StockGroupCodeDefinitionCreate from "@/views/definitions/stock-group-codes/create/Main.vue";

const route: Array<RouteRecordRaw> = [
  {
    path: "definitions",
    component: RouterView,
    children: [
      {
        path: "stock-group-codes",
        component: RouterView,
        children: [
          {
            path: "",
            name: "definitions.stock-group-codes",
            component: StockGroupCodeDefinitions,
            meta: {
              title: "Stok Grup Kodları",
              roles: [],
              permissions: [],
            },
          },
          {
            path: "create",
            name: "definitions.stock-group-codes.create",
            component: StockGroupCodeDefinitionCreate,
            meta: {
              title: "Stok Grup Kodu Ekle",
              roles: [],
              permissions: [],
            },
          },
          {
            path: "edit/:id",
            name: "definitions.stock-group-codes.edit",
            component: StockGroupCodeDefinitionCreate,
            meta: {
              title: "Stok Grup Kodu Düzenle",
              roles: [],
              permissions: [],
            },
          },
        ],
      },
    ],
  },
];

export default route;
