import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-5 text-center" }
const _hoisted_2 = {
  key: 1,
  class: "text-2xl mt-5"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "px-5 pb-8 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueModal = _resolveComponent("VueModal")!

  return (_openBlock(), _createBlock(_component_VueModal, {
    id: _ctx.modalRefId,
    "body-class": "p-0"
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.options.icon)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["text-gray-600 text-center mt-3 text-7xl", _ctx.options.iconClass])
            }, [
              _createElementVNode("i", {
                class: _normalizeClass(_ctx.options.icon)
              }, null, 2)
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.options.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.options.title), 1))
          : _createCommentVNode("", true),
        (_ctx.options.text)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(["text-gray-600 mt-2", _ctx.options.textClass]),
              innerHTML: _ctx.options.text
            }, null, 10, _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          type: "button",
          "data-dismiss": "modal",
          class: _normalizeClass(["btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1", _ctx.options.cancelClass]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
        }, _toDisplayString(_ctx.options.cancelText), 3),
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass(["btn w-24", _ctx.options.confirmClass]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onConfirm && _ctx.onConfirm(...args)))
        }, _toDisplayString(_ctx.options.confirmText), 3)
      ])
    ]),
    _: 1
  }, 8, ["id"]))
}