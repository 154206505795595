import {
  GeneralApiResponse,
  GetFilterFamilyResult,
  GetFilterResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const filterService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetFilterResult>>(`/api/filters/${id}`),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetFilterResult>>(
      `/api/filters`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetFilterResult>>(
      `/api/filters/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/filters/${id}`),
};

export const filterFamilyService = {
  show: (id: any) =>
    useAxios().get<GeneralApiResponse<GetFilterFamilyResult>>(
      `/api/filter-families/${id}`
    ),

  store: (params: unknown) =>
    useAxios().post<GeneralApiResponse<GetFilterFamilyResult>>(
      `/api/filter-families`,
      params
    ),

  update: (id: any, params: unknown) =>
    useAxios().put<GeneralApiResponse<GetFilterFamilyResult>>(
      `/api/filter-families/${id}`,
      params
    ),

  delete: (id: any) =>
    useAxios().delete<GeneralApiResponse<any>>(`/api/filter-families/${id}`),
};
