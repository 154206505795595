import { GeneralApiResponse, MassApiResponse, useAxios } from "@/services";

export * from "./api.types";

export const quickUploadService = {
  material: (params: unknown) =>
    useAxios().post<GeneralApiResponse<MassApiResponse>>(
      `/api/products/quick-upload`,
      params
    ),

  productGroup: (params: unknown, formId: any) =>
    useAxios().post<GeneralApiResponse<MassApiResponse>>(
      `/api/product-groups/quick-upload/${formId}`,
      params
    ),
};
