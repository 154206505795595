import { OrderFormInterface, OrderLineInterface } from "@/services";
import { get, has, sortBy, uniqBy } from "lodash";

export const getOrderLines = (
  formId: any,
  allForms: OrderFormInterface[],
  order: OrderLineInterface[]
): Record<string, any> => {
  formId *= 1;
  const flats: any[][] = [];
  const childrenLengths: any = [];
  const data: any = { headers: [], lines: [] };
  let maxIndexNumber = 0;
  let maxIndexLength = 0;
  const power: any = {};

  order.map((item) => {
    if (formId * 1 === item.form_id) {
      const flatten: any[] = [];

      getRecursiveOrderLineData(
        get(item, "content", []),
        formId,
        allForms,
        flatten,
        childrenLengths
      );
      flats.push(flatten);
    }
  });

  flats.map((flat) => {
    flat.map((f) => {
      if (f.index.length > maxIndexLength) maxIndexLength = f.index.length;
      f.index.map((i: number) => {
        if (i > maxIndexNumber) maxIndexNumber = i;
      });
    });
  });

  for (let i = 0; i < maxIndexLength; i++) {
    power[i] = Math.pow(maxIndexNumber + 1, maxIndexLength - i - 1);
  }

  let headers: any[] = [];

  flats.map((eachFlat) => {
    eachFlat.map((eachFlatLine) => {
      let p = 0;
      eachFlatLine.index.map((n: number, i: number) => {
        p += power[i] * n;
      });
      eachFlatLine.sq = p;

      headers.push({ sq: eachFlatLine.sq, key: eachFlatLine.key });
    });
  });

  headers = sortBy(uniqBy(headers, "sq"), "sq");

  headers.map((header) => {
    data.headers.push({ title: header.key });
  });

  flats.map((eachFlat) => {
    const d0: any[] = [];
    headers.map((header) => {
      const d1: any = {};
      eachFlat.map((eachFlatLine) => {
        if (header.sq == eachFlatLine.sq) {
          d1.value = eachFlatLine.value;
          d1.code = eachFlatLine.code;
        }
      });
      d0.push(d1);
    });
    data.lines.push([...d0]);
  });
  // console.table(data)
  return data;
};

export const getRecursiveOrderLineData = (
  content: any,
  formId: number,
  allForms: OrderFormInterface[],
  flatten: any[],
  childrenLengths: any[],
  parentKey = "",
  index: any = []
): void => {
  let i = 0;
  let distance = 0;

  // const formDb = this.$store.getters.getOrderFormDetail(formId)
  // let formDb: any = {};
  // allForms.map((aForm) => {
  //   if (aForm.id * 1 === formId) formDb = aForm;
  // });

  childrenLengths.map((l) => {
    if (l.key == parentKey) {
      distance = l.distance;
    }
  });
  parentKey = "";

  get(content, "lines.data", []).map((form: any) => {
    i++;

    const column = {
      key: form.key,
      value: form.title ? form.title : form.value,
      index: [...index, i + distance],
    };

    flatten.push({
      key: column.key,
      code: form.code,
      value: column.value,
      index: column.index,
    });

    get(form, "forms.data", []).map((line: any) => {
      if (has(line, "lines.data")) {
        let found = 0;
        let distance = 0;
        childrenLengths.map((l) => {
          if (l.key == column.key + column.value + column.index.join(",")) {
            found = 1;
          }
          distance += l.length;
        });

        if (found == 0) {
          childrenLengths.push({
            key: column.key + column.value + column.index.join(","),
            length: get(line, "lines.data", []).length,
            distance,
          });
        }

        parentKey = column.key + column.value + column.index.join(",");

        getRecursiveOrderLineData(
          line,
          form.value,
          allForms,
          flatten,
          childrenLengths,
          parentKey,
          column.index
        );
      }
    });
  });
};
