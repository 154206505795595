
import { defineComponent, reactive, toRaw } from "vue";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";

export default defineComponent({
  name: "WorkspaceResponsiveFields",
  components: { VueInputContainer },
  props: {
    label: {
      type: String,
      default: () => "",
    },
    query: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const editableQuery = reactive<Record<string, any>>(toRaw(props.query));

    return { editableQuery };
  },
});
