
import { defineComponent, onMounted, ref } from "vue";
import { OrderLineInterface } from "@/services";
import { cloneDeep, get, set } from "lodash";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "OrderCalculationLine",
  props: {
    orderLines: {
      type: Array as () => OrderLineInterface[],
      default: () => [],
    },
  },
  setup(props) {
    const visible = ref<boolean>(true);
    const visibleZeroPrices = ref<boolean>(false);
    const foreignCurrency = ref<boolean>(false);
    const showForeignCurrency = ref<boolean>(false);
    const hasMultipleCurrency = ref<boolean>(false);

    const calculations = ref<any[]>([]);

    const calculate = () => {
      const currencies: any[] = [];
      let currency_rates: any = {};
      let net_total: any = { _: 0 };
      let gross_total: any = { _: 0 };
      let total_vat: any = { _: {} };
      let ifThisIsForeignCurrencyRunTwice: any[] = [0];

      props.orderLines?.map((orderLine) => {
        get(orderLine, "calculation", []).map((line: any) => {
          pushCalculation(line);
          if (currencies.indexOf(line.currency) < 0) {
            currencies.push(line.currency);
            if (line.currency > 0)
              currency_rates["_" + line.currency] = line.currency_rate;
            net_total["_" + line.currency] = 0;
            gross_total["_" + line.currency] = 0;
            total_vat["_" + line.currency] = {};
          }

          if (!foreignCurrency.value && line.currency * 1 > 0) {
            foreignCurrency.value = true;
            showForeignCurrency.value = true;
          }

          ifThisIsForeignCurrencyRunTwice = foreignCurrency.value
            ? ["", line.currency]
            : [""];

          ifThisIsForeignCurrencyRunTwice.map((currency) => {
            net_total["_" + currency] += oldNew(
              line,
              "net_total",
              true,
              currency === 0 || currency === ""
            );
            gross_total["_" + currency] += oldNew(
              line,
              "gross_total",
              true,
              currency === 0 || currency === ""
            );

            if (line.vat_rate !== 0) {
              if (
                typeof total_vat["_" + currency] === "undefined" ||
                typeof total_vat["_" + currency]["_" + line.vat_rate] ===
                  "undefined"
              ) {
                set(total_vat, ["_" + currency, "_" + line.vat_rate], 0);
              }
              total_vat["_" + currency]["_" + line.vat_rate] += oldNew(
                line,
                "total_vat",
                true,
                currency === 0 || currency === ""
              );
            }
          });
        });
      });

      calculations.value.sort((a, b) =>
        a.values.title > b.values.title
          ? 1
          : b.values.title > a.values.title
          ? -1
          : 0
      );

      const ifThisIsForeignCurrencyRunMultipleTimes = [[""]];
      hasMultipleCurrency.value = currencies.length > 1;

      if (foreignCurrency.value) {
        ifThisIsForeignCurrencyRunMultipleTimes.push(currencies);
      }

      ifThisIsForeignCurrencyRunMultipleTimes.map((currencyArray) => {
        currencyArray.map((currency) => {
          if (gross_total["_" + currency] === net_total["_" + currency]) {
            net_total["_" + currency] = 0;
          }

          if (net_total["_" + currency] * 1 > 0) {
            calculations.value.push({
              type: "net_total",
              title: "Net Tutar",
              currency,
              value: net_total["_" + currency],
            });
          }

          Object.keys(total_vat["_" + currency])
            .sort()
            .forEach((key) => {
              calculations.value.push({
                type: "total_vat" + key,
                title: "KDV %" + key.substr(1),
                currency,
                value: total_vat["_" + currency][key],
              });
            });

          calculations.value.push({
            type: "gross_total",
            title: "Toplam Tutar",
            currency,
            value: gross_total["_" + currency],
          });
        });
      });

      if (foreignCurrency.value) {
        Object.keys(currency_rates)
          .sort()
          .forEach((key: any) => {
            calculations.value.push({
              type: "rate",
              title: "Rate",
              currency: key.substr(1) * 1,
              value: currency_rates[key],
            });
          });
      }
    };

    const pushCalculation = (line: any) => {
      const values = cloneDeep(line);
      let notFound = true;
      calculations.value.map((d) => {
        if (
          d.product_id === values.product_id &&
          d.price === values.price &&
          d.old_price === values.old_price
        ) {
          notFound = false;
          calculationSum(d, values);
        }
      });

      if (notFound) {
        // Sıfır olan satırlar gelmesin
        calculations.value.push({
          uuid: helper.uuidv4(),
          product_id: values.product_id,
          price: values.price,
          old_price: values.old_price,
          type: "line",
          values,
        });
      }
    };

    const calculationSum = (d: any, calculation: any) => {
      d.values.net_total += calculation.net_total * 1;
      d.values.total_vat += calculation.total_vat * 1;
      d.values.gross_total += d.values.net_total + d.values.total_vat;
      d.values.old_net_total += calculation.old_net_total * 1;
      d.values.old_total_vat += calculation.old_total_vat * 1;
      d.values.old_gross_total +=
        d.values.old_net_total + d.values.old_total_vat;
      d.values.qty += calculation.qty * 1;

      if (d.values.currency > 0) {
        d.values.currency_net_total += calculation.currency_net_total * 1;
        d.values.currency_total_vat += calculation.currency_total_vat * 1;
        d.values.currency_gross_total +=
          d.values.currency_net_total + d.values.currency_total_vat;
        d.values.currency_old_net_total +=
          calculation.currency_old_net_total * 1;
        d.values.currency_old_total_vat +=
          calculation.currency_old_total_vat * 1;
        d.values.currency_old_gross_total +=
          d.values.currency_old_net_total + d.values.currency_old_total_vat;
      }
    };

    const oldNew = (
      data: any,
      key: any,
      manual_currency_decision = false,
      manual_currency_show_local = false
    ) => {
      if (
        (!manual_currency_decision &&
          data.currency > 0 &&
          showForeignCurrency.value) ||
        (manual_currency_decision && !manual_currency_show_local)
      ) {
        key = "currency_" + key;
      }

      return data[key];
    };

    const isShowMeta = (line: any) => {
      return (
        line.type !== "line" &&
        line.value > 0 &&
        ((showForeignCurrency.value && line.currency !== "") ||
          (!showForeignCurrency.value && line.currency === ""))
      );
    };

    onMounted(() => {
      calculate();
    });

    return {
      visible,
      visibleZeroPrices,
      calculations,
      isShowMeta,
    };
  },
});
