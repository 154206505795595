
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store";
import md5 from "blueimp-md5";
import { useRoute, useRouter } from "vue-router";
import {
  authService,
  formService,
  printTemplateService,
  settingsService,
} from "@/services";
import { ToastNotify } from "@/utils/toast-notify";
import { helper } from "@/utils/helper";
import { get } from "lodash";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const profile = store.getters["auth/profile"];
    const tooManyAttempt = ref<boolean>(false);

    const nameSurname = computed(() => {
      return helper.formatNameSurname(profile);
    });

    const gravatar = computed(() => {
      return `https://secure.gravatar.com/avatar/${md5(
        profile?.email
      )}?size=256`;
    });

    onMounted(async () => {
      cash("body")
        .removeClass("main")
        .removeClass("login")
        .removeClass("error-page")
        .addClass("welcome");

      await authService.profile().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("auth/SET_PROFILE", result.data);
        } else {
          ToastNotify({ text: result.message, className: "error" });
          if (result.kind === "unauthorized") {
            store.dispatch("auth/SET_TOKEN", "");
            store.dispatch("auth/SET_PROFILE", null);
            router.replace({ name: "login" });
          } else if (result.kind === "too-many") {
            tooManyAttempt.value = true;
          }
        }
      });

      printTemplateService.fetch().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("system/SET_PRINT_TEMPLATES", result.data);
        }
      });

      await settingsService.fetch().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("system/SET_SETTINGS", result.data);
          store.dispatch("system/DETECT_SITE", store.state.auth.profile);
        } else if (result.kind === "too-many") {
          tooManyAttempt.value = true;
        }
      });

      await formService.fetch().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("order/SET_FORMS", result.data);
        } else if (result.kind === "too-many") {
          tooManyAttempt.value = true;
        }
      });

      store.dispatch("category/FETCH_ALL_CATEGORIES", undefined);

      if (!tooManyAttempt.value) {
        store.dispatch("auth/SET_SPLASH_SCREEN", false);
        if (route.redirectedFrom) {
          return router.replace({
            name: route.redirectedFrom.name as string,
            params: route.redirectedFrom.params,
            query: route.redirectedFrom.query,
          });
        }
        router.replace({ path: get(route.query, "redirect", "/") as string });
      }
    });

    return {
      nameSurname,
      gravatar,
    };
  },
});
