import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "report--summary-widget" }
const _hoisted_2 = { class: "summary-widget--top" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "summary-widget--detail" }
const _hoisted_5 = { class: "summary-widget--title" }
const _hoisted_6 = { class: "summary-widget--amount" }
const _hoisted_7 = { class: "summary-widget--other" }
const _hoisted_8 = {
  key: 0,
  class: "summary-widget--active"
}
const _hoisted_9 = { class: "summary-widget--title" }
const _hoisted_10 = { class: "summary-widget--amount" }
const _hoisted_11 = {
  key: 1,
  class: "summary-widget--passive"
}
const _hoisted_12 = { class: "summary-widget--title" }
const _hoisted_13 = { class: "summary-widget--amount" }
const _hoisted_14 = {
  key: 0,
  class: "summary-widget--description"
}
const _hoisted_15 = { class: "summary-widget--chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "summary-widget--circle",
          style: _normalizeStyle({ borderColor: _ctx.color })
        }, _toDisplayString(_ctx.abbr), 5),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.label), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.primaryValue), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.secondaryTitle)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.secondaryTitle), 1),
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.secondaryValue), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.otherTitle)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.otherTitle), 1),
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.otherValue), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_15, [
      _renderSlot(_ctx.$slots, "chart")
    ])
  ]))
}