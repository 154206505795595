import { BaseExcel, PROMPT_NEW_LINE } from "@/utils/excel/base";
import ExcelJs from "exceljs";
import { get, set } from "lodash";
import { useStore } from "@/store";

export class MaterialProductExcel extends BaseExcel {
  async process(): Promise<void> {
    this.wb = new ExcelJs.Workbook();
    this.sheetIndex = -1;
    this.sheets = {};
    await this.prepareOtherColumns();
    await this.setHeaderLetters();
    await this.preparePrimaryColumns();
    await this.setHeaderLetters();
    return await super.baseProcess();
  }

  private async prepareOtherColumns(): Promise<void> {
    this.sheets["other"] = {
      protected: true,
      title: "Ürün Özellik Bilgileri",
      state: "hidden",
      headers: {
        material_category_id: {
          key: "material_category_id",
          title: "Kategori",
          fill: "primary",
          rows: [this.categoryId ? this.categoryId.toString() : ""],
        },
        // currency: {
        //   key: "currency",
        //   title: "Para Birimi",
        //   fill: "primary",
        //   rows: [
        //     {
        //       value: "0",
        //       text: "TRY",
        //     },
        //   ],
        // },
        unit: {
          key: "unit",
          title: "Stok Birimi",
          fill: "primary",
          rows: [
            {
              value: "adet",
              text: "Adet",
            },
            {
              value: "m",
              text: "Metre",
            },
            {
              value: "m2",
              text: "Metrekare",
            },
          ],
        },
        vat_rate: {
          key: "vat_rate",
          title: "KDV Oranı",
          fill: "primary",
          rows: ["0", "1", "8", "10", "18", "20"],
        },
        status: {
          key: "status",
          title: "Durum",
          fill: "primary",
          rows: [
            {
              value: "0",
              text: "Pasif",
            },
            {
              value: "1",
              text: "Aktif",
            },
          ],
        },
        price_type: {
          key: "price_type",
          title: "Fiyata KDV Dahil",
          fill: "primary",
          rows: [
            {
              value: "0",
              text: "Hayır",
            },
            {
              value: "1",
              text: "Evet",
            },
          ],
        },
      },
    };
  }

  private async preparePrimaryColumns(): Promise<void> {
    const store = useStore();

    this.sheets["primary"] = {
      title: "Ürünlerinizi Buraya Ekleyin",
      headers: {
        status: {
          key: "status",
          title: "Ürün Satış Durumu",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "list",
            formulae: [this.getAddressOtherSheetField("status")],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "ÜRÜN SATIŞ DURUMU GİRİN",
            prompt: `${PROMPT_NEW_LINE}Ürünün satış durumunu listeden seçiniz.`,
          },
        },
        barcode: {
          key: "barcode",
          title: "Barkod",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "any",
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "BARKOD NUMARASI GİRİN",
            prompt: `${PROMPT_NEW_LINE}Ürünün üzerindeki barkod girilmelidir.${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Bir barkod yalnızca bir satırdaki ürün için kullanılabilir.`,
          },
        },
        item_code: {
          key: "item_code",
          title: "Stok Grup Kodu",
          fill: "primary",
          validation: {
            type: "any",
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "STOK GRUP KODU GİRİN",
            prompt: `${PROMPT_NEW_LINE}Buraya gireceğiniz kod ile ürünleri gruplarsınız.${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Ürün sayfasında aynı gruptaki ürünlerin farklı renk ve ebatlarına geçiş sağlanabilir.`,
          },
        },
        stock_code: {
          key: "stock_code",
          title: "Stok Kodu",
          fill: "primary",
          validation: {
            type: "any",
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "STOK KODU GİRİN",
            prompt: `${PROMPT_NEW_LINE}Bu alana kendi sisteminizdeki kodu yazarak takip edebilirsiniz.`,
          },
        },
        material_category_id: {
          key: "material_category_id",
          title: "Kategori",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "list",
            formulae: [this.getAddressOtherSheetField("material_category_id")],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "KATEGORİ GİRİN",
            prompt: `${PROMPT_NEW_LINE}Excel kategori bazlı oluşturulduğu için bütün satırlarda örnek olarak verilen değer bulunmalıdır.`,
          },
        },
        title: {
          key: "title",
          title: "Ürün Adı",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "any",
            formulae: [],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "ÜRÜN ADI GİRİN",
            prompt:
              `${PROMPT_NEW_LINE}Müşterilerimiz ilk olarak ürün adını görür ve satın almak için ürün adını aratır.` +
              `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Bu nedenle ürün adının dikkatle girilmesi önemlidir.`,
          },
        },
        price_type: {
          key: "price_type",
          title: "Fiyatlara KDV Dahil Mi?",
          fill: "primary",
          color: "FFE76C6C",
          validation: {
            type: "list",
            formulae: [this.getAddressOtherSheetField("price_type")],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "",
            prompt: `${PROMPT_NEW_LINE}Girilen fiyatlara kdv dahil ise listeden EVET seçeneğini seçiniz.`,
          },
        },
        old_price: {
          key: "old_price",
          title: "Piyasa Satış Fiyatı",
          fill: "primary",
          color: "FFE76C6C",
          numberFormat: "0.00",
          validation: {
            type: "decimal",
            operator: "greaterThanOrEqual",
            formulae: [0],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "PİYASA SATIŞ FİYATINI GİRİN",
            prompt:
              `${PROMPT_NEW_LINE}Fiyat sonuna TL ibaresi konulmamalıdır.` +
              `${PROMPT_NEW_LINE}Kuruş değeri virgül ile ayrılarak araya boşluk bırakılmadan 2 hane olacak şekilde yazılmalıdır.` +
              `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Örnek: 19,80 veya 24`,
          },
        },
      },
    };

    if (get(store.getters["auth/profile"], "use_merchant_price") === true) {
      set(this.sheets, "primary.headers", {
        ...get(this.sheets, "primary.headers", {}),
        merchant_price: {
          key: "merchant_price",
          title: "Satış Fiyatı",
          fill: "primary",
          color: "FFE76C6C",
          numberFormat: "0.00",
          validation: {
            type: "decimal",
            operator: "greaterThanOrEqual",
            formulae: [0],
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: "SİTENİZE SATIŞ FİYATINI GİRİN",
            prompt:
              `${PROMPT_NEW_LINE}Ürünü sitenizde'de satmak istediğiniz fiyatı giriniz.` +
              `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Fiyat sonuna TL ibaresi konulmamalıdır.` +
              `${PROMPT_NEW_LINE}Kuruş değeri virgül ile ayrılarak araya boşluk bırakılmadan 2 hane olacak şekilde yazılmalıdır.` +
              `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Örnek: 19,80 veya 24`,
          },
        },
      });
    }

    set(this.sheets, "primary.headers", {
      ...get(this.sheets, "primary.headers", {}),
      price: {
        key: "price",
        title: "Eniyiperde Satış Fiyatı",
        fill: "primary",
        color: "FFE76C6C",
        numberFormat: "0.00",
        validation: {
          type: "decimal",
          operator: "greaterThanOrEqual",
          formulae: [0],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: "ENİYİPERDE SATIŞ FİYATINI GİRİN",
          prompt:
            `${PROMPT_NEW_LINE}Ürünü Eniyiperde'de satmak istediğiniz fiyatı giriniz.` +
            `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Fiyat sonuna TL ibaresi konulmamalıdır.` +
            `${PROMPT_NEW_LINE}Kuruş değeri virgül ile ayrılarak araya boşluk bırakılmadan 2 hane olacak şekilde yazılmalıdır.` +
            `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Örnek: 19,80 veya 24`,
        },
      },
      vat_rate: {
        key: "vat_rate",
        title: "KDV Oranı",
        fill: "primary",
        color: "FFE76C6C",
        validation: {
          type: "list",
          formulae: [this.getAddressOtherSheetField("vat_rate")],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: "KDV ORANI GİRİN",
          prompt: `${PROMPT_NEW_LINE}KDV değerini listeden seçiniz.`,
        },
      },
      stock: {
        key: "stock",
        title: "Stok Miktarı",
        fill: "primary",
        color: "FFE76C6C",
        numberFormat: "0",
        validation: {
          type: "decimal",
          operator: "greaterThanOrEqual",
          formulae: [0],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: "STOK MİKTARINI GİRİN",
          prompt: `${PROMPT_NEW_LINE}Ürünün güncel satılabilir stok miktarını girin. ${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Minimum: 0`,
        },
      },
      unit: {
        key: "unit",
        title: "Stok Birimi",
        fill: "primary",
        color: "FFE76C6C",
        validation: {
          type: "list",
          formulae: [this.getAddressOtherSheetField("unit")],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: "STOK BİRİMİ GİRİN",
          prompt: `${PROMPT_NEW_LINE}Stok birimini listeden seçiniz.`,
        },
      },
      min_width: {
        key: "min_width",
        title: "Minimum En",
        fill: "primary",
        numberFormat: "0",
        validation: {
          type: "decimal",
          operator: "greaterThanOrEqual",
          formulae: [0],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: "MİNİMUM EN GİRİN",
          prompt: `${PROMPT_NEW_LINE}Buraya sipariş edilebilir minimum en ölçüsünü giriniz.`,
        },
      },
      max_width: {
        key: "max_width",
        title: "Maksimum En",
        fill: "primary",
        numberFormat: "0",
        validation: {
          type: "decimal",
          operator: "greaterThanOrEqual",
          formulae: [0],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: "MAKSİMUM EN GİRİN",
          prompt: `${PROMPT_NEW_LINE}Buraya sipariş edilebilir maksimum en ölçüsünü giriniz.`,
        },
      },
      min_height: {
        key: "min_height",
        title: "Minimum Boy",
        fill: "primary",
        numberFormat: "0",
        validation: {
          type: "decimal",
          operator: "greaterThanOrEqual",
          formulae: [0],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: "MİNİMUM BOY GİRİN",
          prompt: `${PROMPT_NEW_LINE}Buraya sipariş edilebilir minimum boy ölçüsünü giriniz.`,
        },
      },
      max_height: {
        key: "max_height",
        title: "Maksimum Boy",
        fill: "primary",
        numberFormat: "0",
        validation: {
          type: "decimal",
          operator: "greaterThanOrEqual",
          formulae: [0],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: "MAKSİMUM BOY GİRİN",
          prompt: `${PROMPT_NEW_LINE}Buraya sipariş edilebilir maksimum boy ölçüsünü giriniz.`,
        },
      },
      color: {
        key: "color",
        title: "Renk",
        fill: "primary",
        validation: {
          type: "any",
          formulae: [],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: "RENK GİRİN",
          prompt: `${PROMPT_NEW_LINE}Ürün rengini listeden seçiniz.`,
        },
      },
      color_code: {
        key: "color_code",
        title: "Renk Kodu",
        fill: "primary",
        validation: {
          type: "any",
          formulae: [],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: "RENK HEX KODU GİRİN",
          prompt: `${PROMPT_NEW_LINE}Ürün rengini belirmek için HEX kodunu girin.${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Beyaz Renk Örneği: #FFFFFF`,
        },
      },
      photo_list: {
        key: "photo_list",
        title: "Görsel Linki",
        fill: "primary",
        validation: {
          type: "any",
          formulae: [],
          showErrorMessage: true,
          showInputMessage: true,
          promptTitle: "GÖRSEL LİNKİ GİRİN",
          prompt:
            `${PROMPT_NEW_LINE}Direkt ürün görsellerini içeren görsel linki eklenmelidir.` +
            `${PROMPT_NEW_LINE}${PROMPT_NEW_LINE}Birden çok görsel linki girmek için linkler arasına virgül koyarak sıralayabilirsiniz.`,
        },
      },
    });
  }
}
