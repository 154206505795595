import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = {
  key: 0,
  class: "feather-eye-off"
}
const _hoisted_3 = {
  key: 1,
  class: "feather-eye"
}
const _hoisted_4 = {
  key: 0,
  class: "flex mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueInput = _resolveComponent("VueInput")!
  const _component_VueInputContainer = _resolveComponent("VueInputContainer")!

  return (_openBlock(), _createBlock(_component_VueInputContainer, { "validation-errors": _ctx.errors }, {
    label: _withCtx(() => [
      _renderSlot(_ctx.$slots, "label")
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_VueInput, {
          class: "flex-1 rounded-r-none border-r-0",
          "model-value": _ctx.modelValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = (val) => _ctx.$emit('update:model-value', val)),
          "has-error": _ctx.errors && _ctx.errors.length > 0,
          type: _ctx.hidden ? 'password' : 'text'
        }, null, 8, ["model-value", "has-error", "type"]),
        _createElementVNode("button", {
          class: "btn btn-sm btn-secondary rounded-l-none px-4 text-lg",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hidden = !_ctx.hidden))
        }, [
          (_ctx.hidden)
            ? (_openBlock(), _createElementBlock("i", _hoisted_2))
            : (_openBlock(), _createElementBlock("i", _hoisted_3))
        ])
      ]),
      (_ctx.visibleStrength)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(["w-full h-1 rounded-lg bg-gray-300 mr-2", {
          'bg-red-400': _ctx.strength === 1,
          'bg-yellow-400': _ctx.strength === 2,
          'bg-blue-400': _ctx.strength === 3,
          'bg-green-400': _ctx.strength === 4,
        }])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(["w-full h-1 rounded-lg bg-gray-300 mr-2", {
          'bg-red-400': _ctx.strength === 1,
          'bg-yellow-400': _ctx.strength === 2,
          'bg-blue-400': _ctx.strength === 3,
          'bg-green-400': _ctx.strength === 4,
        }])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(["w-full h-1 rounded-lg bg-gray-300 mr-2", {
          'bg-yellow-400': _ctx.strength === 2,
          'bg-blue-400': _ctx.strength === 3,
          'bg-green-400': _ctx.strength === 4,
        }])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(["w-full h-1 rounded-lg bg-gray-300 mr-2", {
          'bg-blue-400': _ctx.strength === 3,
          'bg-green-400': _ctx.strength === 4,
        }])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass(["w-full h-1 rounded-lg bg-gray-300", {
          'bg-green-400': _ctx.strength === 4,
        }])
            }, null, 2)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["validation-errors"]))
}