import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "select",
  ref: "selectRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_select = _resolveDirective("select")!

  return _withDirectives((_openBlock(), _createElementBlock("select", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ])), [
    [_directive_select, { props: _ctx.props, emit: _ctx.emit }]
  ])
}