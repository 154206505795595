import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";
import { OrderFormInterface } from "@/services";
import { findIndex, get } from "lodash";

// Getters types
export type Getters = {
  forms(
    state: LocalState,
    getters: Getters,
    rootState: RootState,
    rootGetters: any
  ): OrderFormInterface[];
  form(state: LocalState): (id: any, field?: any) => any;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  forms: (state, getters, rootState) => {
    let merchantFormIds =
      get(rootState.auth.profile, "merchant.form_ids", "") || "";
    merchantFormIds = merchantFormIds.split(",").filter((o: any) => !!o);

    return state.forms.filter((form) => {
      return (
        merchantFormIds.length === 0 ||
        merchantFormIds.indexOf(form.id.toString()) > -1 ||
        form.is_fixed * 1 !== 0
      );
    });
  },
  form:
    (state) =>
    (id, field: any = null) => {
      const index = findIndex(state.forms, { id: id * 1 });
      if (field) return get(state.forms, `${index}.${field}`);
      return get(state.forms, `${index}`);
    },
};
