
import { defineComponent, onMounted } from "vue";
import { useFetchRecords } from "@/utils/records/fetch";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Tippy from "@/components/tippy/Main.vue";
import { useFilterRecords } from "@/utils/records/filter";
import Pagination from "@/components/pagination/Main.vue";
import FilterContainer from "@/components/filter-container/Main.vue";
import { findIndex } from "lodash";
import VueInputContainer from "@/components/form-elements/input-container/Main.vue";
import VueInput from "@/components/form-elements/input/Main.vue";
import { useRouter } from "vue-router";
import { useDeleteRecord } from "@/utils/records/delete";
import { contentService } from "@/services";
import VueSelect from "@/components/form-elements/select/Main.vue";
import VueSelect2 from "@/components/form-elements/select2/Main.vue";
import { Select2Options } from "@/components/form-elements/select2";
import FilterButton from "@/components/filter-container/Button.vue";

export default defineComponent({
  name: "ContentsList",
  components: {
    FilterButton,
    VueSelect2,
    VueSelect,
    VueInput,
    VueInputContainer,
    FilterContainer,
    Pagination,
    Tippy,
    TransactionStatement,
  },
  setup() {
    const router = useRouter();

    const { loading, list, fetch } = useFetchRecords({
      selectionQuery: {
        source: "content",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "title",
            "seo_title",
            "type",
            "status",
            "updated_at",
          ],
          "@order": "id:desc",
          "@func": ["withCount"],
        },
        filterFormat: {
          q: "@where.q",
          id: "@where.id",
          title: "@where._title",
          seo_title: "@where._seo_title",
          header_slug: "@where.header_slug",
          type: "@where.type",
          status: "@where.status",
        },
      },
    });

    const { filterState, filterCount, filterFunctions } = useFilterRecords({
      fetch,
      fields: {
        id: "",
        title: "",
        seo_title: "",
        header_slug: "",
        type: "",
        status: "",
      },
    });

    const { deleteState, triggerDelete, removeDeleteError } = useDeleteRecord({
      serviceMethod: contentService.delete,
      success(id: any) {
        const index = findIndex(list.data, { id });
        if (index > -1) list.data.splice(index, 1);
      },
    });

    const goToEdit = (id: any) => {
      router.push({ name: "contents.edit", params: { id } });
    };

    const parentContentSelect2Options: Select2Options = {
      ajaxQuery: {
        content: {
          "@select": {
            value: "header_slug",
            text: "header_title",
          },
          "@where": { type: ["3"] },
          "@whereNot": { parent: null },
          "@group": ["parent"],
          "@page": 1,
        },
      },
    };

    onMounted(() => {
      fetch(filterFunctions.formattedQuery());
    });

    return {
      loading,
      list,
      filterState,
      filterFunctions,
      deleteState,
      parentContentSelect2Options,
      filterCount,
      triggerDelete,
      removeDeleteError,
      goToEdit,
    };
  },
});
